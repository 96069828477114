import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { callApi } from "../hooks/useapi";
import Layout from "../components/common/Layout";
import { changeOrderType, clearCartCount, setCartProducts, setMenuPopUpShow, setPopMenuOffer, setProductDetails } from "../store/actions/actions";
import { confirm } from "../components/common/ConfirmDialog";

function Offers() {
    const kitchen_id = useSelector((state) => state.root.kitchen_id);
    const [offerData, setOfferData] = useState([]);
    const [discountData, setDiscountData] = useState([]);
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const reOrder = useSelector((state) => state.root.reOrder);
    const items = useSelector((state) => state.root.items);

    useEffect(() => {
        callApi(`user/offer-list?kitchen_id=${kitchen_id}`, "").then((response) => {
            if (response.status === true) {
                setOfferData(response.data);
            }
        });
        callApi("user/dashboard-data-kitchen-id/" + kitchen_id, "")
            .then((response) => {
                let discountMenu = response.data.discount_product.filter((pro) => pro.type === "discount" || pro.type === 'menu')
                setDiscountMenu(discountMenu, response.data.product_list)

            })
            .catch((err) => console.log(err));
    }, []);

    const setDiscountMenu = (disMenu, product_list) => {
        let products = [];
        disMenu &&
            disMenu.map((menu) => {
                const index = product_list.findIndex((pro) => pro.id === menu.product_id);
                if (index > -1) {
                    const date = moment().format("YYYY-MM-DD");
                    const start_from = moment(menu.start_from).format("YYYY-MM-DD");
                    const end_date = moment(menu.end_date).format("YYYY-MM-DD");
                    if (menu?.percentage > 0 && start_from <= date && date <= end_date) {
                        products.push({
                            ...menu,
                            name: product_list[index].name,
                        });
                    }
                }
            });

        setDiscountData(products);
    }
    const handleOpenPopUp = async (productId) => {

        callApi(`user/product-details/${productId}`, "").then(async (response) => {
            if (response.status === true) {
                console.log(response.data.product_list)
                // if (is_available_membership == "1" && isPremiumMember === false) {
                //     sendErrorInfo(
                //         "You have to purchase premium membership plan to purchase this order"
                //     );
                //     navigate("/membership");
                //     return;
                // }
                await dispatch(
                    setCartProducts({
                        selected_size: undefined,
                        selected_topings: {},
                        topings_by_size: [],
                        product_detail: undefined,
                        qty: 1,
                        price: 0,
                        discount: 0,
                        update_index: -1,
                        selectedExtra: {},
                    })
                );
                await dispatch(setProductDetails({}));
                await dispatch(setMenuPopUpShow(true));
                await ClickProductDetails(response.data.product_list, response.data.product_list.id);

            }
        });

    };

    const ClickProductDetails = async (item, id) => {
        if (item?.product_discount) {
            if (
                moment(item?.product_discount?.end_date).format() >= moment().format()
            ) {
                dispatch(
                    setPopMenuOffer({
                        end_date: item?.product_discount?.end_date,
                        offer_type: item?.product_discount?.offer_type,
                        value: item?.product_discount?.percentage,
                    })
                );
            }
        }

        let sizes = item.product_item_list.filter((val) => val.is_size == "1");
        let variant = item.product_item_list.filter((val) => val.is_size == "0");
        if (reOrder == "true" && items.length > 0) {
            if (
                await confirm(
                    "You have some product(s) in your cart. If you will change your kitchen or change product, your product(s) will be deleted. Are you sure you want to remove cart product(s)"
                )
            ) {
                dispatch(clearCartCount());
                localStorage.removeItem(
                    process.env.REACT_APP_BUSINESS + "productsInCart"
                );
                dispatch(changeOrderType(false));
            } else {
                return;
            }
        }

        dispatch(setCartProducts({ product_detail: item, price: 0 }));
        dispatch(setProductDetails({ ...item, sizes, variant }));
        dispatch(changeOrderType(false));
        navigate("/home");
    };
    return (
        <Layout pageClass={"OffersPage"}>
            {/*----- Page title and discription section start -----*/}

            <section className="Pagetitledisc m-0">
                <div className="container-fluid py-3">
                    <div className="row">
                        <div className="col-lg-12 col-12">
                            <div className="d-lg-flex justify-content-between">
                                <div>
                                    {/*----- Section title -----*/}
                                    <h1 className="default_title">Offers</h1>
                                    {/*----- Section Sub title -----*/}
                                    <p className="default_subtitle mb-0">
                                        Looking for great product with some het exiting offers
                                    </p>
                                </div>
                                {/*----- breadcrumb -----*/}
                                <nav className="align-self-end mt-lg-0 mt-4">
                                    <ol className="breadcrumb mb-0">
                                        <li className="breadcrumb-item">
                                            <Link to={"/home"}>Home</Link>
                                        </li>
                                        <li className="breadcrumb-item active">
                                            <p className="d-inline-block fw-bold text-muted">
                                                Offers
                                            </p>
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/*----- Page title and discription section end -----*/}


            <section className="offerCardpart">
                <div className="container">
                    <div className="row justify-content-start gx-5">
                        {
                            discountData.length === 0 && offerData.length === 0 && <>
                                <img
                                    className="noDataImg"
                                    src={process.env.PUBLIC_URL + "/assets/img/NodataIMG.png"}
                                    alt=""
                                />
                                <h1 className="text-danger fw-bold text-center mt-5">
                                    No offers found
                                </h1>
                                <p className="text-danger fw-bold text-center">
                                    Store offers will show here!
                                </p>
                            </>
                        }
                        {offerData &&
                            offerData.map((item, index) => {
                                return (
                                    <div className="col-lg-3 col-12" key={index}>
                                        <div className="card rounded">
                                            <div className="card-body p-4">
                                                <img src={item?.image} className="offerCardpartIMG rounded" alt=" " />

                                                <h1 class="fw-bold text-capitalize">Great Offer</h1>

                                                <p className="text-success fw-bold">
                                                    use
                                                    {" "}
                                                    <span onClick={() => { navigator.clipboard.writeText(item.promo_code); }}>
                                                        {" "}
                                                        {item?.promo_code}{" "}
                                                    </span>

                                                    {" "}

                                                    and get

                                                    {item?.percentage}

                                                    % off

                                                    <p className="card-text hvr-fade">
                                                        End on
                                                        {moment(item?.expire_by).format("DD-MMM")}
                                                    </p>
                                                </p>


                                                <Link to={"/home"} className="btn btn-primary px-4 py-2  fs-3">Order Now</Link>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}

                        {discountData &&
                            discountData.length > 0 &&
                            discountData.map((item, index) => {
                                return (
                                    <div className="col-lg-3 col-12" key={index}>
                                        <div className="card rounded">
                                            <div className="card-body p-4">
                                                <img src={item?.image} className="offerCardpartIMG rounded" alt="" />

                                                <h1 class="fw-bold text-capitalize">Great Offer</h1>

                                                <h2 className="text-capitalize">{item.name}</h2>

                                                <p className="text-success fw-bold">
                                                    GET {item.percentage} % OFF End on {moment(item?.end_date).format("DD-MMM")}
                                                </p>
                                                {item.type === 'menu' && <span className="offer_tag Prodsmallcard">Tpday's Special</span>}
                                                <a href="javascript:void(0)" onClick={() => handleOpenPopUp(item.product_id)} className="btn btn-primary px-4 py-2  fs-3 ">Order Now</a>
                                            </div>
                                        </div>
                                    </div>
                                );

                            })
                        }
                    </div>
                </div>
            </section>
        </Layout>
    );
}

export default Offers;
