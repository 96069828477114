import moment from "moment";
import { Modal, ModalHeader, ModalBody, Collapse } from "reactstrap";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  setCartProducts,
  setMenuPopUpShow,
  setPopMenuOffer,
} from "../../../store/actions/actions";
import { addToCart } from "../../../store/actions/cartActions";
import {
  getToken,
  percentageAmount,
  sendErrorInfo,
} from "../../../utility/helper";
import { AppConst } from "../../../AppConst";
import { checkItemInStock } from "../../../utility/cartHelpers";
import InventoryStatus from "./InventoryStatus";
const MenuPopUp = (props) => {
  const [AddOnItems, setAddOnItems] = useState([]);
  const menu_popup = useSelector((state) => state.root.menu_popup);
  const Product_details = useSelector((state) => state.root.Product_details);
  const cartProducts = useSelector((state) => state.root.cartProducts);
  const menuOffer = useSelector((state) => state.root.menuOffer);
  const cart_items = useSelector((state) => state.cart.items);
  const kitchen_data = useSelector((state) => state.root.kitchen_data);
  const kitchen_id = useSelector((state) => state.root.kitchen_id);
  const selectedKitchn = kitchen_data.find((item) => item.id == kitchen_id);
  const [size, setSize] = useState(true);
  const [variant, setVariant] = useState(false);
  const [addOne, setAddOne] = useState(false);
  const [inStock, setInstock] = useState(true);
  const [itemExisted, setItemExisted] = useState(false)
  const toggleSize = (event) => {
    event.stopPropagation();
    setSize(!size);
  };
  const toggleVariant = () => setVariant(!variant);
  const toggleAddOne = () => setAddOne(!addOne);
  const [counter, setCounter] = useState(1);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {

    const tooltipTriggerList = [].slice.call(
      document.querySelectorAll('[data-toggle="tooltip"]')
    );
    tooltipTriggerList.map((tooltipTriggerEl) => {
      return new window.bootstrap.Tooltip(tooltipTriggerEl);
    });
    if (Object.keys(Product_details).length > 0) {
      if (selectedKitchn?.kitchen_info?.stock_onoff == '1') {
        setInstock(checkItemInStock(Product_details));
      }
    }
  }, [Product_details]);
  useEffect(() => {
    if (menu_popup === false) {
      setAddOnItems([]);
    }
  }, [menu_popup]);

  function unCheckCheckBox() {
    var x = document.getElementsByClassName("variant_checkbox");
    for (let i = 0; i <= x.length; i++) {
      if (x[i] && x[i].checked) {
        x[i].checked = false;
      }
    }
  }
  function unCheckCheckBoxById(id) {
    var x = document.getElementById(id);
    if (x && x.checked) {
      x.checked = false;
    }
  }
  const handleProductAddChange = (
    item,
    product_discount,
    item_price,
    min_item,
    max_item,
    stock
  ) => {
    // setItemExisted(false)
    unCheckCheckBox();

    if (item.id == cartProducts.selected_size) {

      dispatch(
        setCartProducts({
          selected_size: undefined,
          topings_by_size: [],
          selected_topings: {},
          selectedExtra: {},
          discount: 0,
          price: 0,
        })
      );
      return;
    }
    setItemExisted(false)
    // Set Size
    const selected_size = item.id;
    const topings_by_size = item.addons_item;
    let price = item.price;

    let discount = 0;
    if (cartProducts.product_detail.product_discount) {
      if (
        moment(cartProducts?.product_detail.product_discount?.end_date).format(
          "YYYY-MM-DD"
        ) >= moment().format("YYYY-MM-DD")
      ) {
        dispatch(
          setPopMenuOffer({
            offer_type: cartProducts.product_detail.product_discount.offer_type,
            value: cartProducts.product_detail.product_discount.percentage,
            end_date: cartProducts?.product_detail.product_discount?.end_date,
          })
        );
        const per = parseInt(
          cartProducts.product_detail.product_discount.percentage
        );
        if (cartProducts.product_detail.product_discount.offer_type == "flat") {
          discount = parseInt(per);
          price = item.price - parseInt(per);
        } else {
          discount = percentageAmount(item.price, per);
          price = item.price - percentageAmount(item.price, per);
        }
      }
    }
    dispatch(
      setCartProducts({
        ...cartProducts,
        selected_size,
        topings_by_size,
        selected_topings: {},
        discount: discount,
        selectedExtra: {},
        price,
      })
    );
  };

  const updateExtraItems = ({ selectedExtra, price }) => {
    dispatch(
      setCartProducts({
        selectedExtra,
        price,
      })
    );
  };

  const handleProductVariantChange = (
    item,
    selectItem,
    product_discount,
    item_price,
    elementId
  ) => {
    const selectedExtra = { ...cartProducts.selectedExtra };
    let price = parseFloat(cartProducts.price);
    setItemExisted(false)
    if (!selectedExtra[item.id]) {
      // New Item Selected
      selectedExtra[item.id] = [
        {
          id: selectItem.id,
          price:
            item.is_item_free == "false" ? parseFloat(selectItem.price) : 0,
        },
      ];

      if (item.is_item_free == "false") {
        price += parseFloat(selectItem.price);
      }
      updateExtraItems({ selectedExtra, price });
      return;
    }

    // item already exist
    const selected = selectedExtra[item.id];

    const newArr = selected.filter((extra) => {
      if (String(extra.id) == String(selectItem.id)) {
        price -= extra.price;
        return false;
      }
      return true;
    });

    // Remove Existing
    if (newArr.length != selected.length) {
      selectedExtra[item.id] = newArr.length == 0 ? null : newArr;
      updateExtraItems({ selectedExtra, price });
      return;
    }

    // No Limits and Has Limits
    console.log("selected length", selected.length + 1, item.max_item);
    if (item.max_item == 0 || selected.length + 1 <= item.max_item) {
      selectedExtra[item.id].push({
        id: selectItem.id,
        price: item.is_item_free == "false" ? parseFloat(selectItem.price) : 0,
      });

      if (item.is_item_free == "false") {
        price += parseFloat(selectItem.price);
      }
      updateExtraItems({ selectedExtra, price });
    } else {
      unCheckCheckBoxById(elementId);
      sendErrorInfo(`Max ${item.max_item} ${item.title} can be select`);
      return;
    }

    // Radio Button Case
    if (item.max_item == 1) {
      // selectedExtra[item.id] = [
      //   {
      //     id: selectItem.id,
      //     price:
      //       item.is_item_free == "false" ? parseFloat(selectItem.price) : 0,
      //   },
      // ];

      // if (item.is_item_free == "false") {
      //   price += parseFloat(selectItem.price);
      // }
      // updateExtraItems({ selectedExtra, price });
      sendErrorInfo(`Max ${item.max_item} ${item.title} can be select`);
      unCheckCheckBoxById(elementId);
      return;
    }
  };

  const handleProductAddonsChange = (item) => {
    setItemExisted(false)
    if (cartProducts.selected_topings[item.id]) {
      const selected_topings = { ...cartProducts.selected_topings };

      delete selected_topings[item.id];
      dispatch(
        setCartProducts({
          selected_topings,
          price: cartProducts.price - item.price,
        })
      );
      return;
    }
    // Set Topping
    const selected_topings = { ...cartProducts.selected_topings };
    selected_topings[item.id] = item;
    dispatch(
      setCartProducts({
        selected_topings,
        price: cartProducts.price + item.price,
      })
    );
  };

  const handleAddmore = (type) => {
    const selectedVariant = cartProducts.product_detail.product_variant.find(
      (item) => item.id === cartProducts.selected_size
    );
    if (!selectedVariant) {
      return
    }
    if (type == "add") {

      if (
        selectedKitchn?.kitchen_info?.stock_onoff === '1' &&
        selectedVariant?.total_inventory &&
        selectedVariant?.total_inventory[0]?.quantity_total < counter + 1
      ) {
        return sendErrorInfo(
          `Product units can not be greater than ${selectedVariant?.total_inventory &&
          selectedVariant?.total_inventory[0]?.quantity_total
          }`
        );
      }
      let countdata = counter + 1;
      setCounter(countdata);
      cartProducts.qty = countdata;
      dispatch(setCartProducts(cartProducts));
    } else {
      let countdata = counter - 1;
      if (countdata > 0) {
        setCounter(countdata);
        cartProducts.qty = countdata;
      }
    }
  };

  const onClickConfirmPlaceOrder = () => {
    // let data = {
    //   product_id: 0,
    //   offerPercentage: 0,
    //   openFoodModal: false,
    //   outlet_id: 0,
    // };
    // store.dispatch(addOfferModalData(data));
    if (!getToken()) {
      navigate("/login");
      return sendErrorInfo("Please login first");
    }
    if (selectedKitchn?.kitchen_info?.stock_onoff === '1' && inStock === false) {
      return sendErrorInfo("Product is out of stock");
    }
    if (cartProducts.selected_size === undefined) {
      return sendErrorInfo("Please select an item's variant");
    }
    if (validateExtra() === false) {
      return;
    }

    if (cartProducts.price <= 0) {
      sendErrorInfo("Please select a variant size first");

      return;
    }
    if (checkIsItemExist()) {
      sendErrorInfo("This item varient already added in cart");

      return;
    }

    dispatch(addToCart(createCartObject()));
    // dispatch(setMenuPopUpShow(false));
    // navigate("/checkout");

    setItemExisted(true)
    setAddOnItems([]);
  };

  const onClickContinous = (e) => {
    // let data = {
    //   product_id: 0,
    //   offerPercentage: 0,
    //   openFoodModal: false,
    //   outlet_id: 0,
    // };
    // store.dispatch(addOfferModalData(data));
    if (!getToken()) {
      navigate("/login");
      return sendErrorInfo("Please login first");
    }
    if (selectedKitchn?.kitchen_info?.stock_onoff === '1' && inStock === false) {
      return sendErrorInfo("Product is out of stock");
    }
    if (cartProducts.selected_size === undefined) {
      return sendErrorInfo("Please select an item's variant");
    }
    if (!validateExtra()) {
      return;
    }

    if (cartProducts.price <= 0) {
      sendErrorInfo("Please select a variant size first");

      return;
    }
    if (checkIsItemExist()) {
      sendErrorInfo("This item varient already added in cart");

      return;
    }

    dispatch(addToCart(createCartObject()));
    dispatch(setMenuPopUpShow(false));
    setCounter(1);
    setAddOnItems([]);
  };

  const checkIsItemExist = () => {
    const added_items =
      cart_items &&
      cart_items.length > 0 &&
      cart_items.filter((item) => {
        if (item?.product_detail?.id == cartProducts?.product_detail?.id) {
          if (cartProducts.selected_size == item.selected_size) {
            if (
              JSON.stringify(cartProducts.selected_topings) ==
              JSON.stringify(item.selected_topings)
            )
              return true;
          }
        }

        return false;
      });
    if (added_items.length > 0) {
      return true;
    }
    return false;
  };

  const validateExtra = () => {
    let variantIndex = cartProducts.product_detail.product_item_list.findIndex(
      (item) => item.is_size == "0"
    );

    if (
      cartProducts.product_detail.product_item_list &&
      cartProducts.product_detail.product_item_list.length > 1
    ) {
      let product = cartProducts.product_detail.product_item_list;
      const selectedItemVar =
        cartProducts.selectedExtra[product[variantIndex].id] || [];
      const index = product.findIndex(
        (item) => item.id == Object.keys(cartProducts.selectedExtra)[0]
      );

      if (index > -1) {
        const selectedItems =
          cartProducts.selectedExtra[product[index].id] || [];
        if (selectedItems.length < product[index].min_item) {
          sendErrorInfo(
            `Please Select minimum of ${product[index].min_item} variant`
          );

          return false;
        }
        if (selectedItems.length > product[index].max_item) {
          sendErrorInfo(
            `Please Select maximum of ${product[index].max_item} variant`
          );

          return false;
        }

        return true;
      } else if (product[variantIndex].min_item > 0) {
        sendErrorInfo(`Please Select variant`);

        return false;
      } else if (product[variantIndex].min_item < selectedItemVar) {
        sendErrorInfo(`Please Select variant`);

        return false;
      }
    } else {
      if (cartProducts.selected_size === undefined) {
        sendErrorInfo(`Please select a size first`);

        return false;
      } else {
        return true;
      }
    }
    return true;
  };
  const createCartObject = () => {
    const obj = { ...cartProducts.product_detail };
    obj["quantity"] = cartProducts.qty;
    obj["product_detail"] = cartProducts.product_detail;
    obj["selected_size"] = cartProducts.selected_size;
    obj["selected_topings"] = cartProducts.selected_topings;
    obj["selected_topping_obj"] = getSizeById();
    obj["selected_extras"] = cartProducts.selectedExtra;
    obj["product_price"] = cartProducts.price;
    obj["back_up_price"] = cartProducts.price;
    obj["discounted_amount"] = cartProducts.discount;
    obj["discount_start_date"] = cartProducts.product_detail.product_discount
      ? cartProducts.product_detail.product_discount.start_from
      : undefined;
    obj["discount_end_date"] = cartProducts.product_detail.product_discount
      ? cartProducts.product_detail.product_discount.end_date
      : undefined;
    return obj;
  };
  const getSizeById = () => {
    return cartProducts.product_detail.product_variant.filter(
      (item) => item.id == cartProducts.selected_size
    )[0];
  };
  const toggleModal = () => {
    setCounter(1);
    dispatch(setPopMenuOffer({}));
    dispatch(setMenuPopUpShow(!menu_popup));
  };

  return (
    <Modal
      className="modal-dialog-centered modal-dialog-scrollable AddtocardProductModal"
      isOpen={menu_popup}
      toggle={toggleModal}
    >
      <ModalHeader className="d-block border-0 p-0 position-relative">
        <div class="productviewslider">
          <img src={Product_details?.product_image} alt="" />
        </div>

        <button
          type="button"
          className="btn-close customClose"
          onClick={() => {
            setCounter(1);
            dispatch(setMenuPopUpShow(false));
          }}
        >
          <i className="fa-solid fa-xmark" />
        </button>


      </ModalHeader>
      {console.log("Product_details--------90------", Product_details, cart_items)}
      <div className="modal-body">
        <div className="row">
          <div className="col-lg-9 col-12">
            <h1 className="fs-1 text-primary fw-bold mb-lg-4 mb-2">
              {Product_details?.name}
            </h1>


          </div>

          <div className="col-lg-3 col-12 text-lg-end">
            <p className="fw-bold text-primary  mb-lg-4 mb-3 fs-2">
              {" "}
              {AppConst.getBussInfo.currency}
              {(cartProducts.price * counter).toFixed(2)}
            </p>
          </div>

          <div className="col-lg-12">
            <p className="mb-lg-4 mb-2 text-muted pb-3 fs-3 fw-600">
              {Product_details?.product_desc}
            </p>
          </div>

          <div className="col-lg-12 col-12">
            <ul className="somethingextra list-unstyled">
              <li
                onClick={(e) => toggleSize(e)}
                className="d-block extraheading d-flex justify-content-between"
              >
                <h1 className="fw-bold text-primary">
                  {Product_details?.sizes && Product_details?.sizes[0].title}
                </h1>
                {console.log("Product_details?.sizes", Product_details?.sizes)}
                {size === false ? <ArrowDown /> : <ArrowUp />}
              </li>
              {size === true && (
                <small>
                  {Product_details?.sizes &&
                    Product_details?.sizes[0]?.choose_free_item}
                </small>
              )}

              <Collapse isOpen={size}>
                {Product_details &&
                  Product_details?.product_item_list &&
                  Product_details?.product_item_list.map((item, index) => {
                    if (item.is_size === "1") {



                      return item.product_item.map((size, i) => (
                        <li key={i}>
                          {console.log("cartProducts.selected_size", item.id == cartProducts.selected_size)}
                          <div className="form-check position-relative">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              name="Anyone"
                              id={"check-variant" + size.id + index + i}
                              checked={size.id == cartProducts.selected_size}
                              disabled={
                                selectedKitchn?.kitchen_info?.stock_onoff === '1' && inStock === false
                                  ? "disabled"
                                  : selectedKitchn?.kitchen_info?.stock_onoff === '1' && size?.total_inventory &&
                                    size?.total_inventory[0]?.quantity_total <=
                                    0
                                    ? "disabled"
                                    : ""
                              }
                              onChange={() => {
                                handleProductAddChange(
                                  size,
                                  size?.product_discount,
                                  size.price,
                                  item.min_item,
                                  item.max_item,
                                  size?.total_inventory[0]?.quantity_total
                                );
                                setAddOnItems(
                                  size?.addons_item &&
                                  size?.addons_item.filter(
                                    (add) => add.variant_id === size.id
                                  )
                                );
                              }}
                            />
                            <div className="d-flex align-items-center justify-content-start gap-2">
                              <label
                                className="form-check-label text-muted"
                                htmlFor={"check-variant" + size.id + index + i}
                              >
                                {size.size}{" "}

                              </label>
                              {size?.description && (
                                <InfoIcon title={size?.description} />
                              )}
                            </div>
                            <InventoryStatus
                              total_inventory={size?.total_inventory}
                              min_stock={selectedKitchn?.min_stock}
                              stock_onoff={
                                selectedKitchn?.kitchen_info?.stock_onoff
                              }
                              inStock={inStock}
                            />
                          </div>
                          <p className="fw-bold text-primary mb-0 fs-2">
                            {" "}
                            {AppConst.getBussInfo.currency}
                            {size.price}
                          </p>
                        </li>
                      ));
                    }
                  })}
              </Collapse>
            </ul>
          </div>

          {Product_details &&
            Product_details?.product_item_list &&
            Product_details?.product_item_list
              .filter((item) => item.is_size === "0")
              .map((item_variant, idx) => (
                <div className="col-lg-12 col-12" key={idx}>
                  <ul className="somethingextra list-unstyled">
                    <li
                      className="d-block extraheading d-flex justify-content-between"
                      onClick={() => toggleVariant()}
                    >
                      <div>
                        <h1 className="fw-bold text-primary ">
                          {item_variant?.title}
                        </h1>
                      </div>
                      {variant === false ? <ArrowDown /> : <ArrowUp />}
                    </li>
                    {variant === true && (
                      <small>{item_variant?.choose_free_item}</small>
                    )}

                    <Collapse isOpen={variant}>
                      {item_variant?.product_item &&
                        item_variant?.product_item.length > 0 &&
                        item_variant?.product_item
                          .filter((item) => item.is_size === "0")
                          .map((size, i) => {
                            return (
                              <>
                                <li key={i}>
                                  <div className="form-check position-relative">
                                    <input
                                      className="form-check-input variant_checkbox variant_checks"
                                      type="checkbox"
                                      id={"check-extra" + size.id + idx + i}
                                      name="variant"
                                      disabled={
                                        selectedKitchn?.kitchen_info?.stock_onoff === '1' && inStock === false
                                          ? "disabled"
                                          : selectedKitchn?.kitchen_info?.stock_onoff === '1' && size?.total_inventory &&
                                            size?.total_inventory[0]
                                              ?.quantity_total <= 0
                                            ? "disabled"
                                            : ""
                                      }
                                      onChange={() => {
                                        handleProductVariantChange(
                                          item_variant,
                                          size,
                                          null,
                                          null,
                                          "check-extra" + size.id + idx + i
                                        );
                                        // setAddOnItems(size?.addons_item && size?.addons_item.filter((add) => add.variant_id === size.id));
                                      }}
                                    />

                                    <div className="d-flex align-items-center justify-content-start gap-2">
                                      <label
                                        className="form-check-label text-muted"
                                        htmlFor={
                                          "check-extra" + size.id + idx + i
                                        }
                                      >
                                        {size.size}{" "}

                                      </label>
                                      {size?.description && (
                                        <InfoIcon title={size?.description} />
                                      )}
                                    </div>
                                    <InventoryStatus
                                      total_inventory={size?.total_inventory}
                                      min_stock={selectedKitchn?.min_stock}
                                      stock_onoff={
                                        selectedKitchn?.kitchen_info
                                          ?.stock_onoff
                                      }
                                      inStock={inStock}
                                    />
                                  </div>
                                  <p className="fw-bold text-primary mb-0 fs-2">
                                    {" "}
                                    {size.price ? (
                                      <>
                                        {AppConst.getBussInfo.currency}
                                        {size.price}
                                      </>
                                    ) : (
                                      "No Charge"
                                    )}
                                  </p>
                                </li>
                              </>
                            );
                          })}
                    </Collapse>
                  </ul>
                </div>
              ))}
          {AddOnItems.length > 0 && (
            <div className="col-lg-12 col-12">
              <ul className="somethingextra list-unstyled">
                <li
                  onClick={() => toggleAddOne()}
                  className="d-block extraheading d-flex justify-content-between"
                >
                  <h1 className="fw-bold text-primary">Add</h1>

                  {addOne === false ? <ArrowDown /> : <ArrowUp />}
                </li>
                <Collapse isOpen={addOne}>
                  {AddOnItems &&
                    AddOnItems.map((Addons, index) => {
                      return (
                        <li>
                          <div className="form-check position-relative">
                            <input
                              className="form-check-input d-none variant_checkbox"
                              type="checkbox"
                              name={"check-addons-" + Addons.id}
                              onChange={(e) => {
                                handleProductAddonsChange(Addons);
                              }}
                              id={"check-addons-" + Addons.id}
                            />
                            <label
                              className="form-check-label text-muted"
                              htmlFor={"check-addons-" + Addons.id}
                            >
                              {Addons.addon_name}
                            </label>
                          </div>
                          <p className="fw-bold text-primary mb-0 fs-2">
                            {" "}
                            {AppConst.getBussInfo.currency}
                            {Addons.price}
                          </p>
                        </li>
                      );
                    })}
                </Collapse>
              </ul>
            </div>
          )}
        </div>
      </div>

      <div className="modal-footer flex-column border-0 px-lg-5 px-3 mx-0 row justify-content-around align-items-center pt-4 pb-5">
        <div className="col-lg-12 col-12 mb-4 px-0">
          <div className="producttotal d-flex justify-content-between align-items-center">
            <p className="amount fw-bold text-primary mb-0 fs-2">
              Total item:
              <span className="ms-4">
                {" "}
                <span>{AppConst.getBussInfo.currency}</span>{" "}
                {(cartProducts.price * counter).toFixed(2)}
              </span>
            </p>

            <div className="quantity">
              <div className="d-flex counter align-items-center justify-content-center">
                <button type="button" onClick={(e) => handleAddmore("remove")}>
                  -
                </button>
                <p className="mb-0 text-primary fw-bold fs-3">{counter}</p>
                <button type="button" onClick={(e) => handleAddmore("add")}>
                  +
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-12 col-12 px-0 d-flex justify-content-between align-items-center">
          <a
            href="#!"
            onClick={(e) => toggleModal()}
            className="btn btn-outline-secondary d-block w-100 fw-bold fs-2 py-3 me-3"
            data-bs-dismiss="modal"
          >
            Continue shopping
          </a>
          <a
            href="#!"
            onClick={(e) => { if (itemExisted === true && checkIsItemExist()) { dispatch(setMenuPopUpShow(false)); navigate("/checkout") } else { onClickConfirmPlaceOrder() } }}
            className="btn btn-success d-block w-100 fw-bold fs-2 py-3 ms-3"
            data-bs-dismiss="modal"
          >
            {itemExisted === true ? 'Go to cart' : 'Add to cart'}
          </a>
        </div>
      </div>
    </Modal>
  );
};

export default MenuPopUp;

const ArrowUp = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512">
      <path d="M201.4 137.4c12.5-12.5 32.8-12.5 45.3 0l160 160c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L224 205.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l160-160z" />
    </svg>
  );
};

const ArrowDown = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512">
      <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
    </svg>
  );
};

const InfoIcon = ({ title }) => {
  return (
    <svg
      data-toggle="tooltip"
      data-placement="right"
      title={title}
      xmlns="http://www.w3.org/2000/svg"
      height="1em"
      viewBox="0 0 512 512"
    >
      <style dangerouslySetInnerHTML={{ __html: "svg{fill:#52A834}" }} />
      <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM216 336h24V272H216c-13.3 0-24-10.7-24-24s10.7-24 24-24h48c13.3 0 24 10.7 24 24v88h8c13.3 0 24 10.7 24 24s-10.7 24-24 24H216c-13.3 0-24-10.7-24-24s10.7-24 24-24zm40-208a32 32 0 1 1 0 64 32 32 0 1 1 0-64z" />
    </svg>
  );
};
