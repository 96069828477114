import React, { useEffect, useRef, useState } from "react";
import { callApi } from "../../../hooks/useapi";
import moment from "moment";
import { Modal, ModalBody, ModalHeader, NavLink } from "reactstrap";
import { generateRandomString, sendSuccessInfo } from "../../../utility/helper";
import PaymentForm from "../cart/PaymentForm";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { AppConst } from "../../../AppConst";
import { useDispatch, useSelector } from "react-redux";
import { generateTransectionKey } from "../../../store/actions/actions";

const cancelReasons = [
  "Too expensive",
  "Changing membership to another store",
  "Already have a membership at another store",
  "No longer want to use the app",
  "other",
];
const ManageMembership = ({ activetedTab }) => {
  const dispatch = useDispatch();
  const [membershipData, setMembershipData] = useState([]);
  const [cancelModal, setCancelModal] = useState(false);
  const [kitchen_id, setkitchenId] = useState(null);
  const [membershipModal, setMembershipModal] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [plans, setPlans] = useState([]);
  const navigate = useNavigate();
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [formData, setFormData] = useState({
    reason: "",
    error: "",
    expDate: "",
  });
  const location = useLocation();
  const paymentCardRef = useRef(null);
  const transection_key = useSelector((state) => state.root.transection_key);
  useEffect(() => {
    if (transection_key === "") {
      dispatch(generateTransectionKey(generateRandomString(15)));
    }
    if (
      searchParams.get("tid") === transection_key &&
      searchParams.get("amt") &&
      activetedTab === "9"
    ) {
      buyMembershipplan();
    }

    getMemberShip();
  }, []);
  const getMemberShip = () => {
    callApi(
      `user/mermbership-store-by-bussiness/${process.env.REACT_APP_BUSS_ID}`,
      { method: "GET" }
    ).then((response) => {
      if (response.status === true) {
        let arr = [];
        for (let i = 0; i < response.data.kitchen.length; i++) {
          const element = response.data.kitchen[i];

          element.member_ship_plan_by_store = checkBuyPlan(
            element.member_ship_plan_by_store
          );

          arr.push(element);
        }
        setMembershipData(arr);
      }
    });
  };

  const getMembershipPlans = (kitchen_id) => {
    callApi(
      `user/mermbership-package/${process.env.REACT_APP_BUSS_ID}/${kitchen_id}`,
      {
        method: "GET",
      }
    ).then((response) => {
      if (response.status === true) {
        setPlans(response.data);
        setSelectedPlan(response.data[0]);
      }
    });
  };

  const checkBuyPlan = (member_ship_plan_by_store) => {
    const currentDate = new Date();
    let newData = [];
    member_ship_plan_by_store.map((plan) => {
      const validMemberShips = plan.member_ship_buy.filter((memberShip) => {
        const purchaseDate = new Date(memberShip.purchase_date);
        const expiredDate = new Date(memberShip.expired_date);

        return purchaseDate <= currentDate && currentDate <= expiredDate;
      });

      return (newData = [
        {
          ...plan,
          member_ship_buy: validMemberShips,
        },
      ]);
    });
    return newData;
  };
  const onSubmit = (e) => {
    e.preventDefault();
    setConfirmationModal(!confirmationModal);
  };

  const handleCancelMemberShip = (e) => {
    e.preventDefault();
    let request = {
      outlet_id: kitchen_id,
      reason: formData.reason,
    };
    callApi("user/mermbership-cancel", { method: "POST", data: request }).then(
      (response) => {
        if (response.status === true) {
          sendSuccessInfo("Membership Cancelled Successfully.");
          setFormData({ reason: "", error: "", expDate: "" });
          setCancelModal(false);
          setConfirmationModal(!confirmationModal);
          getMemberShip();
        } else {
          console.log(response);
        }
      }
    );
  };
  const buyMembershipplan = () => {
    let postData = {
      amount: searchParams.get("amt"),
      transaction_id: searchParams.get("tid"),
      package_id: searchParams.get("pkg"),
    };
    callApi("user/mermbership-package-buy", {
      method: "POST",
      data: postData,
    }).then((response) => {
      if (response.status === true) {
        navigate("/profile/membership");
        sendSuccessInfo(response.message);
        dispatch(generateTransectionKey(""));

        dispatch(generateTransectionKey(generateRandomString(15)));
        getMemberShip();
      } else {
        sendSuccessInfo(response.error.error);
      }
    });
  };
  return (
    <div className="col-lg-12 col-12">
      <div className="table-responsive">
        <table className="table table-borderless mb-0 table-striped">
          <thead>
            <tr>
              <th className="px-5 py-4">
                <p className="mb-0">Store</p>
              </th>

              <th className="px-5 py-4">
                <p className="mb-0">Package</p>
              </th>

              <th className="px-5 py-4">
                <p className="mb-0">Price</p>
              </th>

              <th className="px-5 py-4">
                <p className="mb-0">Expires On</p>
              </th>

              <th className="px-5 py-4">
                <p className="mb-0">Action</p>
              </th>
            </tr>
          </thead>

          <tbody className="">
            {membershipData &&
              membershipData.length > 0 &&
              membershipData
                .filter((kitchen) => kitchen.status === "0" && kitchen.is_dark_mode === 0)
                .map((item, index) => (
                  <tr key={index}>
                    <td className="px-5 py-4">
                      <p className="mb-0">{item.name}</p>
                    </td>

                    <td className="px-5 py-4">
                      <p className="mb-0">
                        {" "}
                        {item?.member_ship_plan_by_store.length > 0
                          ? item.member_ship_plan_by_store[0].package
                          : "NA"}
                      </p>
                    </td>

                    <td className="px-5 py-4">
                      <p className="mb-0">
                        {item?.member_ship_plan_by_store.length > 0
                          ? item.member_ship_plan_by_store[0].price
                          : "NA"}
                      </p>
                    </td>

                    <td className="px-5 py-4">
                      <p className="mb-0">
                        {" "}
                        {item?.member_ship_plan_by_store.length > 0
                          ? moment(
                            item.member_ship_plan_by_store[0]
                              .member_ship_buy[0]?.expired_date
                          ).format("MMM DD, YYYY")
                          : "NA"}
                      </p>
                    </td>

                    <td className="px-5">
                      {item?.member_ship_plan_by_store.length > 0 ? (
                        item.member_ship_plan_by_store[0].member_ship_buy[0]?.expired_date && (
                          <button
                            onClick={() => {
                              setkitchenId(item.id);
                              setCancelModal(true);
                              setFormData({
                                ...formData,
                                expDate: moment(
                                  item.member_ship_plan_by_store[0]
                                    .member_ship_buy[0]?.expired_date
                                ).format("MMM DD, YYYY"),
                              });
                            }}
                            className="btn btn-outline-danger fs-3 px-4 py-2 w-100  rounded my-3"
                          >
                            Cancel Membership
                          </button>
                        )
                      ) : (
                        <button
                          onClick={() => {
                            getMembershipPlans(item.id);
                            setkitchenId(item.id);
                            setMembershipModal(true);
                          }}
                          className="btn btn-success fs-3 px-4 py-2 rounded w-100 my-3"
                        >
                          Buy Membership
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
          </tbody>
        </table>
      </div>

      <Modal
        isOpen={cancelModal}
        toggle={() => setCancelModal(false)}
        size="lg"
        className="modal-dialog modal-dialog-centered modal-dialog-scrollable AddtocardProductModal CancelOrderModal"
      >
        <ModalHeader className="d-block border-0 p-0 position-relative">
          Do You Want to cancel this mebership
          <button
            onClick={() => {
              setFormData({ error: "", reason: "", expDate: "" });
              setCancelModal(false);
            }}
            type="button"
            className="btn-close customClose"
          >
            <i className="fa-solid fa-xmark" />
          </button>
        </ModalHeader>

        <ModalBody>
          <form onSubmit={onSubmit} className="row">
            {cancelReasons.map((item, i) => (
              <div className="col-lg-12 col-12 mb-2">
                <div className="form-check position-relative">
                  <input
                    className="form-check-input"
                    type="radio"
                    onChange={(e) =>
                      setFormData({ ...formData, reason: e.target.value })
                    }
                    value={item}
                    name="cancel_reason"
                    id={"cause-1" + i}
                  />
                  <label className="form-check-label" htmlFor={"cause-1" + i}>
                    {item}
                  </label>
                </div>
              </div>
            ))}

            <div className="col-lg-5 col-sm-5 col-12 mx-lg-0 mx-auto">
              <button
                type="submit"
                disabled={formData.reason == ""}
                className="btn btn-danger d-block w-100 fs-3 py-2 my-4"
              >
                Cancel Membership
              </button>
            </div>
          </form>
        </ModalBody>
      </Modal>

      <Modal
        centered
        className="onrefreshMembership_Modal modal-dialog-scrollable "
        isOpen={membershipModal}
        toggle={() => setMembershipModal(!membershipModal)}
      >
        <ModalHeader>
          {" "}
          <h1 className="text-primary fw-bold text-uppercase text-center mb-3">
            Become a premium member today &amp; Get months worths of benefits
          </h1>
          <p className="mb-0 text-primary text-lg-start text-center fs-2 fw-bold">
            Be a part of our growing network of thousand of payments
          </p>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            onClick={() => setMembershipModal(!membershipModal)}
          />
        </ModalHeader>

        <div className="modal-body">
          {/*----- Membership plans -----*/}
          <div className="Membershipplan_container">
            <div className="Membershiptitleanddetails d-flex justify-content-between align-items-center mb-4">
              <h3 className="mb-0 text-primary fw-600">
                Choose a membership plan
                <i className="fa-solid fa-star ms-1 text-warning" />
              </h3>
              {/* <h3 className="mb-0">
                <NavLink to={"/membership"} className="fw-500 text-success">
                  Know more about plan
                </NavLink>
              </h3> */}
            </div>
            {plans.length == 0 && (
              <>
                <img
                  className="noDataImg"
                  src={process.env.PUBLIC_URL + "/assets/img/NodataIMG.png"}
                  alt=""
                />
                <h1 className="text-danger fw-bold text-center mt-5">
                  No address found
                </h1>
                <p className="text-danger fw-bold text-center">
                  Your saved addresses will show here!
                </p>
              </>
            )}
            <div className="MembershipplanList">
              {/*----- Plan 1 -----*/}
              {plans &&
                plans.length > 0 &&
                plans.map((item, i) => (
                  <>
                    <input
                      type="radio"
                      className="d-none"
                      id={item.id}
                      name="MembershipPlan"
                      onClick={() => setSelectedPlan(item)}
                      defaultChecked={item.id === selectedPlan.id}
                    />
                    <label htmlFor={item.id} className="mb-4">
                      <p className="mb-0 text-primary fw-600 fs-2">
                        {item?.package}
                      </p>

                      <span className="mamAmount">R{item?.price}/ Month</span>
                    </label>
                  </>
                ))}
            </div>
          </div>
        </div>

        <div className="modal-footer border-0">
          <div className="w-100">
            <button
              class="btn btn-primary d-block w-100 fw-bold fs-2 py-4"
              onClick={() => paymentCardRef.current.click()}
            >
              Continue with {AppConst.getBussInfo.currency}
              {selectedPlan?.price} / <span class="fw-normal">Month</span>
              <i class="fa-solid fa-arrow-right ms-2 align-middle"></i>
            </button>
            <div className="d-none">
              <PaymentForm
                returnUrl={`${location.pathname}?tid=${transection_key}&amt=${selectedPlan?.price}&pkg=${selectedPlan?.id}`}
                totalPrice={selectedPlan?.price}
                uniqeId={transection_key}
                buttonTitle={`Continue with R ${selectedPlan?.price} / Month`}
                ref={paymentCardRef}
              />
            </div>
          </div>

          <button
            onClick={() => setMembershipModal(!membershipModal)}
            className="btn btn-outline-primary d-block w-100 fs-2 py-4 mt-3 guestloginbtn"
          >
            I'll do it later
          </button>
        </div>
      </Modal>
      <Modal
        isOpen={confirmationModal}
        toggle={() => setConfirmationModal(!confirmationModal)}
        size="lg"
        className="modal-dialog modal-dialog-centered modal-dialog-scrollable AddtocardProductModal CancelOrderModal"
      >
        <ModalHeader className="d-block border-0 p-0 position-relative">
          We are sorry to see you go!
          <button
            onClick={() => {
              setConfirmationModal(!confirmationModal);
            }}
            type="button"
            className="btn-close customClose"
          >
            <i className="fa-solid fa-xmark" />
          </button>
        </ModalHeader>

        <ModalBody>
          <div className="row">
            <p className="text-center">
              Your monthly subscription is paid until {formData.expDate} if you
              would like cenceling you subscription. please select "Cancel
              Membership" below
            </p>
            <div class="border border-danger p-4 text-center">
              <i
                class="fas fa-info-circle text-danger mb-2"
                style={{ "font-size": "28px" }}
              ></i>
              <p class="text-danger mb-3">
                Are you sure ? After {formData.expDate} You will no longer be
                able to order membership products
              </p>
            </div>
            <div className="col-lg-5 col-sm-5 col-12 mx-lg-0 mx-auto">
              <button
                type="button"
                onClick={() => {
                  setFormData({ reason: "", error: "" });
                  setCancelModal(false);
                  setConfirmationModal(false);
                }}
                className="btn btn-outline-primary d-block w-100 fs-3 py-2 my-4"
              >
                undo
              </button>
            </div>
            <div className="col-lg-5 col-sm-5 col-12 mx-lg-0 mx-auto">
              <button
                type="button"
                onClick={(e) => handleCancelMemberShip(e)}
                className="btn btn-danger d-block w-100 fs-3 py-2 my-4"
              >
                Cancel Membership
              </button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default ManageMembership;
