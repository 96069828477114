import React, { useEffect, useState } from "react";
import { callApi } from "../../../hooks/useapi";
import moment from "moment";

const NotificationList = ({ getNotificationNo = () => {} }) => {
  const [notificationData, setNotificationData] = useState([]);
  useEffect(() => {
    getNotification();
  }, []);
  const getNotification = () => {
    callApi(`user/notification-list/`, { method: "GET" }).then((response) => {
      if (response.status === true) {
        console.log("Notification data", response.data);
        setNotificationData(response.data);
        getNotificationNo(response.data.length || 0);
        //    setMembershipData(response.data.kitchen);
      }
    });
  };
  return (
    <div className="container mt-4">
      <h2 className="fw-bold text-primary">Notification List</h2>

      <ul className="list-unstyled mt-5">
        {notificationData.length === 0 && (
          <li>
            <p className="text-center text-danger fw-bold">
              No Notification Available
            </p>
          </li>
        )}
        {notificationData &&
          notificationData.length > 0 &&
          notificationData
            .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
            .map((notification) => (
              <li key={notification.id} className="list-group-item mb-3">
                <h3 className="text-primary fw-bold trackoffOTP">
                  {notification.title}
                </h3>
                <p>{notification.message}</p>
                <small>
                  Date: {moment(notification.created_at).format("YYYY-MM-DD")}
                </small>
                <hr />
              </li>
            ))}
      </ul>
    </div>
  );
};

export default NotificationList;
