import io from "socket.io-client";
const port = process.env.REACT_APP_PUBLIC_BASE_URL;
export const socket = io(port);
export const events = {
  error: "error",
  connect_error: "connect_error",
  connect: "connect",
  driverPackageTracing: "driverPackageTracing",
  OrderStatusChange: "OrderStatusChange",
};

export const getSocketConnect = (response, reject) => {
  console.log("socket----", socket?.id);
  if (socket.connected) {
    console.log("sokcet already exist ---->");
    response && response(socket);
  } else {
    socket.connect();
    socket.on("error", (error) => {
      console.log("--error--:");
    });
    socket.on("connect_error", (error) => {
      console.log("--connect_error--:");
    });
    socket.on("connect", () => {
      // ServiceConstent.setSocketConnection(socket);
      console.log("connected:", socket.id);
      response && response(socket);
    });
  }
};

//Event register class

class EventRegister {
  static _Listeners = {
    count: 0,
    refs: {},
  };

  static addEventListener(eventName, callback) {
    if (typeof eventName === "string" && typeof callback === "function") {
      EventRegister._Listeners.count++;
      const eventId = "l" + EventRegister._Listeners.count;
      EventRegister._Listeners.refs[eventId] = {
        name: eventName,
        callback,
      };
      return eventId;
    }
    return false;
  }

  static removeEventListener(id) {
    if (typeof id === "string") {
      return delete EventRegister._Listeners.refs[id];
    }
    return false;
  }

  static removeAllListeners() {
    let removeError = false;
    Object.keys(EventRegister._Listeners.refs).forEach((_id) => {
      const removed = delete EventRegister._Listeners.refs[_id];
      removeError = !removeError ? !removed : removeError;
    });
    return !removeError;
  }

  static emitEvent(eventName, data) {
    Object.keys(EventRegister._Listeners.refs).forEach((_id) => {
      if (
        EventRegister._Listeners.refs[_id] &&
        eventName === EventRegister._Listeners.refs[_id].name
      ) {
        EventRegister._Listeners.refs[_id].callback(data);
      } else {
      }
    });
  }

  /*
   * shortener
   */
  static on(eventName, callback) {
    return EventRegister.addEventListener(eventName, callback);
  }

  static rm(eventName) {
    return EventRegister.removeEventListener(eventName);
  }

  static rmAll() {
    return EventRegister.removeAllListeners();
  }

  static emit(eventName, data) {
    EventRegister.emitEvent(eventName, data);
  }
}

export { EventRegister };

//socket class
export class Socket {
  static mySocket = null;
  static getMySocket() {
    return this.mySocket;
  }
  static setMySocket(mySocket) {
    this.mySocket = mySocket;
  }
  static connected() {
    if (this.getMySocket()) {
      return this.getMySocket().connected;
    }
    return false;
  }
  static disConnect() {
    if (this.getMySocket()) {
      return this.getMySocket().disconnect();
    }
    return false;
  }
}
