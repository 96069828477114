import { getToken } from "../utility/helper";

export const callApi = async (url, options) => {
  // dispatch(setLoading(true));
  const token = getToken();
  try {
    const raw = await fetch(
      process.env.REACT_APP_PUBLIC_BASE_URL + "api/v1/" + url,
      {
        headers: {
          ...(token &&
            token !== undefined && { Authorization: `Bearer ${token}` }),
          "Content-Type": "application/json",
        },
        method: options?.method || "GET",
        body: options?.data ? JSON.stringify(options?.data) : undefined,
      }
    );

    const data = await raw.json();

    if (!raw.ok) throw new Error(data.message);
    return data;
  } catch (error) {
    return error;
  } finally {
    // dispatch(setLoading(false))
  }
};

export const POST_DATA_HEADEERS = {
  "Content-Type": "application/json",
  Authorization: "Bearer " + getToken(),
};
