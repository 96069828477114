import React from "react";

const MyWishlist = () => {
  return (
    <>
      {" "}
      <ul className="nav nav-tabs border-0" id="myTab" role="tablist">
        <li className="nav-item">
          <button
            className="nav-link active border-0"
            data-bs-toggle="tab"
            data-bs-target="#Wishstore"
            type="button"
          >
            Store
          </button>
        </li>
        <li className="nav-item">
          <button
            className="nav-link border-0"
            data-bs-toggle="tab"
            data-bs-target="#Wishproduct"
            type="button"
          >
            Product
          </button>
        </li>
      </ul>
      <div className="tab-content py-5">
        <div className="tab-pane fade show active" id="Wishstore">
          <div className="d-lg-flex flex-wrap justify-content-between position-relative">
            {/*----- Store card -----*/}
            <div className="card storecard wishListITem border-0 mb-lg-5 mb-4">
              <a href="#!" className="stretched-link" />
              <img
                src={process.env.PUBLIC_URL + "/assets/img/nearby1.png"}
                className="w-100 h-100 storeimg"
                alt=""
              />
              <div className="card-img-overlay d-flex flex-column justify-content-around">
                <div>
                  <h1 className="storetitle">Emazulwini Store</h1>
                  <p className="nearbydistance text-warning d-inline-block">
                    10m away from you
                  </p>
                </div>
                <div className="d-flex justify-content-between align-items-center">
                  <span className="bannerlabel text-xl-start text-lg-center text-center">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/img/bannerdelivery.png"
                      }
                      alt=""
                    />
                    Delivery
                  </span>
                  <span className="bannerlabel mx-xl-4 mx-lg-0 mx-4 text-xl-start text-lg-center text-center">
                    <img
                      src={
                        process.env.PUBLIC_URL + "/assets/img/bannerpickup.png"
                      }
                      alt=""
                    />
                    Pickup
                  </span>
                  <span className="bannerlabel text-xl-start text-lg-center text-center">
                    <img
                      src={
                        process.env.PUBLIC_URL + "/assets/img/bannercurior.png"
                      }
                      alt=""
                    />
                    Courier
                  </span>
                </div>
              </div>
            </div>
            {/*----- Store card -----*/}
            <div className="card storecard wishListITem border-0 mb-lg-5 mb-4">
              <a href="#!" className="stretched-link" />
              <img
                src={process.env.PUBLIC_URL + "/assets/img/nearby2.png"}
                className="w-100 h-100 storeimg"
                alt=""
              />
              <div className="card-img-overlay d-flex flex-column justify-content-around">
                <div>
                  <h1 className="storetitle">Mdu's African Product Delight</h1>
                  <p className="nearbydistance text-warning d-inline-block">
                    15m away from you
                  </p>
                </div>
                <div className="d-flex justify-content-between align-items-center">
                  <span className="bannerlabel text-xl-start text-lg-center text-center">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/img/bannerdelivery.png"
                      }
                      alt=""
                    />
                    Delivery
                  </span>
                  <span className="bannerlabel mx-xl-4 mx-lg-0 mx-4 text-xl-start text-lg-center text-center">
                    <img
                      src={
                        process.env.PUBLIC_URL + "/assets/img/bannerpickup.png"
                      }
                      alt=""
                    />
                    Pickup
                  </span>
                  <span className="bannerlabel text-xl-start text-lg-center text-center">
                    <img
                      src={
                        process.env.PUBLIC_URL + "/assets/img/bannercurior.png"
                      }
                      alt=""
                    />
                    Courier
                  </span>
                </div>
              </div>
            </div>
            {/*----- Store card -----*/}
            <div className="card storecard wishListITem border-0 mb-lg-5 mb-4">
              <a href="#!" className="stretched-link" />
              <img
                src={process.env.PUBLIC_URL + "/assets/img/nearby3.png"}
                className="w-100 h-100 storeimg"
                alt=""
              />
              <div className="card-img-overlay d-flex flex-column justify-content-around">
                <div>
                  <h1 className="storetitle">Ko Lapeng African Cuisine</h1>
                  <p className="nearbydistance text-warning d-inline-block">
                    10m away from you
                  </p>
                </div>
                <div className="d-flex justify-content-between align-items-center">
                  <span className="bannerlabel text-xl-start text-lg-center text-center">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/img/bannerdelivery.png"
                      }
                      alt=""
                    />
                    Delivery
                  </span>
                  <span className="bannerlabel mx-xl-4 mx-lg-0 mx-4 text-xl-start text-lg-center text-center">
                    <img
                      src={
                        process.env.PUBLIC_URL + "/assets/img/bannerpickup.png"
                      }
                      alt=""
                    />
                    Pickup
                  </span>
                  <span className="bannerlabel text-xl-start text-lg-center text-center">
                    <img
                      src={
                        process.env.PUBLIC_URL + "/assets/img/bannercurior.png"
                      }
                      alt=""
                    />
                    Courier
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="tab-pane fade" id="Wishproduct">
          <div className="row">
            <div className="col-xxl-4 col-xl-5 col-lg-6 col-12 mb-4">
              <div className="card tabproductcard wishListITem">
                <div className="card-body p-3">
                  <div className="row mx-0">
                    <div className="col-lg-4 col-12 ps-lg-0">
                      <img
                        src={
                          process.env.PUBLIC_URL + "/assets/img/catfood1.png"
                        }
                        className="CardProductIMG"
                        alt=""
                      />
                    </div>
                    <div className="col-lg-8 col-12 pe-lg-0 ">
                      <h2 className="fw-bold twolinetruncate">
                        Couch Potato Burger
                      </h2>

                      <p className="twolinetruncate text-secondary">
                        A Lazy Couch Potato Burger loaded with In House Sauce
                        and OnionsA Lazy Couch Potato Burger loaded with In
                        House Sauce and Onions,
                      </p>

                      <div className="d-flex justify-content-between align-items-center">
                        <p className="mb-0 fw-bold fs-3">R70</p>
                        <div className="fs-3">
                          <svg
                            className="svg-inline--fa fa-star text-warning me-1"
                            aria-hidden="true"
                            focusable="false"
                            data-prefix="fas"
                            data-icon="star"
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 576 512"
                            data-fa-i2svg
                          >
                            <path
                              fill="currentColor"
                              d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"
                            ></path>
                          </svg>
                          {/* <i class="fa-solid fa-star text-warning me-1"></i> Font Awesome fontawesome.com */}
                          <span className="d-inline-block me-3">4.7</span>
                          <a
                            href="#"
                            className="text-decoration-none text-success px-4 rounded-3 bg-primary-subtle fw-600 bg-light d-inline-block"
                          >
                            Add
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MyWishlist;
