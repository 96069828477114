import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  changeOrderType,
  clearCartCount,
  setCartProducts,
  setMenuPopUpShow,
  setPopMenuOffer,
  setProductDetails,
} from "../../../store/actions/actions";
import { AppConst } from "../../../AppConst";
import moment from "moment";
import { confirm } from "../ConfirmDialog";
import { useNavigate } from "react-router-dom";
import { sendErrorInfo } from "../../../utility/helper";
const MenuCard = ({
  indexPage,
  product,
  product_image,
  product_desc,
  name,
  price,
  percentage,
  end_date,
  offer_type,
  menuProduct,
  is_available_membership,
  isPremiumMember,
  isItemInStock,
  stock_onoff,
  openMembershipPopup = () => { }
}) => {
  const dispatch = useDispatch();
  const menu_popup = useSelector((state) => state.root.menu_popup);
  const reOrder = useSelector((state) => state.root.reOrder);
  const items = useSelector((state) => state.root.items);

  const navigate = useNavigate();
  const handleOpenPopUp = async () => {
    if (is_available_membership == "1" && isPremiumMember === false) {
      sendErrorInfo(
        "You have to purchase premium membership plan to purchase this order"
      );
      navigate("/membership");
      return;
    }
    await dispatch(
      setCartProducts({
        selected_size: undefined,
        selected_topings: {},
        topings_by_size: [],
        product_detail: undefined,
        qty: 1,
        price: 0,
        discount: 0,
        update_index: -1,
        selectedExtra: {},
      })
    );
    await dispatch(setProductDetails({}));
    await dispatch(setMenuPopUpShow(true));
    await ClickProductDetails(product, product.id);
  };
  const ClickProductDetails = async (item, id) => {
    if (item?.product_discount) {
      if (
        moment(item?.product_discount?.end_date).format() >= moment().format()
      ) {
        dispatch(
          setPopMenuOffer({
            end_date: item?.product_discount?.end_date,
            offer_type: item?.product_discount?.offer_type,
            value: item?.product_discount?.percentage,
          })
        );
      }
    }

    let sizes = item.product_item_list.filter((val) => val.is_size == "1");
    let variant = item.product_item_list.filter((val) => val.is_size == "0");
    if (reOrder == "true" && items.length > 0) {
      if (
        await confirm(
          "You have some product(s) in your cart. If you will change your kitchen or change product, your product(s) will be deleted. Are you sure you want to remove cart product(s)"
        )
      ) {
        dispatch(clearCartCount());
        localStorage.removeItem(
          process.env.REACT_APP_BUSINESS + "productsInCart"
        );
        dispatch(changeOrderType(false));
      } else {
        return;
      }
    }

    dispatch(setCartProducts({ product_detail: item, price: 0 }));
    dispatch(setProductDetails({ ...item, sizes, variant }));
    dispatch(changeOrderType(false));
  };
  if (menuProduct === true) {
    return (
      <div className="col-xl-3 col-lg-4 col-12 mb-4">
        <div className={isItemInStock === false && stock_onoff == '1' ? "card tabproductcard outofstock" : "card tabproductcard"}>
          <div className="card-body">
            <div className="row mx-0">
              <div className="col-lg-4 col-12 ps-lg-0 position-relative">
                <div className="imgContainer">
                  <img src={product_image} className="CardProductIMG" alt="" />
                </div>
                {is_available_membership == "1" && (
                  <img src="./assets/img/prime.png" className="membershipLogo__1" />

                )}
              </div>

              <div className="col-lg-8 col-12 pe-lg-0">
                <h2 className="fw-bold twolinetruncate px-lg-0 px-4 pt-lg-0 pt-3 ">
                  {name}
                </h2>

                <p className="twolinetruncate text-secondary px-lg-0 px-4 py-lg-0 py-1">
                  {product_desc}
                </p>

                <div className="d-flex justify-content-between align-items-center px-lg-0 px-4 pb-lg-0 pb-3">
                  <p className="mb-0 fw-bold fs-3">
                    {" "}
                    {AppConst.getBussInfo.currency}
                    {price}
                  </p>
                  {/* {isItemInStock === false && stock_onoff == '1' && (
                    <p className="badge bg-danger mb-0 fw-bold fs-4 rounded-5">Out of Stock</p>
                  )} */}
                  <div className="fs-3">
                    {percentage > 0 &&
                      end_date >= moment().format("YYYY-MM-DD") && (
                        <span className="offer_tag Prodsmallcard">
                          {offer_type === "flat"
                            ? AppConst.getBussInfo.currency + percentage
                            : percentage + "%"}{" "}
                          OFF
                        </span>
                      )}
                    {
                      is_available_membership == "1" && isPremiumMember === false ? <button
                        onClick={() => openMembershipPopup()}
                        className="text-decoration-none text-success px-4 rounded-3 bg-primary-subtle fw-600 bg-light d-inline-block border-0 fs-4"
                      >
                        Buy Membership
                      </button> : <button
                        onClick={() => handleOpenPopUp()}
                        className="text-decoration-none text-success px-4 rounded-3 bg-primary-subtle fw-600 bg-light d-inline-block border-0"
                      >
                        Add
                      </button>
                    }


                    {isItemInStock === false && stock_onoff == '1' && <button className="Outofstockaddcard" onClick={() => handleOpenPopUp()}></button>}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="card sliderproduct border-0">
        <div className="card-body p-0">
          <img src={product_image} className="productimg" alt="" />

          <p className="productname text-truncate w-75 fs-3 text-primary fw-600">
            {name}
          </p>

          <div className="d-flex justify-content-between align-items-center">
            <p className="mb-0 fw-bold fs-2">
              {" "}
              {AppConst.getBussInfo.currency}
              {price}
            </p>
            {isItemInStock === false && stock_onoff == '1' && (
              <p className="badge bg-danger mb-0 fw-bold fs-4 rounded-5">Out of Stock</p>
            )}

            {is_available_membership == "1" && (
              <img src="./assets/img/prime.png" className="membershipLogo" />
              // <span className="MembershipProd">Only Membership</span>
            )}
            {
              is_available_membership == "1" && isPremiumMember === false ?
                <button
                  onClick={() => openMembershipPopup()}
                  className="btn fs-2 fw-600"
                >

                  {percentage > 0 && end_date >= moment().format("YYYY-MM-DD") && (
                    <span className="offer_tag ">
                      {offer_type === "flat"
                        ? AppConst.getBussInfo.currency + percentage
                        : percentage + "%"}{" "}
                      OFF
                    </span>
                  )}
                  Buy Membership
                </button> :
                <button
                  onClick={() => handleOpenPopUp()}
                  className="btn fs-2 fw-600"
                >

                  {percentage > 0 && end_date >= moment().format("YYYY-MM-DD") && (
                    <span className="offer_tag ">
                      {offer_type === "flat"
                        ? AppConst.getBussInfo.currency + percentage
                        : percentage + "%"}{" "}
                      OFF
                    </span>
                  )}
                  <i className="fa-solid fa-cart-shopping me-2" />
                  Add to cart
                </button>
            }

          </div>
        </div>
      </div>
    );
  }
};

export default MenuCard;
