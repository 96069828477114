import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setUserLocation } from "../store/actions/actions";
import { getToken, sendErrorInfo } from "../utility/helper";
import { useNavigate } from "react-router-dom";
import AutoCompleteInput from "../components/common/AutoCompleteInput";
const Index = () => {
  const [location, setLocation] = useState("");
  const user_data = useSelector(state => state.root.user_data)
  // Function to handle successful location retrieval
  const showPosition = (position) => {
    const latitude = position.coords.latitude;
    const longitude = position.coords.longitude;

    getLocationByLatLng(latitude, longitude);
  };

  // Function to handle location retrieval error
  const showError = (error) => {
    switch (error.code) {
      case error.PERMISSION_DENIED:
        alert("User denied the request for Geolocation.");
        break;
      case error.POSITION_UNAVAILABLE:
        alert("Location information is unavailable.");
        break;
      case error.TIMEOUT:
        alert("The request to get user location timed out.");
        break;
      case error.UNKNOWN_ERROR:
        alert("An unknown error occurred.");
        break;
      default:
        alert("Please try again latter");
    }
  };

  // Function to request the user's location
  const getLocation = () => {
    if (navigator.geolocation) {
      // Requesting the user's location
      navigator.geolocation.getCurrentPosition(showPosition, showError);
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  };
  const navigate = useNavigate();

  const dispatch = useDispatch();

  // get address lat long
  const getLatlong = async (place) => {
    const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;
    const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
      place
    )}&key=${apiKey}`;

    try {
      const result = await fetch(url);
      const response = await result.json();
      const { results } = response;

      if (results.length > 0) {
        const { geometry } = results[0];
        const { location } = geometry;
        const { lat, lng } = location;
        let saveLoc = {
          address_type: "house",
          apartment: "",
          lat: lat,
          lng: lng,
          address: place,
        };

        dispatch(setUserLocation(saveLoc));
        setLocation(place);
        // setCookies(process.env.REACT_APP_BUSINESS + "lat", lat);
        // setCookies(process.env.REACT_APP_BUSINESS + "lng", lng);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const getLocationByLatLng = (latitude, longitude) => {
    let url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`;
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "OK") {
          const locationInfo = data.results[0].formatted_address;
          let saveLoc = {
            address_type: "house",
            apartment: "",
            lat: latitude,
            lng: longitude,
            address: locationInfo,
          };

          dispatch(setUserLocation(saveLoc));
          console.log("getToken()", getToken())
          if (getToken()) {
            navigate("/stores");
          } else {
            navigate("/login");
          }
        } else {
          alert("Location not found.");
        }
      })
      .catch((error) => {
        console.error("Error fetching location data:", error);
      });
  };
  return (
    <div className="main-container entrypage location">
      <div className="row mx-0 px-0 justify-content-between h-100">
        <div className="col-lg-6 col-xxl-5 col-xl-5 col-12 px-5">
          <div className="entrylogo">
            <img
              src={process.env.PUBLIC_URL + "/assets/img/GreenTExtlogo.png"}
              className="w-100 h-100"
              alt=""
            />
          </div>

          <h1 className="text-primary fw-600">
            To let the site know where you are?
          </h1>

          <p className="text-muted pe-lg-5 me-lg-5">
            Your current location will be displayed on the dashboard and used to
            show the stores nearby
          </p>

          <form action="#" className="regform mt-5">
            {/*----- select country dropdown input ------*/}
            <div className="position-relative">
              <AutoCompleteInput getLatlong={(e) => getLatlong(e)} />
            </div>
            {/*----- Get current location button -----*/}

            <a
              href="#!"
              onClick={() => getLocation()}
              className="getcurrentlocation text-primary hoverlineeffect"
            >
              <i className="fa-solid fa-location-crosshairs textWebgreen me-2" />
              <p className="d-inline-block position-relative mb-0">
                Get current location
              </p>
            </a>

            {/*----- Submit button -----*/}
            <button
              onClick={() => {
                if (location === "") {
                  return sendErrorInfo("Please select a location ");
                } else if (user_data?.token) {
                  navigate("/stores");
                } else {
                  navigate("/login");
                }
              }}
              className="btn btn-primary d-block w-100 fs-2 py-4">
              Available stores located near you
            </button>
          </form>
        </div>

        <div className="col-lg-6 col-xl-7 col-xxl-7 col-12 px-0 h-100 position-relative d-lg-block d-none">
          <div
            className="entrypage-banner w-100 h-100"
            style={{ backgroundImage: "url(./assets/img/locationbg.png)" }}
          ></div>
          {/*----- Banner layer text -----*/}
          <div className="bannerlayertext">
            <p className="mb-0">
              We are one call away from getting your package delivered to your
              doorstep
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
