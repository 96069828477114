import React from "react";

const InventoryStatus = ({
  inStock,
  total_inventory,
  min_stock,
  stock_onoff,
}) => {
  if (stock_onoff === "0") {
    return <></>;
  } else {
    return (
      <>
        {inStock === false ? (
          <p className="badge bg-danger mb-0 mx-3 rounded-5">Out of stock</p>
        ) : (
          total_inventory.length > 0 &&
          total_inventory &&
          total_inventory[0]?.quantity_total < min_stock && (
            <p
              className={
                total_inventory && total_inventory[0]?.quantity_total > 0
                  ? "text-primary mb-0 fw-bold"
                  : "badge bg-danger mb-0 mx-3 rounded-5"
              }
            >
              {total_inventory && total_inventory[0]?.quantity_total > 0 ? (
                <>
                  {" "}
                  (  Only  {total_inventory && total_inventory[0]?.quantity_total} avilable)
                </>
              ) : (
                "Out of stock"
              )}
            </p>
          )
        )}
      </>
    );
  }
};

export default InventoryStatus;
