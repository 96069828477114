import React from "react";
import Header from "../PageCommons/Header";
import Footer from "../PageCommons/Footer";
import MenuPopUp from "./Menu/MenuPopUp";

const Layout = ({ children, pageClass }) => {
  return (
    <div className={"main-container " + pageClass}>
      <Header />
      {children}
      <Footer />
      <MenuPopUp />
    </div>
  );
};

export default Layout;
