import React, { useState } from "react";

const CartAdditionalAction = ({
  formValues,
  setFormValues,
  submitCalculations = () => { },
  kitchenData,
  delivery_method,
  tipRef,
  grandTotal = 0
}) => {
  const tipPercentages = [0, 10, 15, 20, 25];
  const [selectedTip, setSelectedTip] = useState(0);
  const confirmTip = (e) => {
    e.preventDefault()
    tipRef.current = selectedTip
    setFormValues({ ...formValues, tip: tipRef.current });
    submitCalculations();
  }
  return (
    <>
      {" "}
      {/*----- Add suggetions input -----*/}
      <div className="cartcomments">
        <p className="text-muted my-4 fw-bold fs-3">Add notes</p>
        <textarea
          name="suggestions"
          className="form-control"
          rows={3}
          placeholder="Any suggestions...."
          defaultValue={""}
          value={formValues.additionalNotes}
          onChange={(e) =>
            setFormValues({
              ...formValues,
              additionalNotes: e.target.value,
            })
          }
        />
      </div>
      {/*----- Add tip input -----*/}
      {delivery_method === "Delivery" && (
        <>
          <div className="addtip position-relative">
            <p className="text-muted my-4 fw-bold fs-3">Add tip for driver</p>
            <input
              type="number"
              className="form-control"
              placeholder="Enter tip amount"
              value={tipRef.current}
              min={0}
              onChange={(e) => {
                setFormValues({ ...formValues, tip: 0 });
                tipRef.current = e.target.value;
              }}
            />
            <button
              className="addtipbutton"
              onClick={(e) => {
                e.preventDefault();
                if (tipRef.current <= 0) {
                  return
                }
                setFormValues({ ...formValues, tip: parseFloat(tipRef.current).toFixed(2) });
                submitCalculations();
              }}
            >
              {"Add Tip"}
            </button>
          </div>
          {
            grandTotal > 0 && <>
              <p className="text-muted my-4 fw-bold fs-3">Suggested Tip</p>
              <div className="d-flex justify-content-start">
                {grandTotal > 0 && tipPercentages.map((percentage) => {
                  const per = (percentage * grandTotal) / 100;
                  console.log(per, tipRef.current)
                  return (
                    <div className="suggested-tip" key={percentage}>
                      <input
                        type="radio"
                        id={`tip${percentage}`}
                        name="tipPercentage"
                        value={per}
                        checked={selectedTip == per}
                        onChange={(e) => {
                          setSelectedTip(e.target.value)

                        }}
                      />
                      <label htmlFor={`tip${percentage}`}>{percentage}%
                        <br />
                        {(percentage * grandTotal) / 100}
                      </label>
                    </div>
                  )
                })}

              </div>
              <button className="btn btn-primary w-50 fw-600 fs-2 " onClick={(e) => confirmTip(e)} >Confirm</button>
            </>
          }

        </>
      )}
    </>
  );
};

export default CartAdditionalAction;
