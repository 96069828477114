import React, { useEffect, useState } from "react";
import {
  GoogleMap,
  Marker,
  InfoWindow,
  useJsApiLoader,
  Polyline,
  DirectionsRenderer,
  OverlayView,
} from "@react-google-maps/api";

function MapWithMarker({ locations = [], path }) {
  console.log("location ", locations);
  const [selectedLocation, setSelectedLocation] = React.useState(null);
  const [containerStyle, setContainerStyle] = useState({
    width: "100%",
    height: "100%",
  });
  const center = {
    lat: (locations.length > 0 && locations[0].lat) || 40.712776,
    lng: (locations.length > 0 && locations[0].lng) || -74.005974,
  };
  const handleMarkerClick = (location) => {
    setSelectedLocation(location);
  };

  const handleCloseInfoWindow = () => {
    setSelectedLocation(null);
  };

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
  });
  const [directions, setDirections] = useState(null);
  const [customOriginIcon, setCustomOrdiginIcon] = useState({});

  const [customDestinationIcon, setCustomDestinationIcon] = useState({});

  useEffect(() => {
    //
    if (isLoaded) {
      if (path === true && containerStyle) {
        setContainerStyle({ ...containerStyle, height: "100%" });
      }
      const directionsService = new window.google.maps.DirectionsService();

      directionsService.route(
        {
          origin: locations[0],
          destination: locations[1],
          travelMode: window.google.maps.TravelMode.DRIVING,
        },
        (result, status) => {
          if (status === window.google.maps.DirectionsStatus.OK) {
            setDirections(result);
          }
        }
      );
      setCustomOrdiginIcon({
        url: "https://png.pngtree.com/png-clipart/20191120/original/pngtree-store-icon-in-line-style-png-image_5053711.jpg",
        scaledSize: new window.google.maps.Size(40, 40),
      });
      setCustomDestinationIcon({
        url: "https://png.pngtree.com/png-clipart/20190619/original/pngtree-vector-house-icon-png-image_4013530.jpg",
        scaledSize: new window.google.maps.Size(40, 40),
      });
    }
  }, [isLoaded]);
  if (isLoaded) {
    return (
      <>
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={center}
          zoom={13}
          width={100}
          height={100}
        >
          {path === true ? (
            directions && (
              <>
                <DirectionsRenderer
                  directions={directions}
                  options={{ suppressMarkers: true }}
                />

                {locations.map((location, index) => (
                  <Marker
                    key={index}
                    position={location}
                    onClick={() => handleMarkerClick(location)}
                    options={{
                      icon:
                        index === 0 ? customOriginIcon : customDestinationIcon,
                    }}
                  />
                ))}
              </>
            )
          ) : (
            <>
              {locations.map((location, index) => (
                <Marker
                  key={index}
                  position={{ lat: location.lat, lng: location.lng }}
                  onClick={() => handleMarkerClick(location)}
                />
              ))}
            </>
          )}

          {selectedLocation && (
            <InfoWindow
              position={{
                lat: selectedLocation.lat,
                lng: selectedLocation.lng,
              }}
              onCloseClick={handleCloseInfoWindow}
            >
              <div>
                <h3>{selectedLocation.name}</h3>
                <p>{selectedLocation.description}</p>
              </div>
            </InfoWindow>
          )}
        </GoogleMap>
      </>
    );
  } else {
    return <div>Loading</div>;
  }
}

export default MapWithMarker;
