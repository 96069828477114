import React, { useEffect, useState } from "react";
import UserAddress from "../cart/UserAddress";
import { callApi } from "../../../hooks/useapi";
import { sendSuccessInfo } from "../../../utility/helper";
import { confirm } from "../ConfirmDialog";

const MyAddress = () => {
  const [address, setAddress] = useState([]);
  const [addressList, setAddressList] = useState(true);
  const [editAddress, setEditAddress] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const toggleModal = () => {
    if (showModal === true) {
      getUserAddresses();
    }
    setShowModal(!showModal);
  };
  useEffect(() => {
    getUserAddresses();
  }, []);
  const getUserAddresses = () => {
    callApi("user/address-list", { method: "GET" })
      .then((response) => {
        if (response.data.address.length > 0) {
          setAddress(response.data.address);
        } else {
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const deleteAddress = async (id) => {
    if (await confirm("Are you sure you want to delete this address")) {
      let data = {
        address_id: id,
      };
      callApi("user/delete-address", { method: "POST", data: data })
        .then((response) => {
          if (response.status) {
            if (address.length == 1) {
              setAddress([]);
            }
            sendSuccessInfo("Address Successfully Deleted");
            getUserAddresses();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      return;
    }
  };
  return (
    <>
      {" "}
      <div className="d-lg-flex justify-content-between align-items-center">
        <h1 className="fw-bold">Addresses</h1>
        <button
          onClick={() => {
            setEditAddress([]);
            toggleModal();
          }}
          className="btn btn-primary fs-2 px-5 py-3 mt-lg-0 mt-4 addaddresbtn"
        >
          <i className="fa-solid fa-plus me-2" />
          Add new address
        </button>
      </div>
      <div className="row  mt-3 g-4">
        {address.length == 0 && (
          <>
            <img
              className="noDataImg"
              src={process.env.PUBLIC_URL + "/assets/img/NodataIMG.png"}
              alt=""
            />
            <h1 className="text-danger fw-bold text-center mt-5">
              No address found
            </h1>
            <p className="text-danger fw-bold text-center">
              Your saved addresses will show here!
            </p>
          </>
        )}
        {address.map((add, index) => (
          <div className={index > 2 ? "col-lg-4 col-12" : "col-lg-4 col-12"}>
            <div className="card addresscard border-0 h-100">
              <div className="card-body p-4">
                <h3 className="fw-bold d-flex justify-content-between align-items-center mb-4">
                  {index + 1}
                  {add.is_default === "1" ? (
                    <p className="badge bg-secondary mb-0 rounded-pill">
                      {"Default"}
                    </p>
                  ) : (
                    <p
                      className={
                        add.address_type === "house"
                          ? "badge bg-warning mb-0 rounded-pill"
                          : "badge bg-success mb-0 rounded-pill"
                      }
                    >
                      {add.address_type}
                    </p>
                  )}
                </h3>
                <p className="mb-0 text-muted fw-bold">
                  {add.building_name} {add.address.split(",", 3)} {add.city}{" "}
                  {add.pin_code} {add.state} {add.country}{" "}
                </p>
                <p className="mb-0 fw-bold text-end mt-4">
                  <a
                    href="jacascript:void(0)"
                    onClick={() => {
                      setEditAddress(add);
                      setShowModal(true);
                    }}
                    className="text-success text-decoration-none d-inline-block"
                  >
                    Edit
                  </a>
                  <a
                    href="jacascript:void(0)"
                    onClick={() => deleteAddress(add.id)}
                    className="text-danger text-decoration-none d-inline-block ms-3"
                  >
                    Remove
                  </a>
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
      <UserAddress
        onAddAddress={(value) => setAddressList(value)}
        editAddress={editAddress}
        showModal={showModal}
        setShowModal={(val) => setShowModal(val)}
        toggleModal={toggleModal}
      />
    </>
  );
};

export default MyAddress;
