import React, { useEffect, useState, useCallback } from "react";
import { callApi } from "../../../hooks/useapi";
import moment from "moment";
import ScratchCard from "./ScratchCard";
import { sendSuccessInfo } from "../../../utility/helper";

const ManageCoins = () => {
  const [coinsList, setCoinsList] = useState([]);
  const [coinStatus, setCoinStatus] = useState({
    available: 0,
    used: 0,
    scratched: 0
  });
  const [scratchCoins, setScratchCoins] = useState({
    scratch_code: null,
    user_scratch_status: null
  });

  useEffect(() => {
    fetchCoinHistory();
    fetchCoinsList();
    checkScratchedCoins();
  }, []);

  const fetchCoinHistory = useCallback(() => {
    callApi("user/scratch-vouchers-list")
      .then((response) => {
        if (response.status) {
          setCoinStatus((prevState) => ({
            ...prevState,
            scratched: response.data.total_coin
          }));
        }
      })
      .catch((error) => {
        console.error("Failed to fetch coin history", error);
      });
  }, []);

  const fetchCoinsList = useCallback(() => {
    callApi("user/coin-list")
      .then((response) => {
        if (response.status) {
          const scratchList = response.data.coin_list;
          const usedCoin = scratchList.reduce(
            (acc, item) => (item.coin < 0 ? acc + parseInt(item.coin) : acc),
            0
          );

          setCoinStatus((prevState) => ({
            ...prevState,
            used: usedCoin
          }));

          setCoinsList(
            scratchList.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
          );

          fetchTotalCoins();
        }
      })
      .catch((error) => {
        console.error("Failed to fetch coins list", error);
      });
  }, []);

  const fetchTotalCoins = useCallback(() => {
    callApi("user/total-coins")
      .then((response) => {
        if (response.status) {
          setCoinStatus((prevState) => ({
            ...prevState,
            available: response.data.total_coin
          }));
        }
      })
      .catch((error) => {
        console.error("Failed to fetch total coins", error);
      });
  }, []);

  const checkScratchedCoins = useCallback(() => {
    callApi("user/check-scratched-coins")
      .then((response) => {
        if (response.status) {
          const { scratch_code, user_scratch_status } = response.data;
          setScratchCoins({ scratch_code, user_scratch_status });
        }
      })
      .catch((error) => {
        console.error("Failed to check scratched coins", error);
      });
  }, []);

  const handleScratchedCardOpen = useCallback(() => {
    const data = { coin: scratchCoins.scratch_code };
    callApi("user/add-coins", { method: "post", data })
      .then((response) => {
        if (response.status) {
          const message =
            scratchCoins.scratch_code === 0
              ? "Better luck next time"
              : `Congratulations! You WON ${scratchCoins.scratch_code} Coins`;
          sendSuccessInfo(message);
          fetchCoinsList();
        }
      })
      .catch((error) => {
        console.error("Failed to handle scratched card open", error);
      });
  }, [scratchCoins.scratch_code, fetchCoinsList]);

  return (
    <>
      <div className="row">
        <div className="col-lg-12 col-12">
          <h1 className="fw-bold mb-5">My coins</h1>
        </div>
        <div className="col-lg-5 col-12">
          <div className="card Mycoinsdetils border-0 h-100 flex-row align-items-center">
            <div className="card-body p-5">
              <div className="d-flex flex-wrap align-items-center">
                <img src={process.env.PUBLIC_URL + "/assets/img/Mycoins.png"} alt="My Coins" />
                <p className="mb-0 fw-bold">
                  Available coins balance
                  <br />
                  <span className="fw-bold text-success">R {coinStatus.available}</span>
                </p>
                <hr className="w-100 my-4" />
              </div>
              <div className="d-flex flex-wrap justify-content-around align-items-center">
                <p className="mb-0 fw-bold">
                  Used coins
                  <br />
                  <span className="text-muted">R {coinStatus.used}</span>
                </p>
                <p className="mb-0 fw-bold">
                  Scratched Coin
                  <br />
                  <span className="text-muted">R {coinStatus.scratched}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-12">
          <div className="card border-0 h-100 p-5">
            <div className="card-body p-5">
              <i className="fa-solid fa-circle-info text-danger fs-2" />
              <p className="mb-0 fs-2 fw-bold text-muted">
                Coins are valid for 1 year from the date they are created on.
              </p>
            </div>
          </div>
        </div>

        {scratchCoins.user_scratch_status === "false" && (
          <div className="col-lg-12 col-12">
            <h1 className="my-5 fw-bold">Available scratch cards</h1>
            <div className="scratchCardContainer mb-4">
              <ScratchCard
                message={
                  scratchCoins.scratch_code === 0
                    ? "Better luck next time"
                    : `Congratulations! You WON! ${scratchCoins.scratch_code} Coins`
                }
                triggerApi={handleScratchedCardOpen}
              />
            </div>
          </div>
        )}

        <div className="col-lg-12 col-12">
          <div className="table-responsive transcationHistory">
            <table className="table table-borderless mb-0">
              <thead>
                <tr>
                  <th className="px-5 py-4">
                    <p className="mb-0 text-muted">RECENT COIN ACTIVITY</p>
                  </th>
                  <th className="px-5 py-4">
                    <p className="mb-0 text-muted">
                      <i className="fa-solid fa-coins text-warning me-2" />
                      Coins
                    </p>
                  </th>
                </tr>
              </thead>
              <tbody>
                {coinsList.length > 0 ? (
                  coinsList.map((coin, index) => (
                    <tr key={index}>
                      <td className="px-5 pt-4 pb-0">
                        <p className="mb-0 fw-bold fs-3">
                          Earned {coin.coin} coins from order #{coin.order_id}
                          <br />
                          <small className="text-muted">
                            Credited on {moment(coin.created_at).format("DD-MM-YYYY")}
                          </small>
                        </p>
                      </td>
                      <td className="px-5 py-4">
                        <p className="mb-0 text-success fw-bold">+ {coin.coin}</p>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={5} className="text-center">
                      <img src={process.env.PUBLIC_URL + "/assets/img/NodataIMG.png"} className="noDataImg" alt="No Data" />
                      <p className="text-center text-muted fw-bold fs-3 mb-5 pb-5">
                        No Transaction History For You!
                      </p>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default ManageCoins;
