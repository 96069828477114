import React, { useRef, useState } from "react";
import { AppConst } from "../../../AppConst";
import { Modal, ModalBody, ModalHeader, Form } from "reactstrap";
import moment from "moment";
import { callApi } from "../../../hooks/useapi";
import { sendErrorInfo, sendSuccessInfo } from "../../../utility/helper";
import ChatModel from "./chat/ChatModel";

const CurrentOrderListCard = ({
  orderImg,
  orderFood,
  kitchenName,
  kitchenAddress,
  quantity,
  size,
  order_extra,
  orderAddress,
  sendOrderId,
  btnTitle3,
  orderNo,
  showModal,
  orderTime,
  orderOtp,
  orderItem,
  totalPaid,
  btnTitle,
  btnTitle2,
  orderStatus,
  onClick,
  fullOrder = [],
  deliveryBoyInfo,
  Orderitems,
  pre_order,
  pre_full_date_time,
  pre_date,
  pre_time,
  is_self,
  callOrder = () => { },
}) => {
  const [modal, setModal] = useState(false);

  const toggleModal = () => setModal(!modal);

  const [chatModel, setChatModel] = useState(false);
  const toggleChatModel = () => setChatModel(!chatModel);
  const getOrderStatusClass = (status) => {
    switch (status) {
      case '1':
        return 'text-warning ms-2'
      case '0':
        return 'text-danger ms-2'
      default:
        return 'text-success ms-2'
    }
  }
  return (
    <>
      <div className="card storelistcard border-0 mb-4">
        <div className="card-body py-4">
          <div className="row justify-content-around">
            <div className="col-xxl-2 col-xl-3 col-lg-3 col-12">
              <img src={orderImg} className="CardProductIMG" alt="" />
            </div>

            <div className="col-xxl-10 col-xl-9 col-lg-9 col-12 pe-lg-4">
              <div className="d-lg-flex justify-content-between align-items-center">
                <h1 className="fw-bold mb-2 mt-lg-0 mt-3">
                  #{orderNo} {orderFood}
                </h1>

                <p className="fw-bold text-muted d-inline-block mb-0">
                  OTP:
                  <span className="ms-2">{orderOtp}</span>
                </p>
                <p className="fw-bold text-muted d-inline-block mb-0 ms-lg-0 ms-3">
                  Status:
                  {/*======== Add these three status as per condition must remind to arrange class wise ===========*/}
                  <span className={getOrderStatusClass(orderStatus)}>{btnTitle}</span>

                  {/*======== Add these three status as per condition must remind to arrange class wise ===========*/}
                </p>
              </div>

              <p className="text-truncate text-success fs-3 fw-600 my-4">
                {kitchenName}:
                <br className="d-lg-none d-block" />
                <span className="ms-2 text-muted">{kitchenAddress}</span>
              </p>

              <hr className="d-lg-none d-block" />

              <div className="d-lg-flex justify-content-between align-items-center text-muted fs-3 fw-600 my-4">
                <div className="d-xxl-flex">
                  <p>
                    Quantity:
                    <span className="ms-2 text-primary">({quantity})</span>
                  </p>

                  <p className="mx-xxl-5">
                    Size: <span className="ms-2 text-primary">{size}</span>
                  </p>

                  {/* <div className="extralist position-relative">
                    {"Extra"}:
                    <a
                      href="#!"
                      className="ms-2 text-primary cursor-pointer text-decoration-none"
                    >
                      {(order_extra &&
                        order_extra[0]?.order_extra_item.length > 0 &&
                        order_extra[0]?.order_extra_item.length) ||
                        0}
                      {""}
                      {order_extra && order_extra[0]?.title} added
                    </a>
                    <div className="extralisthover">
                      <ul className="extralist d-flex justify-content-between flex-wrap list-unstyled position-relative mb-0">
                        {order_extra &&
                          order_extra[0]?.order_extra_item.length > 0 &&
                          order_extra[0]?.order_extra_item.map((extra) => (
                            <li className="mb-3" key={extra.id}>
                              <span className="d-lg-inline-block d-block">
                                <img
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/assets/img/checkboxGreen.png"
                                  }
                                  className="extratick"
                                  alt=""
                                />
                                {extra.name}||No Extra
                              </span>
                            </li>
                          ))}
                      </ul>
                    </div>
                  </div> */}
                </div>

                <div className="d-xxl-flex justify-content-end PProductlgbtn">
                  <button
                    onClick={() => sendOrderId(orderNo, false)}
                    className="btn btn-outline-primary fs-3 d-lg-inline-block d-none ms-xxl-4"
                  >
                    Track order
                  </button>
                  <button
                    onClick={() => toggleChatModel()}
                    className="btn btn-primary fs-3 d-lg-inline-block d-none ms-xl-4"
                  >
                    Start Chat
                  </button>
                  <button
                    onClick={() => toggleModal()}
                    className="btn btn-primary fs-3 d-lg-inline-block d-none mx-xxl-5"
                  >
                    View order
                  </button>

                  {btnTitle === "Pending" && (
                    <button
                      onClick={() => sendOrderId(orderNo, true)}
                      className="btn btn-danger fs-3 d-lg-inline-block d-none"
                    >
                      Cancel Order
                    </button>
                  )}
                </div>
              </div>
            </div>

            <div className="col-lg-12 col-12 d-lg-none d-block">
              <button
                onClick={() => sendOrderId(orderNo, false)}
                className="btn btn-outline-primary fs-3 d-block w-100"
              >
                Track order
              </button>

              <button
                onClick={() => toggleModal()}
                className="btn btn-primary fs-3 d-block my-3 w-100"
              >
                View order
              </button>

              {btnTitle === "Pending" && (
                <button
                  onClick={() => sendOrderId(orderNo, true)}
                  className="btn btn-danger fs-3 d-block w-100"
                >
                  Cancel Order
                </button>
              )}
            </div>
          </div>
        </div>
      </div>

      <Modal
        isOpen={modal}
        toggle={toggleModal}
        size="lg"
        className="modal-dialog-centered modal-dialog-scrollable AddtocardProductModal myonetwo"
      >
        <ModalHeader className="p-0 d-block">
          <div className="productviewslider">
            <img alt="xbix" src={Orderitems && Orderitems[0]?.product_image} />
          </div>

          <button
            type="button"
            onClick={toggleModal}
            className="btn-close customClose"
          >
            <i class="fa-solid fa-xmark" />
          </button>
        </ModalHeader>

        <ModalBody>
          {is_self == 1 && orderStatus >= "3" && (
            <VarifyOtp
              user_otp={fullOrder.user_otp}
              orderId={fullOrder.id}
              setModel={() => setModal(false)}
            />
          )}
          <div className="row">
            <table className="invoice-table ordderTable table table-borderless border-white table-stripe-column align-middle">
              <thead>
                <tr className="bg-primary text-white">
                  <th className=" text-center p-3">SN</th>
                  <th className=" text-center p-3">Item Descriptions</th>
                  <th className=" text-center p-3">QTY</th>
                  <th className=" text-center p-3">Amount</th>
                </tr>
              </thead>

              <tbody>
                {Orderitems &&
                  Orderitems.length > 0 &&
                  Orderitems.map((orderdeails, index) => (
                    <tr>
                      <td className="text-secondary fw-bold text-center p-3">
                        {index + 1}
                      </td>

                      <td className="text-secondary text-center p-3">
                        <p className="mb-0 fw-bold">{orderdeails.name} </p>
                        <p className="mb-0">
                          <span className="fw-bold me-2">Size :</span>{" "}
                          {orderdeails.variant_size}
                        </p>

                        <p className="mb-1 fw-bold">
                          {orderdeails.order_extra &&
                            orderdeails.order_extra.length > 0 && (
                              <>
                                {orderdeails.order_extra &&
                                  orderdeails.order_extra[0]?.title}
                                :{" "}
                                {orderdeails.order_extra &&
                                  orderdeails.order_extra[0]
                                    ?.order_extra_item &&
                                  orderdeails.order_extra &&
                                  orderdeails.order_extra[0]?.order_extra_item
                                    .length > 0 &&
                                  orderdeails.order_extra &&
                                  orderdeails.order_extra[0]?.order_extra_item.map(
                                    (item) => <>{item.name} </>
                                  )}
                              </>
                            )}
                        </p>

                        {orderdeails?.addons_item &&
                          orderdeails?.addons_item.length > 0 && (
                            <p className="mb-0">
                              <span className="fw-bold me-2">Addons :</span>{" "}
                              {orderdeails.addons_item.map((item) => (
                                <> {item.addon_name} </>
                              ))}
                            </p>
                          )}
                      </td>

                      <td className="text-secondary fw-bold text-center p-3">
                        {orderdeails.quantity}
                      </td>

                      <td className="text-secondary fw-bold text-center p-3">
                        {AppConst.getBussInfo.currency}
                        {orderdeails.price}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>

            <div className="col-lg-12 col-12">
              <table className="table table-bordered mb-0 h-100">
                <thead>
                  <tr className="bg-primary text-white">
                    <th colSpan={"2"}>Your Order Amount</th>
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <td>
                      <p className="mb-0">Amount :</p>
                    </td>

                    <td>
                      <p className="mb-0">
                        {AppConst.getBussInfo.currency}{" "}
                        {fullOrder?.sub_total &&
                          fullOrder?.sub_total.toFixed(2)}
                      </p>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <p className="mb-0">Delivery Fee :</p>
                    </td>

                    <td>
                      <p className="mb-0">
                        {AppConst.getBussInfo.currency}{" "}
                        {fullOrder?.serviceFee &&
                          fullOrder?.serviceFee.toFixed(2)}
                      </p>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <p className="mb-0">VAT :</p>
                    </td>

                    <td>
                      <p className="mb-0">
                        {" "}
                        {AppConst.getBussInfo.currency}{" "}
                        {fullOrder?.tax && fullOrder?.tax.toFixed(2)}(
                        {fullOrder.tax_per}%)
                      </p>
                    </td>
                  </tr>

                  <tr className="bg-primary text-white">
                    <td style={{ borderRightColor: "#ffffff" }}>
                      <p className="mb-0">Sub-Total</p>
                    </td>

                    <td>
                      <p className="mb-0">
                        {AppConst.getBussInfo.currency}{" "}
                        {(
                          fullOrder.sub_total +
                          fullOrder.tax +
                          fullOrder.serviceFee
                        ).toFixed(2)}
                      </p>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <p className="mb-0">Tip Amount :</p>
                    </td>

                    <td>
                      <p className="mb-0">
                        {AppConst.getBussInfo.currency} {fullOrder.tip}
                      </p>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <p className="mb-0">Discount :</p>
                    </td>

                    <td>
                      <p className="mb-0">
                        {AppConst.getBussInfo.currency}
                        <span className="mx-2">-</span>
                        {fullOrder?.discount_amount &&
                          fullOrder?.discount_amount.toFixed(2)}
                      </p>
                    </td>
                  </tr>

                  <tr className="bg-primary text-white">
                    <td style={{ borderRightColor: "#ffffff" }}>
                      <p className="mb-0">Grand-Total</p>
                    </td>

                    <td>
                      {AppConst.getBussInfo.currency}{" "}
                      {fullOrder?.amount && fullOrder?.amount.toFixed(2)}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="col-lg-12 col-12 my-4">
              <h1 class="fs-1 text-primary fw-bold mb-4 d-flex justify-content-between align-items-center">
                Additional Note
              </h1>
              {fullOrder?.additional_notes != "No extra note" ? (
                <p className="mb-0">{fullOrder.additional_notes}</p>
              ) : (
                <p className="text-danger fw-bold mb-0">No notes available</p>
              )}
            </div>
            {deliveryBoyInfo && (
              <div className="col-lg-12 col-12">
                <table className="table table-bordered mb-4">
                  <thead>
                    <tr className="bg-primary text-white">
                      <th colSpan={"2"}>Driver Details</th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr>
                      <td rowSpan={"3"} style={{ maxWidth: "50px" }}>
                        <p className="mb-0">
                          <img
                            src={deliveryBoyInfo?.profile_image}
                            className="modalDriverImg"
                            alt=""
                          />
                        </p>
                      </td>

                      <td>
                        <p className="mb-0">{deliveryBoyInfo?.name}</p>
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <p className="mb-0">
                          <span className="fw-bold me-2">Ph :</span>{" "}
                          {deliveryBoyInfo && deliveryBoyInfo?.mobile}
                        </p>
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <p className="mb-0">
                          <span className="fw-bold me-2">Vehicle Number:</span>{" "}
                          {deliveryBoyInfo && deliveryBoyInfo?.vehicle_number}
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            )}

            <div className="col-lg-12 col-12">
              <table className="table table-bordered mb-4">
                <thead>
                  <tr className="bg-primary text-white">
                    <th colSpan={"2"}>Order Details</th>
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <td>
                      <p className="mb-0">Payment Mode :</p>
                    </td>

                    <td>
                      <p className="mb-0"> {fullOrder.payment_method}</p>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <p className="mb-0">Date :</p>
                    </td>

                    <td>
                      <p className="mb-0">
                        {fullOrder?.pre_full_date_time
                          ? fullOrder.pre_full_date_time
                          : moment(pre_date).format("YYYY-MM-DD")}
                      </p>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <p className="mb-0">Delivered To :</p>
                    </td>

                    <td>
                      <p className="mb-0">{fullOrder.delivery_address}</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </ModalBody>
      </Modal>

      {chatModel === true && (
        <ChatModel
          modal={chatModel}
          toggleModal={() => toggleChatModel()}
          orderId={orderNo}
        />
      )}
    </>
  );
};

export default CurrentOrderListCard;

const VarifyOtp = ({
  orderId,
  user_otp,

  setModel = () => { },
}) => {
  const inputRefs = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
  ];
  const [otp, setOtp] = useState([]);
  function handleInputChange(index, event) {
    const value = event.target.value;

    // If the input is empty and backspace is pressed, move focus to the previous input
    if (value === "" && event.key === "Backspace" && index > 0) {
      inputRefs[index - 1].current.focus();
      return;
    }

    // If the input is a number, update the value and move focus to the next input
    if (/^\d$/.test(value) && index < inputRefs.length - 1) {
      inputRefs[index + 1].current.focus();
    }

    let arr = [...otp];
    arr[index] = /^\d$/.test(value) ? value : ""; // Clear non-digit inputs
    setOtp(arr);
  }
  function handleKeyDown(index, event) {
    if (event.key === "Backspace") {
      event.preventDefault(); // Prevent the backspace event from propagating

      let arr = [...otp];
      arr[index] = "";
      setOtp(arr);

      if (index > 0) {
        inputRefs[index - 1].current.focus();
      }
    }
  }
  const handleVerifyOtp = (e) => {
    e.preventDefault();

    if (otp.join("") != user_otp) {
      sendErrorInfo("Invalid OTP, Try again ");
      return;
    }
    let postData = {
      order_id: orderId,
    };
    callApi("user/order-completed-by-user", {
      method: "POST",
      data: postData,
    }).then((res) => {
      if (res.status === true) {
        sendSuccessInfo(res.message);

        setModel(false);
      } else {
        sendErrorInfo("Something went wrong, Try again ");
      }
    });
  };
  return (
    <form
      onSubmit={(e) => handleVerifyOtp(e)}
      className="regform position-relative INMOdalverfyotp">
      {/*----- OTP input and Resend OTP button ---*/}
      <div className="otpcontainer">
        <h2 className="text-center">Enter OTP</h2>

        <div className="row align-items-center mb-4">
          <div className="col-lg-7 col-12">
            <div className="otpinputs_order">
              {Array.from({ length: 6 }, (_, index) => (
                <input
                  type="number"
                  className="form-control"
                  placeholder=" "
                  maxLength={1}
                  minLength={1}
                  key={index}
                  ref={inputRefs[index]}
                  value={otp[index]}
                  onChange={(event) => handleInputChange(index, event)}
                  onKeyDown={(event) => handleKeyDown(index, event)}
                />
              ))}
            </div>
          </div>

          <div className="col-lg-4 col-12 mx-lg-auto">
            <button type="submit" className="btn btn-primary d-block w-100 fs-3 w-100 py-3">
              Varify OTP {user_otp}
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};
