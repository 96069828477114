import React from "react";

const AddressDetails = ({
  kitchenData,
  deliveryAddress,
  changeAddress,
  setChangeAddress,
  toggleAddressModal = () => {},
}) => {
  return (
    <>
      <div className="outletaddress">
        <h1 className="fw-bold fs-2 mb-2">{kitchenData?.name}</h1>
        <p className="text-secondary fs-3 fw-600 mb-0">
          <i className="fa-solid fa-location-dot text-warning me-2" />
          {kitchenData?.kitchen_info?.address}
        </p>
      </div>
      {/*---- User Delivery Address -----*/}
      <p className="text-muted my-4 fw-bold fs-2">Delivery address</p>
      <div className="useraddress">
        <h1 className="fw-bold fs-2 mb-2 d-flex justify-content-between align-items-center">
          {deliveryAddress?.address_type == "house" ? "Home" : "Business"}
          <a
            onClick={() => setChangeAddress(!changeAddress)}
            href="javascript:void(0)"
            className="text-success fs-4"
          >
            Change address
          </a>
        </h1>
        <p className="text-secondary fs-3 fw-600 mb-0">
          <i className="fa-solid fa-location-dot text-warning me-2" />
          {deliveryAddress?.address}
        </p>
      </div>
      <button
        type="button"
        onClick={(e) => {
          e.preventDefault();
          toggleAddressModal();
        }}
        className="btn btn-outline-primary d-block fw-bold w-100 fs-2 py-4 mt-5"
      >
        Add new address
      </button>
    </>
  );
};

export default AddressDetails;
