import React from 'react'
import Layout from '../../components/common/Layout'
import { Link } from 'react-router-dom'


const TnC = () => {
    return (
        <Layout pageClass={'conditionpage'}>
            <section className="Pagetitledisc m-0">
                <div className="container-fluid py-3">
                    <div className="row">
                        <div className="col-lg-12 col-12">
                            <div className="d-lg-flex justify-content-between">
                                <div>
                                    {/*----- Section title -----*/}
                                    <h1 className="default_title">
                                        Terms & Conditions
                                    </h1>
                                    {/*----- Section Sub title -----*/}
                                    <p className="default_subtitle mb-0">
                                        Go thorugh of our T&Cs
                                    </p>
                                </div>
                                {/*----- breadcrumb -----*/}
                                <nav className="align-self-end mt-lg-0 mt-4">
                                    <ol className="breadcrumb mb-0">
                                        <li className="breadcrumb-item">
                                            <Link to={"/home"}>Home</Link>
                                        </li>
                                        <li className="breadcrumb-item active">
                                            <p className="d-inline-block fw-bold text-muted">
                                                Terms and Conditions
                                            </p>
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/*----- Page title and discription section end -----*/}

            <section className="GreenTnc">
                <div className="container-fluid">
                    <h1 className="fw-bold">
                        Please read these Terms and Conditions ("T&C") carefully before using the Green Central e-commerce platform ("Platform") operated by Green Central ("we," "us," or "our"). These T&C govern your use of the Platform and the services provided therein.
                    </h1>

                    <ol className='mt-5 list-unstyled'>
                        <li class="Main-list">
                            <p class="fs-2"><b>Acceptance of Terms</b></p>

                            <p>
                                By accessing or using our Platform, you agree to comply with and be bound by these Terms and Conditions. If you do not agree with any part of these terms, you may not use the Platform.
                            </p>
                        </li>

                        <li class="Main-list">
                            <p class="fs-2"><b>Registration</b></p>

                            <p>
                                <span>a.</span> To use certain features of the Platform, you may be required to register for an account. You agree to provide accurate and up-to-date information during the registration process.
                            </p>

                            <p>
                                <span>b.</span> You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account.
                            </p>
                        </li>

                        <li class="Main-list">
                            <p class="fs-2"><b>User Responsibilities</b></p>

                            <p>
                                <span>a.</span> You agree to use the Platform for lawful purposes only and in compliance with all applicable laws and regulations.
                            </p>

                            <p>
                                <span>b.</span> You will not engage in any activity that may:
                                <ul className='ps-5 ms-3'>
                                    <li>Infringe upon the rights of others.</li>
                                    <li>Disrupt the operation of the Platform.</li>
                                    <li>Harvest or collect user information without consent.</li>
                                    <li>Transmit viruses or malicious code.</li>
                                </ul>
                            </p>
                        </li>

                        <li class="Main-list">
                            <p class="fs-2"><b>Orders and Deliveries</b></p>

                            <p>
                                <span>a.</span>  When placing an order through the Platform, you agree to provide accurate delivery information and payment details.
                            </p>

                            <p>
                                <span>b.</span>  We will make our best effort to ensure timely delivery; however, delivery times may vary based on factors beyond our control.
                            </p>
                        </li>

                        <li class="Main-list">
                            <p class="fs-2"><b>Payments</b></p>

                            <p>
                                <span>a.</span> Payment for products and services on the Platform is handled securely through our payment processing partners. You agree to their terms and conditions when making payments.
                            </p>

                            <p>
                                <span>b.</span>  Prices are subject to change without notice, and we are not responsible for typographical errors.
                            </p>
                        </li>

                        <li class="Main-list">
                            <p class="fs-2"><b>Returns and Refunds</b></p>

                            <p>
                                a. Our return and refund policy is available on the Platform. Please review it before making a purchase.
                            </p>
                        </li>

                        <li class="Main-list">
                            <p class="fs-2"><b>Intellectual Property</b></p>

                            <p>
                                <span>a.</span>  All content on the Platform, including text, graphics, logos, and images, is the intellectual property of [Your Business Name] and is protected by copyright and trademark laws.
                            </p>
                        </li>

                        <li class="Main-list">
                            <p class="fs-2"><b>Termination</b></p>

                            <p>
                                <span>a.</span>  We reserve the right to terminate or suspend your account and access to the Platform at our discretion, without prior notice, for any violation of these T&C.
                            </p>
                        </li>

                        <li class="Main-list">
                            <p class="fs-2"><b>Limitation of Liability</b></p>
                            <p>
                                <span>a.</span>  We are not liable for any direct, indirect, incidental, consequential, or punitive damages arising out of your use of the Platform.
                            </p>
                        </li>

                        <li class="Main-list">
                            <p class="fs-2"><b>Governing Law</b></p>
                            <p>
                                <span>a.</span>  These T&C are governed by the laws of South Africa, and any disputes will be subject to the jurisdiction of the South African courts.
                            </p>
                        </li>

                        <li class="Main-list">
                            <p class="fs-2"><b>Changes to T&C</b></p>
                            <p>
                                <span>a.</span>  We reserve the right to update or modify these T&C at any time without prior notice. Please review these terms periodically for changes.
                            </p>
                        </li>
                    </ol>
                </div>
            </section>
        </Layout>
    )
}

export default TnC