import React from "react";
import { useSelector } from "react-redux";
import Slider from "react-slick";
import { getDistance } from "../../../utility/helper";
import { useNavigate } from "react-router-dom";

const NearbySection = () => {
  const kitchen_data = useSelector((state) => state.root.kitchen_data);
  const user_address = useSelector((state) => state.root.user_address);
  const navigate = useNavigate();
  let kitchenSlider = {
    ...settings,
    // lazyLoad: 'progressive',
    slidesToShow: kitchen_data.length > 3 ? 3 : kitchen_data.length,
    slidesToScroll: 1,
    arrows: true,
    // fade: true,
    // asNavFor: '.slider-nav',
    adaptiveHeight: true,
    infinite: true,
  };

  const goToStore = (kitchenId) => {
    navigate("/store-details/" + kitchenId);
  };
  return (
    <section className="nearbystores">
      <div className="container-fluid">
        <div className="row justify-content-between">
          <div className="col-lg-9 col-12">
            {/*----- Section title -----*/}
            <h1 className="default_title">Available nearby stores for you</h1>
            {/*----- Section Sub title -----*/}
            <p className="default_subtitle">
              Looking for great product near you
            </p>
          </div>

          <div className="col-lg-2 col-12 text-end d-lg-block d-none">
            <button
              onClick={() => navigate("/stores")}
              className="btn btn-light fs-2 fw-600 py-3  w-100 rounded-3"
              type="button"
            >
              View all store
            </button>
          </div>

          <div className="col-lg-12 col-12">
            <Slider {...kitchenSlider} className="nearbystores_slider">
              {/*----- Store card -----*/}
              {kitchen_data &&
                kitchen_data.length > 0 &&
                kitchen_data
                  .filter((kitchen) => kitchen.status === "0" && kitchen.is_dark_mode === 0)
                  .map((kitchen, i) => (
                    <div
                      className="card storecard border-0  position-relative"
                      style={{ cursor: "pointer" }}
                      onClick={() => goToStore(kitchen.id)}
                      key={i}
                    >
                      <span
                        className={
                          kitchen?.online_status === "online"
                            ? "badge bg-success"
                            : "badge bg-danger"
                        }
                      >
                        {kitchen?.online_status === "online"
                          ? "Online"
                          : "Offline"}
                      </span>
                      <a href="#!" className="stretched-link" />
                      <img
                        src={
                          kitchen?.kitchen_image &&
                            kitchen?.kitchen_image[0]?.banner_image
                            ? kitchen?.kitchen_image &&
                            kitchen?.kitchen_image[0]?.banner_image
                            : "./assets/img/nearby1.png"
                        }
                        className="w-100 h-100 storeimg"
                        alt=""
                      />
                      <div className="logo" style={{
                        position: 'absolute',
                        top: '10px',
                        right: '10px',
                      }}>
                        <img src={kitchen?.kitchen_logo} alt="Logo" width="100" height="100" />
                      </div>
                      <div className="card-img-overlay d-flex flex-column justify-content-around">
                        <div>
                          <h1 className="storetitle">{kitchen.name}</h1>

                          <p className="nearbydistance text-warning d-inline-block">
                            {getDistance(
                              user_address?.lat,
                              user_address?.lng,
                              kitchen.kitchen_info.lat,
                              kitchen.kitchen_info.lng
                            ).toFixed(2)}{" "}
                            KM away from you
                          </p>
                        </div>

                        <div className="d-flex storecardFaci justify-content-between align-items-center">
                          {kitchen?.delivery_active === "1" && (
                            <span className="bannerlabel text-lg-start text-center">
                              <img
                                src="./assets/img/bannerdelivery.png"
                                alt=""
                              />
                              Delivery
                            </span>
                          )}
                          {kitchen?.kitchen_info?.pickup_on_off === "1" && (
                            <span className="bannerlabel mx-4 text-lg-start text-center">
                              <img src="./assets/img/bannerpickup.png" alt="" />
                              Pickup
                            </span>
                          )}

                          {kitchen?.is_courier === "1" && (
                            <span className="bannerlabel text-lg-start text-center">
                              <img src="./assets/img/bannercurior.png" alt="" />
                              Courier
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
            </Slider>
          </div>

          <div className="col-lg-2 col-12 text-end d-lg-none d-block mt-5">
            <button
              onClick={() => navigate("/stores")}
              className="btn btn-light fs-2 fw-600 py-3  w-100 rounded-3"
              type="button"
            >
              View all store
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default NearbySection;
const CustomPrevArrow = (props) => (
  <button
    {...props}
    class="slick-arrow slick-prev"
    style={{ display: "block" }}
  >
    Previous
  </button>
);
const CustomNextArrow = (props) => (
  <button
    {...props}
    class="slick-arrow slick-next"
    style={{ display: "block" }}
  >
    Next
  </button>
);

var settings = {
  arrows: true,
  infinite: true,
  autoplay: false,
  centerMode: true,
  centerPadding: "35px",
  slidesToShow: 5,
  slidesToScroll: 1,
  initialSlide: 1,
  responsive: [
    {
      breakpoint: 1199,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        infinite: true,
        dots: false,
        centerMode: false,
        centerPadding: "0px",
        arrows: true,
      },
    },
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        infinite: true,
        dots: false,
        arrows: true,
        centerMode: false,
        centerPadding: "0px"
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};
