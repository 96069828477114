import { ADD_TO_CART, CLEAR_CART } from "../types";


const initialState = {
    items:[],
    total:0,
    cart_count:0, 
}

const cartReducers = (state = initialState, action) =>{
    switch(action.type){
    case ADD_TO_CART:
        console.log("cart products", action.payload)
        return {
            ...state,
            items:[...state.items, action.payload],
            cart_count:state.items.length
        }
    case CLEAR_CART:
        return{
            ...state,
            items:[],
            total:0,
            cart_count:state.items.length
        }
        default:return state
    }
}

export default cartReducers;