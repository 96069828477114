import React, { useEffect, useState } from "react";
import { callApi } from "../../hooks/useapi";

const AutoCompleteInput = ({ getLatlong = () => { }, selectedAddress }) => {
  const [inputValue, setInputValue] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  useEffect(() => {
    if (inputValue == "") {
      setSuggestions([]);
    }
    if (selectedAddress) {
      setInputValue(selectedAddress);
    }
  }, [selectedAddress]);
  const handleInputChange = (event) => {
    const value = event.target.value;

    setInputValue(value);
    fetchSuggestions(value);
  };
  const fetchSuggestions = async (value) => {
    const response = await callApi(`user/locations?name=${value}`,
      { method: "GET" }
    );

    setSuggestions(response.data.predictions);
  };
  return (
    <>
      <input
        type="text"
        className="form-control"
        placeholder="Enter your location"
        value={inputValue}
        onChange={handleInputChange}
      />

      {suggestions && suggestions.length > 0 && (
        <div className="address_container">
          <ul className="list-unstyled m-0" style={{ height: "100%" }}>
            {suggestions &&
              suggestions.length > 0 &&
              suggestions.map((suggestion, index) => (
                <li
                  key={index}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    getLatlong(suggestion.description);
                    setInputValue(suggestion.description);
                    setTimeout(() => {
                      setSuggestions([]);
                    }, 500);
                  }}
                >
                  {suggestion.description}
                </li>
              ))}
          </ul>
        </div>
      )}
    </>
  );
};

export default AutoCompleteInput;
