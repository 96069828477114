import React, { useEffect, useMemo } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import {
  currentSender,
  getNotificationTokens,
  getServerKey,
  sendChatNotification,
  sendNewMessage,
  sortChat,
} from "./ChatServer";
import {
  ChatConstant,
  isSendChatNotification,
  isShowTyping,
  senderTypes,
} from "./ChatConstant";
import db from "../../../../firebase";
const ChatModel = ({ modal, toggleModal = () => { }, orderId }) => {
  const order_id = orderId;
  const collectionName = `order_${order_id}`;
  const typingName = `typing_${order_id}`;
  const collection = db.collection(collectionName);
  const typingCollection = db.collection(typingName);
  let time = React.useRef(null);
  const typingStatus = "";

  const listRef = React.useRef();

  const [data, setData] = React.useState({
    msg: "",
    list: [],
    totalChat: 0,
    playSound: false,
    spinner: true,
    isReceive: false,
    serverKey: "",
    notificationToken: [],
  });
  const [keyboardHeight, setKeyboardHeight] = React.useState(0);
  const [tokenData, setTokenData] = React.useState({
    serverKey: "",
    notificationToken: []
  })

  const [typing, setTyping] = React.useState({
    list: [],
  });

  const btnDisabled = React.useMemo(() => {
    if (data.msg) {
      return false;
    }
    return true;
  }, [data.msg]);

  const scrollToEnd = () => {
    try {
      if (data.list?.length > 1) {
        listRef.current.scrollToEnd({ animated: true });
      }
    } catch (error) { }
  };

  React.useMemo(() => {
    setTimeout(() => {
      scrollToEnd();
    }, 200);
  }, [keyboardHeight]);

  useEffect(() => {
    const handleKeyboardDidShow = (event) => {
      const { height } = event;
      setKeyboardHeight(height + 50);
    };

    const handleKeyboardDidHide = () => {
      setKeyboardHeight(0);
    };

    window.addEventListener("keyboardDidShow", handleKeyboardDidShow);
    window.addEventListener("keyboardDidHide", handleKeyboardDidHide);

    return () => {
      window.removeEventListener("keyboardDidShow", handleKeyboardDidShow);
      window.removeEventListener("keyboardDidHide", handleKeyboardDidHide);
    };
  }, []);

  useEffect(() => {
    console.log("set data ----- 1", 'isReceive:false');
    setData({ ...data, isReceive: false })
    // setData((prevData) => ({ ...prevData, isReceive: false }));
    getData();

    const collectionRef = db.collection(collectionName);

    const collectionListener = collectionRef.onSnapshot((snapshot) => {
      const _list = sortChat(snapshot.docs.map((doc) => doc.data()));

      if (_list.length > 0) {
        const lastItem = _list[_list.length - 1];

        setData((prevData) => ({
          ...prevData,
          list: [..._list],
          totalChat: _list.length,
        }));
        console.log("set data ----- 2", {
          list: [..._list],
          totalChat: _list.length
        })

        if (lastItem.senderType !== currentSender) {

          //  startReceiveRing(); // You should implement this function
        }

      }
    });

    return () => {
      collectionListener();
      sendTyping(false);
    };
  }, [orderId]);

  const getData = async () => {
    setData({ ...data, spinner: true });
    const res = await Promise.all([
      getAllChats(),
      getServerKey(),
      getNotificationTokens({ order_id })
    ]);

    if (res.length > 0) {
      const chatRes = res[0];
      const keyRes = res[1];
      const tokens = res[2];
      const unReadChats = res[3];

      setData({
        ...data,
        list: [...chatRes.list],
        totalChat: chatRes.list?.length,
        spinner: false,
        isReceive: true,
      });
      setTokenData({
        serverKey: keyRes.serverKey,
        notificationToken: tokens
      })
      // setTimeout(() => {
      //   readUnReadableChatMessages({ order_id, chats: unReadChats })
      // }, 500);
      return;
    }
    setData({ ...data, spinner: false });
  }

  const getAllChats = async () => {
    const obj = {
      list: [],
    };
    const collectionData = await db.collection(collectionName).get();
    const chatList = sortChat(collectionData.docs?.map((item) => item?._data));
    if (chatList?.length > 0) {
      obj["list"] = chatList;
    }
    return obj;
  };

  React.useLayoutEffect(() => {
    const subscriber = db.collection(collectionName).onSnapshot((_data) => {
      const _list = _data.docs
        .map((item) => item?._data)
        .sort((a, b) => a?.localId - b?.localId);
      const lastChat = _list[_list.length - 1];
    });

    return () => {
      subscriber();
    };
  }, []);

  const typingText = React.useMemo(() => {
    if (typing?.list?.length > 0) {
      const item = typing.list[0];
      if (item?.is_start && item?.from !== currentSender) {
        if (item?.from === senderTypes.store) {
          return "Store typing...";
        }
        if (item?.from === senderTypes.driver) {
          return "Delivery person typing...";
        }
        if (item?.from === senderTypes.customer) {
          return "Customer typing...";
        }
      }
    }
    return "";
  }, [JSON.stringify(typing)]);

  React.useEffect(() => {
    const subscriber = db.collection(typingName).onSnapshot((querySnapshot) => {
      const documentData = [];
      querySnapshot.forEach((documentSnapshot) => {
        const data = documentSnapshot.data();
        const id = documentSnapshot.id;
        documentData.push({ id, ...data });
      });
      if (documentData?.length > 0) {
        setTyping({
          ...typing,
          list: documentData,
        });
      }
    });

    return () => {
      subscriber();
    };
  }, []);

  const sendTyping = (typingStatus = false) => { };

  const onSendMessage = () => {

    if (data.msg !== "") {
      sendNewMessage({
        msg: data.msg,
        collection,
        order_id: order_id,
        localId: data.totalChat + 1
      });

      if (isSendChatNotification) {
        const title = "New message";
        sendChatNotification({
          title,
          message: data.msg,
          serverKey: tokenData.serverKey,
          to: tokenData.notificationToken,
          order_id: order_id
        });
      }
      setData({
        ...data,
        msg: ""
      });
    }
    if (time.current) {
      clearTimeout(time?.current);
      time.current = undefined;
    }
    // setTimeout(() => {
    //   startSendRing();
    // }, 100);
  }
  React.useMemo(() => {
    if (data.msg == "") {
      sendTyping(false);
      return;
    }
    if (data.msg) {
      if (time?.current) {
        return;
      }
      time.current = setTimeout(() => {
        time.current = "";
        sendTyping(true);
      }, 1000);
      return;
    }
  }, [data.msg]);

  const onWriteChat = (value) => {
    setData({ ...data, msg: value });
  };

  const onViewLayout = (w, h) => {
    scrollToEnd();
  };



  const renderChats = () => {
    if (data.list?.length > 0) {
      return (
        <div className="card-body p-0">
          <div className="CahtBox">
            <div className="chatConversation">
              <div className="conversationBox">
                <ul className="MsgBody list-unstyled overflow-hidden">
                  {data.list?.map((item, index) => (
                    <ChatView
                      key={String(index) + "1212"}
                      msg={item?.msg}
                      isRight={currentSender === item?.senderType}
                      time={item?.time}
                      type={item?.senderType}
                    />
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      );
    }
    return null;
  };
  return (
    <Modal isOpen={modal} toggle={toggleModal} size="lg" className="modal-dialog-centered modal-dialog-scrollable OrderChatModal">
      <ModalBody>
        <button type="button" onClick={toggleModal} className="btn-close customClose">
          <i class="fa-solid fa-xmark" />
        </button>

        <div className="card">
          <div className="card-header">
            Order Chat
          </div>
          {renderChats()}
          <div className="card-footer position-relative">
            <ChatInput
              sendPress={onSendMessage}
              onChangeText={onWriteChat}
              value={data.msg}
              btnDisabled={btnDisabled}
              typing={typingText}
            />
          </div>
        </div>
      </ModalBody>
    </Modal >
  );
};

export default ChatModel;
// <li className="sender position-relative">
//   <p className="mb-1">
//     Hey! it's been 10 mins late.. Where are you?
//   </p>

//   <span className="msgtime">10:08 PM</span>
// </li>

// <li className="replyofsend text-end position-relative">
//   <p className="mb-1">
//     Yes! I am on my way to deliver your order. I'll be front of your within 4 mins.
//   </p>

//   <span className="msgtime">10:12 PM</span>
// </li>

function ChatView(props) {
  const {
    msg = "Hello",
    isRight,
    msgList = ["Hello", "How are you"],
    time,
    type,
  } = props;

  if (isRight) {
    return (
      <>
        <li className=" replyofsend text-end position-relative">
          <Chat isRight={isRight} msg={msg} time={time} type={type} />
        </li>
      </>
    );
  }
  return (
    <li className="sender position-relative">
      <Chat msg={msg} time={time} type={type} />
    </li>
  );
}

function Chat(props) {
  const { msg, isRight, time, type } = props;

  const timeText = useMemo(() => {
    if (isRight) {
      return time;
    }
    if (type) {
      if (type === senderTypes.store) {
        const senderName = "Store";
        return `${senderName}, ${time}`;
      }
      if (type === senderTypes.driver) {
        const senderName = "Delivery person";
        return `${senderName}, ${time}`;
      }
      if (type === senderTypes.customer) {
        const senderName = "Customer";
        return `${senderName}, ${time}`;
      }
    }
    return time;
  }, [isRight]);

  return (
    <>
      <p className="mb-1">
        {msg}
      </p>
      <span className="msgtime">{timeText}</span>
    </>
  );
}

const ChatInput = ({ sendPress, onChangeText, value, btnDisabled, typing }) => {
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      if (e.target.value.length > 0) {

        sendPress()
      }
    }
  };
  return (
    <div className="chatSendBox">
      <textarea class="form-control" onChange={(e) => onChangeText(e.target.value)} onKeyDown={handleKeyDown}
        value={value} placeholder="Enter Your Message Here...." />
      <button className="btn btn-outline-secondary" type="button" id="button-addon2" onClick={sendPress}
        disabled={btnDisabled}>Send</button>
    </div>
  );
};
