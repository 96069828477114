import { Modal, Form, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { callApi } from "../../../hooks/useapi";
import {
  getCookies,
  sendErrorInfo,
  sendSuccessInfo,
  setCookies,
} from "../../../utility/helper";
import { setUserLocation } from "../../../store/actions/actions";
import AutoCompleteInput from "../AutoCompleteInput";

function UserAddress({
  onAddAddress,
  editAddress,
  showModal,
  setShowModal,
  toggleModal,
}) {
  const [address1, setAddress1] = useState({
    pin_code: "",
    country: "",
    state: "",
    city: "",
    building_name: "",
    address: "",
    address_type: "house",
    lat: "",
    lng: "",
    is_default: "",
  });
  const [address, setAddress] = useState();

  const [edit, setEdit] = useState([]);
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
    reset,
  } = useForm({ mode: "onBlur" });
  const dispatch = useDispatch();
  // const onClickConfirm = event =>{
  //     event.preventDefault();
  //     props.history.push("/home");
  // }

  useEffect(() => {
    if (Object.keys(editAddress).length > 0) {
      setShowModal(true);
      setAddress1(editAddress);
      reset(editAddress);
      setEdit(true);
    } else {
      setAddress1({
        pin_code: "",
        country: "",
        state: "",
        city: "",
        building_name: "",
        address: "",
        address_type: "house",
        lat: "",
        lng: "",
        is_default: "",
      });
      setAddress([]);
      reset({});
    }
  }, [editAddress]);
  const [disabled, setDisabled] = useState("");
  const onSubmit = (data) => {
    setDisabled("disabled");
    data.is_default = address1.is_default;

    if (editAddress?.id) {
      data.address_id = editAddress.id;
      callApi("user/edit-address", { method: "POST", data: data })
        .then((response) => {
          if (response.status) {
            sendSuccessInfo("Successfully update address");

            onAddAddress && onAddAddress(true);
            setEdit(false);
            editAddress = "";
            reset({});

            setAddress1([]);
            setAddress([]);
            if (data.is_default == 1) {
              let saveLoc = {
                address: data.address_type,
                apartment: data.building_name,
                lat: data.lat,
                lng: data.lng,
                address: data.address,
              };

              dispatch(setUserLocation(saveLoc));
              // setCookies(
              //   process.env.REACT_APP_BUSINESS + "address",
              //   data.address,
              //   { path: "/" }
              // );
              // setCookies(process.env.REACT_APP_BUSINESS + "lat", data.lat);
              // setCookies(process.env.REACT_APP_BUSINESS + "lng", data.lng);
            }
            toggleModal();
            resetAddress();
            setDisabled("");
          } else {
            for (const [index, value] of response.errors.entries()) {
              sendErrorInfo(value.detail);
            }
          }
        })
        .catch((error) => {
          //return error.data;
          setDisabled("");
          for (const [index, value] of errors.entries()) {
            sendErrorInfo(value);
          }
        });
    } else {
      callApi("user/add-address", { method: "POST", data: data })
        .then((response) => {
          if (response.status) {
            sendSuccessInfo("Successfully save address");
            toggleModal();
            resetAddress();
            onAddAddress && onAddAddress(true);
            reset();
            setAddress1([]);
            setAddress([]);
            if (data.is_default == 1) {
              setCookies(
                process.env.REACT_APP_BUSINESS + "address",
                data.address,
                { path: "/" }
              );
              let saveLoc = {
                address_type: data.address_type,
                apartment: data.building_name,
                lat: data.lat,
                lng: data.lng,
                address: data.address,
              };

              dispatch(setUserLocation(saveLoc));
              // setCookies(process.env.REACT_APP_BUSINESS + "lat", data.lat);
              // setCookies(process.env.REACT_APP_BUSINESS + "lng", data.lng);
            }
            setDisabled("");
          } else {
            setDisabled("");
            //   for (const [index, value] of response.errors.entries()) {
            //       toast.error(value.detail, {
            //       position: toast.POSITION.TOP_RIGHT
            //       });
            //   }
          }
        })
        .catch((error) => {
          setDisabled("");
          //return error.data;
          //   for (const [index, value] of errors.entries()) {
          //       toast.error(value, {
          //       position: toast.POSITION.TOP_RIGHT
          //       });
          //   }
        });
    }
  };

  const handleChange = (address) => {
    setAddress(address);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setAddress1({ ...address1, [name]: value });
  };

  const handleSelect = async (address) => {
    setAddress(address);
    const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;
    const urlNew = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
      address
    )}&key=${apiKey}`;

    try {
      const result = await fetch(urlNew);
      const response = await result.json();
      const { results } = response;

      if (results.length > 0) {
        const { geometry } = results[0];
        const { location } = geometry;
        const { lat, lng } = location;
        let saveLoc = {
          lat: lat,
          lng: lng,
          address: address,
        };

        // dispatch(setUserLocation(saveLoc));

        // setCookies(process.env.REACT_APP_BUSINESS + "lat", lat);
        // setCookies(process.env.REACT_APP_BUSINESS + "lng", lng);
        const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${saveLoc.lat},${saveLoc.lng}&sensor=true&key=${process.env.REACT_APP_GOOGLE_API_KEY}`;
        fetch(url)
          .then((result) => result.json())
          .then((response) => {
            if (response && response.results && response.results.length > 0) {
              // const obj = {};
              let obj = {
                address: "",
                pin_code: "",
                country: "",
                state: "",
                city: "",
                lat: saveLoc.lat,
                lng: saveLoc.lng,
                address_type: "house",
              };
              obj.address = response.results[0].formatted_address;

              response.results[0].address_components.map((item) => {
                const TYPE = item.types[0];
                if (TYPE == "postal_code") {
                  obj.pin_code = item.long_name;
                } else if (TYPE == "country") {
                  obj.country = item.long_name;
                } else if (TYPE == "administrative_area_level_1") {
                  obj.state = item.long_name;
                } else if (TYPE == "administrative_area_level_2") {
                  obj.city = item.long_name;
                }
              });
              obj["address"] = address;
              setAddress1(obj);
              reset(obj);
            }
          })
          .catch((error) => console.error("Error", error));
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const openModal = () => {
    setShowModal(true);
    setEdit(false);
  };
  const resetAddress = () => {
    setAddress1({
      pin_code: "",
      country: "",
      state: "",
      city: "",
      building_name: "",
      address: "",
      address_type: "house",
      lat: "",
      lng: "",
      is_default: "",
    });
  };

  return (
    <Modal
      isOpen={showModal}
      toggle={() => {
        toggleModal();
        resetAddress();
      }}
      size="lg"
      className="modal-dialog-centered modal-dialog-scrollable"
    >
      <ModalBody className="px-lg-5 px-3 pt-5 pb-lg-5 pb-3">
        <div class="AddnewAddress_Modal" tabindex="-1">
          <h1 class="text-primary fw-bold text-uppercase w-75 mx-auto pb-2 border-2 border-bottom  text-center mb-5">
            Please add your address details
          </h1>

          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            onClick={() => {
              toggleModal();
              resetAddress();
            }}
          ></button>

          <form
            onSubmit={handleSubmit(onSubmit)}
            className="login-form bg-transparent pt-0"
          >
            <div className="row">
              <div className="col-lg-6 col-12">
                <div className="position-relative pb-4 mb-4">
                  <h2 className="">
                    Enter location :
                  </h2>

                  <AutoCompleteInput
                    getLatlong={(e) => handleSelect(e)}
                    selectedAddress={address1.address}
                  />
                </div>
              </div>

              <div className="col-lg-6 col-12">
                <h2 className="">
                  Enter{" "}
                  {address1.address_type === "house"
                    ? "House no"
                    : "Business Name"}{" "}
                  :
                </h2>

                <div className="position-relative pb-4 mb-4">
                  <input
                    class="form-control"
                    {...register("building_name", {
                      required: "Flat no is required",
                    })}
                    placeholder={
                      address1.address_type === "house"
                        ? "House no"
                        : "Business Name"
                    }
                    name="building_name"
                    value={address1.building_name}
                    onChange={handleInputChange}
                  />

                  {
                    <span className="f-error">
                      {" "}
                      {errors?.building_name?.message}
                    </span>
                  }
                </div>
              </div>

              <div className="col-lg-6 col-12">
                <h2 className="">Enter City Name :</h2>

                <div className="position-relative pb-4 mb-4">
                  <input
                    className="form-control"
                    {...register("city", {
                      required: "City name is required",
                      pattern: {
                        value: /^[a-zA-Z]+(?:[-\s][a-zA-Z]+)*$/,
                        message: "City name must be alphabetic",
                      },
                    })}
                    value={address1.city}
                    placeholder="City"
                    name="city"
                    onChange={handleInputChange}
                  />
                  <span className="f-error"> {errors?.city?.message}</span>
                  {/* {errors.city &&  errors.city.type === 'pattern' && <span className="f-error"> City name must be alphabetic</span>} */}

                  <input
                    type="hidden"
                    name="address"
                    {...register("address")}
                    value={address1.address}
                  />

                  <input
                    type="hidden"
                    name="lat"
                    {...register("lat")}
                    value={address1.lat}
                  />

                  <input
                    type="hidden"
                    name="lng"
                    {...register("lng")}
                    value={address1.lng}
                  />
                </div>
              </div>

              <div className="col-lg-6 col-12">
                <h2 className="">Enter State :</h2>

                <div className="position-relative pb-4 mb-4">
                  <input
                    {...register("state", {
                      required: "State name is required",
                      pattern: {
                        value: /^[a-zA-Z]+(?:[-\s][a-zA-Z]+)*$/,
                        message: "State name must be alphabetic",
                      },
                    })}
                    value={address1.state}
                    className="form-control"
                    placeholder="State"
                    name="state"
                    onChange={handleInputChange}
                  />
                  <span className="f-error"> {errors?.state?.message}</span>
                  {/* {errors.state &&  errors.state.type === 'pattern' && <span className="f-error"> State name must be alphabetic</span>} */}
                </div>
              </div>

              <div className="col-lg-6 col-12">
                <h2 className="">Enter Country :</h2>

                <div className="position-relative pb-4 mb-4">
                  <input
                    {...register("country", {
                      required: "Country name is required",
                      pattern: {
                        value: /^[a-zA-Z]+(?:[-\s][a-zA-Z]+)*$/,
                        message: "Country name must be alphabetic",
                      },
                    })}
                    value={address1.country}
                    className="form-control"
                    placeholder="Country"
                    name="country"
                    onChange={handleInputChange}
                  />
                  <span className="f-error"> {errors?.country?.message}</span>
                  {/* {errors.country &&  errors.country.type === 'pattern' && <span className="f-error"> Country name must be alphabetic</span>} */}
                </div>
              </div>

              <div className="col-lg-6 col-12">
                <h2 className="">Enter Pincode :</h2>

                <div className="position-relative pb-4 mb-4">
                  <input
                    {...register("pin_code", {
                      required: "Pin code is required",
                    })}
                    value={address1.pin_code}
                    className="form-control"
                    name="pin_code"
                    placeholder="pin Code"
                    onChange={handleInputChange}
                  />
                  <span className="f-error"> {errors?.pin_code?.message}</span>
                  {/* {errors.pin_code &&  errors.pin_code.type === 'pattern' && <span className="f-error"> Enter valid pincode</span>} */}
                </div>
              </div>

              <div className="col-lg-6 col-12">
                <div className="position-relative pb-4 mb-4">
                  <span className="fw-bold me-2">Select Address Type :</span>
                  <div className="form-check form-check-inline position-relative">
                    <input
                      className="form-check-input custom-control-input"
                      {...register("address_type", {
                        require: "Address type is required",
                      })}
                      onChange={handleInputChange}
                      checked={address1.address_type === "house"}
                      type="radio"
                      name="address_type"
                      id="inlineRadio111"
                      value="house"
                    />
                    <label
                      className="form-check-label custom-control-label"
                      htmlFor="inlineRadio111"
                    >
                      House
                    </label>
                  </div>

                  <div className="form-check form-check-inline position-relative">
                    <input
                      className="form-check-input custom-control-input"
                      {...register("address_type", {
                        require: "Address type is required",
                      })}
                      onChange={handleInputChange}
                      checked={address1.address_type === "office"}
                      type="radio"
                      name="address_type"
                      id="inlineRadio222"
                      value="office"
                    />

                    <label
                      className="form-check-label custom-control-label"
                      htmlFor="inlineRadio222"
                    >
                      Business
                    </label>
                  </div>

                  <span className="f-error">
                    {" "}
                    {errors?.address_type?.message}
                  </span>
                </div>
              </div>

              <div className="col-lg-6 col-12">
                <div className="form-check position-relative">
                  <input
                    type="checkbox"
                    id="address-check"
                    className="d-none"
                    value="1"
                    checked={address1.is_default === "1" ? true : false} // Convert to boolean
                    onChange={(e) => {
                      console.log("e target", e.target);
                      setAddress1({
                        ...address1,
                        is_default: e.target.checked === true ? "1" : "0", // Use "1" or "0"
                      });
                    }}
                    name="is_default"
                  />

                  <label
                    for="address-check"
                    className="DCustomCheck ps-4 mb-lg-0 mb-5"
                  >
                    Make This default address{" "}
                  </label>

                  <input
                    type="hidden"
                    name="address"
                    {...register("address")}
                    value={address1.address}
                  />
                  <input
                    type="hidden"
                    name="lat"
                    {...register("lat")}
                    value={address1.lat}
                  />
                  <input
                    type="hidden"
                    name="lng"
                    {...register("lng")}
                    value={address1.lng}
                  />
                </div>
              </div>

              <div className="col-lg-6">
                <button
                  disabled={disabled}
                  class="btn btn-primary d-block w-100 fs-2 py-4"
                  type="submit"
                >
                  Add Address
                </button>
              </div>

              <div className="col-lg-6 mt-lg-0 mt-3">
                <button
                  type="button"
                  onClick={(e) => {
                    e.preventDefault();
                    toggleModal();
                    resetAddress();
                  }}
                  className="btn btn-outline-danger d-block w-100 fs-2 py-4 guestloginbtn"
                >
                  I'll do it later
                </button>
              </div>
            </div>
          </form>
        </div>
      </ModalBody>
    </Modal>
  );
}

export default UserAddress;
