import React, { useEffect, useState } from "react";
import { AppConst } from "../../../AppConst";
import { Modal, ModalBody, ModalHeader, Form } from "reactstrap";
import moment from "moment";
import { callApi } from "../../../hooks/useapi";
import { sendErrorInfo, sendSuccessInfo } from "../../../utility/helper";
import { useNavigate } from "react-router-dom";
import ReactStars from "react-rating-stars-component";
const OrderListCard = ({
  orderImg,
  orderFood,
  kitchenName,
  kitchenAddress,
  quantity,
  size,
  order_extra = [],
  orderAddress,
  sendOrderId,
  btnTitle3,
  orderNo,
  showModal,
  orderTime,
  orderOtp,
  orderItem,
  totalPaid,
  btnTitle,
  btnTitle2,
  onClick,
  fullOrder = [],
  deliveryBoyInfo,
  Orderitems,
  pre_order,
  pre_full_date_time,
  pre_date,
  pre_time,
  kitchen_id,
  is_self,
  orderStatus,
  handleReOrder = () => { },
  delivery_boy_info,
}) => {
  const [modal, setModal] = useState(false);
  const [modal1, setModal1] = useState(false);
  const [modal2, setModal2] = useState(false);
  const [driverId, setDriverId] = useState(0);
  const [kitchenOrderInfo, setKitchenOrderInfo] = useState([]);
  const [driverOrderInfo, setDriverOrderInfo] = useState([]);
  const [driverRating, setDriverRating] = useState(3);
  const [foodRating, setfoodRating] = useState(3);
  const [driverReview, setDriverReview] = useState("");
  const [foodReview, setfoodReview] = useState("");
  const [currentTime, setCurrentTime] = useState(100);
  const toggleModal = () => setModal(!modal);
  const toggleModal1 = () => setModal1(!modal1);
  const toggleModal2 = () => setModal2(!modal2);
  const navigate = useNavigate();
  const getOrderDetails = () => {
    callApi("user/order-details/" + orderNo, { method: "GET" }).then(
      (response) => {
        if (response.status) {
          if (
            response.data &&
            response.data.order_details[0].order_kitchen_rating
          ) {
            setKitchenOrderInfo(
              response.data.order_details[0].order_kitchen_rating
            );
          }
          if (
            response.data &&
            response.data.order_details[0].driver_order_rating
          ) {
            setDriverOrderInfo(
              response.data.order_details[0].driver_order_rating
            );
          }
        }
      }
    );
  };

  const redirectPrintInvoice = () => {
    navigate("/invoice", {
      state: {
        fullOrder: fullOrder,
      },
    });
  };
  const handleFoodRating = (value) => {
    setfoodRating(value);
    if (value == "1") {
      setCurrentTime(65);
    } else if (value == "2") {
      setCurrentTime(85);
    } else if (value == "3") {
      setCurrentTime(100);
    } else if (value == "4") {
      setCurrentTime(125);
    } else if (value == "5") {
      setCurrentTime(149);
    }
  };
  const submitFoodFeedback = (e) => {
    e.preventDefault();
    let data = {
      kitchen_id: kitchen_id,
      order_id: orderNo,
      rating: foodRating,
      review: foodReview,
    };
    callApi("user/add-kitchen-rating", { method: "POST", data: data }).then(
      (response) => {
        if (response.status) {
          sendSuccessInfo("Thanks for your feedback");
          getOrderDetails();
          setModal1(false);
        } else {
          sendErrorInfo(response.errors[0].detail);
        }
      }
    );
    // console.log("medical cannabis review", data);
  };
  const submitDriverFeedback = (e) => {
    e.preventDefault();
    let data = {
      driver_id: driverId,
      order_id: orderNo,
      rating: driverRating,
      review: driverReview,
    };
    callApi("user/add-driver-rating", { method: "POST", data: data }).then(
      (response) => {
        if (response.status) {
          sendSuccessInfo("Thanks for your feedback");
          getOrderDetails();
          setModal2(false);
        }
      }
    );
  };
  const getOrderStatusClass = (status) => {
    switch (status) {
      case '1':
        return 'text-warning ms-2'
      case '0':
        return 'text-danger ms-2'
      default:
        return 'text-success ms-2'
    }
  }
  return (
    <>
      <div className="card storelistcard border-0 mb-4">
        <div className="card-body py-4">
          <div className="row justify-content-around">
            <div className="col-xxl-2 col-xl-3 col-lg-3 col-12">
              <img src={orderImg} className="CardProductIMG" alt="" />
            </div>

            <div className="col-xxl-10 col-xl-9 col-lg-9 col-12 pe-lg-4">
              <div className="d-lg-flex justify-content-between align-items-center">
                <h1 className="fw-bold mb-2 mt-lg-0 mt-3">
                  #{orderNo} {orderFood}
                </h1>
                <p className="fw-bold text-muted d-inline-block mb-0">
                  Status :<span className={getOrderStatusClass(orderStatus)}>{btnTitle}</span>
                </p>
              </div>

              <p className="text-truncate text-success fs-3 fw-600 my-4">
                {kitchenName} :
                <br className="d-lg-none d-block" />
                <span className="ms-lg-2 text-muted">{kitchenAddress}</span>
              </p>

              <hr className="d-lg-none d-block" />

              <div className="d-lg-flex justify-content-between align-items-center text-muted fs-3 fw-600 my-4">
                <div className="d-xxl-flex">
                  <p>
                    Quantity:
                    <span className="ms-2 text-primary">({quantity})</span>
                  </p>
                  <p className="mx-xxl-5">
                    Size: <span className="ms-2 text-primary">{size}</span>
                  </p>
                  <div className="extralist position-relative">
                    {"Extra"}:
                    <a
                      href="#!"
                      className="ms-2 text-primary cursor-pointer text-decoration-none"
                    >
                      {order_extra[0]?.order_extra_item.length || 0}{" "}
                      {order_extra[0]?.title} added
                    </a>
                  </div>
                  <div className="extralisthover">
                    <ul className="extralist d-flex justify-content-between flex-wrap list-unstyled position-relative mb-0">
                      {order_extra[0]?.order_extra_item.length > 0 &&
                        order_extra[0]?.order_extra_item.map((extra) => (
                          <li className="mb-3" key={extra.id}>
                            <span className="d-lg-inline-block d-block">
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/img/checkboxGreen.png"
                                }
                                className="extratick"
                                alt=""
                              />
                              {extra.name}
                            </span>
                          </li>
                        ))}
                    </ul>
                  </div>
                </div>

                <div className="d-xxl-flex justify-content-end PProductlgbtn">
                  <button
                    onClick={() => {
                      handleReOrder(fullOrder);
                    }}
                    className="btn btn-outline-primary fs-3 d-lg-inline-block d-none ms-xxl-4"
                  >
                    Re-order
                  </button>

                  <button
                    onClick={() => {
                      getOrderDetails();
                      setTimeout(() => {
                        toggleModal();
                      }, 1000);
                    }}
                    className="btn btn-primary fs-3 d-lg-inline-block d-none mx-xxl-5"
                  >
                    View order
                  </button>
                </div>
              </div>
            </div>

            <div className="col-lg-12 col-12 d-lg-none d-block">
              <button
                onClick={() => {
                  handleReOrder(fullOrder);
                }}
                className="btn btn-outline-primary fs-3 d-block w-100 mb-3"
              >
                Re-order
              </button>

              <button
                onClick={() => {
                  getOrderDetails();
                  setTimeout(() => {
                    toggleModal();
                  }, 1000);
                }}
                className="btn btn-primary fs-3 d-block w-100"
              >
                View order
              </button>
            </div>
          </div>
        </div>
      </div>

      <Modal
        isOpen={modal}
        toggle={toggleModal}
        size="lg"
        className="modal-dialog-centered modal-dialog-scrollable AddtocardProductModal"
      >
        <ModalHeader className="p-0 d-block">
          <div className="productviewslider">
            <img alt="xbix" src={Orderitems && Orderitems[0]?.product_image} />
          </div>

          <button
            type="button"
            onClick={toggleModal}
            className="btn-close customClose"
          >
            <i class="fa-solid fa-xmark" />
          </button>
        </ModalHeader>

        <ModalBody>
          <div className="row">
            <div className="col-lg-12 col-12">
              <div className="table-responsive">
                <table className="invoice-table ordderTable table table-borderless border-white align-middle table-stripe-column">
                  <thead>
                    <tr className="bg-primary text-white">
                      <th className=" text-center p-3">SN</th>
                      <th className=" text-center p-3">Item Descriptions</th>
                      <th className=" text-center p-3">QTY</th>
                      <th className=" text-center p-3">Amount</th>
                    </tr>
                  </thead>

                  <tbody>
                    {Orderitems &&
                      Orderitems.length > 0 &&
                      Orderitems.map((orderdeails, index) => (
                        <tr>
                          <td className="text-secondary fw-bold text-center p-3">
                            {index + 1}
                          </td>

                          <td className="text-secondary text-center p-3">
                            <p className="mb-0 fw-bold">{orderdeails.name} </p>
                            <p className="mb-0">
                              <span className="fw-bold me-2">Size :</span>{" "}
                              {orderdeails.variant_size}
                            </p>

                            <p className="mb-1 fw-bold">
                              {orderdeails.order_extra &&
                                orderdeails.order_extra.length > 0 && (
                                  <>
                                    {orderdeails.order_extra &&
                                      orderdeails.order_extra[0]?.title}
                                    :{" "}
                                    {orderdeails.order_extra &&
                                      orderdeails.order_extra[0]
                                        ?.order_extra_item &&
                                      orderdeails.order_extra &&
                                      orderdeails.order_extra[0]
                                        ?.order_extra_item.length > 0 &&
                                      orderdeails.order_extra &&
                                      orderdeails.order_extra[0]?.order_extra_item.map(
                                        (item) => <>{item.name} </>
                                      )}
                                  </>
                                )}
                            </p>

                            {orderdeails?.addons_item &&
                              orderdeails?.addons_item.length > 0 && (
                                <p className="mb-0">
                                  <span className="fw-bold me-2">Addons :</span>{" "}
                                  {orderdeails.addons_item.map((item) => (
                                    <> {item.addon_name} </>
                                  ))}
                                </p>
                              )}
                          </td>

                          <td className="text-secondary fw-bold text-center p-3">
                            {orderdeails.quantity}
                          </td>

                          <td className="text-secondary fw-bold text-center p-3">
                            {AppConst.getBussInfo.currency}
                            {orderdeails.price}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
            {orderStatus === "0"
              ? ""
              : kitchenOrderInfo.length > 0 && (
                <div>
                  <div className="card-body py-0">
                    <h4 className="card-titile">Product Rating</h4>
                    <div style={{ pointerEvents: "none" }}>
                      <ReactStars
                        count={5}
                        value={kitchenOrderInfo[0].rating || 0}
                        disable
                        size={24}
                        activeColor="#ffd700"
                      />
                    </div>
                    <p className="card-text text-muted mb-1">
                      {" "}
                      {kitchenOrderInfo[0].review
                        ? kitchenOrderInfo[0].review
                        : "No review added"}
                    </p>
                  </div>
                </div>
              )}


            <div className="col-lg-12 col-12">
              <div className="table-responsive">
                <table className="table table-bordered mb-4">
                  {is_self != "1" && deliveryBoyInfo && (

                    <thead>
                      <tr className="bg-primary text-white">
                        <th colSpan={"2"}>Driver Details</th>
                      </tr>
                    </thead>
                  )}

                  <tbody>
                    {is_self != "1" && deliveryBoyInfo && (
                      <>
                        <tr>
                          <td rowSpan={"3"} style={{ maxWidth: "50px" }}>
                            <p className="mb-0">
                              <img
                                src={delivery_boy_info?.profile_image}
                                className="modalDriverImg"
                                alt=""
                              />
                            </p>
                          </td>

                          <td>
                            <p className="mb-0">{delivery_boy_info?.name}</p>
                          </td>
                        </tr>

                        <tr>
                          <td>
                            <p className="mb-0">
                              <span className="fw-bold me-2">Ph :</span>{" "}
                              {delivery_boy_info && delivery_boy_info?.mobile}
                            </p>
                          </td>
                        </tr>

                        <tr>
                          <td>
                            <p className="mb-0">
                              <span className="fw-bold me-2">
                                Vehicle Number:
                              </span>{" "}
                              {delivery_boy_info &&
                                delivery_boy_info?.vehicle_number}
                            </p>
                          </td>
                        </tr></>
                    )}
                    <tr>
                      {(is_self === 0 &&
                        orderStatus !== "0" &&
                        driverOrderInfo.length === 0) || (orderStatus !== "0" &&
                          kitchenOrderInfo.length === 0) && <td colSpan={"2"} className="p-3 text-center">
                          {orderStatus !== "0" &&
                            kitchenOrderInfo.length === 0 && (
                              <button
                                className="btn btn-primary fw-bold fs-3 py-2 px-3 me-4"
                                onClick={() => {
                                  getOrderDetails();
                                  setModal1(true);
                                }}
                              >
                                Rate Product
                              </button>
                            )}

                          {is_self === 0 &&
                            orderStatus !== "0" &&
                            driverOrderInfo.length === 0 && (
                              <button
                                className="btn btn-primary fw-bold fs-3 py-2 px-3"
                                onClick={() => {
                                  setDriverId(
                                    delivery_boy_info && delivery_boy_info.id
                                  );
                                  setModal2(true);
                                }}
                              >
                                Rate Driver
                              </button>
                            )}
                        </td>}
                    </tr>
                  </tbody>
                </table>
              </div>
              {orderStatus !== "0" && driverOrderInfo.length > 0 && (
                <div>
                  <div className="card-body p-0">
                    <h2 className="card-titile">Driver Rating</h2>
                    <div style={{ pointerEvents: "none" }}>
                      <ReactStars
                        count={5}
                        value={driverOrderInfo[0].rating}
                        size={24}
                        disable
                        activeColor="#ffd700"
                      />
                    </div>
                    <p className="card-text text-muted">
                      {" "}
                      {driverOrderInfo[0].review
                        ? driverOrderInfo[0].review
                        : "No Review Added"}
                    </p>
                  </div>
                </div>

              )}


              {pre_order === "1" && (
                <div className="card-footer bg-blue d-flex align-items-center">
                  <h4 className="">Pre order on:</h4>
                  <p className="ms-auto ">
                    {moment(pre_date).format("DD MMM, YYYY")} {pre_time}{" "}
                  </p>
                </div>
              )}

              <div className="col-lg-12 col-12">
                <table className="table table-bordered mb-0 h-100 mb-4">
                  <thead>
                    <tr className="bg-primary text-white">
                      <th colSpan={"2"}>Your Order Amount</th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr>
                      <td>
                        <p className="mb-0">Amount :</p>
                      </td>

                      <td>
                        <p className="mb-0">
                          {AppConst.getBussInfo.currency}{" "}
                          {fullOrder?.sub_total &&
                            fullOrder?.sub_total.toFixed(2)}
                        </p>
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <p className="mb-0">Delivery Fee :</p>
                      </td>

                      <td>
                        <p className="mb-0">
                          {AppConst.getBussInfo.currency}{" "}
                          {fullOrder?.serviceFee &&
                            fullOrder?.serviceFee.toFixed(2)}
                        </p>
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <p className="mb-0">VAT :</p>
                      </td>

                      <td>
                        <p className="mb-0">
                          {" "}
                          {AppConst.getBussInfo.currency}{" "}
                          {fullOrder?.tax && fullOrder?.tax.toFixed(2)}(
                          {fullOrder.tax_per}%)
                        </p>
                      </td>
                    </tr>

                    <tr className="bg-primary text-white">
                      <td style={{ borderRightColor: "#ffffff" }}>
                        <p className="mb-0">Sub-Total</p>
                      </td>

                      <td>
                        <p className="mb-0">
                          {AppConst.getBussInfo.currency}{" "}
                          {(
                            fullOrder.sub_total +
                            fullOrder.tax +
                            fullOrder.serviceFee
                          ).toFixed(2)}
                        </p>
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <p className="mb-0">Tip Amount :</p>
                      </td>

                      <td>
                        <p className="mb-0">
                          {AppConst.getBussInfo.currency} {fullOrder.tip}
                        </p>
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <p className="mb-0">Discount :</p>
                      </td>

                      <td>
                        <p className="mb-0">
                          {AppConst.getBussInfo.currency}
                          <span className="mx-2">-</span>
                          {fullOrder?.discount_amount &&
                            fullOrder?.discount_amount.toFixed(2)}
                        </p>
                      </td>
                    </tr>

                    <tr className="bg-primary text-white">
                      <td style={{ borderRightColor: "#ffffff" }}>
                        <p className="mb-0">Grand-Total</p>
                      </td>

                      <td>
                        {AppConst.getBussInfo.currency}{" "}
                        {fullOrder?.amount && fullOrder?.amount.toFixed(2)}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className="col-lg-12 col-12 my-4">
                <h1 class="fs-1 text-primary fw-bold mb-2 d-flex justify-content-between align-items-center">
                  Additional Note
                </h1>
                {fullOrder?.additional_notes != "No extra note" ? (
                  <p className="mb-0">{fullOrder.additional_notes}</p>
                ) : (
                  <p className="text-danger fw-bold mb-0">No notes available</p>
                )}
              </div>
              {fullOrder?.cancel_reason && (
                <div className="col-lg-12 col-12 my-4">
                  <h1 class="fs-1 text-primary fw-bold mb-2 d-flex justify-content-between align-items-center">
                    Cancel Reason
                  </h1>

                  <p className="mb-0 text-danger fw-600">{fullOrder.cancel_reason}</p>
                </div>
              )}
              <div className="col-lg-12 col-12">
                <table className="table table-bordered mb-4">
                  <thead>
                    <tr className="bg-primary text-white">
                      <th colSpan={"2"}>Order Details</th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr>
                      <td>
                        <p className="mb-0">Payment Mode :</p>
                      </td>

                      <td>
                        <p className="mb-0">{fullOrder.payment_method}</p>
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <p className="mb-0">Date :</p>
                      </td>

                      <td>
                        <p className="mb-0">
                          {" "}
                          {fullOrder?.pre_full_date_time
                            ? fullOrder.pre_full_date_time
                            : moment(pre_date).format("YYYY-MM-DD")}
                        </p>
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <p className="mb-0">Delivered To :</p>
                      </td>

                      <td>
                        <p className="mb-0">{fullOrder.delivery_address}</p>
                      </td>
                    </tr>

                    <tr>
                      <td colSpan={"2"} className="p-3 text-center">
                        <button
                          className="btn btn-primary fw-bold fs-3 px-4 py-2"
                          onClick={redirectPrintInvoice}
                        >
                          Print Invoice
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>

      <Modal
        isOpen={modal1}
        toggle={toggleModal1}
        className="modal-dialog modal-dialog-centered modal-dialog-scrollable AddtocardProductModal CancelOrderModal"
      >
        <ModalHeader className="d-block border-0 p-0 position-relative ">
          <span className="fw-bold"> Your feed back is important </span>

          <button
            onClick={toggleModal1}
            type="button"
            className="btn-close customClose"
          >
            <i className="fa-solid fa-xmark" />
          </button>
        </ModalHeader>

        <ModalBody className="pt-2 px-4 mx-3">
          <hr />

          <h2 className="fw-bold mb-2">
            Rate your product
            <br className="d-lg-none d-block" />
            <small className="fw-400 fs-4 ms-lg-3 text-success">
              (Rate the quality of the order)
            </small>
          </h2>

          <form onSubmit={submitFoodFeedback}>
            <ReactStars
              count={5}
              size={24}
              activeColor="#ffd700"
              value={foodRating}
              onChange={(e) => handleFoodRating(e)}
            />

            <p className="text-primary fw-bold">Share your review with us :</p>

            <div className="form-group mb-5">
              <textarea
                placeholder="write your review"
                className="form-control"
                onChange={(e) => setfoodReview(e.target.value)}
              ></textarea>
            </div>

            <div className="d-flex justify-content-center mb-3">
              <button
                className="btn btn-primary d-block fw-bold fs-3 py-2 px-4"
                type="submit"
              >
                Submit your feedback
              </button>
            </div>
          </form>
        </ModalBody>
      </Modal>

      <Modal
        isOpen={modal2}
        toggle={toggleModal2}
        className="modal-dialog modal-dialog-centered modal-dialog-scrollable AddtocardProductModal CancelOrderModal"
      >
        <ModalHeader className="d-block border-0 p-0 position-relative ">
          <span className="fw-bold"> Your feed back is important </span>

          <button
            onClick={toggleModal2}
            type="button"
            className="btn-close customClose"
          >
            <i className="fa-solid fa-xmark" />
          </button>
        </ModalHeader>

        <ModalBody className="pt-2 px-4 mx-3">
          <hr />

          <h2 className="fw-bold mb-2">
            Rate your driver
            <br className="d-lg-none d-block" />
            <small className="fw-400 fs-4 ms-lg-3 text-success">
              (Share your experience with our delivery boy)
            </small>
          </h2>

          <form onSubmit={submitDriverFeedback}>
            <ReactStars
              count={5}
              size={24}
              activeColor="#ffd700"
              value={driverRating}
              onChange={(value) => setDriverRating(value)}
            />

            <p className="text-primary fw-bold">Share your review with us :</p>

            <div className="form-group mb-5">
              <textarea
                placeholder="write your review"
                className="form-control"
                onChange={(e) => setDriverReview(e.target.value)}
              ></textarea>
            </div>

            <div className="d-flex justify-content-center mb-3">
              <button
                className="btn btn-primary d-block fw-bold fs-3 py-2 px-4"
                type="submit"
              >
                Submit your feedback
              </button>
            </div>
          </form>
        </ModalBody>
      </Modal>
    </>
  );
};

export default OrderListCard;
