export const KM_RANGES = {
  KM: "KM",
  Miles: "Miles",
};

export class AppConst {
  static getBussInfo = {
    owner_id: process.env.REACT_APP_OWNER_ID,
    business_owner_id: process.env.REACT_APP_OWNER_ID,
    is_live: true,
    currency: "R",
    live_payment_id: "com.appwish.cafejapan.customer.payments",
    app_name: "Green Central",
    distance_by: KM_RANGES.KM, // KM, Miles
    country_code: "+27",
    currency_code: "ZAR",
  };
}
