import React from "react";
import { AppConst } from "../../../AppConst";

const HeroSection = () => {
  return (
    <section className="herobanner position-relative m-0">
      <div className="row justify-content-around flex-column h-100">
        <div className="col-xl-7 col-lg-6 col-12">
          <h1 className="bannerheading mb-0">
            Harmonizing Wellness and Sophistication with Medicinal Cannabis.
          </h1>

          {/* <p className="my-4 pb-3">Discover the best of world cuisine</p> */}

          <span className="bannerlabel">
            <img src="./assets/img/bannerdelivery.png" alt="" />
            Delivery
          </span>

          <span className="bannerlabel mx-4">
            <img src="./assets/img/bannerpickup.png" alt="" />
            Pickup
          </span>

          <span className="bannerlabel">
            <img src="./assets/img/bannercurior.png" alt="" />
            Courier
          </span>
        </div>

        <div className="col-lg-12 col-12">
          <p className="mb-0 fs-3 text-white opacity-50">
            {AppConst.getBussInfo.app_name}: Cheltenham, united kingdom
          </p>
          <p className="mb-0 fs-3 text-white opacity-50">Since: 2019</p>
        </div>
      </div>
      {/*----- Background Video -----*/}

      <video src="./assets/img/herobannervideo.mov" loop autoPlay muted />

      {/*----- Banner floating image -----*/}
      <img
        src="./assets/img/banner-image.png"
        className="bannerfloatingimg d-lg-block d-none"
        alt=""
      />
    </section>
  );
};

export default HeroSection;
