import React from "react";

const PaymentForm = React.forwardRef(
  ({ returnUrl, totalPrice, uniqeId, buttonTitle }, ref) => {
    return (
      <div>
        <form action={process.env.REACT_APP_PAYMENT_GATWAY_URL} method="post">
          <input
            type="hidden"
            name="merchant_id"
            value={process.env.REACT_APP_MARCHENT_ID}
          />
          <input
            type="hidden"
            name="merchant_key"
            value={process.env.REACT_APP_MARCHENT_KEY}
          />
          <input
            type="hidden"
            name="return_url"
            value={process.env.REACT_APP_LOCAL_PATH + returnUrl}
          />
          <input
            type="hidden"
            name="cancel_url"
            value={`${process.env.REACT_APP_LOCAL_PATH}${
              returnUrl.split("?")[0]
            }`}
          />
          <input
            type="hidden"
            name="notify_url"
            value={`${process.env.REACT_APP_LOCAL_PATH}/notify`}
          />
          <input type="hidden" name="m_payment_id" value={"id"} />
          <input type="hidden" name="amount" value={totalPrice} />
          <input type="hidden" name="item_name" value={uniqeId} />

          <button type="submit" className="btn" ref={ref}>
            {buttonTitle}
          </button>
        </form>
      </div>
    );
  }
);

export default PaymentForm;
