import React from "react";
import { AppConst } from "../../../AppConst";

const AddedItemsDetails = ({
  cartData,
  handleAddmore,
  handleClearCart = () => { },
}) => {
  return (
    <>
      {" "}
      <div className="d-flex justify-content-between">
        {" "}
        <p className="text-muted my-4 fw-bold fs-3">Items Added</p>{" "}
        {cartData && cartData.length > 0 && (
          <a
            onClick={() => handleClearCart()}
            href="#!"
            className="text-success  my-4 fw-bold fs-3"
          >
            Clear Cart
          </a>
        )}
      </div>
      <div className="addeditemsdeatils">
        {/*----- Added product card -----*/}
        {cartData &&
          cartData.length > 0 &&
          cartData.map((item, index) => (
            <div className="card border-0 mb-3">
              <div className="card-body">
                <div className="row mx-0">
                  <div className="col-lg-2 col-12 ps-lg-0">
                    <img
                      src={item?.product_image}
                      className="CardProductIMG"
                      alt=""
                    />
                  </div>

                  <div className="col-lg-10 col-12 pe-lg-0  mt-lg-0 mt-3">
                    <h2 className="fw-bold d-flex justify-content-between align-items-center">
                      {item?.name}
                      <p className="mb-0 fw-bold fs-3">
                        {" "}
                        {AppConst.getBussInfo.currency}
                        {(
                          item?.product_price &&
                          item?.product_price.toFixed(2) * item.quantity
                        ).toFixed(2)}
                      </p>
                    </h2>
                    <p className="mb-0 fw-bold fs-3">
                      {" "}
                      {AppConst.getBussInfo.currency}
                      {item?.product_price &&
                        item?.product_price.toFixed(2)} X {item.quantity}
                    </p>

                    <div className="d-flex justify-content-between align-items-center">
                      <ul className="extralist list-unstyled position-relative my-lg-0 my-4">
                        <li className="mb-0">
                          <span className="d-lg-inline-block d-block">
                            <img
                              src="./assets/img/checkboxGreen.png"
                              className="extratick"
                              alt=""
                            />
                            {item?.selected_topping_obj?.name}
                          </span>

                          <a className="ms-lg-4 fw-bold cursor-pointer">
                            View {Object.keys(item?.selected_topings).length}{" "}
                            more extras
                          </a>
                          <div className="extralisthover">
                            <ul className="extralist d-flex justify-content-between flex-wrap list-unstyled position-relative mb-0">
                              {Object.keys(item?.selected_topings).map(
                                (keys) => (
                                  <li className="mb-3">
                                    <span className="d-lg-inline-block d-block">
                                      <img
                                        src="./assets/img/checkboxGreen.png"
                                        className="extratick"
                                        alt=""
                                      />
                                      {item?.selected_topings[keys].addon_name}
                                    </span>
                                  </li>
                                )
                              )}
                            </ul>
                          </div>
                        </li>
                      </ul>

                      <div className="quantity">
                        <div className="d-flex counter align-items-center justify-content-lg-center">
                          <button
                            type="button"
                            onClick={(e) =>
                              handleAddmore({
                                type: "remove",
                                index: index,
                                quantity: item.quantity,
                              })
                            }
                          >
                            -
                          </button>
                          <p className="mb-0 text-primary fw-bold fs-3">
                            {item.quantity}
                          </p>
                          <button
                            type="button"
                            onClick={(e) =>
                              handleAddmore({
                                type: "add",
                                index: index,
                                quantity: item.quantity,
                              })
                            }
                          >
                            +
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>
    </>
  );
};

export default AddedItemsDetails;
