import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { callApi } from "../../../hooks/useapi";
import {
  sendErrorInfo,
  sendSuccessInfo,
  setCookies,
} from "../../../utility/helper";
import { saveUserData } from "../../../store/actions/actions";

const UserProfile = () => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({ mode: "onChange" });
  const userData = useSelector((state) => state.root.user_data);
  const dispatch = useDispatch();
  useEffect(() => {
    reset({
      name: userData.name,
      email: userData.email,
      mobile: userData.mobile,
      phone_code: userData.phone_code,
      gender: userData.gender,
    });
  }, [userData]);
  const onSubmit = (data) => {
    // data.gender = data.gender;

    let PostRequest = data;

    callApi("user/profile-update", { method: "POST", data: PostRequest })
      .then((response) => {
        if (response.status) {
          setCookies(
            process.env.REACT_APP_BUSINESS + "user_data",
            JSON.stringify(response.data),
            { path: "/" }
          );
          dispatch(saveUserData(response.data));
          sendSuccessInfo("Successfully Update.");
        } else {
          sendErrorInfo(response.error.error);
        }
      })
      .catch((error) => {
        sendErrorInfo("Server error try again");
      });
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="row">
        <div className="col-lg-6 col-12 form-group mb-4">
          <label>Enter Full Name :</label>
          <input
            placeholder="Full Name"
            name="name"
            {...register("name", { required: "Name is required" })}
            className="form-control"
          />
        </div>

        <div className="col-lg-6 col-12 form-group mb-4">
          <label>Enter Email :</label>
          <input
            type="email"
            {...register("email")}
            readOnly
            className="form-control"
          />
        </div>

        <div className="col-lg-6 col-12 mb-4">
          <label>Select Country :</label>
          <input
            type="text"
            {...register("phone_code")}
            readOnly
            className="form-control"
          />
        </div>

        <div className="col-lg-6 col-12 mb-4">
          <label>Mobile :</label>
          <input
            type="text"
            {...register("mobile")}
            readOnly
            className="form-control"
          />
        </div>

        <div className="col-lg-12 col-12 mb-4">
          <span className="me-3">Select Gender :</span>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              {...register("gender")}
              type="radio"
              name="gender"
              id="inlineRadio1"
              value="male"
            />
            <label className="form-check-label" for="inlineRadio1">
              Male
            </label>
          </div>

          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              {...register("gender")}
              type="radio"
              name="gender"
              id="inlineRadio2"
              value="female"
            />
            <label className="form-check-label" for="inlineRadio2">
              Female
            </label>
          </div>
        </div>

        <div className="col-xl-2 col-lg-4 col-12 text-end ms-lg-auto">
          <button type="submit" className="btn btn-success d-block w-100 fw-600  fs-3  py-2 mt-5">
            Update Profile
          </button>
        </div>
      </div>
    </form>
  );
};

export default UserProfile;
