import React, { useEffect, useRef, useState } from "react";
import { callApi } from "../../../hooks/useapi";
import { AppConst } from "../../../AppConst";
import PaymentForm from "../cart/PaymentForm";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { generateTransectionKey } from "../../../store/actions/actions";
import { generateRandomString, sendSuccessInfo } from "../../../utility/helper";
import moment from "moment";
const Wallet = ({ getWalletBalance = () => { }, activetedTab }) => {
  const paymentCardRef = useRef(null);
  const [walletData, setWalletData] = useState({});
  const [amount, setAmount] = useState(0);
  const dispatch = useDispatch();
  const transection_key = useSelector((state) => state.root.transection_key);
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  useEffect(() => {
    console.log("activetedTab", activetedTab);
    if (
      searchParams.get("tid") === transection_key &&
      searchParams.get("amt") &&
      activetedTab === "1"
    ) {
      let postData = {
        amount: searchParams.get("amt"),
        transaction_id: searchParams.get("tid"),
        description: "Top-up added",
      };
      callApi("user/wallet-recharge", { method: "POST", data: postData }).then(
        (response) => {
          if (response.status === true) {
            sendSuccessInfo("Wallet recharge succefully");
            dispatch(generateTransectionKey(""));
            getWallet();
            navigate("/profile/wallet");
            dispatch(generateTransectionKey(generateRandomString(15)));
          } else {
            sendSuccessInfo(response.error.error);
          }
        }
      );
    }
    if (transection_key === "") {
      dispatch(generateTransectionKey(generateRandomString(15)));
    }
    getWallet();
  }, [activetedTab]);
  const getWallet = () => {
    callApi("user/wallet-amount", { method: "GET" }).then((response) => {
      if (response.status === true) {
        getWalletBalance(response.data?.total_amount || 0);
        setWalletData(response.data);
      }
    });
  };

  const getUsedPrice = () => {
    let value = 0;
    walletData?.wallet_history &&
      walletData?.wallet_history.length > 0 &&
      walletData?.wallet_history.filter((item) => {
        if (item.type !== "credit") {
          value = value + Math.abs(item.amount);
        }
      });
    return value;
  };

  const getAmount = (amount) => {
    let value = amount.toString();
    return value ? value.replace("-", "") : "N/A";
  };
  return (
    <div className="row">
      <div className="col-xl-6 col-xxl-4 col-lg-6 col-12">
        <div className="card walletBalance border-0 h-100 flex-row align-items-center">
          <div className="card-body p-5">
            <div className="d-flex flex-wrap align-items-center">
              <i className="fa-solid fa-wallet" />
              <p className="mb-0 fw-bold">
                Wallet balance
                <br />
                <span className="fw-bold text-success">
                  {" "}
                  {AppConst.getBussInfo.currency}
                  {walletData?.total_amount || 0}
                </span>
              </p>

              <hr className="w-100 my-4" />
            </div>

            <div className="d-flex flex-lg-wrap justify-content-around align-items-center">
              <p className="mb-0 fw-bold">
                Used limit
                <br />
                <span className="text-muted">
                  {AppConst.getBussInfo.currency}
                  {getUsedPrice().toFixed(2)}
                </span>
              </p>

              <p className="mb-0 fw-bold">
                Available limit
                <br />
                <span className="text-muted">
                  {AppConst.getBussInfo.currency}
                  {parseFloat(walletData?.total_amount || 0).toFixed(2)}
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="col-xl-6 col-xxl-4 col-lg-6 col-12 mt-lg-0 mt-5">
        {/* <img
          src={process.env.PUBLIC_URL + "/assets/img/PaymentCard.png"}
          className="w-100 d-block"
          alt=""
        /> */}
        {/* <a
          href="#!"
          className="text-primary text-decoration-none fw-bold d-block my-2 ms-3"
        >
          Download card
          <i className="fa-solid fa-download ms-3 text-success" />
        </a> */}
      </div>

      <div className="col-lg-12 col-12 my-5">
        <div className="card border-0 wallettopupinput">
          <div className="card-body px-lg-5 px-3 py-5">
            <p className="mb-lg-4 mb-5">
              <i className="fa-solid fa-plus text-success me-3" />
              Add top-up to wallet
            </p>

            <div className="d-lg-flex">
              <input
                type="number"
                onChange={(e) => setAmount(e.target.value)}
                className="form-control w-50"
                placeholder=""
                min={0}
              />

              <button
                disabled={amount <= 0 ? "disabled" : ""}
                type="submit"
                class="btn btn-primary py-3 px-5 ms-lg-5 mt-lg-0 mt-4"
                onClick={() => paymentCardRef.current.click()}
              >
                Proceed to add{" "}
                <span class="ms-1">
                  {AppConst.getBussInfo.currency}
                  {amount}
                </span>
              </button>

              <div className="d-none">
                <PaymentForm
                  returnUrl={`${location.pathname}?tid=${transection_key}&amt=${amount}`}
                  totalPrice={amount}
                  uniqeId={transection_key}
                  buttonTitle={`Proceed to add ${AppConst.getBussInfo.currency}`}
                  ref={paymentCardRef}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-12 col-12">
        <div className="table-responsive transcationHistory">
          <table className="table table-borderless mb-0">
            <thead>
              <tr>
                <th className="px-5 py-4">
                  <p className="mb-0">Transactions</p>
                </th>
                <th className="col-1 col-md-2 px-5 py-4">
                  <p className="mb-0">Amount</p>
                </th>
                <th className="px-5 py-4">
                  <p className="mb-0">Status</p>
                </th>
                <th className="col-1 col-md-2 px-5 py-4">
                  <p className="mb-0">Date</p>
                </th>
                <th className="px-5 py-4">
                  <p className="mb-0">Comments</p>
                </th>
              </tr>
            </thead>
            <tbody className="">
              {walletData?.wallet_history &&
                walletData?.wallet_history.length > 0 &&
                walletData?.wallet_history
                  .sort(
                    (a, b) => new Date(b.created_at) - new Date(a.created_at)
                  )
                  .map((item, i) => (
                    <tr key={i}>
                      <td className="px-5 py-4">
                        <p className="mb-0">{item?.transaction_id}</p>
                      </td>
                      <td className="px-5 py-4">
                        <p className="mb-0">
                          {item.type !== "credit" ? "-" : ""}{" "}
                          {AppConst.getBussInfo.currency}
                          {getAmount(item?.amount)}
                        </p>
                      </td>
                      <td className="px-5 py-4">
                        <p
                          className={
                            item.type === "credit"
                              ? "mb-0 badge bg-success"
                              : "mb-0 badge bg-danger"
                          }
                        >
                          {item.type}
                        </p>
                      </td>
                      <td className="px-5 py-4">
                        <p className="mb-0">
                          {moment(item?.created_at).format(
                            "MMM DD, YYYY hh:mm:a"
                          )}
                        </p>
                      </td>
                      <td style={{ width: "500px" }} className="px-5 py-4">
                        <p className="mb-0">
                          {item.type === "credit"
                            ? item?.description
                            : "Paid for Order no " + item.order_id}
                        </p>
                      </td>
                    </tr>
                  ))}
              {walletData?.wallet_history &&
                walletData?.wallet_history.length === 0 && (
                  <tr className="px-5 py-4">
                    <td colSpan={5}>
                      <img
                        className="noDataImg"
                        src={
                          process.env.PUBLIC_URL + "/assets/img/NodataIMG.png"
                        }
                        alt=""
                      />
                      <p className="text-center text-muted fw-bold fs-3 mb-5 pb-5">
                        No Transaction History For You!
                      </p>
                    </td>
                  </tr>
                )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Wallet;
