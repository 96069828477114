import React from "react";
import PropTypes from "prop-types";
import { Modal, ModalBody, ModalHeader, ModalFooter, Button } from "reactstrap";
// import Button from "";
import { confirmable, createConfirmation } from "react-confirm";

class Confirmation extends React.Component {
  render() {
    const {
      proceedLabel,
      cancelLabel,
      title,
      confirmation,
      show,
      proceed,
      enableEscape = true,
    } = this.props;
    return (
      <div className="static-modal">
        <Modal
          isOpen={show}
          toggle={() => proceed(false)}
          backdrop={enableEscape ? true : "static"}
          keyboard={enableEscape}
          size="lg"
          className="modal-dialog modal-dialog-centered modal-dialog-scrollable AddtocardProductModal CancelOrderModal"
        >
          <ModalHeader className="d-block border-0 p-0 position-relative">
            <span className="fw-bold">Confirmation</span>
            <button
              onClick={() => proceed(false)}
              type="button"
              className="btn-close customClose"
            >
              <i className="fa-solid fa-xmark" />
            </button>
          </ModalHeader>

          <ModalBody>
            <p className="fs-2 text-primary">{confirmation}</p>

            <hr className="my-4" />

            <div className="d-flex justify-content-center alig-items-center mt-5 mb-3">
              {cancelLabel && <Button
                className="btn btn-danger px-4 py-2 fs-3"
                onClick={() => proceed(false)}
              >
                {cancelLabel}
              </Button>}

              <Button
                className="btn btn-outline-primary px-4 py-2 fs-3 ms-3"
                onClick={() => proceed(true)}
              >
                {proceedLabel}
              </Button>
            </div>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

Confirmation.propTypes = {
  okLabbel: PropTypes.string,
  cancelLabel: PropTypes.string,
  title: PropTypes.string,
  confirmation: PropTypes.string,
  show: PropTypes.bool,
  proceed: PropTypes.func, // called when ok button is clicked.
  enableEscape: PropTypes.bool,
};

export function confirm(
  confirmation,
  proceedLabel = "OK",
  cancelLabel = "Cancel",
  options = {}
) {
  return createConfirmation(confirmable(Confirmation))({
    confirmation,
    proceedLabel,
    cancelLabel,
    ...options,
  });
}
