import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { initStore } from "./store/index";
import { saveState } from "./utility/localstorage";
import ScrollToTop from "./utility/ScrollToTop";
import { ReactNotifications } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import "intl-tel-input/build/css/intlTelInput.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { throttle } from "lodash";
import { registerServiceWorker } from "./serviceworkerfile";
const store = initStore();
store.subscribe(
  throttle(() => {
    saveState({
      root: store.getState().root,
      cart: store.getState().cart,
    });
  }, 1000)
);
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter basename={process.env.PUBLIC_URL}>
    <Provider store={store}>
      <ScrollToTop />
      <ReactNotifications />
      <App />
    </Provider>
  </BrowserRouter>
);
registerServiceWorker();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
