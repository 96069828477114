import React, { useState } from 'react'
import Layout from '../../components/common/Layout'

import {
    Accordion,
    AccordionBody,
    AccordionHeader,
    AccordionItem,
} from "reactstrap";
import { Link } from 'react-router-dom';



const Faq = () => {

    return (
        <Layout pageclassName={"FAQPage"}>
            {/*----- Page title and discription section start -----*/}
            <section className="Pagetitledisc m-0">
                <div className="container-fluid py-3">
                    <div className="row">
                        <div className="col-lg-12 col-12">
                            <div className="d-lg-flex justify-content-between">
                                <div>
                                    {/*----- Section title -----*/}
                                    <h1 className="default_title">
                                        Frequently ask question
                                    </h1>
                                    {/*----- Section Sub title -----*/}
                                    <p className="default_subtitle mb-0">
                                        Get quick answers to common queries about our services, membership plans, and more
                                    </p>
                                </div>
                                {/*----- breadcrumb -----*/}
                                <nav className="align-self-end mt-lg-0 mt-4">
                                    <ol className="breadcrumb mb-0">
                                        <li className="breadcrumb-item">
                                            <Link to={"/home"}>Home</Link>
                                        </li>

                                        <li className="breadcrumb-item active">
                                            <p className="d-inline-block fw-bold text-muted">
                                                FAQ
                                            </p>
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/*----- Page title and discription section end -----*/}

            {/*----- FAQ section start -----*/}
            <FAQSection />
            {/*----- FAQ section end -----*/}
        </Layout>
    )
}

export default Faq


export const FAQSection = () => {
    const [open, setOpen] = useState("1");
    const toggle = (id) => {
        if (open === id) {
            setOpen();
        } else {
            setOpen(id);
        }
    };
    return (
        <section className="faqsection">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-11 col-12">
                        <div className="faqcontainer secMargin_left secPadding_left mt-5 pt-3">
                            <div className="container">
                                <Accordion
                                    open={open}
                                    toggle={toggle}
                                    className="accordion-flush"
                                >
                                    <AccordionItem>
                                        <AccordionHeader targetId="1">
                                            Keep your password confidential?
                                        </AccordionHeader>

                                        <AccordionBody
                                            accordionId="1"
                                            className=" text-muted fw-bold p-lg-5 p-0"
                                        >
                                            <p className="mb-0 fs-2">
                                                Some online purchases usually necessitate the customer to 'login' before viewing and placing an order. For this, the customers are required to enter a username and password. The customer must not share their login information with anyone. Information like birth date, maiden name or phone number should be avoided while selecting a password. The password chosen at a particular website should be different from the passwords kept at other websites. Alpha-numeric passwords with at least 8 characters are tougher to steal.
                                            </p>
                                        </AccordionBody>
                                    </AccordionItem>

                                    <AccordionItem>
                                        <AccordionHeader targetId="2">
                                            Unable to view the details in my profile?
                                        </AccordionHeader>

                                        <AccordionBody
                                            accordionId="2"
                                            className=" text-muted fw-bold p-lg-5 p-0"
                                        >
                                            <p className="mb-0 fs-2">
                                                Please check if your app is due for an update. If not, please share the details via mailto:info@greencentral.co.za
                                            </p>
                                        </AccordionBody>
                                    </AccordionItem>

                                    <AccordionItem>
                                        <AccordionHeader targetId="3">
                                            How much time it takes to deliver the order?
                                        </AccordionHeader>

                                        <AccordionBody
                                            accordionId="3"
                                            className=" text-muted fw-bold p-lg-5 p-0"
                                        >
                                            <p className="mb-0 fs-2">
                                                Generally it takes between 45 minutes to 1 hour time to deliver the order. Due to long distance or heavy traffic, delivery might take few extra minutes
                                            </p>
                                        </AccordionBody>
                                    </AccordionItem>

                                    <AccordionItem>
                                        <AccordionHeader targetId="4">
                                            I got the wrong/incomplete order. What do I do?
                                        </AccordionHeader>

                                        <AccordionBody
                                            accordionId="4"
                                            className=" text-muted fw-bold p-lg-5 p-0"
                                        >
                                            <p className="mb-0 fs-2">
                                                Call us immediately. We, do our best to make sure every order goes smoothly, but unfortunately sometimes one of us slips up. When this happens we want to know about it right away so we can make it right as quickly as possible
                                            </p>
                                        </AccordionBody>
                                    </AccordionItem>

                                    <AccordionItem>
                                        <AccordionHeader targetId="5">
                                            Can I change the address / number?
                                        </AccordionHeader>

                                        <AccordionBody
                                            accordionId="5"
                                            className=" text-muted fw-bold p-lg-5 p-0"
                                        >
                                            <p className="mb-0 fs-2">
                                                Any major change in delivery address is not possible after you have placed an order with us. However, slight modifications like changing the flat number, street name, landmark etc. are allowed. If you have received delivery executive details, you can directly call him, else you could contact our customer service team.
                                            </p>
                                        </AccordionBody>
                                    </AccordionItem>

                                    <AccordionItem>
                                        <AccordionHeader targetId="6">
                                            Do you support bulk orders?
                                        </AccordionHeader>

                                        <AccordionBody
                                            accordionId="6"
                                            className=" text-muted fw-bold p-lg-5 p-0"
                                        >
                                            <p className="mb-0 fs-2">
                                                In order to provide all customers with a great selection and to ensure on time delivery of your meal, we reserve the right to limit the quantities depending on supply.
                                            </p>
                                        </AccordionBody>
                                    </AccordionItem>

                                    <AccordionItem>
                                        <AccordionHeader targetId="7">
                                            Do you charge for delivery?
                                        </AccordionHeader>

                                        <AccordionBody
                                            accordionId="7"
                                            className=" text-muted fw-bold p-lg-5 p-0"
                                        >
                                            <p className="mb-0 fs-2">
                                                NO
                                            </p>
                                        </AccordionBody>
                                    </AccordionItem>
                                </Accordion>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>)
}