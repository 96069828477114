import React from "react";
import { getTaxValue, itemsPrice } from "../../../utility/cartHelpers";
import { AppConst } from "../../../AppConst";

const OrderSummary = ({
  cartData,
  deliveryChareges,
  formValues,
  offerDiscount,
  grandTotal,
  kitchenData,
  coins
}) => {
  return (
    <div className="ordersummery pt-0 px-0 pb-3">
      <div className="table-responsive">
        <table className="table table-borderless table-striped text-center mb-0">
          <tbody>
            <tr>
              <th className>
                <p className="mb-0 text-primary fw-bold mb-3">Order Amount:</p>
              </th>

              <th>
                <p className="mb-0 text-primary fw-bold mb-3">
                  Delivery Charges:
                </p>
              </th>
              <th>
                <p className="mb-0 text-primary fw-bold mb-3">Driver Tip:</p>
              </th>
              <th>
                <p className="mb-0 text-primary fw-bold mb-3">Coin Reedem:</p>
              </th>
              <th>
                <p className="mb-0 text-primary fw-bold mb-3">Vat (%):</p>
              </th>
              <th>
                <p className="mb-0 text-primary fw-bold mb-3">Discount(%):</p>
              </th>
              <th>
                <p className="mb-0 text-primary fw-bold mb-3">
                  Total Order Amount:
                </p>
              </th>
            </tr>
            <tr>
              <td>
                <p className="mb-0 pt-3 text-muted fw-bold">
                  {AppConst.getBussInfo.currency}{" "}
                  {itemsPrice(cartData).toFixed(2)}
                </p>
              </td>
              <td>
                <p className="mb-0 pt-3 text-muted fw-bold">
                  {AppConst.getBussInfo.currency} {parseFloat(deliveryChareges).toFixed(2)}
                </p>
              </td>
              <td>
                <p className="mb-0 pt-3 text-muted fw-bold">
                  {AppConst.getBussInfo.currency} {formValues?.tip}
                </p>
              </td>
              <td>
                <p className="mb-0 pt-3 text-muted fw-bold">
                  {AppConst.getBussInfo.currency} {coins?.coinValue}
                </p>
              </td>
              <td>
                <p className="mb-0 pt-3 text-muted fw-bold">
                  {AppConst.getBussInfo.currency}{" "}
                  {
                    getTaxValue(
                      kitchenData,
                      parseFloat(itemsPrice(cartData)).toFixed(2)
                    ).tax
                  }{" "}
                  ({" "}
                  {
                    getTaxValue(
                      kitchenData,
                      parseFloat(itemsPrice(cartData)).toFixed(2)
                    ).tax_percentage
                  }{" "}
                  % )
                </p>
              </td>
              <td>
                <p className="mb-0 pt-3 text-muted fw-bold">
                  -{AppConst.getBussInfo.currency} {parseFloat(offerDiscount).toFixed(2)}
                </p>
              </td>
              <td>
                <p className="mb-0 pt-3 text-muted fw-bold">
                  {AppConst.getBussInfo.currency} {grandTotal.toFixed(2)}
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default OrderSummary;
