import React, { useEffect, useState } from "react";
import intlTelInput from "intl-tel-input";
import "intl-tel-input/build/css/intlTelInput.css";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import { callApi } from "../../hooks/useapi";
import AlertError from "../../components/common/AlertError";
import moment from "moment";
import { AppConst } from "../../AppConst";

export default function SignUp() {
  const [showPassword, setShowPassword] = useState(false);
  const [apiMessage, setApiMessage] = useState({
    show: false,
    type: "success",
    message: "",
  });
  const navigate = useNavigate();

  const {
    register,
    watch,
    formState: { errors },
    handleSubmit,
  } = useForm({ mode: "onBlur" });

  useEffect(() => {
    const input = document.querySelector("#CountryCode");
    intlTelInput(input, {
      separateDialCode: true,
      initialCountry: "za",
    });
  }, []);

  const handleSignUp = (data) => {
    const input = document.querySelector(".iti__selected-dial-code");
    delete data["checkbox"];
    let alldata = {
      ...data,
      phone_code: input.innerText,
      gender: data.gender,
      buss_id: process.env.REACT_APP_BUSS_ID,
      owner_id: process.env.REACT_APP_BUSS_ID,
    };
    data.mobile = data.mobile.replace(/^0+/, "");
    data.mobile = data.mobile.replace(
      `/^${AppConst.getBussInfo.country_code}+/`,
      ""
    );
    callApi("user/register-with-otp ", { method: "POST", data: alldata }).then(
      (res) => {
        if (res?.status == true) {
          navigate("/verify-otp", {
            state: {
              otp: res?.data,
            },
          });
        } else {
          setApiMessage({
            show: true,
            type: "danger",
            message: res.errors[0].detail,
          });
          toggleAlert();
        }
      }
    );
  };
  const toggleAlert = () => {
    setTimeout(() => {
      setApiMessage({ show: false, message: "", type: "success" });
    }, 5000);
  };
  return (
    <>
      {/*----- main container start s----*/}
      <div className=" main-container entrypage signup">
        <div className="row mx-0 px-0 justify-content-between h-100">
          <div className="col-xl-5 col-lg-6 col-12 px-xl-5 px-lg-5 px-5">
            <div className="entrylogo">
              <img src="./assets/img/GreenTExtlogo.png" className="w-100 h-100" alt="" />
            </div>

            <h1 className="text-primary fw-600 text-uppercase text-center my-xl-4 my-xxl-4 my-lg-3 my-5 pb-xxl-4 pb-xl-5 pb-lg-3 pb-5">
              Create your account
            </h1>

            <form
              onSubmit={handleSubmit(handleSignUp)}
              className="regform position-relative row"
            >
              {/* <AlertError alert={apiMessage} /> */}

              {apiMessage.show === true && <AlertError alert={apiMessage} />}

              <div className="col-lg-6 col-12">
                {/*----- Name input ------*/}
                <div className="position-relative pb-4 mb-4">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Name"
                    {...register("name", {
                      required: "Name is required",
                      pattern: {
                        value: /^[a-zA-Z ]{2,30}$/,
                        message: "Please enter a valid  name",
                      },
                    })}
                  />
                  {errors.name && errors.name.message && (
                    <div className="f-error">
                      <i className="fa-regular fa-circle-xmark me-1" />
                      {errors.name && errors.name.message}
                    </div>
                  )}
                </div>
              </div>

              <div className="col-lg-6 col-12">
                {/*----- select country dropdown input ------*/}
                <div className="position-relative pb-4 mb-4">
                  <input
                    type="text"
                    id="CountryCode"
                    className="form-control"
                    placeholder="Phone number"
                    {...register("mobile", {
                    })}
                  />
                  {errors.mobile && errors.mobile.message && (
                    <div className="f-error">
                      <i className="fa-regular fa-circle-xmark me-1" />
                      {errors.mobile && errors.mobile.message}
                    </div>
                  )}
                </div>
              </div>

              <div className="col-lg-6 col-12">
                {/*----- Email input ------*/}
                <div className="position-relative pb-4 mb-4">
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Email"
                    {...register("email", {
                      required: "Email is required",
                      pattern: {
                        value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                        message: "Incorrect Email format",
                      },
                    })}
                  />
                  {errors.email && errors.email.message && (
                    <div className="f-error">
                      <i className="fa-regular fa-circle-xmark me-1" />
                      {errors.email && errors.email.message}
                    </div>
                  )}
                </div>
              </div>

              <div className="col-lg-6 col-12">
                <div className="position-relative pb-4 mb-4">
                  <input
                    type="date"
                    className="form-control"
                    name="dob"
                    max={moment().subtract(18, "years").format("YYYY-MM-DD")}
                    placeholder="Email"
                    {...register("dob", {
                      required: "DOB is required",
                    })}
                  />
                  {errors.dob && errors.dob.message && (
                    <div className="f-error">
                      <i className="fa-regular fa-circle-xmark me-1" />
                      {errors.dob && errors.dob.message}
                    </div>
                  )}
                </div>
              </div>

              <div className="col-xxl-12 col-xl-6 col-lg-6 col-12">
                {/*----- Password input ------*/}
                <div className="position-relative pb-xl-4 pb-lg-4 pb-4 mb-xl-4 mb-lg-2 mb-4">
                  <input
                    type={!showPassword ? `password` : "text"}
                    className="form-control"
                    id="loginpassword"
                    placeholder="Password"
                    {...register("password", {
                      required: "password is required",
                      minLength: {
                        value: 4,
                        message: "Password min length 4 Character",
                      },
                    })}
                  />
                  <button
                    type="button"
                    className="showhidebutton"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? (
                      <svg
                        height={20}
                        width={20}
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 576 512"
                      >
                        <path d="M572.52 241.4C518.29 135.59 410.93 64 288 64S57.68 135.64 3.48 241.41a32.35 32.35 0 0 0 0 29.19C57.71 376.41 165.07 448 288 448s230.32-71.64 284.52-177.41a32.35 32.35 0 0 0 0-29.19zM288 400a144 144 0 1 1 144-144 143.93 143.93 0 0 1-144 144zm0-240a95.31 95.31 0 0 0-25.31 3.79 47.85 47.85 0 0 1-66.9 66.9A95.78 95.78 0 1 0 288 160z" />
                      </svg>
                    ) : (
                      <svg
                        height={20}
                        width={20}
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 640 512"
                      >
                        <path d="M320 400c-75.85 0-137.25-58.71-142.9-133.11L72.2 185.82c-13.79 17.3-26.48 35.59-36.72 55.59a32.35 32.35 0 0 0 0 29.19C89.71 376.41 197.07 448 320 448c26.91 0 52.87-4 77.89-10.46L346 397.39a144.13 144.13 0 0 1-26 2.61zm313.82 58.1l-110.55-85.44a331.25 331.25 0 0 0 81.25-102.07 32.35 32.35 0 0 0 0-29.19C550.29 135.59 442.93 64 320 64a308.15 308.15 0 0 0-147.32 37.7L45.46 3.37A16 16 0 0 0 23 6.18L3.37 31.45A16 16 0 0 0 6.18 53.9l588.36 454.73a16 16 0 0 0 22.46-2.81l19.64-25.27a16 16 0 0 0-2.82-22.45zm-183.72-142l-39.3-30.38A94.75 94.75 0 0 0 416 256a94.76 94.76 0 0 0-121.31-92.21A47.65 47.65 0 0 1 304 192a46.64 46.64 0 0 1-1.54 10l-73.61-56.89A142.31 142.31 0 0 1 320 112a143.92 143.92 0 0 1 144 144c0 21.63-5.29 41.79-13.9 60.11z" />
                      </svg>
                    )}
                  </button>
                  {errors.password && errors.password.message && (
                    <div className="f-error">
                      <i className="fa-regular fa-circle-xmark me-1" />
                      {errors.password && errors.password.message}
                    </div>
                  )}
                </div>
              </div>

              <div className="col-xxl-12 col-xl-6 col-lg-6 col-12">
                {/*----- All product tab list -----*/}
                <nav className="d-lg-block d-xl-block d-xxl-none d-none">
                  <div className="foodCATSM position-relative px-0 pb-xl-4 pb-lg-4 pb-4 mb-xl-4 mb-lg-2 mb-4">
                    <input type="checkbox" className="d-none" id="FoodCAtDrop" />

                    <label htmlFor="FoodCAtDrop">Select Gender</label>

                    <ul className="ProCATLISTs nav nav-tabs d-block">
                      <li>
                        <input
                          className="form-check-input d-none"
                          type="radio"
                          value={"male"}
                          name="gender
                  "
                          {...register("gender", {
                            required: "Gender is required",
                          })}
                          id="inlineCheckbox1"
                          defaultValue="option1"
                        />

                        <label
                          className="form-check-label"
                          htmlFor="inlineCheckbox1"
                        >
                          Male
                        </label>
                      </li>

                      <li>
                        <input
                          className="form-check-input d-none"
                          type="radio"
                          id="inlineCheckbox2"
                          defaultValue="option2"
                          name="gender"
                          value={"female"}
                          {...register("gender", {
                            required: "Gender is required",
                          })}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="inlineCheckbox2"
                        >
                          Female
                        </label>
                      </li>
                    </ul>

                    {errors.gender && errors.gender.message && (
                      <div className="f-error">
                        <i className="fa-regular fa-circle-xmark me-1" />
                        {errors.gender && errors.gender.message}
                      </div>
                    )}
                  </div>
                </nav>

                <div className="d-flex flex-lg-row flex-column mb-5 align-items-lg-center position-relative signupgender d-xxl-block  d-xl-none d-lg-none d-block">
                  <h3 className="fw-bold me-5">Select Gender :-</h3>

                  <div className="d-flex">
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        value={"male"}
                        name="gender
                  "
                        {...register("gender", {
                          required: "Gender is required",
                        })}
                        id="inlineCheckbox1"
                        defaultValue="option1"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="inlineCheckbox1"
                      >
                        Male
                      </label>
                    </div>

                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        id="inlineCheckbox2"
                        defaultValue="option2"
                        name="gender"
                        value={"female"}
                        {...register("gender", {
                          required: "Gender is required",
                        })}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="inlineCheckbox2"
                      >
                        Female
                      </label>
                    </div>
                  </div>

                  {errors.gender && errors.gender.message && (
                    <div className="f-error">
                      <i className="fa-regular fa-circle-xmark me-1" />
                      {errors.gender && errors.gender.message}
                    </div>
                  )}
                </div>
              </div>

              <div className="col-lg-12 col-12">
                <div className="otheroption">
                  <div className="hoverlineeffect">
                    <p className="mb-0">
                      <Link to={"/login"}>Already have an account ?</Link>
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-lg-12 col-12">
                <div className="TnCcheckbox position-relative">
                  <input
                    type="checkbox"
                    id="tnccheck"
                    className="d-none"
                    {...register("checkbox", {
                      required: "Please read and accept T&C",
                    })}
                  />

                  {errors.checkbox && errors.checkbox.message && (
                    <div className="f-error">
                      <i className="fa-regular fa-circle-xmark me-1" />
                      {errors.checkbox && errors.checkbox.message}
                    </div>
                  )}

                  <label htmlFor="tnccheck">
                    <p className="mb-0 fs-3 text-muted">
                      By completing the signup you are agree to the
                      <Link to={"/privacy"} className="text-primary fw-600 mx-2">
                        Privacy Policies
                      </Link>
                      and
                      <Link to="/tnc" className="text-primary fw-600 mx-2">
                        Terms and Conditions
                      </Link>
                    </p>

                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="black"
                      strokeWidth={2}
                    >
                      <polyline points="20 6 9 17 4 12" className="check" />
                    </svg>
                  </label>
                </div>
              </div>

              <div className="col-lg-12 col-12">
                {/*----- Submit button(Continue to login) -----*/}
                <button
                  type="submit"
                  className="btn btn-primary d-block w-100 fs-2 py-4 updatepass"
                >
                  Sign up
                </button>
              </div>
            </form>
          </div>

          <div className="col-xl-7 col-lg-6 col-12 px-0 h-100 position-relative d-lg-block d-none">

            {/*----- Banner container -----*/}
            <div className="entrypage-banner w-100 h-100" style={{ backgroundImage: "url(./assets/img/loginbg.png)" }}></div>

            {/*----- Banner layer text -----*/}
            <div className="bannerlayertext">
              <p className="mb-0">
                Everything you need delivered now with <br />
                <span className="text-decoration-underline text-uppercase">
                  {AppConst.getBussInfo.app_name}
                </span>
              </p>
            </div>
          </div>
        </div>
        {/*----- main container end ----*/}
      </div>
    </>
  );
}
