import React from "react";

const AppInfoSection = () => {
  return (
    <section className="mobileapp">
      <div className="container-fluid">
        <div className="row align-items-xl-center">
          <div className="col-xl-4 col-lg-5 col-10 mx-auto">
            <img
              src="./assets/img/customer-app.png"
              className="w-100"
              alt=""
            />
          </div>

          <div className="col-lg-5 col-12 mx-auto">
            <h1 className="default_title mb-5">
              Download Our Mobile App That Make You More Easy to Order
            </h1>

            <p className="default_subtitle">
              Dispensaries cater to local clientele, offering affordable and informal spaces for wellness seekers. Simple settings, low prices, and a range of medicinal options.
            </p>

            <ul className="list-unstyled downloadappsteps">
              <li>
                <p className="mb-0">Select Your favourite product</p>
              </li>
              <li>
                <p className="mb-0">Add to cart</p>
              </li>
              <li>
                <p className="mb-0">Order your product</p>
              </li>
            </ul>

            <div className="systemplatformimg d-lg-block d-flex">
              {/*----- ios platform img -----*/}
              <img src="./assets/img/android.png" alt="" />

              {/*----- Android platform img -----*/}
              <img src="./assets/img/ios.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AppInfoSection;
