import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { callApi } from "../../../hooks/useapi";
import { generateRandomString, sendSuccessInfo } from "../../../utility/helper";
import {
  addAdditionalInfo,
  generateTransectionKey,
} from "../../../store/actions/actions";
import { AppConst } from "../../../AppConst";
import PaymentForm from "./PaymentForm";
import DateTimePicker from "./DateTimePicker";

const PaymentMethod = ({
  handleChangeDeliveryMethod,
  setDelivery,
  delivery_method,
  submitCalculations,
  kitchenData,
  paymentType,
  setPaymentType,
  getWallet,
  walletData,
  disabled,
  formValues,
  onSelectDateAndTime = () => { },
  coins,
  setCoins = () => { },
  coinsDiscount
}) => {
  const paymentCardRef = useRef(null);
  const coinRef = useRef();
  const [amount, setAmount] = useState(0);
  const dispatch = useDispatch();
  const transection_key = useSelector((state) => state.root.transection_key);
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  useEffect(() => {
    if (
      searchParams.get("tid") === transection_key &&
      searchParams.get("amt") &&
      searchParams.get("type") === "wallet"
    ) {
      let postData = {
        amount: searchParams.get("amt"),
        transaction_id: searchParams.get("tid"),
        description: "Top-up added",
      };
      callApi("user/wallet-recharge", {
        method: "POST",
        data: postData,
      }).then((response) => {
        if (response.status === true) {
          sendSuccessInfo("Wallet recharge succefully");
          dispatch(generateTransectionKey(""));
          getWallet();
          navigate("/checkout");
          setAmount("");
          dispatch(generateTransectionKey(generateRandomString(15)));
        } else {
          sendSuccessInfo(response.error.error);
        }
      });
    }
    if (transection_key === "") {
      dispatch(generateTransectionKey(generateRandomString(15)));
    }
    getWallet();
  }, []);

  return (
    <div className="col-lg-4 col-12">
      {console.log("kitchenData=======", kitchenData)}
      {kitchenData?.is_pre_order === "1" && (
        <DateTimePicker
          onSelectDate={(e) => onSelectDateAndTime(e)}
          maxDays={kitchenData?.pre_order_days}
          maxTime={kitchenData?.pre_order_time}
        />
      )}

      {/*----- Payment details container -----*/}
      <h1 className="fs-1 fw-bold mb-4 px-lg-0 px-4 mt-lg-0 mt-5">Choose Deliver Method</h1>
      {kitchenData?.delivery_active === "1" && (
        <div className="form-check form-check-inline ms-lg-0 ms-4">
          <input
            className="form-check-input"
            type="radio"
            value={"Delivery"}
            onChange={() => {
              handleChangeDeliveryMethod("Delivery");
              setDelivery(true);
            }}
            name="deliverymethod"
            id="Delivery"
            defaultChecked={delivery_method === "Delivery"}
          />

          <label className="form-check-label" htmlFor="Delivery">
            Delivery
          </label>
        </div>
      )}
      {kitchenData?.kitchen_info?.pickup_on_off === "1" && (
        <div className="form-check form-check-inline">
          <input
            className="form-check-input"
            type="radio"
            value={"Pickup"}
            name="deliverymethod"
            onChange={() => {
              handleChangeDeliveryMethod("Pickup");
              setDelivery(false);
            }}
            id="Pickup"
            defaultChecked={delivery_method === "Pickup"}
          />

          <label className="form-check-label" htmlFor="Pickup">
            Pickup
          </label>
        </div>
      )}
      {kitchenData?.is_courier === "1" && (
        <div className="form-check form-check-inline">
          <input
            className="form-check-input"
            type="radio"
            value={"Courier"}
            name="deliverymethod"
            onChange={() => {
              handleChangeDeliveryMethod("Courier");
              setDelivery(true);
            }}
            id="Courier"
            defaultChecked={delivery_method === "Courier"}
          />

          <label className="form-check-label" htmlFor="Courier">
            Courier
          </label>
        </div>
      )}

      <h1 className="fs-1 fw-bold mb-4 px-lg-0 px-4 mt-lg-0 mt-5">Choose payment option</h1>
      <div className="paymentdetails">
        {/*----- payincas input radio -----*/}
        {kitchenData?.kitchen_info?.online_payment == '1' &&
          <>
            <input
              type="radio"
              id="PayCreditDebit"
              className="d-none"
              value={"online"}
              defaultChecked={paymentType == "online"}
              name="SelectPaymenttype"
              onChange={(e) => setPaymentType(e.target.value)}
            />
            <label htmlFor="PayCreditDebit" className="fs-2 text-primary">
              <img src="./assets/img/walletpay.png" alt="" />
              Online Payment
            </label>
          </>}
        {kitchenData?.kitchen_info?.cash_on_delivery == '1' &&
          <>
            <input
              type="radio"
              id="PayINWallet"
              className="d-none"
              defaultChecked={paymentType == "cash"}
              value={"cash"}
              onChange={(e) => setPaymentType(e.target.value)}
              name="SelectPaymenttype"
            />
            <label htmlFor="PayINWallet" className="fs-2 text-primary">
              <img src="./assets/img/payincash.png" alt="" />
              Pay with cash
            </label>
          </>}
        {kitchenData?.kitchen_info?.is_machine == '1' &&
          <>
            <input
              type="radio"
              id="PayINCAsh"
              className="d-none"
              defaultChecked={paymentType == "card"}
              value={"card"}
              onChange={(e) => setPaymentType(e.target.value)}
              name="SelectPaymenttype"
            />
            <label htmlFor="PayINCAsh" className="fs-2 text-primary">
              <img src="./assets/img/creditdebitpay.png" alt="" />
              Pay with card
            </label>
          </>}
        {/*----- pay by wallet input radio -----*/}
        {/* <input
          type="radio"
          id="PayINWallet"
          className="d-none"
          checked={paymentType == "wallet"}
          value={"wallet"}
          name="SelectPaymenttype"
          onChange={(e) => setPaymentType(e.target.value)}
        />
        <label htmlFor="PayINWallet" className="fs-2 text-primary">
          <img src="./assets/img/walletpay.png" alt="" />
          <div className="d-inline-block">
            Pay with wallet
            <br />
            <span className="text-success fs-4 fw-bold">
              Wallet balance: {AppConst.getBussInfo.currency}
              {walletData?.total_amount || 0}
            </span>
          </div>
        </label> */}
        {/*----- Wallet topup input -----*/}
        {/* <div className="topupwallet position-relative">
          <p className="text-muted my-4 fs-3">Add top-up to wallet</p>
          <p className="randslogo mb-0">R</p>
          <input
            type="number"
            className="form-control"
            value={amount}
            min={0}
            onChange={(e) => setAmount(e.target.value)}
          />
          <div className="d-none">
            <PaymentForm
              returnUrl={`${location.pathname}?tid=${transection_key}&amt=${amount}&type=wallet`}
              totalPrice={amount}
              uniqeId={transection_key}
              buttonTitle={`Proceed to add ${AppConst.getBussInfo.currency}`}
              ref={paymentCardRef}
            />
          </div>
          <button
            disabled={amount <= 0 ? "disabled" : ""}
            onClick={() => {
              dispatch(
                addAdditionalInfo({
                  tip: formValues.tip,
                  additionalNotes: formValues.additionalNotes,
                  courier_id: "",
                })
              );
              paymentCardRef.current.click();
            }}
            className="topupbutton"
            type="button"
          >
            Add
          </button>
        </div> */}
        {/*----- Payment by credit/debit card -----*/}

        <div className="topupwallet position-relative">
          <p className="text-muted my-4 fs-3">Reedam Coins <span>(Available Coins {coins?.total})</span>
            <br />
            <span className="text-small"> {AppConst.getBussInfo.currency} 1.00 = {coinsDiscount}</span>
          </p>

          <p className="randslogo mb-0">R</p>
          <input
            type="number"
            className="form-control"
            value={coinRef.current}
            min={0}
            max={coins?.total}
            onChange={(e) => {
              setCoins({ ...coins, applied: 0 })
              coinRef.current = e.target.value;
            }}
          />
          <button
            disabled={coinRef.current <= 0 ? "disabled" : ""}
            onClick={(e) => {
              setCoins({ ...coins, applied: JSON.parse(coinRef.current) });
            }}
            className="topupbutton"
            type="button"
          >
            {coins.applied > 0 ? "Coin Applied" : "Apply Coins"}
          </button>
        </div>

        {/*----- card selection for payemnt input -----*/}

        <button
          disabled={disabled}
          onClick={(e) => submitCalculations({ event: e, apiCall: true, kitchenData: kitchenData })}
          className="btn btn-primary d-block fw-600 w-100 fs-2 py-4 mt-5"
        >
          Continue to checkout
        </button>
      </div>
    </div >
  );
};

export default PaymentMethod;
