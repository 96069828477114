import React, { useEffect, useRef, useState } from "react";
import { callApi } from "../../hooks/useapi";
import { useLocation, useNavigate } from "react-router-dom";
import { AppConst } from "../../AppConst";
import { setCookies } from "../../utility/helper";
import { saveUserData } from "../../store/actions/actions";
import { useDispatch } from "react-redux";
import AlertError from "../../components/common/AlertError";

export const OTP = () => {
  const inputRefs = [useRef(null), useRef(null), useRef(null), useRef(null)];
  const [checkbox, setCheckbox] = useState("");
  const [apiMessage, setApiMessage] = useState({
    show: false,
    type: "success",
    message: "",
  });
  const [userData, setUserData] = useState([]);
  const [otp, setOtp] = useState([]);
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    if (!location.state?.otp) {
      navigate("/signup");
    } else {
      setOtp(location.state.otp.otp.split(""));
      setUserData(location.state.otp);
    }
  }, []);
  function handleInputChange(index, event) {
    const value = event.target.value;

    // If the input is empty and backspace is pressed, move focus to the previous input
    if (value === "" && event.key === "Backspace" && index > 0) {
      inputRefs[index - 1].current.focus();
      return;
    }

    // If the input is a number, update the value and move focus to the next input
    if (/^\d$/.test(value) && index < inputRefs.length - 1) {
      inputRefs[index + 1].current.focus();
    }

    let arr = [...otp];
    arr[index] = /^\d$/.test(value) ? value : ""; // Clear non-digit inputs
    setOtp(arr);
  }
  function handleKeyDown(index, event) {
    if (event.key === "Backspace") {
      event.preventDefault(); // Prevent the backspace event from propagating

      let arr = [...otp];
      arr[index] = "";
      setOtp(arr);

      if (index > 0) {
        inputRefs[index - 1].current.focus();
      }
    }
  }
  const resendOTP = (e) => {
    e.preventDefault();
    let data = userData;
    callApi("user/resent-otp", { method: "POST", data: data }).then((res) => {
      setUserData({ ...userData, otp: res.data.otp });
      setOtp(res.data.otp.split(""));
      // setOtpResend(res?.data?.otp);
    });
  };
  const handleVerifyOtp = (e) => {
    e.preventDefault();
    if (checkbox === "") {
      setApiMessage({
        show: true,
        type: "danger",
        message: "Please read and accept T&C",
      });
      toggleAlert();
      return
    }
    if (otp.join("") != userData.otp) {
      setApiMessage({
        show: true,
        type: "danger",
        message: "Invalid OTP, Try again ",
      });
      toggleAlert();
      return;
    }
    let data = userData;
    data.gender = data.gender;
    data.owner_id = process.env.REACT_APP_BUSS_ID;
    data.buss_id = AppConst.getBussInfo.business_owner_id;
    //   data.phone_code = data.phone_code.substring(1);
    data.phone_code = data.phone_code.substring(1);
    data.mobile = data.mobile.replace(/^0+/, "");
    data.mobile = data.mobile.replace(`/^${data.phone_code}+/`, "");
    let PostRequest = data;

    callApi("user/register", { method: "POST", data: PostRequest })
      .then((response) => {
        // console.log("server reponse"+JSON.stringify(response));
        if (response.status === true) {
          //response = JSON.parse(JSON.stringify(response));
          setCookies(
            process.env.REACT_APP_BUSINESS + "user_data",
            JSON.stringify(response.data),
            { path: "/" }
          );
          setCookies(
            process.env.REACT_APP_BUSINESS + "token",
            response.data.token,
            { path: "/" }
          );
          setCookies(
            process.env.REACT_APP_BUSINESS + "type",
            response.data.type,
            { path: "/" }
          );
          dispatch(saveUserData(response.data));
          setApiMessage({
            type: "success",
            show: false,
            message: "Successfully Registerd.",
          });
          //

          navigate("/stores");
        } else {
          //   console.log(response.errors);
          if (response?.errors) {
            setApiMessage({
              show: true,
              type: "danger",
              message: response.errors[0].detail,
            });
            toggleAlert();
          } else {
            setApiMessage({
              show: true,
              type: "danger",
              message: response.data?.message,
            });
          }
        }
        //console.log("new:"+this.state.Screentype);
      })
      .catch((error) => {
        //return error.data;
        // console.log(errors);
        for (const [index, value] of error.entries()) {
          setApiMessage({
            show: true,
            type: "danger",
            message: value,
          });
          toggleAlert();
        }
      });
  };
  const toggleAlert = () => {
    setTimeout(() => {
      setApiMessage({ show: false, message: "", type: "success" });
    }, 5000);
  };
  return (
    <div className=" main-container entrypage OTP">
      <div className="row mx-0 px-0 justify-content-between h-100">
        <div className="col-lg-5 col-12 px-5 pt-5">
          <div className="entrylogo">
            <img
              src="./assets/img/GreenTExtlogo.png"
              className="w-100 h-100"
              alt=""
            />
          </div>

          <h1 className="text-primary fw-600 text-uppercase text-center my-5 pb-5">
            OTP Verification
          </h1>
          <form
            onSubmit={(e) => handleVerifyOtp(e)}
            className="regform position-relative h-50"
          >
            {apiMessage.show === true && <AlertError alert={apiMessage} />}

            <div className="d-flex flex-column h-100 justify-content-between">
              {/*----- OTP input and Resend OTP button ---*/}
              <div className="otpcontainer">
                <div className="otpinputs">
                  {Array.from({ length: 4 }, (_, index) => (
                    <input
                      type="number"
                      className="form-control"
                      placeholder=" "
                      maxLength={1}
                      minLength={1}
                      key={index}
                      ref={inputRefs[index]}
                      value={otp[index]}
                      onChange={(event) => handleInputChange(index, event)}
                      onKeyDown={(event) => handleKeyDown(index, event)}
                    />
                  ))}
                </div>

                {/*----- Resend OTP option -----*/}
                <div className="otheroption align-items-center justify-content-center">
                  <div className="hoverlineeffect">
                    <p className="mb-0 d-inline-block fw-600 fs-3 me-2">
                      Don’t get code?
                    </p>

                    <button
                      // href="#!"
                      onClick={(e) => resendOTP(e)}
                      className="d-inline-block text-warning opacity-100 bg-transparent border-0"
                    >
                      Resend OTP
                    </button>
                  </div>
                </div>
              </div>

              <div className="align-self-end">
                <div className="TnCcheckbox">
                  <input
                    type="checkbox"
                    id="tnccheck"
                    onChange={(e) =>
                      e.target.checked === true
                        ? setCheckbox("checked")
                        : setCheckbox("")
                    }
                    className="d-none"
                  />
                  <label htmlFor="tnccheck">
                    <p className="mb-0 fs-3 text-muted">
                      By completing the signup you are agree to the
                      <a href="#!" className="text-primary fw-600 mx-2">
                        Privacy Policies
                      </a>
                      and
                      <a href="#!" className="text-primary fw-600 mx-2">
                        Terms and Conditions
                      </a>
                    </p>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="black"
                      strokeWidth={2}
                    >
                      <polyline points="20 6 9 17 4 12" className="check" />
                    </svg>
                  </label>
                </div>
                {/*----- Submit button(Continue to login) -----*/}
                <button
                  type="submit"
                  className="btn btn-primary d-block w-100 fs-2 py-4 otPBUtton"
                >
                  Sign up
                </button>
              </div>
            </div>
          </form>
        </div>

        <div className="col-lg-7 col-12 px-0 h-100 position-relative d-lg-block d-none">
          {/*----- Banner container -----*/}
          <div
            className="entrypage-banner w-100 h-100"
            style={{ backgroundImage: "url(./assets/img/loginbg.png)" }}
          ></div>
          {/*----- Banner layer text -----*/}
          <div className="bannerlayertext">
            <p className="mb-0">
              Everything you need delivered now with
              <br />
              <span className="text-decoration-underline text-uppercase">
                {AppConst.getBussInfo.app_name}
              </span>
            </p>
          </div>
        </div>
      </div>
      {/*----- main container end ----*/}
    </div>
  );
};
