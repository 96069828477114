import React from "react";

const FormError = ({ message }) => {
  return (
    <>
      {message && (
        <div className="f-error">
          <i className="fa-regular fa-circle-xmark me-1" />
          {message}
        </div>
      )}
    </>
  );
};

export default FormError;
