import React from "react";
import Slider from "react-slick";
import MenuCard from "../Menu/MenuCard";
import { checkItemInStock } from "../../../utility/cartHelpers";
import { useSelector } from "react-redux";

const TrandingProductSection = ({ products, isPremiumMember, openMembershipPopup = () => { } }) => {
  const kitchen_data = useSelector((state) => state.root.kitchen_data);
  const kitchen_id = useSelector((state) => state.root.kitchen_id);
  const selectedKitchn = kitchen_data.find((item) => item.id == kitchen_id);
  let ProductSlider = {
    ...settings,
    // lazyLoad: 'progressive',
    slidesToShow: products.length > 4 ? 4 : products.length,
    slidesToScroll: 4,
    arrows: true,
    // fade: true,
    // asNavFor: '.slider-nav',
    adaptiveHeight: true,
    infinite: true,
  };
  return (
    <section className="trending_product px-0 mx-0">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12 col-12">
            <div className="secPadding_left secMargin_left">
              {/*----- Section title -----*/}
              <h1 className="default_title">Trending Products</h1>
              {/*----- Section Sub title -----*/}
              <p className="default_subtitle">
                Browse our list of best selling products
              </p>
            </div>
          </div>
        </div>
      </div>

      <Slider {...ProductSlider} className="trending_product_slider">
        {/*----- Product card -----*/}
        {products &&
          products.length > 0 &&
          products.map((product, key) => (
            <MenuCard
              key={key}
              product_image={product.product_image}
              name={product.name}
              end_date={product?.product_discount?.end_date}
              offer_type={product?.product_discount?.offer_type}
              isPremiumMember={isPremiumMember}
              percentage={product?.product_discount?.percentage}
              product={product}
              is_available_membership={product?.is_available_membership}
              isItemInStock={checkItemInStock(product)}
              price={
                product?.product_item_list &&
                product?.product_item_list &&
                product?.product_item_list[0]?.product_item &&
                product?.product_item_list[0]?.product_item[0]?.price
              }
              indexPage={true}
              stock_onoff={selectedKitchn?.kitchen_info?.stock_onoff}
              openMembershipPopup={() => openMembershipPopup()}
            />
          ))}
      </Slider>
    </section>
  );
};

export default TrandingProductSection;
const CustomPrevArrow = (props) => (
  <button
    {...props}
    class="slick-arrow slick-prev"
    style={{ display: "block" }}
  >
    Previous
  </button>
);
const CustomNextArrow = (props) => (
  <button
    {...props}
    class="slick-arrow slick-next"
    style={{ display: "block" }}
  >
    Next
  </button>
);
var settings = {
  arrows: true,
  infinite: true,
  autoplay: false,
  centerMode: true,
  centerPadding: "35px",
  slidesToShow: 5,
  slidesToScroll: 1,
  initialSlide: 1,
  responsive: [
    {
      breakpoint: 1199,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        arrows: true,
        dots: false,
        centerMode: true,
        centerPadding: "50px"
      },
    },
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: true,
        arrows: true,
        dots: false,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        arrows: true,
        initialSlide: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        arrows: true,
        slidesToScroll: 1,
      },
    },
  ],
};
