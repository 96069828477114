import React from "react";
import { useSelector } from "react-redux";
import { NavLink, useLocation } from "react-router-dom";
import { getToken } from "../../utility/helper";

const Header = () => {
  const kitchen_data = useSelector((state) => state.root.kitchen_data);
  const location = useLocation();
  const cartItems = useSelector((state) => state.cart.items);
  const kitchen_id = useSelector((state) => state.root.kitchen_id);
  const getSelectedKitchen = () => {
    const index = kitchen_data.findIndex((cat) => cat.id == kitchen_id);

    return kitchen_data[index];
  };
  return (
    <header>
      <nav className="navbar navbar-expand-lg">
        {/*----- container fluid start -----*/}
        <div className="container-fluid">
          {/*----- Website logo -----*/}
          <NavLink to={"/home"} className="navbar-brand">
            <img
              src={process.env.PUBLIC_URL + "/assets/img/GreenCentral_WhiteLogo.png"}
              className="w-100 h-100"
              alt=""
            />
          </NavLink>

          <ul className="list-unstyled text-white d-lg-none d-flex justify-content-evenly mb-0">
            <li className="position-relative">
              <a
                className="text-white text-decoration-none headerWish"
                href="#"
              >
                <i class="fa-regular fa-heart"></i>
                <span className="notifcount">10</span>
              </a>
            </li>

            <li className="position-relative">
              <NavLink
                className="text-white text-decoration-none headercard"
                to={"/checkout"}
              >
                <i class="fa-solid fa-cart-shopping"></i>
                <span className="notifcount">{cartItems.length || 0}</span>
              </NavLink>
            </li>

            <li className="profileicon">
              <NavLink
                className="text-white text-decoration-none"
                to={"/profile/edit"}
              >
                M
              </NavLink>
            </li>
          </ul>

          {/*----- Header menu toggle button for small screeen -----*/}
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#delivernowheader"
          >
            <span className="navbar-toggler-icon" />
          </button>

          <div className="d-lg-none d-block mt-4" id="smHederlocation">
            <form action="#!" className="locationsetinput position-relative">
              <h3 className="mb-3 text-white fw-bold ps-3">
                Selected store : {getSelectedKitchen()?.name}
              </h3>

              <NavLink to={"/stores"} className="">
                <i className="fa-solid fa-location-dot text-warning" />
                <input
                  type="text"
                  placeholder=" "
                  defaultValue={getSelectedKitchen()?.kitchen_info?.address}
                  disabled
                />

                <i className="fa-solid fa-angle-down text-warning" />
              </NavLink>

              <div className="customtooltip d-lg-none d-none">
                <h2 className="fw-bold fs-2">Showing your default location</h2>
                <p className="fw-600 text-muted my-3 fs-3">
                  Not the right address? Tap herer to select your nearby store
                  to see the results.
                </p>

                <button
                  className="btn btn-light fs-4 fw-600 py-2 px-5 text-uppercase rounded-3 mt-3"
                  type="button"
                >
                  Ok got it !
                </button>
              </div>
            </form>
          </div>

          <div className="collapse navbar-collapse" id="delivernowheader">
            <form action="#!" className="locationsetinput position-relative">
              <h3 className="mb-0 text-white fw-bold ps-3 d-lg-block d-none">
                Selected store : {getSelectedKitchen()?.name}
              </h3>

              <NavLink to={"/stores"} className="d-lg-block d-none">
                <i className="fa-solid fa-location-dot text-warning" />
                <input
                  type="text"
                  placeholder=" "
                  defaultValue={getSelectedKitchen()?.kitchen_info?.address}
                  disabled
                />

                <i className="fa-solid fa-angle-down text-warning" />
              </NavLink>

              <div className="customtooltip d-lg-none d-none">
                <h2 className="fw-bold fs-2">Showing your default location</h2>
                <p className="fw-600 text-muted my-3 fs-3">
                  Not the right address? Tap herer to select your nearby store
                  to see the results.
                </p>

                <button
                  className="btn btn-light fs-4 fw-600 py-2 px-5 text-uppercase rounded-3 mt-3"
                  type="button"
                >
                  Ok got it !
                </button>
              </div>
            </form>

            <ul className="navbar-nav ms-auto">
              <li className="nav-item d-none">
                <a
                  className="nav-link"
                  data-bs-toggle="modal"
                  data-bs-target="#PRODUCTSEARCHMODAL"
                  href="#!"
                >
                  <i className="fa-solid fa-magnifying-glass me-2" />
                  Search
                </a>
              </li>

              <li className="nav-item">
                <NavLink to={"/about"} className="nav-link">
                  About
                </NavLink>
              </li>

              <li className="nav-item">
                <NavLink className="nav-link" to={"/membership"}>
                  Membership
                  <img src={process.env.PUBLIC_URL + "/assets/img/newicon.png"} className="ms-2" alt="" />
                  {/* <img
                    src="http://156.67.210.236/deliver_now/assets/img/newicon.png"
                    className="ms-2"
                    alt=""
                  /> */}
                </NavLink>
              </li>

              <li className="nav-item">
                <NavLink to={"/offers"} className="nav-link" >
                  Offers
                  <img src={process.env.PUBLIC_URL + "/assets/img/fireicon.png"} className="ms-2" alt="" />
                  {/* <img
                    src="http://156.67.210.236/deliver_now/assets/img/fireicon.png"
                    className="ms-2"
                    alt=""
                  /> */}
                </NavLink>
              </li>

              {!getToken() && <li className="nav-item">
                <NavLink to={"/login"} className="nav-link">
                  Login
                </NavLink>
              </li>}

              {getToken() && <li className="nav-item position-relative d-lg-block d-none">
                <NavLink className="nav-link" to={"/checkout"}>
                  Cart
                  <span className="notifcount">{cartItems.length || 0}</span>
                </NavLink>
              </li>}

              {getToken() && <li className="nav-item profileicon d-lg-block d-none">
                <NavLink className="nav-link" to={"/profile/edit"}>
                  M
                </NavLink>
              </li>}
            </ul>
          </div>
        </div>
        {/*----- container fluid end -----*/}
      </nav>
    </header>
  );
};

export default Header;
