import React, { useEffect, useRef, useState } from "react";
import Layout from "../components/common/Layout";
import { useDispatch, useSelector } from "react-redux";
import { callApi } from "../hooks/useapi";
import {
  createCheckoutObjArray,
  getDeliveryRates,
  itemsPrice,
} from "../utility/cartHelpers";
import {
  Haversineformula,
  generateRandomString,
  sendErrorInfo,
} from "../utility/helper";
import {
  addAdditionalInfo,
  changeOrderType,
  clearCartCount,
  generateTransectionKey,
  setDeliverType,
  setUserLocation,
} from "../store/actions/actions";
import { addToCart } from "../store/actions/cartActions";
import moment from "moment";

import { AppConst } from "../AppConst";
import { confirm } from "../components/common/ConfirmDialog";
import UserAddress from "../components/common/cart/UserAddress";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import {
  courierOrderNotPlaced,
  createShipment,
  getShipmentRates,
} from "../utility/ShipmentMethods";
import CheckoutPageHeader from "../components/common/cart/CheckOutPageHeader";
import AddressDetails from "../components/common/cart/AddressDetails";
import AddedItemsDetails from "../components/common/cart/AddedItemsDetails";
import CartAdditionalAction from "../components/common/cart/CartAdditionalAction";
import OrderSummary from "../components/common/cart/OrderSummary";
import PaymentMethod from "../components/common/cart/PaymentMethod";
import PaymentForm from "../components/common/cart/PaymentForm";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";

const Checkout = () => {
  const transection_key = useSelector((state) => state.root.transection_key);
  const additionalInfo = useSelector((state) => state.root.additionalInfo);
  const [walletData, setWalletData] = useState({});
  const [checkOutApiCalled, setCheckoutApiCalled] = useState(false);
  const cartData = useSelector((state) => state.cart.items);
  const kitchen_data = useSelector((state) => state.root.kitchen_data);
  const kitchen_id = useSelector((state) => state.root.kitchen_id);
  const [paymentType, setPaymentType] = useState("cash");
  const tipRef = useRef(additionalInfo.tip);
  const [showAddressModal, setShowShowAddressModal] = useState(false);
  const toggleAddressModal = () => {
    if (showAddressModal === true) {
      getAddressList();
    }
    setShowShowAddressModal(!showAddressModal);
  };
  const [kitchenData, setKitchen] = useState(null);
  const [coinsDiscount, setCoinDiscount] = useState(0);
  const [deliveryChareges, setDeliveryCharges] = useState(0);
  const [minOrder, setMinOrder] = useState(0);
  const [offerCode, setOfferCode] = useState("");
  const [offerDiscount, setOfferDiscount] = useState(0);
  // const [offerApplied, setOfferApplied] = useState(false);
  const [coinsSubmitted, setCoinsSubmitted] = useState(false)
  const [delivery, setDelivery] = useState(true);
  const [userActive, setUserActive] = useState(0);
  const [orderConfirmed, setOrderConfirmed] = useState({
    show: false,
    orderId: "",
  });
  const [changeAddress, setChangeAddress] = useState(false);
  const [orderDate, setOrderDate] = useState({
    date: "",
    time: "",
    timeInAmPM: "",
  });
  const [userAddressList, setUserAddressList] = useState([]);

  const [grandTotal, setGrandTotal] = useState(0);
  const [formValues, setFormValues] = useState({
    tip: additionalInfo.tip ? additionalInfo.tip : 0,
    additionalNotes: additionalInfo.additionalNotes
      ? additionalInfo.additionalNotes
      : "",
  });
  const [coins, setCoins] = useState({
    applied: 0,
    total: 0,
    coinValue: 0,
  });
  const [isDisabled, setIsDisabled] = useState("");
  const deliveryAddress = useSelector((state) => state.root.user_address);
  const delivery_method = useSelector((state) => state.root.delivery_method);
  const [totalAmount, setTotalAmount] = useState(itemsPrice(cartData) || 0);
  const [courierData, setCourierData] = useState({
    rate: 0,
    serviceCode: "",
    courier_id: "",
  });

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const paymentCardRef = useRef(null);
  const courier_id_ref = useRef(
    additionalInfo.courier_id ? additionalInfo.courier_id : null
  );
  const [searchParams, setSearchParams] = useSearchParams();
  useEffect(() => {
    if (delivery_method === "Courier") {
      handleChangeDeliveryMethod("Courier");
    } else if (delivery_method === "Pickup") {
      setFormValues({ ...formValues, tip: 0 });
      tipRef.current = 0;
    }

    callApi("user/total-coins", { method: "GET" }).then((response) => {
      if (response.status) {
        setCoins({ ...coins, total: response.data.total_coin });
      }
    });

    getAddressList();
    callApi("user/check-status").then((res) => {
      setUserActive(res.is_active);
    });
  }, []);

  const getAddressList = () => {
    callApi("user/address-list", "").then((response) => {
      if (response.status) {
        setUserAddressList(response.data.address);
      }
    });
  };

  useEffect(() => {
    const index = kitchen_data.findIndex((cat) => cat.id == kitchen_id);
    if (index > -1) {
      determineStatus(
        kitchen_data[index]?.delivery_active,
        kitchen_data[index]?.kitchen_info?.pickup_on_off,
        kitchen_data[index]?.is_courier
      );

      setKitchen(kitchen_data[index]);
      setTotalAmount(itemsPrice(cartData));
      let kitchenInfo = kitchen_data[index].kitchen_info;
      if (kitchenInfo && kitchenInfo.coin_discount > 0) {
        setCoinDiscount(kitchenInfo && kitchenInfo.coin_discount);
      }
      setMinOrder(kitchenInfo && kitchenInfo.min_order);

      delivery_method != "Pickup" &&
        setDeliveryCharges(kitchenInfo && kitchenInfo.delivery_charges);
      let dis = 0;
      cartData.forEach((each) => {
        console.log(
          "ach.discounted_amount",
          dis,
          each.discounted_amount,
          each.quantity
        );
        if (each.discounted_amount > 0) {
          dis = dis + each.discounted_amount * each.quantity;
        }
      });
      console.log("dis---------------", dis);
      setOfferDiscount(dis);
      // if (getCookies(process.env.REACT_APP_BUSINESS + "lat")) {
      //   // let user_data = getCookies('lat');
      //   let addressData = {
      //     lat: getCookies(process.env.REACT_APP_BUSINESS + "lat"),
      //     lng: getCookies(process.env.REACT_APP_BUSINESS + "lng"),
      //     address: getCookies(process.env.REACT_APP_BUSINESS + "address"),
      //   };
      //   dispatch(setUserLocation(addressData));
      // }
    } else {
      alert("Kitchen not found")
    }

  }, [kitchen_id, delivery_method, cartData]);
  useEffect(() => {
    if (
      searchParams.get("tid") === transection_key &&
      searchParams.get("amt") &&
      searchParams.get("type") === "medicalcannabis" &&
      kitchenData != null &&
      grandTotal > 0 &&
      checkOutApiCalled === false
    ) {
      setCheckoutApiCalled(true);
      setTimeout(() => {
        CheckoutOrders(searchParams.get("tid"), searchParams.get("amt"));
      }, 2000);
    }
    if (transection_key === "") {
      dispatch(generateTransectionKey(generateRandomString(15)));
    }
  }, [kitchenData, grandTotal]);
  const handleRemoveItemInCart = async (index) => {
    if (await confirm("Are you sure you want to remove this item from cart")) {
      let tempCart = [...cartData];
      // console.log("temp data------1", tempCart);
      // let index = tempCart.findIndex((item) => item.selected_size === itemId);
      // console.log("Index------1", index);
      if (index > -1) {
        tempCart.splice(index, 1);
      }
      console.log("temp data------2", tempCart);
      if (tempCart) {
        if (tempCart.length === 0) {
          dispatch(clearCartCount());
          //  setCartData([]);
        }
        dispatch(clearCartCount());

        tempCart.forEach((cart) => {
          if (cart.selected_size > 0) {
            dispatch(addToCart(cart));
          }
        });
      }
    } else {
      return;
    }
  };
  const handleClearCart = async (itemId) => {
    if (await confirm("Are you sure you want to clear cart")) {
      dispatch(clearCartCount());
      navigate("/home");
      //  setCartData([]);
    } else {
      return;
    }
  };
  //apply coupon code
  // const handleApplyCouponCode = () => {
  //   let price = calculateOrderPrice();
  //   const postData = {
  //     promo_code: offerCode,
  //     amount: price,
  //   };
  //   console.log(postData);
  //   callApi("user/apply-offer-code", { method: "POST", data: postData }).then(
  //     (response) => {
  //       if (response.status) {
  //         setOfferApplied(true);
  //         setTotalAmount(totalAmount - response.data.discount_amount);
  //         setOfferDiscount(response.data.discount_amount);
  //         sendSuccessInfo(response.message);
  //       } else {
  //         sendErrorInfo(response.errors.error);
  //       }
  //     }
  //   );
  // };

  // const calculateOrderPrice = () => {
  //   return parseFloat(
  //     cartData.reduce((previous, item) => {
  //       if (
  //         item.discounted_amount > 0 &&
  //         item.discount_start_date &&
  //         item.discount_end_date &&
  //         checkTodayDateInRange(
  //           item.discount_start_date,
  //           item.discount_end_date
  //         )
  //       ) {
  //         return previous;
  //       }
  //       return previous + item.back_up_price * parseInt(item.quantity);
  //     }, 0)
  //   );
  // };

  const submitCalculations = (e, apicall, kitchenData) => {
    if (e) {
      e.preventDefault();
    }

    if (apicall === true) {
      setIsDisabled("disabled");
    }

    let totalCoins = coins.applied / coinsDiscount;

    let tip = parseInt(tipRef.current || formValues.tip);
    let tex =
      (
        (parseFloat(itemsPrice(cartData)) * kitchenData?.kitchen_info?.tax) /
        100
      ).toFixed(2) || 0;
    // setUseCoin(coins.applied);
    // setCoins({ ...coins, applied: coins.applied });

    let delivery_charges = deliveryChareges;
    // setAdditionalNotes(formValues.additional_notes);
    // setTax(tex.toFixed(2));
    let distance = Haversineformula(
      kitchenData?.kitchen_info?.lat,
      kitchenData?.kitchen_info?.lng,
      deliveryAddress?.lat,
      deliveryAddress?.lng
    );

    delivery_charges =
      delivery_method === "Courier"
        ? courierData.rate || 0
        : delivery_method === "Pickup"
          ? 0
          : getDeliveryRates({
            cart_value: itemsPrice(cartData),
            delivery_method,
            kitchen_id,
            kitchen_data,
            deliveryAddress,
          });

    if (delivery === false && delivery_method === "Pickup") {
      delivery_charges = 0;
      tip = 0;
    }
    if (totalCoins > 0 && tip > 0) {
      setCoins({ ...coins, coinValue: totalCoins });
      setDeliveryCharges(delivery_charges.toFixed(2));
      setGrandTotal(
        parseFloat(itemsPrice(cartData)) +
        parseFloat(delivery_charges) +
        parseFloat(tip) +
        parseFloat(tex).toFixed(2) -
        parseFloat(totalCoins)
      );
    } else if (tip > 0) {
      // setTip(tip);
      setDeliveryCharges(delivery_charges);
      setGrandTotal(
        parseFloat(itemsPrice(cartData)) +
        parseFloat(delivery_charges) +
        parseFloat(tip) +
        parseFloat(tex)
      );
    } else if (totalCoins > 0) {
      setGrandTotal(
        parseFloat(itemsPrice(cartData)) +
        parseFloat(delivery_charges) +
        parseFloat(tex) -
        parseFloat(totalCoins)
      );
      setCoins({ ...coins, coinValue: totalCoins });
      setDeliveryCharges(delivery_charges.toFixed(2));
    } else {
      setDeliveryCharges(delivery_charges);

      setGrandTotal(
        itemsPrice(cartData) + parseFloat(delivery_charges) + parseFloat(tex)
      );
    }
    if (apicall) {

      if (cartData.length <= 0) {
        setIsDisabled("");
        return sendErrorInfo("Please add products in cart first");
      }

      if (
        (orderDate.date == "" || orderDate.time == "") &&
        kitchenData?.is_pre_order == "1"
      ) {
        setIsDisabled("");
        return sendErrorInfo("Please select order time");
      }
      callApi(
        "user/kitchen-list?lat=" +
        deliveryAddress.lat +
        "&lng=" +
        deliveryAddress.lng +
        "&bus_owner_id=" +
        process.env.REACT_APP_BUSS_ID,
        { method: "GET" }
      ).then((response) => {
        if (deliveryAddress.address == undefined) {
          setIsDisabled("");
          return sendErrorInfo("Please select delivery address");
        }
        if (response.status) {
          let index = undefined;
          index = response.data.kitchen_list.findIndex(
            (kit) => kit.id == kitchen_id
          );
          let kitchen_data;
          if (index < 0) {
            setIsDisabled("");
            return sendErrorInfo("Store is not available");
          } else {
            kitchen_data = response.data.kitchen_list[index];
            if (
              delivery === true &&
              kitchen_data.kitchen_info.min_order >= totalAmount
            ) {
              setIsDisabled("");
              return sendErrorInfo(
                `Minimum order greater or equal to ${kitchen_data.kitchen_info.min_order}`
              );
            } else if (kitchen_data.is_dark_mode == 1) {
              setIsDisabled("");
              return sendErrorInfo("Store is not accepting orders right now");
            } else if (kitchen_data.status === "1") {
              setIsDisabled("");
              return sendErrorInfo("Store is not accepting orders right now");
            } else if (kitchen_data.is_kitchenbusy == "true") {
              setIsDisabled("");
              return sendErrorInfo("Store is not accepting orders right now");
            } else if (userActive == -1) {
              setIsDisabled("");
              return sendErrorInfo(
                "Your are blocked by admin, you can not make orders"
              );
            } else if (
              delivery === false &&
              kitchen_data.kitchen_info.pickup_min_order >= totalAmount
            ) {
              setIsDisabled("");
              return sendErrorInfo(
                `Minimum pickup order greater or equal to ${kitchen_data.kitchen_info.pickup_min_order}`
              );
            }
            var extra = moment().format("YYYY-MM-DD") + " ";
            let open_time = moment(
              extra + kitchen_data.kitchen_info.delivery_open
            );
            let close_time = moment(
              extra + kitchen_data.kitchen_info.delivery_close
            );
            if (moment().isBetween(open_time, close_time)) {
              if (delivery_method === "Courier" && apicall === true) {
                createShipment(courierData.rate, courierData.serviceCode).then(
                  (res) => {
                    if (res.courier_id) {
                      courier_id_ref.current = res.courier_id;
                      setCourierData({
                        ...courierData,
                        courier_id: res.courier_id,
                      });
                      if (paymentType === "online") {
                        dispatch(
                          addAdditionalInfo({
                            tip: formValues.tip,
                            additionalNotes: formValues.additionalNotes,
                            courier_id: res.courier_id,
                          })
                        );
                        paymentCardRef.current.click();
                      } else {
                        CheckoutOrders("nopay");
                      }
                    } else {
                      return;
                    }
                  }
                );
              } else {
                if (paymentType === "online") {
                  dispatch(
                    addAdditionalInfo({
                      tip: formValues.tip,
                      additionalNotes: formValues.additionalNotes,
                      courier_id: "",
                    })
                  );
                  paymentCardRef.current.click();
                } else {
                  CheckoutOrders("nopay");
                }
              }
            } else {
              setIsDisabled("");
              return sendErrorInfo("Store is off");
            }
          }
        }
      });
    }
  };

  //Order confirm functions

  const getCheckoutBody = (transactionId, grandtotal) => {
    let deliveryStatus = delivery_method === "Pickup" ? false : delivery;
    let is_courier = delivery_method === "Courier" ? true : false;
    let courierId =
      delivery_method === "Courier"
        ? courierData.courier_id
          ? courierData.courier_id
          : courier_id_ref.current
        : null;
    // if(orderDate.time.match( /^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/ && delivery == true)){
    //   deliveryStatus = false
    // }
    // else {
    //   deliveryStatus = true
    // }
    // const { cart_items, promo_code, delivery_method, user_address, user_coin, pre_order } = this.props;
    const driver_tip =
      deliveryStatus == true && formValues.tip ? formValues.tip : 0;
    const tax_percentage =
      kitchenData?.kitchen_info && kitchenData?.kitchen_info.tax > 0
        ? kitchenData?.kitchen_info.tax
        : 0;
    const tax =
      tax_percentage > 0 ? (tax_percentage * itemsPrice(cartData)) / 100 : 0;

    // const delivery_charges = getDeliveryRates({ cart_value: totalAmount });
    const obj = {};
    obj["address_type"] = deliveryAddress.address_type;
    obj["products"] = createCheckoutObjArray(cartData);
    obj["kitchen_id"] = kitchenData?.id;
    obj["sub_total"] = itemsPrice(cartData);
    obj["amount"] = grandtotal
      ? parseFloat(grandtotal).toFixed(2)
      : grandTotal.toFixed(2); // final price

    if (coins.applied > 0) {
      obj["coins"] = coins.applied;
      obj["coins_discount"] = coinsDiscount;
    }
    obj["tax"] = tax; //tax;
    obj["tax_per"] = tax_percentage; //tax_percentage;

    obj["discount_amount"] = offerDiscount;
    obj["additional_notes"] =
      formValues.additionalNotes && formValues.additionalNotes.trim() != ""
        ? formValues.additionalNotes.trim()
        : "No extra note";

    obj["promo_code"] = offerCode ? offerCode : null;

    obj["transaction_id"] = transactionId ? transactionId : "nopay";
    // Delivery Module
    obj["is_delivered_free"] = deliveryChareges > 0 ? false : true;
    obj["serviceFee"] = deliveryChareges;
    obj["delivery_address"] = deliveryAddress.address;
    obj["d_lat"] = deliveryAddress.lat;
    obj["d_lng"] = deliveryAddress.lng;
    obj["landmark"] = "N/A";
    // obj["is_first_order"] = this.orderCount == 0 ? '1' : '0';
    obj["is_first_order"] = "0";
    // obj["flat_number"] = user_address.apartment ? user_address.apartment : 'N/A';
    obj["flat_number"] = deliveryAddress.apartment
      ? deliveryAddress.apartment
      : "N/A";
    obj["is_machine"] = paymentType === "card" ? "1" : "0";
    obj["tip"] = driver_tip;
    // Pickup
    obj["is_self"] = deliveryStatus === false ? "1" : "0";

    obj["paid"] =
      paymentType === "card"
        ? 0
        : paymentType === "wallet"
          ? 1
          : transactionId === "nopay"
            ? 0
            : 1;

    obj["payment_method"] =
      paymentType === "card"
        ? "Card on Delivery"
        : paymentType === "wallet"
          ? "Wallet"
          : transactionId === "nopay"
            ? "Cash on Delivery"
            : "Online Payment";
    // for bussiness owner
    obj["owner_id"] = AppConst.getBussInfo.owner_id;
    obj["buss_id"] = AppConst.getBussInfo.business_owner_id;

    // Pre_Order Section
    obj["pre_order"] =
      deliveryStatus === false && orderDate.time != "" ? "1" : "0";

    let time = orderDate.time.includes("ASAP")
      ? moment().format("hh:mm A")
      : orderDate.time
        ? orderDate.time
        : moment().format("hh:mm");
    obj["pre_date"] =
      deliveryStatus === false && kitchenData.is_pre_order == "1"
        ? moment(orderDate.date).format("YYYY-MM-DD")
        : moment().format("YYYY-MM-DD");
    obj["pre_time"] =
      deliveryStatus === false && kitchenData.is_pre_order == "1"
        ? orderDate.timeInAmPM
        : null;
    obj["pre_full_date_time"] =
      deliveryStatus === true || orderDate.date == ""
        ? null
        : `${orderDate.timeInAmPM}, ${orderDate.date}`;
    obj["is_courier"] = is_courier ? "1" : "0";
    obj["courier_tracking_id"] =
      is_courier && courierId ? courierId : "Test_0001";
    return obj;
  };

  const CheckoutOrders = (transactionId, grandtotal) => {
    if (paymentType === "wallet") {
      if (grandTotal > walletData.total_amount) {
        return sendErrorInfo(
          "You dont have sufficient amount in your wallet, kindly add more amount in wallet"
        );
      }
    }
    let OrdersData = getCheckoutBody(transactionId, grandtotal);
    console.log("OrdersData", OrdersData);
    callApi("user/order-place", { method: "POST", data: OrdersData })
      .then((response) => {
        setIsDisabled("");
        if (response.status) {
          if (response.data.order_id) {
            localStorage.removeItem(
              process.env.REACT_APP_BUSINESS + "productsInCart"
            );
            if (paymentType === "wallet") {
              refereshWalletData(grandTotal, response.data.order_id);
            }
            dispatch(
              addAdditionalInfo({
                tip: 0,
                additionalNotes: "",
                courier_id: "",
              })
            );
            navigate("/checkout");
            localStorage.removeItem(process.env.REACT_APP_BUSINESS + "Total");
            // dispatch(addCurrentOrder(response.data.order_id));
            dispatch(clearCartCount());
            dispatch(changeOrderType(false));
            setOrderConfirmed({
              show: true,
              orderId: response.data.order_id,
            });
          }
        } else {
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const refereshWalletData = (amount, order_id) => {
    let postData = {
      amount: amount,
      order_id: order_id,
      transaction_id: "ab5asd",
    };
    callApi("user/wallet-used", { method: "POST", data: postData }).then(
      (response) => {
        if (response.status === true) {
          getWallet();
        }
      }
    );
  };
  const onSelectDateAndTime = (e) => {
    if (e) {
      setOrderDate({
        ...orderDate,
        date: e.date,
        time: e.timeIn24hr,
        timeInAmPM: e.timeInAmPM,
      });
    }
  };
  const handleChangeAddress = (address) => {
    setGrandTotal(0);
    setCoins({ ...coins, applied: 0, coinValue: 0 });
    setDeliveryCharges(0);
    setFormValues({ ...formValues, tip: 0 });
    let saveLoc = {
      address_type: address?.address_type,
      apartment: address?.building_name || "",
      lat: address.lat,
      lng: address.lng,
      address: address.address,
    };
    dispatch(setUserLocation(saveLoc));
    setChangeAddress(!changeAddress);
  };

  const handleAddmore = (type, index, quantity) => {
    let tempCart = [...cartData];
    if (type == "add") {
      console.log("tempCart[index].product_detail", tempCart[
        index
      ])
      const selectedVariant = tempCart[
        index
      ].product_detail.product_variant.find(
        (item) => item.id === tempCart[index].selected_size
      );
      if (
        kitchenData?.kitchen_info?.stock_onoff === '1' &&
        selectedVariant?.total_inventory &&
        selectedVariant?.total_inventory[0]?.quantity_total < quantity + 1
      ) {
        return sendErrorInfo(
          `Product units can not be greater than ${selectedVariant?.total_inventory &&
          selectedVariant?.total_inventory[0]?.quantity_total
          }`
        );
      }
      let countdata = quantity + 1;
      tempCart[index].quantity = countdata;
      dispatch(clearCartCount());
      tempCart.forEach((cart) => {
        if (cart.selected_size > 0) {
          dispatch(addToCart(cart));
        }
      });
    } else {
      let countdata = quantity - 1;
      if (countdata > 0) {
        tempCart[index].quantity = countdata;
        dispatch(clearCartCount());
        tempCart.forEach((cart) => {
          if (cart.selected_size > 0) {
            dispatch(addToCart(cart));
          }
        });
      } else if (countdata === 0) {
        handleRemoveItemInCart(index);
      }
    }
    submitCalculations(null, false, kitchenData);
  };

  const handleChangeDeliveryMethod = (method) => {
    dispatch(setDeliverType(method));
    if (method === "Pickup") {
      setDeliveryCharges(0);
      setFormValues({ ...formValues, tip: 0 });
      tipRef.current = 0;
    }
    if (method === "Courier") {
      getShipmentRates().then((response) => {
        if (!response.rate) {
          courierOrderNotPlaced();
        } else {
          setCourierData({
            ...courierData,
            rate: response.rate,
            serviceCode: response.serviceCode,
          });
        }
      });
    } else {
      setCourierData({ rate: "", serviceCode: "", courier_id: "" });
    }
  };
  useEffect(() => {
    submitCalculations(null, false, kitchenData);
  }, [
    courierData.rate,
    kitchenData,
    delivery_method,
    cartData,
    deliveryAddress,
    coinsSubmitted
  ]);
  const getWallet = () => {
    callApi("user/wallet-amount", { method: "GET" }).then((response) => {
      if (response.status === true) {
        setWalletData(response.data);
      }
    });
  };
  function determineStatus(delivery, pickup, courier) {
    if (delivery_method === "Pickup" && pickup === "0") {
      if (delivery === "1") {
        return handleChangeDeliveryMethod("Delivery");
      } else {
        return handleChangeDeliveryMethod("Courier");
      }
    } else if (delivery_method === "Delivery" && delivery === "0") {
      if (pickup === "1") {
        return handleChangeDeliveryMethod("Pickup");
      } else {
        return handleChangeDeliveryMethod("Courier");
      }
    } else if (delivery_method === "Courier" && courier === "0") {
      if (pickup === "1") {
        return handleChangeDeliveryMethod("Pickup");
      } else {
        return handleChangeDeliveryMethod("Delivery");
      }
    }
  }

  const toggleModal = () => { };

  return (
    <Layout pageClass={"checkoutpage"}>
      <CheckoutPageHeader
        delivery={delivery}
        totalAmount={totalAmount}
        kitchenData={kitchenData}
        delivery_method={delivery_method}
      />

      {/*----- Page title and discription section end -----*/}
      <section className="checkoutDetailssection mt-0 mx-0">
        <div className="container-fluid">
          <form action="#">
            <div className="row">
              <div className="col-lg-8 col-12">
                <div className="addressAnditemsdetails">
                  {/*----- Selectd Outlet address -----*/}

                  <AddressDetails
                    kitchenData={kitchenData}
                    deliveryAddress={deliveryAddress}
                    changeAddress={changeAddress}
                    setChangeAddress={() => setChangeAddress(!changeAddress)}
                    toggleAddressModal={() => toggleAddressModal()}
                  />
                  {/*---- Item's Details -----*/}
                  <AddedItemsDetails
                    cartData={cartData}
                    handleClearCart={() => handleClearCart()}
                    handleAddmore={(e) =>
                      handleAddmore(e.type, e.index, e.quantity)
                    }
                  />
                  <CartAdditionalAction
                    tipRef={tipRef}
                    formValues={formValues}
                    delivery_method={delivery_method}
                    grandTotal={itemsPrice(cartData).toFixed(2)}
                    submitCalculations={() =>
                      submitCalculations(null, false, kitchenData)
                    }
                    setFormValues={(e) => {
                      setFormValues({
                        ...formValues,
                        tip: e.tip,
                        additionalNotes: e.additionalNotes,
                      });
                    }}
                    kitchenData={kitchenData}
                  />
                  {/*----- Order summary -----*/}
                  <p className="text-muted my-4 fw-bold fs-3">Order summary</p>

                  <OrderSummary
                    cartData={cartData}
                    deliveryChareges={deliveryChareges}
                    offerDiscount={offerDiscount}
                    grandTotal={grandTotal}
                    kitchenData={kitchenData}
                    formValues={formValues}
                    coins={coins}
                  />
                </div>
              </div>

              <PaymentMethod
                formValues={formValues}
                kitchenData={kitchenData}
                handleChangeDeliveryMethod={(e) =>
                  handleChangeDeliveryMethod(e)
                }
                disabled={isDisabled}
                setDelivery={(e) => setDelivery(e)}
                delivery_method={delivery_method}
                paymentType={paymentType}
                setPaymentType={(e) => setPaymentType(e)}
                getWallet={() => getWallet()}
                walletData={walletData}
                onSelectDateAndTime={(e) => onSelectDateAndTime(e)}
                coins={coins}
                setCoins={(e) => {
                  if (e.applied > 0) {
                    setCoinsSubmitted(true)
                  } else {
                    setCoinsSubmitted(false)
                  }
                  setCoins(e)
                }}
                submitCalculations={(data) => {
                  submitCalculations(data.event, data.apiCall, data.kitchenData)
                }}
                coinsDiscount={coinsDiscount}
              />
            </div>
          </form>
        </div>
        <div className="d-none">
          <PaymentForm
            returnUrl={`${location.pathname}?tid=${transection_key}&amt=${grandTotal}&type=medicalcannabis`}
            totalPrice={grandTotal}
            uniqeId={transection_key}
            buttonTitle={`Proceed to add ${AppConst.getBussInfo.currency}`}
            ref={paymentCardRef}
          />
        </div>
      </section>
      <UserAddress
        onAddAddress={(value) => setUserAddressList(value)}
        editAddress={[]}
        showModal={showAddressModal}
        setShowModal={(val) => setShowShowAddressModal(val)}
        toggleModal={toggleAddressModal}
      />

      <Modal
        className="modal-dialog-centered modal-dialog-scrollable AddtocardProductModal"
        isOpen={changeAddress}
        toggle={() => setChangeAddress(!changeAddress)}
      >
        <ModalHeader className="d-block px-0 pt-0 pb-3 position-relative ">
          <span className="fw-bold fs-1 ps-3 py-4 d-block">
            Select Delivery/Pickup Location
          </span>

          <button
            type="button"
            onClick={() => setChangeAddress(!changeAddress)}
            className="btn-close customClose"
          >
            <i className="fa-solid fa-xmark" />
          </button>
        </ModalHeader>

        <ModalBody>
          {" "}
          {userAddressList.length == 0 && (
            <>
              <img
                className="noDataImg"
                src={process.env.PUBLIC_URL + "/assets/img/NodataIMG.png"}
                alt=""
              />
              <h1 className="text-danger fw-bold text-center mt-5">
                No address found
              </h1>
            </>
          )}
          {userAddressList &&
            userAddressList.length > 0 &&
            userAddressList.map((address, index) => (
              <div key={index} className="alert alert-success mb-4">
                <p className=" align-items-center m-0">
                  <i className="fas fa-map-marker-alt me-2" />
                  <span>{address.address}</span>
                </p>

                <div className="text-end">
                  <button
                    type="button"
                    onClick={() => handleChangeAddress(address)}
                    class="badge rounded-2 bg-primary border-0 py-3 px-3 fs-4"
                  >
                    Select Address
                  </button>
                </div>
              </div>
            ))}
        </ModalBody>
      </Modal>

      <Modal
        className="modal-dialog-centered modal-dialog-scrollable AddtocardProductModal"
        isOpen={orderConfirmed.show}
      >
        <ModalBody>
          <>
            <div
              id="SuccessModal"
              className="ThankYouforordermodal"
              data-bs-backdrop="static"
              data-bs-keyboard="false"
              tabIndex={-1}
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-body">
                    <img src="./assets/img/success.gif" alt="" />
                    <h1 className="fs-1 fw-bold text-center mb-0">
                      Thanks for ordering
                    </h1>
                    <p className="text-success text-center fw-bold fs-3 my-3">
                      Your order has been placed successfully
                    </p>
                    <p className="text-muted text-center fw-bold mb-0">
                      Order number: #{orderConfirmed?.orderId}
                    </p>
                    <Link
                      to={"/profile/orders"}
                      className="btn btn-primary d-block fw-600 w-100 fs-2 py-3 mt-5"
                    >
                      Track your order
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </>
        </ModalBody>
      </Modal>
    </Layout>
  );
};

export default Checkout;
