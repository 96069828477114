import React, { useEffect, useState } from "react";
import Layout from "../components/common/Layout";
import { TabContent, TabPane } from "reactstrap";
import Wallet from "../components/common/Profile/Wallet";
import MyOrders from "../components/common/Profile/MyOrders";
import MyAddress from "../components/common/Profile/MyAddress";
import MyWishlist from "../components/common/Profile/MyWishlist";
import ManageCoins from "../components/common/Profile/ManageCoins";
import UserProfile from "../components/common/Profile/UserProfile";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../utility/helper";
import { Link, useNavigate, useParams } from "react-router-dom";
import { AppConst } from "../AppConst";
import ManageMembership from "../components/common/Profile/ManageMembership";
import ManagePassword from "../components/common/Profile/ManagePassword";
import NotificationList from "../components/common/Profile/NotificationList";
import { addKitchenData, addKitchenId, addKitchenName, clearCartCount, saveUserData } from "../store/actions/actions";
const Profile = () => {
  // const [activeTab, setActiveTab] = useState("1");
  const [wallet, setWallet] = useState(0);
  const [walletStatus, setWalletStatus] = useState(false)
  const [notificationNo, setNotificationNo] = useState(0)
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const { tabId } = useParams();
  const toggleTab = (tab) => {
    navigate(`/profile/${tab}`);

    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const userData = useSelector((state) => state.root.user_data);
  const kitchen_data = useSelector((state) => state.root.kitchen_data);
  const kitchen_id = useSelector((state) => state.root.kitchen_id);
  useEffect(() => {
    const index = kitchen_data.findIndex((cat) => cat.id == kitchen_id);
    if (index > -1) {
      const kitchen = kitchen_data[index];
      if (kitchen?.kitchen_info?.wallet_on_off == "1") {
        setWalletStatus(true)
      }
    }
  }, []);
  function handleDeleteAccount() { }


  const navLinks = [
    {
      id: "edit",
      icon: "fa-solid fa-pencil",
      text: "Edit Profile",
      className: "nav-link",
    },
    {
      id: "orders",
      icon: "fa-regular fa-rectangle-list",
      text: "Order list",
      className: "nav-link active",
    },
    {
      id: "password",
      icon: "fa-solid fa-lock",
      text: "Change password",
      className: "nav-link",
    },
    {
      id: "address",
      icon: "fa-solid fa-location-dot",
      text: "Manage address",
      className: "nav-link",
    },
    {
      id: "notifications",
      icon: "fa-regular fa-bell",
      text: "Notifications",
      className: "nav-link",
      value: notificationNo,
    },


    // {
    //   id: "wishlist",
    //   icon: "fa-solid fa-heart",
    //   text: "Wishlist",
    //   className: "nav-link",
    // },
    ...(walletStatus
      ? [
        {
          id: "wallet",
          icon: "fa-solid fa-wallet",
          text: "My wallet",
          className: "nav-link",
          value: AppConst.getBussInfo.currency + wallet,
        },
      ]
      : []),
    {
      id: "coins",
      icon: "fa-solid fa-coins",
      text: "My coins",
      className: "nav-link",
    },
    // {
    //   id: "6",
    //   icon: "fa-regular fa-credit-card",
    //   text: "Manage payments",
    //   className: "nav-link",
    // },

    {
      id: "membership",
      icon: "fa-solid fa-link-slash",
      text: "Manage Membership",
      className: "nav-link",
    },
    {
      id: "10",
      icon: "fa-solid fa-trash-can",
      text: "Delete account",
      className: "nav-link",
      onClick: handleDeleteAccount,
    }
  ];
  return (
    <Layout pageClass={"profilePage"}>
      <section className="Pagetitledisc m-0 secPadding_right  secPadding_left">
        <div className="container-fluid py-3  secPadding_right  secPadding_left">
          <div className="row">
            <div className="col-lg-12 col-12">
              <div className="d-lg-flex justify-content-between">
                <div>
                  {/*----- Section title -----*/}
                  <h1 className="default_title">Customer profile</h1>
                  {/*----- Section Sub title -----*/}
                  <p className="default_subtitle mb-0">
                    Looking for great product  near you
                  </p>
                </div>
                {/*----- breadcrumb -----*/}
                <nav className="align-self-end mt-lg-0 mt-4">
                  <ol className="breadcrumb mb-0">
                    <li className="breadcrumb-item">
                      <Link to={"/home"}>Home</Link>
                    </li>
                    <li className="breadcrumb-item active">
                      <p className="d-inline-block fw-bold text-muted">
                        Profile
                      </p>
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*----- Page title and discription section end -----*/}


      <section className="customerprofilecontainer mt-3">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-3 col-12">
              <ul className="profileoptionList list-unstyled mb-0">
                <li>
                  <div className="userdetailsbrief flex-wrap">
                    <p className="mb-0 proname">
                      {capitalizeFirstCharacter(userData?.name)}
                    </p>
                    {/*     */}
                    <p className="mb-0 text-primary fw-bold">
                      {userData?.name}
                      <br />
                      <span className="text-muted"> {userData?.mobile}</span>
                    </p>

                    <a href="#!" onClick={() => toggleTab("edit")}>
                      <i className="fa-regular fa-pen-to-square text-success" />
                    </a>

                  </div>
                </li>

                <li className="sublistpart d-lg-block d-none">
                  <div className="nav nav-pills d-block">
                    {navLinks.map((link) => (
                      <button
                        key={link.id}
                        className={`nav-link ${tabId === link.id ? "active" : ""
                          }`}
                        onClick={() => toggleTab(link.id)}
                        type="button"
                      >
                        <span>
                          <i className={`fa-solid ${link.icon} me-3`} />
                          {link.text}
                        </span>

                        {link.value && (
                          <span className="text-success fw-bold">
                            {link.value}
                          </span>
                        )}
                      </button>
                    ))}

                    <a
                      href="#!"
                      onClick={() => {
                        dispatch(saveUserData({}));
                        dispatch(clearCartCount())
                        dispatch(addKitchenData([]))
                        dispatch(addKitchenId(0))
                        dispatch(addKitchenName(''))
                        logoutUser();
                        navigate("/");
                      }}
                      className="nav-link"
                    >
                      <span className="">
                        <i className="fa-solid fa-arrow-right-from-bracket me-3" />
                        Logout
                      </span>
                    </a>
                  </div>
                </li>
              </ul>
            </div>

            <div className="col-lg-9 col-12">
              <a href="#!" className="smlogoutbtn d-lg-none d-block">
                Logout
              </a>

              <nav className="d-lg-none d-block mt-5">
                <div className="foodCATSM d-lg-none d-block position-relative mb-3">
                  <input type="checkbox" className="d-none" id="FoodCAtDrop" />

                  <label htmlFor="FoodCAtDrop">Select Profile Option</label>

                  <ul className="ProCATLISTs nav nav-tabs d-block">
                    {/*======== User Profile option Tabs ========*/}
                    {navLinks.map((link) => (
                      <li onClick={() => toggleTab(link.id)}>
                        <p>
                          {link.text}
                          {link.value && (
                            <span className="fw-bold text-success ms-3">
                              {link.value}
                            </span>
                          )}
                        </p>
                      </li>
                    ))}
                  </ul>
                </div>
              </nav>

              <TabContent
                activeTab={tabId}
                className="customerprodescription mt-lg-0 mt-5"
              >
                <TabPane tabId={"wallet"}>
                  <Wallet
                    activetedTab={tabId}
                    getWalletBalance={(e) => setWallet(e)}
                  />
                </TabPane>

                <TabPane tabId={"orders"}>
                  <MyOrders activetedTab={tabId} />
                </TabPane>

                <TabPane tabId={"address"}>
                  <MyAddress />
                </TabPane>

                <TabPane tabId={"wishlist"}>
                  <MyWishlist />
                </TabPane>

                <TabPane tabId={"coins"}>
                  <ManageCoins />
                </TabPane>

                <TabPane tabId={"6"}>
                  <ManageCoins />
                </TabPane>

                <TabPane tabId={"password"}>
                  <ManagePassword />
                </TabPane>

                <TabPane tabId={"notifications"}>
                  <NotificationList
                    getNotificationNo={(e) => setNotificationNo(e)}
                  />
                </TabPane>

                <TabPane tabId={"membership"}>
                  <ManageMembership activetedTab={tabId} />
                </TabPane>

                <div className="tab-pane fade" id="profileOPTION6">
                  <p className="mb-0 fw-bold">Change Password</p>
                </div>

                <div className="tab-pane fade" id="profileOPTION7">
                  <p className="mb-0 fw-bold">Notifications</p>
                </div>

                <div className="tab-pane fade" id="profileOPTION8">
                  <p className="mb-0 fw-bold">Cancel Membership</p>
                </div>

                <div className="tab-pane fade" id="profileOPTION9">
                  <p className="mb-0 fw-bold">Delete Account</p>
                </div>

                <TabPane tabId={"edit"}>
                  <UserProfile />
                </TabPane>
              </TabContent>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Profile;
const capitalizeFirstCharacter = (str) => {
  if (!str) {
    return "";
  }

  return str.charAt(0).toUpperCase();
};
