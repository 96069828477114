import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { callApi } from "../../../hooks/useapi";
import { setMenuPopUpShow } from "../../../store/actions/actions";
import MenuCard from "./MenuCard";
import { checkItemInStock } from "../../../utility/cartHelpers";

const MenuSection = ({ isPremiumMember, kitchenData, openMembershipPopup = () => { } }) => {
  const [filter, setFilter] = useState("");
  const [categoriesData, setCategoriesData] = useState([]);
  const [subCategoryData, setSubcategoryData] = useState([]);
  const productData = useRef([]);
  const [selectedCat, setSelectedCat] = useState();
  const [selectedSubCat, setSelectedSubCat] = useState();
  const [filterProduct, setFilterProduct] = useState([]);
  const kitchen_data = useSelector((state) => state.root.kitchen_data);
  const kitchen_id = useSelector((state) => state.root.kitchen_id);
  const selectedKitchn = kitchen_data.find((item) => item.id == kitchen_id);
  const dispatch = useDispatch();

  const onChnageMenu = (id) => {
    setSelectedCat(id);
    setSelectedSubCat();
    let match = new RegExp(id, "g");
    setFilterProduct(getProductsByCategoryId(id, productData.current));
  };

  const onChnageSubMenu = (id) => {
    setSelectedSubCat(id);
    let match = new RegExp(id, "g");
    setFilterProduct(getProductsBySubCategoryId(id, productData.current));
  };
  useEffect(() => {
    setCategoriesData([]);
    setFilterProduct([]);
    if (kitchenData != null) {
      let category = kitchenData.categories_list;
      if (category.length > 0) {
        let categories = getAllCategory(category);

        setCategoriesData(categories);
        getAllSubCategoryCategory(categories[0]);
        productData.current = kitchenData.product_list;
        setSelectedCat(categories[0].id);
        setFilterProduct(
          getProductsByCategoryId(categories[0].id, kitchenData.product_list)
        );
      }
    }
    //
  }, [kitchenData]);

  useEffect(() => {
    return () => {
      dispatch(setMenuPopUpShow(false));
    };
  }, []);
  const handleFilterItem = () => {
    if (filter === "membership") {
      let item = filterProduct.filter(
        (item) => item.is_available_membership == "1"
      );
      return item;
    } else if (filter === "nomembership") {
      let item = filterProduct.filter(
        (item) => item.is_available_membership != "1"
      );
      return item;
    } else if (filter === "veg") {
      let item = filterProduct.filter((item) => item.veg == "1");
      return item;
    } else if (filter === "low") {
      let item = filterProduct.sort((a, b) => {
        return (
          ((a?.product_item_list &&
            a?.product_item_list[0]?.product_item &&
            a?.product_item_list[0]?.product_item[0]?.price) ||
            0) -
          (b?.product_item_list &&
            b?.product_item_list[0]?.product_item &&
            b?.product_item_list[0]?.product_item[0]?.price) || 0
        );
      });
      return item;
    } else if (filter === "high") {
      let item = filterProduct.sort((a, b) => {
        return (
          ((b?.product_item_list &&
            b?.product_item_list[0]?.product_item &&
            b?.product_item_list[0]?.product_item[0]?.price) ||
            0) -
          (a?.product_item_list &&
            a?.product_item_list[0]?.product_item &&
            a?.product_item_list[0]?.product_item[0]?.price) || 0
        );
      });

      return item;
    } else if (filter === "") {
      return filterProduct;
    }
  };

  const getAllSubCategoryCategory = (cat) => {
    if (cat) {
      let category =
        cat?.sub_child_category && cat.sub_child_category.map((item) => item);
      setSubcategoryData(category);
    } else {
      setCategoriesData([]);
    }
  };

  const categoriesList = () => {
    return (
      categoriesData &&
      categoriesData.length &&
      categoriesData.map((category, index) => (
        <li>
          <button
            onClick={() => {
              getAllSubCategoryCategory(category);
              onChnageMenu(category.id);
            }}
            key={index}
            className={
              selectedCat == category.id ? "nav-link active" : "nav-link"
            }
            data-bs-toggle="tab"
            data-bs-target="#collpro1"
            type="button"
          >
            {category.name}
          </button>
        </li>
      ))
    );
  };

  const subCategoriesList = () => {
    return (
      subCategoryData &&
      subCategoryData.length > 0 &&
      subCategoryData.map((category, index) => (
        <button
          onClick={() => onChnageSubMenu(category.id)}
          key={index}
          className={
            selectedSubCat == category.id ? "nav-link active" : "nav-link"
          }
          data-bs-toggle="tab"
          data-bs-target="#collpro1"
          type="button"
        >
          {category.name}
        </button>
      ))
    );
  };
  return (
    <section className="foodCollection px-0 mx-0">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12 col-12">
            <div className="secPadding_left secMargin_left">
              {/*----- Section title -----*/}
              <h1 className="default_title">Our Regular Product Collections</h1>

              {/*----- Section Sub title -----*/}
              <p className="default_subtitle">
                {/* Discover the best of world cuisine */}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="foodfilter text-xl-end text-lg-start text-start d-lg-block d-none">
        <input
          type="radio"
          name="Filter of medical cannabis"
          id="filter1"
          className="d-none"
          defaultChecked
          onClick={() => setFilter("")}
        />
        <label htmlFor="filter1">Relevance</label>
        <input
          type="radio"
          name="Filter of medical cannabis"
          id="filter33"
          className="d-none"
          onClick={() => setFilter("membership")}
        />
        <label htmlFor="filter33"> Membership product / THC</label>
        <input
          type="radio"
          name="Filter of medical cannabis"
          id="filter345"
          className="d-none"
          onClick={() => setFilter("nomembership")}
        />
        <label htmlFor="filter345"> Non membership product</label>
        <input
          type="radio"
          name="Filter of medical cannabis"
          id="filter3"
          className="d-none"
          onClick={() => setFilter("low")}
        />
        <label htmlFor="filter3">
          Cost <i className="fa-solid fa-arrow-right fs-6"></i> Low to High
        </label>
        {/*  */}
        <input
          type="radio"
          name="Filter of medical cannabis"
          id="filter4"
          className="d-none"
          onClick={() => setFilter("high")}
        />
        <label htmlFor="filter4">
          Cost <i className="fa-solid fa-arrow-right fs-6"></i> High to Low
        </label>

        <input
          type="radio"
          name="Filter of medical cannabis"
          id="filter5"
          className="d-none"
          onClick={() => setFilter("veg")}
        />
        <label htmlFor="filter5">Pure Veg</label>
      </div>

      <div className="foodfilterSM d-lg-none d-block position-relative mb-5">
        <input type="checkbox" className="d-none" id="foodfilterdrop" />

        <label htmlFor="foodfilterdrop">Filter your medical cannabis by</label>

        <ul className="filterList">
          <li>
            <input
              type="radio"
              name="Filter of medical cannabis"
              id="filter1"
              className="d-none"
              defaultChecked
              onClick={() => setFilter("")}
            />
            <label htmlFor="filter1">Relevance</label>
          </li>

          <li>
            <input
              type="radio"
              name="Filter of medical cannabis"
              id="filter3"
              className="d-none"
              onClick={() => setFilter("low")}
            />
            <label htmlFor="filter3">Low to High</label>
          </li>

          <li>
            <input
              type="radio"
              name="Filter of medical cannabis"
              id="filter4"
              className="d-none"
              onClick={() => setFilter("high")}
            />
            <label htmlFor="filter4">High to Low</label>
          </li>

          <li>
            <input
              type="radio"
              name="Filter of medical cannabis"
              id="filter5"
              className="d-none"
              onClick={() => setFilter("veg")}
            />
            <label htmlFor="filter5">Pure Veg</label>
          </li>
        </ul>
      </div>

      {/*---- Product category tab pane -----*/}
      <div className="foodtypes">
        {/*----- All product tab list -----*/}
        <nav className="d-lg-none d-block">
          <div className="foodCATSM d-lg-none d-block position-relative mb-3">
            <input type="checkbox" className="d-none" id="FoodCAtDrop" />

            <label htmlFor="FoodCAtDrop">Select Product category</label>

            <ul className="ProCATLISTs nav nav-tabs d-block">
              {/*---- Product tab -----*/}
              {categoriesList()}
            </ul>
          </div>
        </nav>

        <nav className="d-lg-none d-block">
          <div className="foodCATSM d-lg-none d-block position-relative mb-3">
            <input type="checkbox" className="d-none" id="FoodCAtDrop12" />

            <label htmlFor="FoodCAtDrop12">Select Product Sub category</label>

            <ul className="ProCATLISTs nav nav-tabs d-block">
              {/*---- Product tab -----*/}
              {subCategoriesList()}
            </ul>
          </div>
        </nav>

        <nav className="d-lg-block d-none LgProdmenueCAT">
          <div className="nav nav-tabs">
            {/*---- Product tab -----*/}
            {categoriesList()}
          </div>
        </nav>

        <nav className="d-lg-block d-none LgProdmenueSUBCAT">
          <div className="nav nav-tabs">
            {/*---- Product tab -----*/}
            {subCategoriesList()}
          </div>
        </nav>
        <div
          className="tab-content mt-lg-5 secPadding_left secMargin_left secPadding_right secMargin_right"
          id="nav-tabContent"
        >
          <div className="tab-pane fade show active" id="collpro1">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-12 col-12">
                  {/*----- Section Sub title -----*/}
                  <p className="default_subtitle mb-2 px-lg-0 px-2">
                    See all results
                  </p>
                </div>
                {handleFilterItem() && handleFilterItem()?.length === 0 && (
                  <h1 className="text-danger fw-bold text-center mt-5">
                    No products available in this category, Please select
                    another category
                  </h1>
                )}
                {handleFilterItem() &&
                  handleFilterItem().length > 0 &&
                  handleFilterItem().map((product, index) => (
                    <MenuCard
                      key={product.id}
                      product_image={product.product_image}
                      name={product.name}
                      end_date={product?.product_discount?.end_date}
                      offer_type={product?.product_discount?.offer_type}
                      percentage={product?.product_discount?.percentage}
                      isPremiumMember={isPremiumMember}
                      product={product}
                      product_desc={product?.product_desc}
                      isItemInStock={checkItemInStock(product)}
                      price={
                        product?.product_item_list &&
                        product?.product_item_list &&
                        product?.product_item_list[0]?.product_item &&
                        product?.product_item_list[0]?.product_item[0]?.price
                      }
                      is_available_membership={product.is_available_membership}
                      indexPage={false}
                      menuProduct={true}
                      stock_onoff={selectedKitchn?.kitchen_info?.stock_onoff}
                      openMembershipPopup={() => openMembershipPopup()}
                    />
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MenuSection;

const getAllCategory = (cates = []) => {
  if (cates && cates.length > 0) {
    let category = [];
    cates.map((item) => {
      if (item?.sub_category && item?.sub_category.length > 0) {
        category = [...category, ...item?.sub_category];
      }
    });
    return category;
  }
  return [];
};

const getProductsByCategoryId = (cateId, products = []) => {
  if (cateId && products && products.length > 0) {
    const res = products.filter((item) => item?.categories_id === cateId);
    return res;
  }
  return [];
};

const getProductsBySubCategoryId = (cateId, products = []) => {
  if (cateId && products && products.length > 0) {
    const res = products.filter((item) => item?.sub_category == cateId);
    return res;
  }
  return [];
};

// const getProductsBySubCategoryId = (cateId, products = []) => {
//   if (cateId && products && products.length > 0) {
//     const res = products.filter((item) => +item?.sub_category === +cateId);
//     return res;
//   }
//   return [];
// };
