import React from "react";
import Slider from "react-slick";

const CustomerTestimonials = () => {
  return (
    <section className="testimonial position-relative py-0">
      <div className="container-fluid mt-5 pt-5">
        <div className="row mt-lg-5">
          <div className="col-lg-12 col-12">
            <Slider {...settings} className="testimonialSlider">
              <div className="card border-0">
                <div className="card-body">
                  <div className="row">
                    <div className="col-lg-6 col-12">
                      {/*----- customer testimonial -----*/}
                      <div className="customersaying position-relative">
                        <h1 className="default_title mb-5">
                          What Ajay Singh say about us
                        </h1>

                        <p className="fs-1 fw-500 text-secondary">
                          A fantastic organisation! Great cutomer support from
                          beginning to end of the process. The team are really
                          informed and go the extra mile at every stage. I would
                          recommend them unreservedly.
                        </p>

                        <p className="fs-3 fw-600 mb-0 mt-5">Crystal Reinger</p>

                        <p className="fs-3 fw-500 text-secondary">
                          South Africa
                        </p>
                      </div>
                    </div>
                    {/*----- Customer Image -----*/}
                    <img
                      src="./assets/img/testimonialImage.png"
                      className="testicusimg"
                      alt=""
                    />
                  </div>
                </div>
              </div>

              <div className="card border-0">
                <div className="card-body">
                  <div className="row">
                    <div className="col-lg-6 col-12">
                      {/*----- customer testimonial -----*/}
                      <div className="customersaying position-relative">
                        <h1 className="default_title mb-5">
                          What Morne say about us
                        </h1>

                        <p className="fs-1 fw-500 text-secondary">
                          A fantastic organisation! Great cutomer support from
                          beginning to end of the process. The team are really
                          informed and go the extra mile at every stage. I would
                          recommend them unreservedly.
                        </p>

                        <p className="fs-3 fw-600 mb-0 mt-5">Crystal Reinger</p>

                        <p className="fs-3 fw-500 text-secondary">
                          India
                        </p>
                      </div>
                    </div>
                    {/*----- Customer Image -----*/}
                    <img
                      src="./assets/img/testimonialImage.png"
                      className="testicusimg"
                      alt=""
                    />
                  </div>
                </div>
              </div>

              <div className="card border-0">
                <div className="card-body">
                  <div className="row">
                    <div className="col-lg-6 col-12">
                      {/*----- customer testimonial -----*/}
                      <div className="customersaying position-relative">
                        <h1 className="default_title mb-5">
                          What Akram say about us
                        </h1>

                        <p className="fs-1 fw-500 text-secondary">
                          A fantastic organisation! Great cutomer support from
                          beginning to end of the process. The team are really
                          informed and go the extra mile at every stage. I would
                          recommend them unreservedly.
                        </p>

                        <p className="fs-3 fw-600 mb-0 mt-5">Crystal Reinger</p>

                        <p className="fs-3 fw-500 text-secondary">
                          Beherain
                        </p>
                      </div>
                    </div>
                    {/*----- Customer Image -----*/}
                    <img
                      src="./assets/img/testimonialImage.png"
                      className="testicusimg"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </Slider>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CustomerTestimonials;

var settings = {
  arrows: true,
  infinite: true,
  autoplay: true,
  // speed: 2000,

  centerMode: false,
  centerPadding: "0px",
  slidesToShow: 1,
  slidesToScroll: 1,
  initialSlide: 1,
  responsive: [
    {
      breakpoint: 1199,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        dots: false,
      },
    },
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: true,
        dots: false,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};
