import React, { useEffect, useState } from "react";
import Layout from "../components/common/Layout";
import { callApi } from "../hooks/useapi";
import { AppConst } from "../AppConst";
import { useDispatch, useSelector } from "react-redux";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { generateRandomString, getToken, sendErrorInfo, sendSuccessInfo } from "../utility/helper";
import { generateTransectionKey } from "../store/actions/actions";
import PaymentForm from "../components/common/cart/PaymentForm";
import { checkActiveMemberShip } from "../utility/cartHelpers";
import moment from "moment";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from "reactstrap";

const Membership = () => {

  const kitchen_id = useSelector((state) => state.root.kitchen_id);
  const [plans, setPlans] = useState([]);
  const dispatch = useDispatch();
  const transection_key = useSelector((state) => state.root.transection_key);
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [activePlance, setActivePlan] = useState(false);

  useEffect(() => {
    if (!getToken()) {
      sendErrorInfo("Plesae login first")
      return navigate("/login")
    } else
      if (
        searchParams.get("tid") === transection_key &&
        searchParams.get("amt")
      ) {
        buyMembershipplan();
      }
    if (transection_key === "") {
      dispatch(generateTransectionKey(generateRandomString(15)));
    }
    checkActiveMemberShip(kitchen_id).then((response) => {
      setActivePlan(response);
    });
    getMembershipPlans();
  }, []);
  const getMembershipPlans = () => {
    callApi(
      `user/mermbership-package/${process.env.REACT_APP_BUSS_ID}/${kitchen_id}`,
      {
        method: "GET",
      }
    ).then((response) => {
      if (response.status === true) {
        setPlans(response.data);
      }
    });
  };

  const buyMembershipplan = () => {
    let postData = {
      amount: searchParams.get("amt"),
      transaction_id: searchParams.get("tid"),
      package_id: searchParams.get("pkg"),
    };
    callApi("user/mermbership-package-buy", {
      method: "POST",
      data: postData,
    }).then((response) => {
      if (response.status === true) {
        sendSuccessInfo(response.message);
        dispatch(generateTransectionKey(""));
        getMembershipPlans();
        navigate("/home");
        dispatch(generateTransectionKey(generateRandomString(15)));
      } else {
        sendSuccessInfo(response.error.error);
      }
    });
  };
  return (
    <Layout pageclassName={"MembershipPage"}>
      <section className="Pagetitledisc px-0 m-0">
        <div className="container-fluid py-3">
          <div className="row">
            <div className="col-lg-12 col-12">
              <div className="d-lg-flex justify-content-between">
                <div>
                  {/*----- Section title -----*/}
                  <h1 className="default_title secPadding_left secMargin_left">
                    {AppConst.getBussInfo.app_name} Premium membership
                  </h1>
                  {/*----- Section Sub title -----*/}
                  <p className="default_subtitle secPadding_left secMargin_left mb-0">
                    Looking for great product near you
                  </p>
                </div>

                {/*----- breadcrumb -----*/}
                <nav className="align-self-end mt-lg-0 mt-4 secPadding_right secMargin_right">
                  <ol className="breadcrumb mb-0">
                    <li className="breadcrumb-item">
                      <Link to={"/home"}>Home</Link>
                    </li>
                    <li className="breadcrumb-item active">
                      <p className="d-inline-block fw-bold text-muted">
                        Membership
                      </p>
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*----- Page title and discription section end -----*/}

      {/*----- Membership details section start -----*/}
      <section className="membershipDetails">
        <div className="container-fluid">
          <div className="row justify-content-around">
            <div className="col-lg-7 col-12 mx-auto">
              <h1 className="default_title text-center mb-5">
                Our premium membership structure
              </h1>
              <p className="default_subtitle text-center">
                Elevate your delivery experience with our tailored membership
                plans for businesses and stores. Choose the perfect plan to
                enhance convenience and satisfaction for your valued customers.
              </p>
            </div>
            <div className="col-12" />
            <div className="col-lg-3 col-10 mx-lg-0 mx-auto text-center">
              <div className="memplancard card text-center position-relative px-4 pt-4 pb-4 rounded-3 h-100">
                <div className="card-body">
                  <p className="mb-2 fw-600 fs-2">Unlimited stores</p>
                  <p className="text-secondary mb-0">
                    Find all stores available in your zone.
                  </p>
                  <img
                    src="./assets/img/greencheck.png"
                    className="greencheck"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-10 mx-lg-0 mx-auto text-center my-lg-0 my-5">
              <div className="memplancard card text-center position-relative px-4 pt-4 pb-4 rounded-3 h-100">
                <div className="card-body">
                  <p className="mb-2 fw-600 fs-2">No per-user fee</p>
                  <p className="text-secondary mb-0">
                    Enjoy our service without any individual charges.
                  </p>
                  <img
                    src="./assets/img/greencheck.png"
                    className="greencheck"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-10 mx-lg-0 mx-auto text-center">
              <div className="memplancard card text-center position-relative px-4 pt-4 pb-4 rounded-3 h-100">
                <div className="card-body">
                  <p className="mb-2 fw-600 fs-2">Unlimited products</p>
                  <p className="text-secondary mb-0">
                    Access an extensive range of products.
                  </p>
                  <img
                    src="./assets/img/greencheck.png"
                    className="greencheck"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*----- Membership details section end -----*/}

      {/*----- Membership plan card section start -----*/}
      <section className="membershipPlancard">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-7 col-12 mx-auto">
              <h1 className="default_title text-center mb-5">
                Our pricing plan
              </h1>
              <p className="default_subtitle text-center">
                Experience affordability and flexibility with our
                customer-centric pricing plans. Choose the one that brings you
                the best value.
              </p>
            </div>

            <div className="col-lg-12 col-12 my-4" />

            {/*----- Basic member plan -----*/}
            {plans &&
              plans.length > 0 &&
              plans.filter(item => item.status === '0').map((item, i) => (
                <div className="col-xl-3 col-lg-4 col-12 mb-5" key={i}>
                  <div className="card memPlancard h-100">
                    <div className="card-body">
                      <h3 className="text-center mb-4">
                        {item.package}
                        <i className="fa-solid fa-star basicplan ms-2" />
                      </h3>

                      <hr />

                      <h1 className="text-center mb-5 fw-bold">
                        <span>
                          {AppConst.getBussInfo.currency}
                          {item?.price}
                        </span>
                        <span className="fw-500">/</span>
                        <span className="fw-500">Month</span>
                      </h1>

                      <ul className="planbenifits list-unstyled fw-normal">
                        <li className="mb-2">
                          <i className="fa-regular fa-circle-check text-success me-3" />
                          {item?.product_access} free products
                        </li>

                        <li className="mb-2">
                          <i className="fa-regular fa-circle-check text-success me-3" />
                          05 store access
                        </li>

                        <li className="mb-2">
                          <i className="fa-regular fa-circle-check text-success me-3" />
                          {item?.order_access} orders
                        </li>

                        {activePlance.buy_pckage === true &&
                          activePlance.package_data?.package_id == item.id && (
                            <li className="mb-2 text-warning">
                              Membership Expires on{" "}
                              {moment(
                                activePlance.package_data?.expired_date
                              ).format("DD MMM,YYYY")}
                            </li>
                          )}
                      </ul>
                    </div>

                    <div className="card-footer">
                      {!activePlance.package_data && (
                        <PaymentForm
                          returnUrl={`${location.pathname}?tid=${transection_key}&amt=${item?.price}&pkg=${item?.id}`}
                          totalPrice={item?.price}
                          uniqeId={transection_key}
                          buttonTitle={`Buy Now`}
                        />
                      )}
                    </div>
                  </div>
                </div>
              ))}
          </div>

          <div className="col-lg-12 col-12">
            <div className="d-lg-flex justify-content-between">
              <div>
                {/*----- Section title -----*/}
                <h1 className="default_title">
                  Frequently ask question
                </h1>
                {/*----- Section Sub title -----*/}
                <p className="default_subtitle mb-0">
                  Get quick answers to common queries about our membership plans
                </p>
              </div>
              {/*----- breadcrumb -----*/}
            </div>
            <FAQSection />
          </div>
        </div>

      </section>
      {/*----- Membership plan card section end -----*/}
    </Layout>
  );
};

export default Membership;

const FAQSection = () => {
  const [open, setOpen] = useState("1");
  const toggle = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };
  return (
    <section className="faqsection">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-11 col-12">
            <div className="faqcontainer secMargin_left secPadding_left mt-5 pt-3">
              <div className="container">
                <Accordion
                  open={open}
                  toggle={toggle}
                  className="accordion-flush"
                >
                  <AccordionItem>
                    <AccordionHeader targetId="1">
                      Is it possible to cancel my Membership ?
                    </AccordionHeader>

                    <AccordionBody
                      accordionId="1"
                      className=" text-muted fw-bold p-lg-5 p-0"
                    >
                      <p className="mb-0 fs-2">
                        Certainly, you have the freedom to cancel your membership at any point. Should you choose to cancel before the membership period ends, you will still have the privilege of making purchases as a member until the original expiry date.
                      </p>
                    </AccordionBody>
                  </AccordionItem>

                  <AccordionItem>
                    <AccordionHeader targetId="2">
                      What is the duration of my membership's validity?
                    </AccordionHeader>

                    <AccordionBody
                      accordionId="2"
                      className=" text-muted fw-bold p-lg-5 p-0"
                    >
                      <p className="mb-0 fs-2">
                        Your membership remains valid for a period of 30 days. It will automatically renew every 30 days unless you opt for cancellation.
                      </p>
                    </AccordionBody>
                  </AccordionItem>

                  <AccordionItem>
                    <AccordionHeader targetId="3">
                      Can I hold membership at different stores?
                    </AccordionHeader>

                    <AccordionBody
                      accordionId="3"
                      className=" text-muted fw-bold p-lg-5 p-0"
                    >
                      <p className="mb-0 fs-2">
                        Absolutely, each individual store maintains its own membership. You are welcome to become a member at as many stores as you desire.
                      </p>
                    </AccordionBody>
                  </AccordionItem>

                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>)
}