import React from "react";
import { AppConst } from "../../../AppConst";
import { Link } from "react-router-dom";

const CheckoutPageHeader = ({
  delivery,
  totalAmount,
  kitchenData,
  delivery_method,
}) => {
  return (
    <section className="Pagetitledisc m-0">
      <div className="container-fluid py-3">
        <div className="row">
          <div className="col-lg-12 col-12">
            <div className="d-flex flex-lg-row flex-column justify-content-between">
              <div>
                {/*----- Section title -----*/}
                <h1 className="default_title secPadding_left secMargin_left">
                  Checkout
                </h1>

                {/*----- Section Sub title -----*/}
                <p className="default_subtitle secPadding_left secMargin_left  mb-0">
                  Looking for great product near you
                </p>

                {delivery === true &&
                  delivery_method === "Delivery" &&
                  parseFloat(totalAmount) <
                    parseFloat(
                      kitchenData && kitchenData.free_delivery_after
                    ) && (
                    <>
                      <p className="mb-0 fs-2 fw-bold text-success mt-3 secPadding_left secMargin_left">
                        Free Delivery After {AppConst.getBussInfo.currency}{" "}
                        {kitchenData?.free_delivery_after}
                      </p>
                    </>
                  )}
              </div>

              <nav className="align-self-lg-end mt-lg-0 mt-4 secPadding_right secMargin_right">
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to={"/home"}>Home</Link>
                  </li>
                  <li className="breadcrumb-item active">
                    <p className="d-inline-block fw-bold text-muted">Cart</p>
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CheckoutPageHeader;
