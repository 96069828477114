export const ADD_KITCHENDATA = "ADD_KITCHENDATA";
export const SAVE_USER_DATA = "SAVE_USER_DATA";
export const USER_LOCATION = "USER_LOCATION";
export const ADD_KITCHEN_ID = "ADD_KITCHEN_ID";
export const ADD_KITCHEN_NAME = "ADD_KITCHEN_NAME";
export const TODAYS_MENU = "TODAYS_MENU";
export const MENU_POPUP_SHOW = "MENU_POPUP_SHOW";
export const CART_SLIDER_SHOW = "CART_SLIDER_SHOW";
//menu page
export const POPUPMENUOFFER = "POPUPMENUOFFER";
export const SETCARTPRODUCTS = "SETCARTPRODUCTS";
export const SETPRODUCTDETAILS = "SETPRODUCTDETAILS";
export const REORDER = "REORDER";

//Cart
export const ADD_TO_CART = "ADD_TO_CART";
export const CLEAR_CART = "CLEAR_CART";
export const DELIVERY_TYPE = "DELIVERY_TYPE";
export const GENERATE_TRANSECTION_KEY = "GENERATE_TRANSECTION_KEY";
export const ADD_ADDITIONAL_INFO = "ADD_ADDITIONAL_INFO";
