import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import 'firebase/compat/messaging'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
  apiKey: "AIzaSyDMq4swMb63l3gGGC3lfuAIrTjwjo-o0P4",
  authDomain: "greencentral-406609.firebaseapp.com",
  projectId: "greencentral-406609",
  storageBucket: "greencentral-406609.appspot.com",
  messagingSenderId: "1084369240829",
  appId: "1:1084369240829:web:52c9ccea9569e341fb3494"
};


const app = firebase.initializeApp(firebaseConfig);

// Initialize Firestore
const db = app.firestore();
let messaging = null;
try {
  if (firebase.messaging.isSupported()) {

    messaging = firebase.messaging();
    messaging.usePublicVapidKey("BP7yH7ine7HRFAk8olVPE-I8GpJ-psdtMRYniaCYEvY1teXW9DbGV7rVhtKh8xXpcSpTBwczjxH6vWof2NdL-Mg");
  }
} catch (e) { }
export const requestPermission = async () => {
  try {
    const permissionStatus = await Notification.requestPermission()
    if (permissionStatus === 'denied') {
      console.log('Notifications blocked by the user')
      showEnableNotificationsPrompt()
      return
    }
    const token = await messaging.getToken();
    console.log('FCM token:', token)
    return token
  } catch (error) {
    console.log('Error requesting permission:', error)
  }
}


const showEnableNotificationsPrompt = () => {
  // Display a prompt or instructions to the user to enable notification manually
  // For example, you can show a button or a message to guide the user to the browser settings.
  // The specific implementation will depend on your UI/UX design.
  // alert('Please enable notifications in your browser setting.')
}
export default db;

