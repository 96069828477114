import React from 'react'
import Layout from '../../components/common/Layout'
import { useNavigate } from 'react-router-dom'

const PricacyPolicy = () => {
    const navigate = useNavigate()
    return (
        <Layout>
            <div className="wrapper hero-banner  privacy">
                <section className="solutions header-margin">
                    <div className="container">
                        <a href="#!" onClick={() => navigate(-1)} className="btn btn-light mb-5">Back</a>
                        <div className="section-heading pt-xl">
                            <h1>Privacy <span className="text-orange" data-aos="fade-down">Policy</span> </h1>
                        </div>
                    </div>
                </section>
                <section className="privacy-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-11 push-md-1">
                                <div id="faq1">
                                    <div id="faq1_1" className="py-5">
                                        <h4><i className="fa-solid fa-location-dot mr-1" />Green Central is located at:
                                            1st Floor, Block B, North Park
                                            Black River Park,
                                            2 Fir Street, Observatory,
                                            Cape Town
                                        </h4>
                                        <hr />
                                        <p className="lead">
                                            ABOUT GREENCENTRAL AND THIS POLICY
                                        </p>
                                        <p>
                                            GreenCentral is the data controller of your personal data. Should you have any
                                            questions
                                            or concerns relating to this privacy
                                            policy or your personal data, you can contact us at info@greencentral.com.
                                            GreenCentral collects and processes personal data in the course of installation and
                                            use
                                            of the GreenCentral apps; and in relation
                                            to registration and on-boarding and provision of services, in accordance with the
                                            relevant terms of use. Please note
                                            that the processing of geographical location data is necessary for functional use of
                                            GreenCentral app. GreenCentral may also
                                            collect personal data about individuals that interact with us, including by browsing
                                            our websites and using our apps.
                                            By using our websites, apps and services, you acknowledge that we process your
                                            personal data in accordance with this
                                            privacy policy.
                                        </p>
                                    </div>
                                </div>
                                <div id="faq2">
                                    <h3><i className="fa-solid fa-cookie mr-1" />
                                        WHAT TYPES OF CUSTOMER PERSONAL DATA WE COLLECT AND PROCESS
                                    </h3>
                                    <hr />
                                    <div id="faq2_1" className="pb-5">
                                        <p className="lead">
                                            Personal data is generally processed in order to provide the service contracted with
                                            the GreenCentral app
                                            collects data about Customers which may include:
                                        </p>
                                        <ul className="p-0">
                                            <li>
                                                biographical and contact information including name, telephone number, e-mail
                                                address, as collected during installation
                                                and sign up to the app;
                                            </li>
                                            <li>
                                                information related to Order(s) made via the greencentral app and
                                                information
                                                related to each Order made (special
                                                packaging requests, for example, and ordering preferences, etc.);
                                            </li>
                                            <li>
                                                geographic location from where you access and use the app from and to what
                                                location you request delivery to (this is
                                                required for the service);
                                            </li>
                                            <li>
                                                where you register for an account, your user ID and associated account
                                                information such as your preferences and any
                                                applicable promo codes;
                                            </li>
                                            <li>
                                                feedback ratings (including information provided to resolve disputes);
                                            </li>
                                            <li>
                                                information regarding your order including geographical location, the time of
                                                provision of service, details of the route
                                                of delivering and destination; and other order history;
                                            </li>
                                            <li>
                                                text, images and other user content you provide;
                                            </li>
                                            <li>
                                                if we ban or suspend you from use of the service, a record of that ban or
                                                suspension;
                                            </li>
                                            <li>
                                                website and communication information such as details collected by cookies and
                                                other similar tracking technologies when
                                                you use our sites or apps;
                                            </li>
                                            <li>
                                                details of support queries you make (e.g. through our apps or via other
                                                communication channels), including record of any
                                                reported lost items.
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div id="faq3">
                                    <h3><i className="fa-solid fa-id-card mr-1" />
                                        CUSTOMER PERSONAL DATA RETENTION
                                    </h3>
                                    <hr />
                                    <div id="faq3_1" className="pb-5">
                                        <p className="lead">
                                            You can view your personal data in the Profile Section of the greencentral
                                            app.
                                            Your personal data will be stored as
                                            long as you have an active User account, unless your personal data is required to be
                                            retained for certain legitimate
                                            business purposes including accounting, dispute resolution or fraud prevention
                                            purposes. This follows that:
                                        </p>
                                        <ul className="pl-3">
                                            <li>
                                                If your account will be closed the personal data will be stored for an
                                                additional 12- month period.
                                            </li>
                                            <li>
                                                In the event of disputes or investigations, the data shall be retained until the
                                                claim or investigation is
                                                satisfied/resolved or the expiry date of such claims.
                                            </li>
                                        </ul>
                                        <p className="lead">
                                            After we no longer need your personal data, it will be deleted, securely destroyed
                                            or anonymized (according to the
                                            policies set out in this section) from the databases. Please note that the
                                            uninstallation of the greencentral app
                                            from your device does not cause the automatic deletion, destruction or anonymization
                                            of your personal data.
                                        </p>
                                    </div>
                                    <div id="faq3_2" className="pb-5">
                                        <h4><i className="fa-solid fa-arrows-up-down-left-right mr-1" />
                                            AUTOMATED DECISION MAKING</h4>
                                        <hr />
                                        <div className="pt-4">
                                            <p className="lead">
                                                We may undertake automatic analysis of your personal data to make certain
                                                decisions on an automated basis without human
                                                intervention. This may include decisions around:
                                            </p>
                                            <ul className="p-0">
                                                <li>
                                                    Allowing you to use, or to continue to use our app and services - we may use
                                                    information relating to the outcome of
                                                    background checks, verification processes and behavioral analysis (such as
                                                    where your behavior appears to be consistent
                                                    with money laundering or known fraudulent conduct, or is inconsistent with
                                                    your previous submissions) to automatically
                                                    decide whether we will allow you to use our apps and services, or to
                                                    automatically stop you from using our apps and
                                                    services.
                                                </li>
                                                <li>
                                                    Pricing – we may use information relating to your location and timestamps,
                                                    the estimated duration and distance of tasks
                                                    related to deliveries of Orders, the number of concurrent Customers and
                                                    Delivery Agents using the app and our services
                                                    within the vicinity, estimated traffic and other related information to
                                                    determine the estimated duration of tasks
                                                    related to deliveries.
                                                </li>
                                            </ul>
                                            <p className="lead">
                                                In relation to each of the instances of automated decision-making referred to
                                                above, you may have the right to require
                                                us to review the decision; to your point of view; and to contest the
                                                outcome of the decision.
                                            </p>
                                            <p className="lead">
                                                We may reject the request, including when providing the information would result
                                                in a disclosure of a trade secret.
                                                However, generally in these circumstances, our response will be limited to
                                                verifying (or requesting an applicable third
                                                party to verify) that the algorithm and source data are functioning as
                                                anticipated without error or bias.
                                            </p>
                                            <p className="lead">
                                                For more information about the rights you may have under applicable data
                                                protection law (and how to exercise these),
                                                please see the section below titled “Your rights in relation to your personal
                                                data”.
                                            </p>
                                        </div>
                                    </div>
                                    <div id="faq4">
                                        <h3><i className="fa-solid fa-repeat mr-1" />DIRECT MARKETING TO USERS</h3>
                                        <hr />
                                        <div id="faq4_1" className="pb-5">
                                            <p className="lead">
                                                We may contact you with promotional messages regarding the GreenCentral apps and
                                                our
                                                selected business partners’ products and
                                                services by in-app notification, email, SMS, phone, social media and where
                                                possible, we will ask for your consent at the
                                                time we collect your data to conduct any of these types of marketing on our
                                                website or in the GreenCentral apps.
                                            </p>
                                        </div>
                                    </div>
                                    <div id="faq5">
                                        <h3><i className="fa-regular fa-thumbs-up mr-1" />
                                            DISCLOSURE OF PERSONAL DATA TO THIRD PARTIES GENERAL DISCLOSURE OF PERSONAL DATA
                                        </h3>
                                        <hr />
                                        <div id="faq5_1" className="pb-5">
                                            <p className="lead">
                                                GreenCentral shares and discloses your personal data to third parties for the
                                                purposes and bases set out in the section above
                                                titled “How and why we use this personal data”. These third parties may include
                                                Vendors where the Customer has ordered
                                                from, Drivers who deliver Orders, other service providers, advisors and our
                                                group companies.
                                            </p>
                                        </div>
                                    </div>
                                    <div id="faq6">
                                        <h3><i className="fa-brands fa-connectdevelop mr-1" />
                                            DISCLOSURE OF PERSONAL DATA AS BETWEEN CUSTOMERS &amp; DELIVERY AGENTS
                                        </h3>
                                        <hr />
                                        <div id="faq6_1" className="pb-5">
                                            <p className="lead">
                                                The personal data of Delivery Agents is disclosed to Customers once the Delivery
                                                Agent has accepted the request for
                                                delivery service and contract for delivery service is concluded between the
                                                Customer and the Delivery Agent.
                                            </p>
                                            <p className="lead">
                                                The Customer will see the Delivery Agent’s name, vehicle registration details,
                                                and geographic location of the Delivery
                                                Agent. At the same time, the personal data of the Customer is disclosed to the
                                                Delivery Agent with whom the Customer
                                                concludes the contract for the delivery service. Via the Agent app, the
                                                Delivery Agent will see the username of
                                                the Customer, geographic location of the Customer and information about the
                                                Order made by the Customer (including the
                                                Vendor where the Order was placed). The Delivery Agent will only have masked
                                                telephonic access to the Customer.
                                            </p>
                                            <p className="lead">
                                                Pursuant to data processing agreement concluded between the Delivery Agent and
                                                the Agent app, after providing
                                                the delivery service, the Delivery Agent is entitled to retain the Customer data
                                                related to the performance of the
                                                delivery service for the maximum period of 1 month.
                                            </p>
                                        </div>
                                    </div>
                                    <div id="faq6">
                                        <h3><i className="fa-brands fa-connectdevelop mr-1" />
                                            DISCLOSURE OF PERSONAL DATA AS BETWEEN CUSTOMERS &amp; VENDORS
                                        </h3>
                                        <hr />
                                        <div id="faq6_1" className="pb-5">
                                            <p className="lead">
                                                The personal data of Customers is disclosed to the Vendor once the Customer has
                                                placed an Order. The Vendor will see the
                                                Customer’s username, information about the Order placed, together with any
                                                information submitted by the Customer
                                                together with the Order (for example packing instructions, if such request is
                                                made by the Customer in the course of
                                                placing the Order). The Customer's customer details and delivery location are
                                                masked from the Vendor.
                                            </p>
                                        </div>
                                    </div>
                                    <div id="faq6">
                                        <h3><i className="fa-brands fa-connectdevelop mr-1" />
                                            HOW WE PROTECT YOUR PERSONAL DATA
                                        </h3>
                                        <hr />
                                        <div id="faq6_1" className="pb-5">
                                            <p className="lead">
                                                No data transmission over the Internet or websites can be guaranteed to be
                                                secure from intrusion. However, we maintain
                                                commercially reasonable physical, electronic and procedural safeguards to
                                                protect your personal data in accordance with
                                                applicable data protection legislative requirements. All personal data you
                                                provide to us is stored on our or our
                                                subcontractors’ secure servers and accessed and used subject to our security
                                                policies and standards. Where we have given
                                                you (or where you have chosen) a password which enables you to access certain
                                                parts of our website or mobile apps, you
                                                are responsible for keeping this password confidential and for complying with
                                                any other security procedures that we
                                                notify you of. We ask you not to share a password with anyone.
                                            </p>
                                        </div>
                                    </div>
                                    <div id="faq6">
                                        <h3><i className="fa-brands fa-connectdevelop mr-1" />
                                            YOUR RIGHTS IN RELATION TO YOUR PERSONAL DATA
                                        </h3>
                                        <hr />
                                        <div id="faq6_1" className="pb-5">
                                            <p className="lead">
                                                Under certain conditions, you may have the right to:
                                            </p>
                                            <ul className="p-0">
                                                <li>
                                                    be provided with details about what personal data we hold about you and to
                                                    be provided with a copy of your personal
                                                    data;
                                                </li>
                                                <li>
                                                    require us to update any inaccuracies in the personal data we hold. In order
                                                    to assist us with this, please keep your
                                                    user account profile information in the greencentral app up to
                                                    date,
                                                    or
                                                    contact us at info@GreenCentral .com to inform us
                                                    of any updates that should be made to your personal data;
                                                </li>
                                                <li>
                                                    withdraw your consent (where our processing activity is based on your
                                                    consent);
                                                </li>
                                                <li>
                                                    be provided with a copy of the personal data you have provided to us in a
                                                    machine-readable format so that you can
                                                    transfer it to another provider or ask us to transfer this to another data
                                                    controller (where our processing activity is
                                                    based on contract performance or consent);
                                                </li>
                                                <li>
                                                    require us to delete personal data (including where our processing activity
                                                    is based on your consent or our legitimate
                                                    interests). Please note that the uninstallation of the greencentral
                                                    app
                                                    in your device does not itself cause the
                                                    deletion of your personal data;
                                                </li>
                                                <li>
                                                    restrict how we use your personal data whilst a complaint is being
                                                    investigated;
                                                </li>
                                                <li>
                                                    object to our processing of your personal data (where our processing is
                                                    based on our legitimate interests);
                                                </li>
                                            </ul>
                                            <p className="lead">
                                                If you would like to exercise a right, we encourage you to contact our customer
                                                support via contact details specified
                                                below in section “Questions and Updates”. If you exercise any of these rights we
                                                will check your entitlement and respond
                                                in most cases within a month.
                                            </p>
                                        </div>
                                    </div>
                                    <div id="faq6">
                                        <h3><i className="fa-brands fa-connectdevelop mr-1" />
                                            THIRD PARTY LINKS
                                        </h3>
                                        <hr />
                                        <div id="faq6_1" className="pb-5">
                                            <p className="lead">
                                                Our websites and apps may contain links to other third party websites. If you
                                                follow a link to any of those third party
                                                websites, please note that they have their own privacy policies and that we do
                                                not accept any responsibility or
                                                liability for their policies or their processing of your personal data. Please
                                                check these policies before you submit
                                                any personal data to such third party websites
                                            </p>
                                        </div>
                                    </div>
                                    <div id="faq6">
                                        <h3><i className="fa-brands fa-connectdevelop mr-1" />
                                            QUESTIONS AND UPDATES
                                        </h3>
                                        <hr />
                                        <div id="faq6_1" className="pb-5">
                                            <p className="lead">
                                                Certain personal data can be viewed and corrected in the GreenCentral apps. If
                                                you
                                                have any disputes, questions or concerns
                                                about this privacy policy or your personal data, we encourage you to first
                                                contact us at info@GreenCentral .com
                                            </p>
                                            <p className="lead">
                                                This privacy policy was last updated on 03.05.2021. Any changes we may make to
                                                this privacy policy in the future will be
                                                posted on this page. Please check back regularly to keep informed of updates or
                                                changes to this privacy policy.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div></Layout>
    )
}

export default PricacyPolicy