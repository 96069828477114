import {
  ADD_ADDITIONAL_INFO,
  ADD_KITCHENDATA,
  ADD_KITCHEN_ID,
  ADD_KITCHEN_NAME,
  CART_SLIDER_SHOW,
  DELIVERY_TYPE,
  GENERATE_TRANSECTION_KEY,
  MENU_POPUP_SHOW,
  POPUPMENUOFFER,
  REORDER,
  SAVE_USER_DATA,
  SETCARTPRODUCTS,
  SETPRODUCTDETAILS,
  TODAYS_MENU,
  USER_LOCATION,
} from "../types";

const initialState = {
  kitchen_data: [],
  user_data: {},
  user_address: {},
  kitchen_id: 0,
  kitchen_name: "",
  todaysMenu: [],
  menuOffer: {},
  delivery_method: "Delivery",
  reOrder: false,
  Product_details: {},
  menu_popup: false,
  cart_slider: false,
  register_user: {},
  transection_key: "",
  additionalInfo: {
    tip: 0,
    additionalNotes: "",
    courier_id: "",
  },
  cartProducts: {
    selected_size: undefined,
    selected_topings: {},
    topings_by_size: [],
    product_detail: undefined,
    qty: 1,
    price: 0,
    discount: 0,
    update_index: -1,
    selectedExtra: {},
  },
};
const reducers = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_USER_DATA:
      return {
        ...state,
        user_data: action.payload,
      };
    case ADD_KITCHENDATA:
      return {
        ...state,
        kitchen_data: action.payload,
      };
    case USER_LOCATION:
      return {
        ...state,
        user_address: action.payload,
      };
    case ADD_KITCHEN_ID:
      return {
        ...state,
        kitchen_id: action.payload,
      };
    case ADD_KITCHEN_NAME:
      return {
        ...state,
        kitchen_name: action.payload,
      };
    case TODAYS_MENU:
      return {
        ...state,
        todaysMenu: action.payload,
      };
    case POPUPMENUOFFER:
      return {
        ...state,
        menuOffer: action.payload,
      };

    case SETCARTPRODUCTS:
      let payload = action.payload;
      return {
        ...state,
        cartProducts: { ...state.cartProducts, ...payload },
      };
    case SETPRODUCTDETAILS:
      return {
        ...state,
        Product_details: action.payload,
      };

    case REORDER:
      return {
        ...state,
        reOrder: action.payload,
      };
    case DELIVERY_TYPE:
      return {
        ...state,
        delivery_method: action.payload,
      };
    case CART_SLIDER_SHOW:
      return {
        ...state,
        cart_slider: action.payload,
      };
    case MENU_POPUP_SHOW:
      return {
        ...state,
        menuOffer:{},
        menu_popup: action.payload,
      };
    case GENERATE_TRANSECTION_KEY:
      return {
        ...state,
        transection_key: action.payload,
      };
    case ADD_ADDITIONAL_INFO:
      return {
        ...state,
        additionalInfo: action.payload,
      };
    default:
      return state;
  }
};

export default reducers;
