import React, { useEffect, useState } from "react";
import Layout from "../components/common/Layout";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getDistance, sendErrorInfo, sendSuccessInfo } from "../utility/helper";
import MapWithMarker from "../components/common/Maps/MapWithMarker";
import { confirm } from "../components/common/ConfirmDialog";
import {
  addKitchenId,
  addKitchenName,
  clearCartCount,
} from "../store/actions/actions";
import { POST_DATA_HEADEERS, callApi } from "../hooks/useapi";

const StoreDetails = () => {
  const { id } = useParams();
  const [kitchen, setKitchen] = useState();
  const kitchen_data = useSelector((state) => state.root.kitchen_data);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const kitchen_id = useSelector((state) => state.root.kitchen_id);
  const cart_items = useSelector((state) => state.cart.items);
  const user_address = useSelector((state) => state.root.user_address);
  const [allowNotification, setAllowNotification] = useState(false)
  useEffect(() => {
    console.log(id);
    const index = kitchen_data.findIndex((cat) => cat.id == id);
    setKitchen(kitchen_data[index]);
    getNotificationStatus(kitchen_data[index])
  }, []);

  const getNotificationStatus = (kit) => {

    callApi('user/get-notitifation-status', { method: "Get" })
      .then(response => {
        if (response.status === true) {
          let kitData = response.data.data
          let index = kitData.findIndex(item => item.kitchen_id == kit.id);
          if (index > -1) {
            setAllowNotification(kitData[index].status == '1' ? true : false)
          }
        }
      })
  }


  const handleSubscrbeNotification = () => {
    let postData = {
      kitchen_id: kitchen.id,
      status: allowNotification === true ? '0' : '1'
    }
    callApi('user/notitifation-status', { method: "POST", data: postData })
      .then(response => {
        if (response.status === true) {
          sendSuccessInfo(response.message)
          getNotificationStatus(kitchen)
        } else {
          sendErrorInfo(response.message)
        }
      })
  }

  const handleKitchenId = async (id, name) => {
    if (id != kitchen_id && cart_items.length > 0) {
      if (
        await confirm(
          "You have some product(s) in your cart. If you will change your kitchen, your product(s) will be deleted. Are you sure you want to remove cart product(s)"
        )
      ) {
        dispatch(clearCartCount());
        localStorage.removeItem(
          process.env.REACT_APP_BUSINESS + "productsInCart"
        );
        dispatch(addKitchenId(id));
        dispatch(addKitchenName(name));
        localStorage.setItem(process.env.REACT_APP_BUSINESS + "kitchen_id", id);
        navigate("/home");
      } else {
        return;
      }
    } else {
      dispatch(addKitchenId(id));
      dispatch(addKitchenName(name));
      localStorage.setItem(process.env.REACT_APP_BUSINESS + "kitchen_id", id);
      navigate("/home");
    }
  };
  return (
    <Layout pageClass={"storedetails"}>
      <section className="storebanner mb-0 pb-0">
        <div className="container-fluid">
          <div className="row justify-content-between">
            <div className="col-lg-12 col-12">
              <div
                className="banner rounded-3 position-relative"
                style={{
                  backgroundImage: `url(${kitchen?.kitchen_image[0]?.banner_image})`,
                }}
              >
                {/* <button className="shareButton">
                  <i className="fa-regular fa-share-from-square" />
                </button>
                <input type="checkbox" className="d-none" id="MyWishList" />
                <label htmlFor="MyWishList" className="wishlistButton">
                  <i className="fa-regular fa-heart" />
                </label> */}
                <div className="logo" style={{
                  position: 'absolute',
                  bottom: '10px',
                  left: '10px',
                }}>
                  <img src={kitchen?.kitchen_logo} alt="Logo" width="100" height="100" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="storedescription pt-0">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-8 col-12 pe-lg-5">
              {/*----- Section title -----*/}
              <h1 className="default_titleLG mb-4">{kitchen?.name}</h1>
              {/*----- Section Sub title -----*/}
              {/* <p className="default_subtitle">
                Nihil vitae sed aspernatur quibusdam praesentium consectetur.
                Quae reprehenderit dicta. Ipsam voluptatem sit corporis quia
                voluptate impedit optio dignissimos qui. Earum et quod iusto
                fuga eius sapiente aut eligendi eum.
              </p> */}

              <p className="text-muted fw-600 fs-2 mb-5 pb-lg-3">
                <i className="fa-solid fa-location-dot text-warning me-2" />
                {kitchen?.kitchen_info?.address}
              </p>

              <p
                className={
                  kitchen?.online_status == "online"
                    ? "mb-4 storestatus fs-1 fw-bold text-muted online offlinewaiting d-lg-none d-block"
                    : "mb-4 storestatus fs-1 fw-bold text-muted  offlinewaiting d-lg-none d-block"
                }
              >
                Status :
                <span className="mx-2">
                  <span className="dot me-2" />
                  {kitchen?.online_status == "online" ? "Online" : "Offline"}
                </span>
              </p>

              <p className="fs-3 fw-bold text-muted">
                <span className="text-primary fs-2 me-2">Time :</span>
                {kitchen?.kitchen_info?.delivery_open} -{" "}
                {kitchen?.kitchen_info?.delivery_close}
              </p>

              <p className="fs-3 fw-bold text-muted">
                <span className="text-primary fs-2 me-2">Contact :</span>
                {kitchen?.mobile}
              </p>

              <p className="fs-3 fw-bold text-muted mb-5 pb-lg-3">
                <span className="text-primary fs-2 me-2">Distance :</span>
                {getDistance(
                  user_address?.lat,
                  user_address?.lng,
                  kitchen?.kitchen_info?.lat,
                  kitchen?.kitchen_info?.lng
                ).toFixed(2)}
                Km from you
              </p>
              <div className="d-flex justify-content-start">
                <p className="fs-3 fw-bold text-muted">
                  <span className="text-primary fs-2 me-2">Subscribe to Notifications:</span>

                </p>
                {
                  console.log("alllow notifica", allowNotification)
                }
                <div class="fs-1 form-check form-switch ms-5">
                  <input class="form-check-input" type="checkbox" onChange={(e) => handleSubscrbeNotification()} checked={allowNotification === true ? 'checked' : ''} role="switch" id="flexSwitchCheckDefault" />
                </div>
              </div>




              {/*----- Service avaiablity status card start -----*/}
              <div className="avaiablityStatus">
                <div className="row align-items-center mx-0 px-0 mb-5">
                  <div className="col-lg-3 col-6 mx-0 px-0">
                    <p className="text-primary fs-1 fw-bold mb-0">
                      Available For :
                    </p>
                  </div>

                  <div className="col-lg-6 col-6 mx-0 px-0 text-lg-start text-end">
                    <button
                      type="button"
                      onClick={() =>
                        handleKitchenId(kitchen?.id, kitchen?.name)
                      }
                      className="btn btn-primary fs-3 px-4 py-1"
                    >
                      View Menu
                    </button>
                  </div>
                </div>

                {kitchen?.delivery_active === "1" && (
                  <div className="bannerlabel text-center">
                    <div className="d-flex align-items-center justify-content-lg-start justify-content-evenly">
                      <img src="../assets/img/bannerdeliverygreen.png" alt="" />
                      <span className="mx-4 px-2">
                        <strong className="text-primary fw-bold">
                          Delivery
                        </strong>
                        <br />
                        <small className="text-muted fw-bold fs-4">
                          (1-2 hours)
                        </small>
                      </span>
                      <i className="fa-regular fa-circle-check text-success fs-2" />
                    </div>
                  </div>
                )}
                {kitchen?.is_courier === "1" && (
                  <div className="bannerlabel text-center mx-lg-4">
                    <div className="d-flex align-items-center justify-content-lg-start justify-content-evenly">
                      <img src="../assets/img/bannercuriorgreen.png" alt="" />
                      <span className="mx-4 px-2">
                        <strong className="text-primary fw-bold">
                          Courier
                        </strong>
                        <br />
                        <small className="text-muted fw-bold fs-4">
                          (2-5 days)
                        </small>
                      </span>
                      <i className="fa-regular fa-circle-check text-success fs-2" />
                    </div>
                  </div>
                )}
                {kitchen?.kitchen_info?.pickup_on_off === "1" && (
                  <div className="bannerlabel my-lg-0 my-4 text-center">
                    <div className="d-flex align-items-center justify-content-lg-start justify-content-evenly">
                      <img src="../assets/img/bannerpickupgreen.png" alt="" />
                      <span className="mx-4 px-2">
                        <strong className="text-primary fw-bold">Collection</strong>
                        <br />
                        <small className="text-muted fw-bold fs-4">
                          (0-24 Hrs)
                        </small>
                      </span>
                      <i className="fa-regular fa-circle-check text-success fs-2" />
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="col-lg-4 col-12">
              <p
                className={
                  kitchen?.online_status == "online"
                    ? "mb-0 storestatus fs-1 fw-bold text-muted online offlinewaiting d-lg-block d-none"
                    : "mb-0 storestatus fs-1 fw-bold text-muted  offlinewaiting d-lg-block d-none"
                }
              >
                Status:
                <span className="ms-2">
                  <span className="dot me-2" />
                  {kitchen?.online_status == "online" ? "Online" : "Offline"}
                </span>
              </p>
              <div className="card storelocationmap">
                <MapWithMarker
                  locations={[
                    {
                      lat: parseFloat(kitchen?.kitchen_info?.lat),
                      lng: parseFloat(kitchen?.kitchen_info?.lng),
                      name: kitchen?.name,
                      description: kitchen?.kitchen_info?.address,
                    },
                  ]}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default StoreDetails;
