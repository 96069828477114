import React, { useEffect, useRef, useState } from "react";
import Layout from "../../components/common/Layout";
import HeroSection from "../../components/common/HomePage/HeroSection";
import OrderTrackSection from "../../components/common/HomePage/OrderTrackSection";
import NearbySection from "../../components/common/HomePage/NearbySection";
import TrandingProductSection from "../../components/common/HomePage/TrandingProductSection";
import MenuSection from "../../components/common/Menu/MenuSection";
import PremiumMemberSection from "../../components/common/HomePage/PremiumMemberSection";
import ServiceSection from "../../components/common/HomePage/ServiceSection";
import CustomerTestimonials from "../../components/common/HomePage/CustomerTestimonials";
import AppInfoSection from "../../components/common/HomePage/AppInfoSection";
import { useDispatch, useSelector } from "react-redux";
import {
  generateRandomString,
  getCookies,
  sendSuccessInfo,
  setCookies,
} from "../../utility/helper";
import {
  NavLink,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { callApi } from "../../hooks/useapi";
import {
  addKitchenData,
  addKitchenId,
  addKitchenName,
  generateTransectionKey,
  setCartSliderShow,
  setMenuPopUpShow,
  setTodaysMenu,
} from "../../store/actions/actions";
import moment from "moment";
import { ClickProductDetails } from "../../utility/MenuAction";
import { Modal, ModalHeader } from "reactstrap";
import { checkActiveMemberShip } from "../../utility/cartHelpers";
import PaymentForm from "../../components/common/cart/PaymentForm";
import { AppConst } from "../../AppConst";

const Home = () => {
  const kitchen_id = useSelector((state) => state.root.kitchen_id);
  const kitchen_data = useSelector((state) => state.root.kitchen_data);
  const dispatch = useDispatch();
  const [state, setState] = useState({
    lat: "",
    lng: "",
    categories_list: [],
    product_list: [],
    kitchen_load: true,
  });
  const [categoryData, setCategoriesData] = useState([]);
  const [productData, setProductData] = useState([]);
  const [isPremiumMember, setIsPremiumMember] = useState(true);
  const user_address = useSelector((state) => state.root.user_address);
  const [membershipModal, setMembershipModal] = useState(false);
  const [plans, setPlans] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [kitchenData, setKitchenData] = useState(null)
  const location = useLocation();
  const navigate = useNavigate();
  const paymentCardRef = useRef(null);
  const transection_key = useSelector((state) => state.root.transection_key);
  useEffect(() => {
    let selectedKitchen = kitchen_data.find(kitchen => kitchen.id === kitchen_id);
    console.log("selectedKitchen", selectedKitchen)
    if (!user_address?.lat && !user_address?.lng) {
      navigate("/");
    } else if (kitchen_id == 0) {
      navigate("/");
    }
    if (
      searchParams.get("tid") === transection_key &&
      searchParams.get("amt")
    ) {
      buyMembershipplan();
    }
    getMembershipPlans()
    checkActiveMemberShip(kitchen_id).then((response) => {
      if (response.buy_pckage === false) {
        if (transection_key === "") {
          dispatch(generateTransectionKey(generateRandomString(15)));
        }
        getMembershipPlans();
        setIsPremiumMember(false);
        selectedKitchen?.kitchen_info?.membership_on_off == '1' && setMembershipModal(true);
      } else if (response.canceled_by_customer === true) {
        if (transection_key === "") {
          dispatch(generateTransectionKey(generateRandomString(15)));
        }
        getMembershipPlans();
        setIsPremiumMember(false);
        selectedKitchen?.kitchen_info?.membership_on_off == '1' &&   setMembershipModal(true);
      }
    });
  }, []);

  useEffect(() => {
    if (user_address?.lat) {
      setState({
        ...state,
        lat: user_address?.lat,
      });
      setState({
        ...state,
        lng: getCookies(process.env.REACT_APP_BUSINESS + "lng"),
      });
    }
    if (!user_address?.lat && !user_address?.lng) {
      //

      callApi(
        "user/kitchen-list?lat=" +
        user_address?.lat +
        "&lng=" +
        user_address?.lng +
        "&bus_owner_id=" +
        process.env.REACT_APP_BUSS_ID,
        ""
      )
        .then((response) => {
          if (response.status === true) {
            dispatch(
              addKitchenData(
                response.data.kitchen_list.concat(
                  response.data.kitchen_offline_list
                )
              )
            );
            if (
              localStorage.getItem(
                process.env.REACT_APP_BUSINESS + "kitchen_id"
              ) > 0
            ) {
              return;
            } else {
              dispatch(addKitchenId(response.data.kitchen_list[0].id));
              dispatch(addKitchenName(response.data.kitchen_list[0].name));
            }

            window.localStorage.setItem(
              process.env.REACT_APP_BUSINESS + "kitchen_data",
              JSON.stringify(
                response.data.kitchen_list.concat(
                  response.data.kitchen_offline_list
                )
              )
            );
            setCookies(
              process.env.REACT_APP_BUSINESS + "kitchen_data",
              JSON.stringify(response.data.kitchen_list[0]),
              { path: "/" }
            );
          } else {
          }
          //console.log("new:"+this.state.Screentype);
        })
        .catch((error) => {
          //return error.data;
          console.log(error);
        });
    }

    callApi("user/dashboard-data-kitchen-id/" + kitchen_id, {
      method: "GET",
    }).then((response) => {
      if (response.status === true) {
        setKitchenData(response.data)
        const date = moment().format("YYYY-MM-DD");
        let category = response.data.categories_list;
        if (category.length > 0 && category[0].sub_category.length > 0) {
          setCategoriesData(category && category[0].sub_category);
          // setSubCategoriesData(category && category[0].sub_category && category && category[0].sub_category[0].sub_child_category);
          // setFilterProduct(response.data.product_list.filter((pro) => pro.sub_category == category[0].sub_category[0].sub_child_category[0].id));
        }
        setProductData(response.data.product_list);
        let todaysMenu = response.data.discount_product.filter((pro) => {
          const start_from = moment(pro.start_from).format("YYYY-MM-DD");
          const end_date = moment(pro.end_date).format("YYYY-MM-DD");
          if (pro.type === "menu" && start_from <= date && date <= end_date) {
            return pro;
          }
        });
        if (todaysMenu.length > 0) {
          dispatch(setTodaysMenu(todaysMenu));
        }
        // productData.current = response.data.product_list;
      }
    });
  }, [kitchen_id]);

  const getProductInfo = (product_id) => {
    callApi("user/product-details/" + product_id, { method: "GET" }).then(
      (response) => {
        let item = response.data.product_list;
        ClickProductDetails(item, product_id);
        dispatch(setMenuPopUpShow(true));
      }
    );
  };
  useEffect(() => {
    return () => {
      dispatch(setMenuPopUpShow(false));
      dispatch(setCartSliderShow(false));
    };
  }, []);
  const getMembershipPlans = () => {

    callApi(
      `user/mermbership-package/${process.env.REACT_APP_BUSS_ID}/${kitchen_id}`,
      {
        method: "GET",
      }
    ).then((response) => {
      if (response.status === true) {
        setPlans(response.data);
        setSelectedPlan(response.data[0]);
      }
    });
  };

  const buyMembershipplan = () => {
    let postData = {
      amount: searchParams.get("amt"),
      transaction_id: searchParams.get("tid"),
      package_id: searchParams.get("pkg"),
    };
    callApi("user/mermbership-package-buy", {
      method: "POST",
      data: postData,
    }).then((response) => {
      if (response.status === true) {
        sendSuccessInfo(response.message);
        dispatch(generateTransectionKey(""));
        getMembershipPlans();
        navigate("/membership");
        dispatch(generateTransectionKey(generateRandomString(15)));
      } else {
        sendSuccessInfo(response.error.error);
      }
    });
  };

  return (
    <Layout pageClass="homepage">
      <HeroSection />

      <OrderTrackSection />

      <NearbySection />

      <TrandingProductSection
        products={productData}
        isPremiumMember={isPremiumMember}
        openMembershipPopup={() => setMembershipModal(true)}
      />

      <MenuSection kitchenData={kitchenData} isPremiumMember={isPremiumMember} openMembershipPopup={() => setMembershipModal(true)} />

      <PremiumMemberSection plan={plans[0]} isPremiumMember={isPremiumMember} purchasePlan={() => setMembershipModal(true)} />

      <ServiceSection />

      <CustomerTestimonials />

      <AppInfoSection />

      <Modal
        centered
        className="onrefreshMembership_Modal modal-dialog-scrollable"
        isOpen={membershipModal}
        toggle={() => setMembershipModal(!membershipModal)}
      >

        <div className="modal-body">
          {/*----- Membership plans -----*/}
          <div className="Membershipplan_container">
            <div className="membership_content text-center">
              <img src="./assets/img/prime.png" className="prime-logo" />
              <p className="fw-600 text-success" >Green Central</p>


            </div>
            <div className="about_membership">
              <h3 className="text-center fw-600">These products are reserved exclusively for Members and Only Members of this store can order these products</h3>
              <ul class="list-group">

                <li class="list-group-item">
                  <i className="fa-solid fa-star ms-1 text-warning" /> To see membership only products at this store, click menu and filter by membership products
                </li>
                <li class="list-group-item">
                  <i className="fa-solid fa-star ms-1 text-warning" /> Membership is billed every 30 days and can be cancelled any time.
                </li>
                <li class="list-group-item">
                  <i className="fa-solid fa-star ms-1 text-warning" /> Full terms and conditions are available under FAQ in your profile
                </li>
                <li class="list-group-item">
                  <i className="fa-solid fa-star ms-1 text-warning" /> Please see below membership costs for membership at this store.
                </li>
              </ul>
            </div>
            <div className="Membershiptitleanddetails d-flex justify-content-between align-items-center mt-3 mb-3">
              <h3 className="mb-0 text-primary fw-600 text-center">
                Would you like to purchase this membership package below, in order to become a member of this
              </h3>

            </div>
            {plans.length == 0 && (
              <>
                <img
                  className="noDataImg"
                  src={process.env.PUBLIC_URL + "/assets/img/NodataIMG.png"}
                  alt=""
                />
                <h1 className="text-danger fw-bold text-center mt-5">
                  No address found
                </h1>
                <p className="text-danger fw-bold text-center">
                  Your saved addresses will show here!
                </p>
              </>
            )}
            <div className="MembershipplanList">
              {/*----- Plan 1 -----*/}
              {plans &&
                plans.length > 0 &&
                plans.filter(item => item.status === '0').map((item, i) => (
                  <>
                    <input
                      type="radio"
                      className="d-none"
                      id={item.id}
                      name="MembershipPlan"
                      onClick={() => setSelectedPlan(item)}
                      defaultChecked={item.id === selectedPlan.id}
                    />
                    <label htmlFor={item.id} className="mb-4">
                      <p className="mb-0 text-primary fw-600 fs-2">
                        {item?.package}
                      </p>

                      <span className="mamAmount">R{item?.price}/ Month</span>
                    </label>
                  </>
                ))}
            </div>
          </div>
        </div>

        <div className="modal-footer border-0">
          <div className="w-100">

            <div className="d-none">
              <PaymentForm
                returnUrl={`${location.pathname}?tid=${transection_key}&amt=${selectedPlan?.price}&pkg=${selectedPlan?.id}`}
                totalPrice={selectedPlan?.price}
                uniqeId={transection_key}
                buttonTitle={`Continue with R ${selectedPlan?.price} / Month`}
                ref={paymentCardRef}
              />
            </div>
            <div className="d-flex justify-content-between">
              <button
                onClick={() => setMembershipModal(!membershipModal)}
                className="btn btn-outline-primary fs-2 w-25 guestloginbtn"
              >
                No
              </button>

              <button
                class="btn btn-primary fw-bold fs-2 w-25"
                onClick={() => paymentCardRef.current.click()}
              >
                Yes
                <i class="fa-solid fa-arrow-right ms-2 align-middle"></i>
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </Layout>
  );
};

export default Home;
