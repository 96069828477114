import React from "react";

const AlertError = ({ alert }) => {
  if (alert?.type === "success" && alert?.show === true) {
    return (
      <div className="alert alert-success d-flex align-items-center justify-content-between pe-4">
        <p className="mb-0 fw-500 fs-3 text-dark">{alert?.message}</p>
        <i className="fa-solid fa-circle-check text-success ms-2" />
      </div>
    );
  } else {
    return (
      <div className="alert alert-danger d-flex align-items-center justify-content-between pe-4">
        <p className="mb-0 fw-500 fs-3 text-dark">{alert?.message}</p>
        <i class="fa-solid fa-triangle-exclamation text-danger ms-2"></i>
      </div>
    );
  }
};

export default AlertError;
