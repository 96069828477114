import React from "react";
import { Link, NavLink } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <footer>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-3 col-12">
              <NavLink to={"/home"} className="d-block">
                <img
                  src={process.env.PUBLIC_URL + "/assets/img/GreenCentralGreenLogo.png"}
                  alt=" "
                  className="footerLogo"
                />
              </NavLink>

              <p className="fw-normal text-muted fw-600">
                Discover all the stores currently accessible through our Green Central platform and explore the range of products available for doorstep delivery.
              </p>

              <div className="socialIcons mt-5 mb-lg-0 mb-3">
                <a href="#!" className="d-inline-block me-3">
                  <i className="fa-brands fa-square-facebook" />
                </a>
                <a href="#!" className="d-inline-block me-3">
                  <i className="fa-brands fa-square-instagram" />
                </a>
                <a href="#!" className="d-inline-block me-3">
                  <i className="fa-brands fa-linkedin" />
                </a>
                <a href="#!" className="d-inline-block me-3">
                  <i className="fa-brands fa-square-twitter" />
                </a>
              </div>
            </div>

            <div className="col-lg-2 col-12 ms-auto">
              <div className="serviceslist">
                <ul className="list-unstyled">
                  <li className="mb-4">
                    <h1 className="fw-bold mb-0 text-uppercase">Services</h1>
                  </li>

                  <li className="mb-3">
                    <Link to={"/about#how_it_work"}
                      className="mb-0 text-muted fw-600 text-decoration-none"
                    >
                      How it works
                    </Link>
                  </li>

                  <li className="mb-3">
                    <Link to={"/membership"}
                      className="mb-0 text-muted fw-600 text-decoration-none"
                    >
                      Membership
                    </Link>
                  </li>

                  <li className="mb-3">
                    <Link
                      to="/privacy"
                      className="mb-0 text-muted fw-600 text-decoration-none"
                    >
                      Terms &amp; policies
                    </Link>
                  </li>

                  {/* <li className="mb-3">
                    <a
                      href="https://greencentral.co.za/helpandsupport.html"
                      className="mb-0 text-muted fw-600 text-decoration-none"
                    >
                      Help &amp; Support
                    </a>
                  </li> */}
                </ul>
              </div>
            </div>

            <div className="col-lg-2 col-12 my-lg-0 my-5">
              <div className="serviceslist">
                <ul className="list-unstyled">
                  <li className="mb-4">
                    <h1 className="fw-bold mb-0 text-uppercase">QUICK LINKS</h1>
                  </li>

                  <li className="mb-3">
                    <Link to={"/about#services"}
                      className="mb-0 text-muted fw-600 text-decoration-none"
                    >
                      Services
                    </Link>
                  </li>

                  <li className="mb-3">
                    <Link to={"/about"} className="mb-0 text-muted fw-600 text-decoration-none">
                      About
                    </Link>
                  </li>

                  <li className="mb-3">
                    <Link to={"/faq"}
                      className="mb-0 text-muted fw-600 text-decoration-none"
                    >
                      Faq
                    </Link>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-lg-2 col-12">
              <div className="serviceslist">
                <ul className="list-unstyled">
                  <li className="mb-4">
                    <h1 className="fw-bold mb-0 text-uppercase">CONTACT US</h1>
                  </li>

                  <li className="mb-3">
                    <a
                      href="#!"
                      className="mb-0 text-muted fw-600 text-decoration-none"
                    >
                      <span className="fw-bold text-dark border-bottom">(For Help & Support)-</span>
                      <br />
                      1st Floor, Block B, North Park, Black River Park, 2 Fir Street,
                      Observatory,
                      Cape Town 7925
                    </a>
                  </li>
                  <li className="mb-3">
                    <a
                      href="mailto:info@greencentral.co.za"
                      className="mb-0 text-primary text-decoration-none fw-bold"
                    >
                      info@greencentral.co.za
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <div className="foooterBottom fw-500">
        <p className="mb-0 fs-3 text-center">
          Copyright © 2023
          <NavLink to={"/home"} className="fw-bold ms-2">
            greencentral.co.za
          </NavLink>
        </p>
      </div>
    </>
  );
};

export default Footer;
