import React from "react";
import moment from "moment";
import { sendErrorInfo } from "./helper";
import { initStore } from "../store/index";

const store = initStore();
export const CourierUrl = {
  get_rate: `https://api.shiplogic.com/v2/rates`,
  create_shipment: "https://api.shiplogic.com/v2/shipments",
  cancel_shipment: "https://api.shiplogic.com/v2/shipments/cancel",
  // courier_tracking_url: (id) => `https://sandbox.shiplogic.com/track?ref=${id}`
  courier_tracking_url: (id) =>
    `https://portal.thecourierguy.co.za/track?ref=${id}`,
  track: (id) =>
    `https://api.shiplogic.com/v2/tracking/shipments?tracking_reference=${id}`,
};

export const courierOrderNotPlaced = () => {
  sendErrorInfo(
    "Oops looks like our delivery trucks do not deliver to your area. Please check back in as we are always adding new locations."
  );
};


const isLive = true;

const keys = {
  live: "676bc5d8709241288b881b8c1f41170d",
  sandbox: "de725350f88d49358c964e6655c33ac1"
}
const authKey = isLive ? keys.live : keys.sandbox;

const parcelSize = {
  length: 120,
  height: 60,
  width: 100,
  weight: 0.02,
};

const getCoord = (value) => {
  return parseFloat(value);
};

const getFilteredRate = (rates = []) => {
  if (rates && rates?.length > 0) {
    if (rates?.length == 1) {
      return rates[0];
    }
    const rate = rates.find((item) => item?.service_level?.code == "ECO");
    if (rate) {
      return rate;
    }
    const rate2 = rates.find((item) => item?.service_level?.code == "OVN");
    if (rate2) {
      return rate2;
    }
    const rate3 = rates.find((item) => item?.service_level?.code == "LOF");
    if (rate3) {
      return rate3;
    }
    return rates[0];
  }
};

const ShipmentRequest = async ({
  url,
  data,
  isPost,
  isHideMessage = false,
}) => {
  const header = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: `Bearer ${authKey}`,
    "X-Amz-Date": "1675939616",
  };

  var requestOptions = {
    method: isPost ? "POST" : "Get",
    headers: header,
    body: JSON.stringify(data),
    redirect: "follow",
  };

  try {
    const res = await fetch(url, requestOptions);
    try {
      const result = await res.json();
      return result;
    } catch (error) {
      if (isHideMessage) {
        return;
      }
      sendErrorInfo(
        "Courier service unfortunately not available now, will be back soon."
      );
    }
  } catch (error) {
    console.log("error", error);
  }
};

export const getShipmentRates = async () => {
  const kitchen = store.getState().root.kitchen_data;
  const index = kitchen.findIndex(
    (cat) => cat.id === store.getState().root.kitchen_id
  );
  const kitchen_info = kitchen[index]?.kitchen_info;
  const deliveryAddress = store.getState().root.user_address;

  const collectionDate = moment().format("YYYY-MM-DD");
  const deliveryDate = moment().add("days", 2).format("YYYY-MM-DD");

  const obj = {
    collection_address: {
      type: "business",
      company: kitchen?.name,
      street_address: kitchen_info?.address,
      local_area: "",
      city: "",
      zone: "",
      country: "ZA",
      code: "0181",
      lat: getCoord(kitchen_info?.lat),
      lng: getCoord(kitchen_info?.lng),
    },
    delivery_address: {
      type: "residential",
      company: "",
      street_address: deliveryAddress?.address,
      local_area: deliveryAddress?.apartment ?? "",
      city: "",
      zone: "",
      country: "ZA",
      code: "0081",
      lat: getCoord(deliveryAddress?.lat),
      lng: getCoord(deliveryAddress?.lng),
    },
    parcels: [
      {
        // "submitted_length_cm": parcelSize.length,
        // "submitted_width_cm": parcelSize.width,
        // "submitted_height_cm": parcelSize.height,
        // "submitted_weight_kg": parcelSize.weight
        submitted_length_cm: 10.0,
        submitted_width_cm: 10.0,
        submitted_height_cm: 10.0,
        submitted_weight_kg: 1.0,
      },
    ],
    declared_value: 1500,
    collection_min_date: collectionDate,
    delivery_min_date: deliveryDate,
  };

  const result = {
    rate: "",
    serviceCode: "",
  };
  const res = await ShipmentRequest({
    url: CourierUrl?.get_rate,
    isPost: true,
    data: obj,
  });

  // console.log("Rates=======res-------", JSON.stringify());

  if (res?.rates && res?.rates?.length > 0) {
    // const rateRes = res?.rates[0];
    const rateRes = getFilteredRate(res?.rates);
    console.log("rates res========", JSON.stringify(rateRes));

    result["rate"] = rateRes?.rate; //delivery charges
    result["serviceCode"] = rateRes?.service_level?.code; //create shippemnt arguments
  }

  return result;
};

export const createShipment = async (rate, serviceCode) => {
  console.log({ rate, serviceCode });
  const kitchen = store.getState().root.kitchen_data;
  const index = kitchen.findIndex(
    (cat) => cat.id === store.getState().root.kitchen_id
  );
  const kitchen_info = kitchen[index]?.kitchen_info;
  const deliveryAddress = store.getState().root?.user_address;

  const user = store.getState().root?.user_data;
  const userMobile = `${user?.phone_code}${user?.mobile}`;

  const collectionDate = moment();
  const deliveryDate = moment().add("days", 2);

  const shipping_rate = parseInt(rate);

  const obj = {
    collection_address: {
      type: "business",
      company: kitchen?.name,
      street_address: kitchen_info?.address,
      local_area: "",
      city: "",
      zone: "",
      country: "ZA",
      code: "0181",
      lat: getCoord(kitchen_info?.lat),
      lng: getCoord(kitchen_info?.lng),
    },
    collection_contact: {
      name: kitchen?.name ?? "test store",
      mobile_number: kitchen?.mobile ? String(kitchen?.mobile) : "12345",
      email: kitchen?.email ?? "test@gmail.com",
    },
    delivery_address: {
      type: "residential",
      company: "",
      street_address: deliveryAddress?.address,
      local_area: deliveryAddress?.apartment ?? "",
      city: "",
      zone: "",
      country: "ZA",
      code: "0081",
      lat: getCoord(deliveryAddress?.lat),
      lng: getCoord(deliveryAddress?.lng),
    },
    delivery_contact: {
      name: user?.name ?? "test",
      mobile_number: userMobile ?? "1234",
      email: user?.email ?? "test@gmail.com",
    },
    parcels: [
      {
        parcel_description: "Standard flyer",
        submitted_length_cm: 10.0,
        submitted_width_cm: 10.0,
        submitted_height_cm: 10.0,
        submitted_weight_kg: 1.0,
      },
    ],
    "opt_in_rates": [],
    "opt_in_time_based_rates": [],
    "special_instructions_collection": isLive ? "courier shipment" : "This is a test shipment - DO NOT COLLECT",
    "special_instructions_delivery": isLive ? "courier shipment" : "This is a test shipment - DO NOT DELIVER",
    "declared_value": 1100,
    "collection_min_date": collectionDate,
    "collection_after": "08:00",
    "collection_before": "16:00",
    "delivery_min_date": deliveryDate,
    "delivery_after": "10:00",
    "delivery_before": "17:00",
    "custom_tracking_reference": "",
    "customer_reference": "ORDERNO1",
    "service_level_code": serviceCode, //LLX
    //"service_level_code": "LLX",
    "mute_notifications": false
  };

  const result = {
    courier_id: "",
  };
  const res = await ShipmentRequest({
    url: CourierUrl?.create_shipment,
    isPost: true,
    data: obj,
  });

  console.log("shipment", JSON.stringify(obj));

  if (res && res?.short_tracking_reference) {
    result["courier_id"] = res?.short_tracking_reference;
    return result;
  }
  return result;
};

export const cancelShipment = async (tracking_id) => {
  if (tracking_id) {
    const data = {
      tracking_reference: tracking_id,
    };
    const res = await ShipmentRequest({
      url: CourierUrl?.cancel_shipment,
      isPost: true,
      data: data,
    });

    if (res && res?.status && res?.status === "cancelled") {
      return true;
    }
    return false;
  }
};

export const getCourierTrackDetail = async (tracking_id) => {
  const obj = {
    status: "",
    trackingList: [],
  };
  const res = await ShipmentRequest({
    url: CourierUrl?.track(tracking_id),
    isPost: false,
    isHideMessage: true,
  });

  if (res && res?.shipments && res?.shipments?.length > 0) {
    const data = res?.shipments[0];
    obj["status"] = data?.status;
    obj["trackingList"] = data?.tracking_events ?? [];
  }

  return obj;
};
