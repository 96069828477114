import React, { useEffect } from "react";
import { Offcanvas, OffcanvasBody } from "reactstrap";
import MapWithMarker from "../Maps/MapWithMarker";

const TrackOrder = ({
  isOpen,
  toggleCanvas = () => { },
  orderId,
  orderStatus = "1",
  location,
  pickup,
  user_otp,
}) => {
  return (
    <>
      <Offcanvas
        direction="end"
        className="pt-0"
        isOpen={isOpen}
        toggle={() => toggleCanvas()}
        id="OrderTRACkingOffcanvas"
      >
        <h1 className="trackerHeading">Order Tracker</h1>

        <OffcanvasBody className="pt-0 px-0">
          <div className="trackingMap">
            <button
              type="button"
              className="btn-close"
              onClick={() => toggleCanvas()}
            />

            <MapWithMarker path={true} locations={location} />
          </div>

          <div className="trackingstatusTimeline pt-2">
            <ul className="timelineList list-unstyled mb-0">
              <li className="underProcess completed">
                <p className="mb-0">Success!</p>
                <small className="text-muted fw-bold">
                  Your order has been placed, waiting for the store to accept.
                </small>
                <img
                  src={
                    process.env.PUBLIC_URL + "/assets/img/TimelineCompleted.png"
                  }
                  alt=""
                />
                <p className="mb-0 processlines">
                  <span />
                  <span />
                  <span />
                  <span />
                </p>
              </li>
              {console.log("orderStatus---", orderStatus)}
              <li
                className={
                  parseInt(orderStatus) >= 2
                    ? "underProcess completed"
                    : "underProcess"
                }
              >
                <p className="mb-0">Great Sutff!</p>
                <small className="text-muted fw-bold">
                  Your order has been accepted by the store.
                </small>
                <img
                  src={
                    process.env.PUBLIC_URL + "/assets/img/TimelineCompleted.png"
                  }
                  alt=""
                />
                <p className="mb-0 processlines">
                  <span />
                  <span />
                  <span />
                  <span />
                </p>
              </li>
              <li
                className={
                  parseInt(orderStatus) >= 3
                    ? "underProcess completed"
                    : "underProcess"
                }
              >
                <p className="mb-0">Order Ready!</p>
                <small className="text-muted fw-bold">
                  {pickup === true
                    ? "Your Order has been prepared and ready for you to collect please ask your store attendant to verify the order OTP number. "
                    : "Your Order has been Prepared and ready for delivery person to collect."}
                </small>
                <img
                  src={
                    process.env.PUBLIC_URL + "/assets/img/TimelineCompleted.png"
                  }
                  alt=""
                />
                {pickup === false && (
                  <p className="mb-0 processlines">
                    <span />
                    <span />
                    <span />
                    <span />
                  </p>
                )}
              </li>
              {pickup === false && (
                <>
                  <li
                    className={
                      parseInt(orderStatus) >= 3
                        ? "underProcess completed"
                        : "underProcess"
                    }
                  >
                    <p className="mb-0">Great news!</p>
                    <small className="text-muted fw-bold">
                      Your delivery person has been located.
                    </small>
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/img/TimelineCompleted.png"
                      }
                      alt=""
                    />
                    <p className="mb-0 processlines">
                      <span />
                      <span />
                      <span />
                      <span />
                    </p>
                  </li>
                  <li
                    className={
                      parseInt(orderStatus) >= 4
                        ? "underProcess completed"
                        : "underProcess"
                    }
                  >
                    <p className="mb-0">On Route.</p>
                    <small className="text-muted fw-bold">
                      Your delivery person is on the way.
                    </small>
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/img/TimelineCompleted.png"
                      }
                      alt=""
                    />
                    <p className="mb-0 processlines">
                      <span />
                      <span />
                      <span />
                      <span />
                    </p>
                  </li>
                  <li
                    className={
                      parseInt(orderStatus) >= 5
                        ? "underProcess completed"
                        : "underProcess"
                    }
                  >
                    <p className="mb-0">Your delivery has arrived.</p>
                    <small className="text-muted fw-bold">
                      Your delivery person has arrived.
                    </small>
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/img/TimelineCompleted.png"
                      }
                      alt=""
                    />
                  </li>
                </>
              )}
            </ul>

            {pickup === false && (
              <>
                <h3 className="text-primary px-5 mt-4 pt-lg-3 text-capitalize fw-bold trackoffOTP">
                  <p className="mb-3 d-flex justify-content-between align-items-center">
                    <hr className="" />
                    <span>Verify OTP</span>
                    <hr className="" />
                  </p>

                  <span className="fw-400">
                    please provide your delivery person with the OTP number
                    appearing on the bottom of your tracking screen, to complete
                    your order.
                  </span>
                </h3>

                <div className="px-5 pt-3 fs-2">
                  <span className="text-success fw-bold">OTP : </span>
                  <small className="text-primary fw-bold">{user_otp}</small>
                </div>
              </>
            )}
          </div>
        </OffcanvasBody>

        {/* <div className="offcanvas-footer px-5 mx-3 pb-3">
          <a href="#!" className="btn btn-primary d-block w-100 fs-2 py-3 mt-5">
            View order
          </a>

          <a href="#!" className="btn btn-outline-primary d-block w-100 fs-2 py-3 mt-5 text-danger">
            Cancel order
          </a>
        </div> */}
      </Offcanvas>
    </>
  );
};

export default TrackOrder;
