import React, { useEffect, useState } from "react";
import { callApi } from "../../../hooks/useapi";
import CurrentOrderListCard from "../Orders/CurrentOrderListCard";
import { Modal, ModalBody, ModalHeader, TabPane, TabContent } from "reactstrap";
import { sendErrorInfo, sendSuccessInfo } from "../../../utility/helper";
import OrderListCard from "../Orders/OrderListCard";
import { Socket, getSocketConnect } from "../../../utility/Socket";
import TrackOrder from "../Orders/TrackOrder";
import { useDispatch, useSelector } from "react-redux";
import { confirm } from "../ConfirmDialog";
import {
  addKitchenId,
  addKitchenName,
  changeOrderType,
  clearCartCount,
} from "../../../store/actions/actions";
import { getCheckInventoryForm, getProductsVariantsInventory, kitchenProductToCart } from "../../../utility/cartHelpers";
import { addToCart } from "../../../store/actions/cartActions";
import { useNavigate } from "react-router-dom";
const MyOrders = ({ activetedTab }) => {
  const deliveryAddress = useSelector((state) => state.root.user_address);
  const kitchen_id = useSelector((state) => state.root.kitchen_id);
  const cartData = useSelector((state) => state.cart.items);
  const kitchen_data = useSelector((state) => state.root.kitchen_data);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [orderList, setOrderList] = useState([]);
  const [currentOrder, setCurrentOrder] = useState([]);
  const [pageNo, setPageN0] = useState(1);
  const [loadingText, setloadingText] = useState("Load More");
  const [btnDisable, setBtnDisable] = useState(false);

  const [cancelId, setCancelId] = useState(0);
  const [activeTab, setActiveTab] = useState("1");
  const [cancelModal, setCancelModal] = useState(false);
  const [orderStatus, setOrderStatus] = useState("");
  const [formData, setFormData] = useState({
    reason: "",
    error: "",
  });
  const [trachOrder, setTrackOrder] = useState(false);
  const [deliverLocation, setDeliveryLocation] = useState([]);
  const [is_self, setIsSelf] = useState(false);
  const [userOTP, setUserOtp] = useState("");
  let sockets;
  useEffect(() => {
    // setInterval(() => {

    if (activetedTab == "2") {
      getSocketConnect((socket) => {
        Socket.setMySocket(socket);
        sockets = socket;
        socket.addEventListener("OrderStatusChange", (data) => {
          console.log("socket listining ", data);
          getCurrentOrders();
          console.log("cancel id==================", cancelId);
          if (parseInt(data.order_id) === parseInt(cancelId)) {
            console.log("data.order_status-------------", data.order_status);
            setOrderStatus(data.order_status);
          }
          if (data.order_status == "6") {
            setTrackOrder(false);
          }
        });
      });
      return () => {
        sockets.removeEventListener();
        sockets.removeEventListener("OrderStatusChange");
      };
    }
  }, [activetedTab, cancelId]);
  useEffect(() => {
    getCurrentOrders();
  }, []);

  const getCurrentOrders = () => {
    callApi("user/current-order", { method: "GET" }).then((response) => {
      if (response.status === true) {
        if (cancelId) {
          let orders = response.data.order;
          let index = orders.findIndex((item) => item.id === cancelId);
          console.log(index, "orders[index]", orders[index]);
          if (index > -1) {
            // setOrderStatus(orders[index].type);
          }
        }
        setCurrentOrder(response.data.order);
      }
    });
  };

  const getPastOrders = (page) => {
    setloadingText("Loading Data....");
    setBtnDisable("disable");
    callApi("user/order-history/" + page, { method: "GET" })
      .then((response) => {
        if (response.status) {
          if (response.data.order_history.length > 0) {
            setOrderList((orders) => [
              ...orders,
              ...response.data.order_history,
            ]);
            setloadingText("Load More");
            setBtnDisable("");
          } else {
            setloadingText("No Data Found");
            setBtnDisable("disable");
          }
        }
      })
      .catch((error) => {
        //return error.data;
        console.log(error);
      });
  };
  const loadMoreOrders = () => {
    setPageN0(pageNo + 1);
    getPastOrders(pageNo + 1);
  };

  const handleReOrder = async (order) => {
    const form = getCheckInventoryForm(order.items);
    console.log("handleReOrder", form)
    callApi(
      "user/kitchen-list?lat=" +
      deliveryAddress.lat +
      "&lng=" +
      deliveryAddress.lng +
      "&bus_owner_id=" +
      process.env.REACT_APP_BUSS_ID,
      { method: "GET" }
    ).then(async (response) => {
      let selectedKitchen = null
      if (response.status) {
        let index = undefined;
        index = response.data.kitchen_list.findIndex(
          (kit) => kit.id === order.kitchen_id
        );

        if (index < 0) {
          return sendErrorInfo(
            "This order does not deliver on selected address"
          );
        } else {
          if (order.kitchen_id != kitchen_id || cartData.length > 0) {
            if (
              await confirm(
                "You have some product(s) in your cart. If you will change your kitchen, your product(s) will be deleted. Are you sure you want to remove cart product(s)"
              )
            ) {
              dispatch(clearCartCount());
              // localStorage.removeItem(process.env.REACT_APP_BUSINESS + "productsInCart");
              dispatch(addKitchenId(order.kitchen_id));
              localStorage.setItem(
                process.env.REACT_APP_BUSINESS + "kitchen_id",
                order.kitchen_id
              );
            } else {
              return;
            }
            dispatch(addKitchenId(order.kitchen_id));
            localStorage.setItem(
              process.env.REACT_APP_BUSINESS + "kitchen_id",
              order.kitchen_id
            );
            const kitchenIndex = kitchen_data.findIndex(
              (item) => item.id == order.kitchen_id
            );
            if (kitchenIndex > -1) {
              selectedKitchen = kitchen_data[kitchenIndex]
              const name = kitchen_data[kitchenIndex].name;
              dispatch(addKitchenName(name));
            }
          } else {
            const kitchenIndex = kitchen_data.findIndex(
              (item) => item.id == order.kitchen_id
            );
            if (kitchenIndex > -1) {
              selectedKitchen = kitchen_data[kitchenIndex]

            }
          }
          let productInVentory = [];
          console.log("selectedKitchen", selectedKitchen)
          if (selectedKitchen && selectedKitchen?.kitchen_info?.stock_onoff == '1') {
            let lawStockItem = "";
            const res = await getProductsVariantsInventory({ form });
            productInVentory = res.list;
            for (let item of res?.list) {
              if (+item?.quantity <= 0) {
                lawStockItem = item;
                break;
              }
            }
            if (lawStockItem) {
              sendErrorInfo(

                `${lawStockItem?.name} is out of stock for now, you can try after some time.`,
              );
              return
            }
          }

          callApi("user/dashboard-data-kitchen-id/" + order.kitchen_id, {
            method: "GET",
          }).then((response) => {
            if (response.status) {
              const products = response.data.product_list;
              const discount_product = response.data.discount_product;
              let OBJ = kitchenProductToCart(
                order.items,
                products,
                discount_product
              );
              // console.log("obj", OBJ);
              dispatch(changeOrderType(true));
              OBJ.forEach((each) => {
                dispatch(addToCart(each));
              });

              navigate("/checkout");
            }
          });
        }
      }
    });
  };
  const sendOrderId = (index, val, type, idx, self, user_otp) => {
    console.log("set cancel id, ", index);
    if (val === true) {
      setCancelModal(true);
    } else {
      setTrackOrder(true);
    }

    if (idx > -1) {
      setDeliveryLocation([
        {
          lat: parseFloat(currentOrder[idx].kitchen_info.lat),
          lng: parseFloat(currentOrder[idx].kitchen_info.lng),
          name: currentOrder[idx].name,
          description: currentOrder[idx].kitchen_info.address,
        },
        {
          lat: parseFloat(currentOrder[idx].d_lat),
          lng: parseFloat(currentOrder[idx].d_lng),
          name: currentOrder[idx].name,
          description: currentOrder[idx].kitchen_info.address,
        },
      ]);
    }

    setIsSelf(self == 1 ? true : false);
    setUserOtp(user_otp);
    setOrderStatus(type);
    setCancelId(index);
  };
  const onSubmit = (e) => {
    e.preventDefault();
    if (orderStatus > 1) {
      setCancelModal(false);
      return sendErrorInfo("Your Order is accepted you can not cancel it");
    }
    let request = {
      order_id: cancelId,
      cancel_reason: formData.reason,
      cancel_by: "2",
    };
    callApi("user/ordre-cancel", { method: "POST", data: request }).then(
      (response) => {
        if (response.status === true) {
          sendSuccessInfo("Order Cancelled Successfully.");
          setCancelModal(false);
          getCurrentOrders();
        } else {
          console.log(response);
        }
      }
    );
  };
  return (
    <>
      {" "}
      <ul className="nav nav-tabs border-0" id="myTab" role="tablist">
        <li className="nav-item">
          <button
            className={
              activeTab == "1"
                ? "active nav-link  border-0"
                : " nav-link  border-0"
            }
            onClick={() => setActiveTab("1")}
            type="button"
          >
            Current Orders
          </button>
        </li>
        <li className="nav-item">
          <button
            className={
              activeTab == "2"
                ? "active nav-link  border-0"
                : " nav-link  border-0"
            }
            onClick={() => {
              setOrderList([]);
              getPastOrders(1);
              setActiveTab("2");
            }}
            type="button"
          >
            Past Orders
          </button>
        </li>
      </ul>
      <TabContent activeTab={activeTab} className="pt-5">
        <TabPane tabId={"1"}>
          {currentOrder && currentOrder.length === 0 && (
            <div className="row">
              <div className="col-lg-12 col-12 text-center">
                <img
                  className="noDataImg"
                  src={process.env.PUBLIC_URL + "/assets/img/NodataIMG.png"}
                  alt=""
                />
                <p className="text-center text-muted fw-bold fs-3 mb-5 pb-5">
                  No Order Found
                </p>
              </div>
            </div>
          )}
          {currentOrder &&
            currentOrder.length > 0 &&
            currentOrder.map((orderlist, index) => (
              <CurrentOrderListCard
                key={index}
                fullOrder={orderlist}
                deliveryBoyInfo={orderlist?.delivery_boy_info}
                orderImg={
                  orderlist &&
                  orderlist?.items &&
                  orderlist?.items[0] &&
                  orderlist?.items[0].product_image
                }
                orderFood={
                  (orderlist &&
                    orderlist?.items.length > 0 &&
                    orderlist?.items[0]?.name) ||
                  ""
                }
                size={
                  (orderlist &&
                    orderlist?.items &&
                    orderlist?.items[0] &&
                    orderlist?.items[0].variant_name) ||
                  ""
                }
                quantity={
                  orderlist &&
                  orderlist?.items &&
                  orderlist?.items[0] &&
                  orderlist?.items[0].quantity
                }
                order_extra={
                  orderlist &&
                  orderlist?.items &&
                  orderlist?.items[0] &&
                  orderlist?.items[0].order_extra
                }
                is_self={orderlist?.is_self}
                orderAddress={orderlist?.delivery_address}
                orderNo={orderlist?.id}
                orderTime={orderlist?.deliverd_date_time}
                orderItem={orderlist?.items.length + " Items"}
                pre_order={orderlist?.pre_order}
                pre_full_date_time={orderlist?.pre_full_date_time}
                pre_date={orderlist?.pre_date}
                pre_time={orderlist?.pre_time}
                totalPaid={orderlist?.amount}
                orderOtp={orderlist?.user_otp}
                kitchenName={orderlist?.kitchen_info.name}
                kitchenAddress={orderlist?.kitchen_info.address}
                btnTitle={
                  orderlist?.type == "0"
                    ? "Canceled"
                    : orderlist?.type == "1"
                      ? "Pending"
                      : orderlist?.type == "2"
                        ? "Preparing"
                        : orderlist?.type == "3" && orderlist?.is_self == "1"
                          ? "Waiting for customer"
                          : orderlist?.type == "3" && orderlist?.is_self == "0"
                            ? "Waiting for driver"
                            : orderlist?.type == "4"
                              ? "Picked By Driver"
                              : orderlist?.type == "5"
                                ? "Delivered"
                                : "Completed"
                }
                btnTitle2="Order Status"
                orderStatus={orderlist?.type}
                sendOrderId={(id, val) =>
                  sendOrderId(
                    id,
                    val,
                    orderlist?.type,
                    index,
                    orderlist.is_self,
                    orderlist?.user_otp
                  )
                }
                // showModal={showModal}
                Orderitems={orderlist?.items}
                rating={false}
              // onClick={toggleModal}
              />
            ))}
          <TrackOrder
            isOpen={trachOrder}
            orderId={""}
            orderStatus={orderStatus}
            toggleCanvas={() => setTrackOrder(!trachOrder)}
            location={deliverLocation}
            pickup={is_self}
            user_otp={userOTP}
          />
        </TabPane>

        <TabPane tabId={"2"}>
          {orderList && orderList.length === 0 && (
            <div className="row">
              <div className="col-lg-12 col-12 text-center">
                <img
                  className="noDataImg"
                  src={process.env.PUBLIC_URL + "/assets/img/NodataIMG.png"}
                  alt=""
                />
                <p className="text-center text-muted fw-bold fs-3 mb-5 pb-5">
                  No Order Found
                </p>
              </div>
            </div>
          )}
          {orderList &&
            orderList.length > 0 &&
            orderList.map((orderlist, index) => (
              <OrderListCard
                key={index}
                fullOrder={orderlist}
                deliveryBoyInfo={orderlist?.delivery_boy_info}
                orderImg={
                  orderlist &&
                  orderlist?.items &&
                  orderlist?.items[0] &&
                  orderlist?.items[0].product_image
                }
                orderFood={
                  orderlist && orderlist?.items && orderlist?.items[0]?.name
                }
                size={
                  orderlist &&
                  orderlist?.items &&
                  orderlist?.items[0] &&
                  orderlist?.items[0].variant_name
                }
                quantity={
                  orderlist &&
                  orderlist?.items &&
                  orderlist?.items[0] &&
                  orderlist?.items[0].quantity
                }
                order_extra={
                  orderlist &&
                  orderlist?.items &&
                  orderlist?.items[0] &&
                  orderlist?.items[0].order_extra
                }
                is_self={orderlist?.is_self}
                orderAddress={orderlist?.delivery_address}
                orderNo={orderlist?.id}
                orderTime={orderlist?.deliverd_date_time}
                orderItem={orderlist?.items.length + " Items"}
                pre_order={orderlist?.pre_order}
                pre_full_date_time={orderlist?.pre_full_date_time}
                pre_date={orderlist?.pre_date}
                pre_time={orderlist?.pre_time}
                totalPaid={orderlist?.amount}
                orderOtp={orderlist?.user_otp}
                kitchenName={orderlist?.kitchen_info.name}
                kitchenAddress={orderlist?.kitchen_info.address}
                btnTitle={
                  orderlist?.type == "0"
                    ? "Canceled"
                    : orderlist?.type == "1"
                      ? "Pending"
                      : orderlist?.type == "2"
                        ? "Preparing"
                        : orderlist?.type == "3" && orderlist?.is_self == "1"
                          ? "Waiting for customer"
                          : orderlist?.type == "3" && orderlist?.is_self == "0"
                            ? "Waiting for driver"
                            : orderlist?.type == "4"
                              ? "Picked By Driver"
                              : orderlist?.type == "5"
                                ? "Delivered"
                                : "Completed"
                }
                btnTitle2="Order Status"
                orderStatus={orderlist?.type}
                sendOrderId={(id, val) =>
                  sendOrderId(id, val, orderlist?.type, orderlist.is_self)
                }
                // showModal={showModal}
                Orderitems={orderlist?.items}
                rating={true}
                // onClick={toggleModal}
                handleReOrder={handleReOrder}
                delivery_boy_info={orderlist?.delivery_boy_info}
                kitchen_id={orderlist?.kitchen_id}
              />
            ))}

          <div className="text-center">
            {" "}
            <button
              className="btn btn-outline-primary  fw-bold fs-2 px-4"
              onClick={loadMoreOrders}
              disabled={btnDisable}
            >
              {loadingText}
            </button>
          </div>
        </TabPane>
      </TabContent>

      <Modal
        isOpen={cancelModal}
        toggle={() => setCancelModal(false)}
        size="lg"
        className="modal-dialog modal-dialog-centered modal-dialog-scrollable AddtocardProductModal CancelOrderModal"
      >
        <ModalHeader className="d-block border-0 p-0 position-relative">
          Do You Want to cancel this order
          <button
            type="button"
            onClick={() => {
              setFormData({ error: "", reason: "" });
              setCancelModal(false);
            }}
            className="btn-close customClose"
          >
            <i className="fa-solid fa-xmark" />
          </button>
        </ModalHeader>

        <ModalBody>
          <form onSubmit={onSubmit} className="row">
            <div className="col-lg-12 col-12 mb-2">
              {/*----- option 1 of cancle -----*/}
              <div className="form-check position-relative">
                <input
                  className="form-check-input"
                  type="radio"
                  onChange={(e) =>
                    setFormData({ ...formData, reason: e.target.value })
                  }
                  value={"I want to cancel the order"}
                  name="cancel_reason"
                  id="cause-1"
                />

                <label className="form-check-label" htmlFor="cause-1">
                  I want to cancel the order
                </label>
              </div>
            </div>

            <div className="col-lg-12 col-12 mb-2">
              {/*----- option 2 of cancle -----*/}
              <div className="form-check position-relative">
                <input
                  className="form-check-input"
                  type="radio"
                  onChange={(e) =>
                    setFormData({ ...formData, reason: e.target.value })
                  }
                  value={"I want to change the kitchen"}
                  name="cancel_reason"
                  id="cause-2"
                />
                <label className="form-check-label" htmlFor="cause-2">
                  I want to change the kitchen
                </label>
              </div>
            </div>

            <div className="col-lg-12 col-12 mb-2">
              {/*----- option 3 of cancle -----*/}
              <div className="form-check position-relative">
                <input
                  className="form-check-input"
                  onChange={(e) =>
                    setFormData({ ...formData, reason: e.target.value })
                  }
                  value={"I want to Change my delivery Address"}
                  type="radio"
                  name="cancel_reason"
                  id="cause-3"
                />
                <label className="form-check-label" htmlFor="cause-3">
                  I want to Change my delivery Address
                </label>
              </div>
            </div>

            <div className="col-lg-12 col-12 mb-2">
              {/*----- option 4 of cancle -----*/}
              <div className="form-check position-relative">
                <input
                  className="form-check-input"
                  onChange={(e) =>
                    setFormData({ ...formData, reason: e.target.value })
                  }
                  value={"I am not able to contact kitchen"}
                  type="radio"
                  name="cancel_reason"
                  id="cause-4"
                />
                <label className="form-check-label" htmlFor="cause-4">
                  I am not able to contact kitchen
                </label>
              </div>
            </div>

            <div className="col-lg-12 col-12 mb-2">
              {/*----- option 5 of cancle -----*/}
              <div className="form-check position-relative">
                <input
                  className="form-check-input"
                  onChange={(e) =>
                    setFormData({ ...formData, reason: e.target.value })
                  }
                  value={"I am not able to contact the driver"}
                  type="radio"
                  name="cancel_reason"
                  id="cause-5"
                />
                <label className="form-check-label" htmlFor="cause-5">
                  I am not able to contact the driver
                </label>
              </div>
            </div>

            <div className="col-lg-12 col-12 mb-2">
              {/*----- option 6 of cancle -----*/}
              <div className="form-check position-relative">
                <input
                  className="form-check-input"
                  onChange={(e) =>
                    setFormData({ ...formData, reason: e.target.value })
                  }
                  value={"I want to edit my order"}
                  type="radio"
                  name="cancel_reason"
                  id="cause-6"
                />
                <label className="form-check-label" htmlFor="cause-6">
                  I want to edit my order
                </label>
              </div>
            </div>

            <div className="col-lg-12 col-12 mb-2">
              {/*----- option 7 of cancle -----*/}
              <div className="form-check position-relative">
                <input
                  className="form-check-input"
                  onChange={(e) =>
                    setFormData({ ...formData, reason: e.target.value })
                  }
                  value={"My order is getting delayed"}
                  type="radio"
                  name="cancel_reason"
                  id="cause-7"
                />
                <label className="form-check-label" htmlFor="cause-7">
                  My order is getting delayed
                </label>
              </div>
            </div>

            <div className="col-lg-3 col-sm-4 col-6 mx-lg-0 mx-auto">
              <button
                type="submit"
                disabled={formData.reason == ""}
                className="btn btn-danger d-block w-100 fs-3 py-2 my-4"
              >
                Cancel Order
              </button>
            </div>
          </form>
        </ModalBody>
      </Modal>
    </>
  );
};

export default MyOrders;
