import moment from "moment";
import { callApi } from "../hooks/useapi";
import { initStore } from "../store/index";
import { AppConst, KM_RANGES } from "../AppConst";
import { Haversineformula, sendErrorInfo } from "./helper";
const store = initStore();

export const checkActiveMemberShip = async (kitchen_id) => {
  const response = await callApi(
    `user/mermbership-package-active/${kitchen_id}`,
    {
      method: "GET",
    }
  );
  if (response.status === true) {
    return response.data;
  } else {
    return false;
  }
};

export const itemsPrice = (items) => {
  if (items && Array.isArray(items)) {
    return parseFloat(
      items.reduce((previous, item) => {
        return previous + item.product_price * parseInt(item.quantity);
      }, 0)
    );
  }
  return 0;
};

export const checkTodayDateInRange = (start, end) => {
  if (start && end) {
    const date = moment().format("YYYY-MM-DD");
    const start_from = moment(start).format("YYYY-MM-DD");
    const end_date = moment(end).format("YYYY-MM-DD");
    return start_from <= date && date <= end_date;
  }
};

export const getDeliveryRates = ({
  cart_value = 10,
  delivery_method,
  kitchen_id,
  kitchen_data,
  deliveryAddress,
}) => {
  let range_type;
  callApi(`user/get-bussiness-details/${kitchen_id}`, {
    method: "GET",
  }).then((response) => {
    if (response.status === true) {
      range_type = response?.data?.range_type;
    }
  });
  range_type = range_type ? range_type : AppConst.getBussInfo.distance_by;

  if (delivery_method == "Delivery") {
    const address = deliveryAddress;

    if (address && address.lat) {
      const index =
        kitchen_data && kitchen_data.findIndex((cat) => cat.id == kitchen_id);

      const kitchen = kitchen_data[index];
      // const kitchen = store.getState().kitchan;

      if (kitchen && kitchen.kitchen_info) {
        const start_lat = kitchen.kitchen_info && kitchen.kitchen_info.lat;
        const start_lng = kitchen.kitchen_info && kitchen.kitchen_info.lng;
        const end_lat = address.lat;
        const end_lng = address.lng;

        if (start_lat && start_lng && end_lat && end_lng) {
          let range = parseFloat(
            Haversineformula(start_lat, start_lng, end_lat, end_lng)
          );
          console.log("range", { range, address, kitchen });
          range = range_type == KM_RANGES.Miles ? range : range / 1.609;

          const delivery_radius =
            parseFloat(kitchen.extra_km_radius) > 0
              ? parseFloat(kitchen.extra_km_radius)
              : parseFloat(kitchen.km_radius);

          const delivery_charger = parseFloat(
            kitchen.kitchen_info.outlet_delivery_charges
          );
          const over_km_charge = parseFloat(
            kitchen.kitchen_info.extra_mile_charges
          );
          const free_after = parseFloat(kitchen.free_delivery_after);
          // location available in given range
          if (cart_value >= free_after && free_after > 0) {
            return 0;
          }
          if (delivery_radius >= range) {
            return parseInt(delivery_charger);
          } else {
            const extra_km_charges =
              parseFloat(range - delivery_radius) * over_km_charge;
            return parseInt(extra_km_charges + delivery_charger);
          }
        }
      }
    }
  }

  return 0;
};

export const getProductsVariantsInventory = async ({
  form,
}) => {
  const obj = {
    list: []
  }
  const res = await callApi('user/check-inventory', {
    data: form,
    method: "POST"
  });
  console.log("check-inventory", res)
  if (res && res?.status) {
    obj['list'] = res?.data;
  }
  return obj;
}

export const kitchenProductToCart = (orderItems, products, discount_product = []) => {

  let cartProducts = [];
  // const orderItems = this.state.order_detail.items;
  if ((!products || products.length == 0) && (!orderItems || orderItems.length == 0)) {
    return false
  }

  const getDiscountFromOrderItems = (product_id) => {
    const obj = {
      discount: "",
      discount_start_date: "",
      discount_end_date: ""
    }
    const pItem = discount_product?.find((item) => {
      let startDate = moment(item?.start_from, "YYYY-MM-DD HH:mm:ss");
      let endDate = moment(item?.end_date, "YYYY-MM-DD HH:mm:ss");
      if (
        item?.product_id === product_id &&
        moment().isBetween(startDate, endDate, null, "[]")
      ) {
        return item;
      }
    });
    if (+pItem?.percentage > 0) {
      obj['discount'] = pItem?.percentage;
      obj['discount_start_date'] = pItem?.start_from;
      obj['discount_end_date'] = pItem?.end_date;
    }
    return obj;
  }

  products.filter(item => {
    const orderedIndex = orderItems.findIndex(order_item => order_item.product_id == item.id);

    if (orderedIndex == -1) {
      return false
    }
    const cartItem = {};
    const orderProduct = orderItems[orderedIndex];
    cartItem.product_detail = item
    cartItem.quantity = orderProduct.quantity;

    // Find Variant of the product
    const variantIndex = item.product_variant.findIndex(variant => variant.id == orderProduct.variant_id);
    if (variantIndex == -1) {
      return false
    }
    const selectVariant = item.product_variant[variantIndex];

    cartItem.selected_size = selectVariant.id;
    cartItem.selected_topping_obj = selectVariant;


    // Find Selected Addons
    const selectedAddons = {};

    selectVariant.addons_item.map(item_addon => {
      const isItem = orderProduct.addons_item.findIndex(order_addon => order_addon.add_ons_id == item_addon.id) > -1;
      if (isItem) {
        selectedAddons[item_addon.id] = item_addon;
      }
    });
    cartItem.selected_topings = selectedAddons;

    // Find Select Extras
    const selectExtras = {};
    orderProduct.order_extra.map(extra_heading => {

      const index = item.product_item_list.findIndex(product_extra => product_extra.id == extra_heading.product_extra_id);
      if (index == -1) {
        return false
      }

      const extra = item.product_item_list[index];
      selectExtras[extra_heading.product_extra_id] = extra.product_item
        .map(item => ({ id: item.id, price: item.price }))
        .filter(item =>
          extra_heading.order_extra_item.findIndex(extra_product => extra_product.order_extra_item_id == item.id) > -1
        );
    });

    cartItem.selected_extras = selectExtras;

    // calculate price of Order 
    let price = 0;

    price += parseFloat(selectVariant.price);

    Object.keys(cartItem.selected_extras).forEach(item => {
      cartItem.selected_extras[item].forEach(item => {
        price += parseFloat(item.price);
      })
    });

    Object.keys(cartItem.selected_topings).forEach(item => {
      price += parseFloat(cartItem.selected_topings[item].price);
    });

    cartItem.back_up_price = price;
    cartItem.product_price = price;
    // cartItem.discounted_amount = 0;
    const discountData = getDiscountFromOrderItems(item?.id);
    cartItem.discounted_amount = discountData.discount;
    if (discountData?.discount > 0) {
      cartItem.discount_start_date = discountData.discount_start_date;
      cartItem.discount_end_date = discountData.discount_end_date;
    }


    cartProducts.push({
      ...item,
      ...cartItem
    });
  });
  return cartProducts;

  return null
}

export const getCheckInventoryForm = (product_items = []) => {
  const form = [];
  product_items?.map((item) => {
    if (item?.variant_id) {
      form.push({
        variant_id: item?.variant_id,
        product_id: item?.product_id,
        name: item?.name
      });
    }
    const extra = Array.isArray(item?.order_extra) ? [...item?.order_extra] : [];
    extra?.map((extra_item) => {
      const extras = Array.isArray(extra_item?.order_extra_item) ? [...extra_item?.order_extra_item] : [];
      extras?.map((_item) => {
        form.push({
          variant_id: _item?.order_extra_item_id,
          product_id: extra_item?.product_id,
          name: _item?.name
        });
      })
    })
  });
  return {
    products: form
  }
}





export const createCheckoutObjArray = (items) => {
  function getAddons(obj) {
    const addons = [];
    if (obj) {
      const arr = Object.keys(obj);
      arr.map((key) => {
        if (obj[key]) {
          addons.push({
            add_ons_id: obj[key].addons_id,
            add_ons_price: obj[key].price,
          });
        }
      });
    }
    return addons;
  }

  function getExtraItems(item) {
    let list = [];
    if (item.selected_extras) {
      const extras = item.selected_extras;

      item.product_item_list.map((_extra) => {
        if (extras[_extra.id]) {
          const selected_items = [];

          _extra.product_item.forEach((_product) => {
            if (
              extras[_extra.id].findIndex((extra) => extra.id == _product.id) >
              -1
            ) {
              selected_items.push({
                id: _product.id,
                name: _product.name,
                price: _product.price,
              });
            }
          });

          list.push({
            id: _extra.id,
            title: _extra.title,
            items: selected_items,
          });
        }
      });
    }

    return list;
  }

  return items.map((_item) => {
    let discount = 0;
    if (
      _item.discounted_amount > 0 &&
      _item.discount_start_date &&
      _item.discount_end_date &&
      checkTodayDateInRange(_item.discount_start_date, _item.discount_end_date)
    ) {
      discount = _item.discounted_amount;
    }
    const obj = {};
    obj["product_id"] = _item.id;
    obj["variant_id"] = _item.selected_size;
    obj["extra_notes"] = _item.additional_note;
    obj["quantity"] = _item.quantity;
    obj["price"] = _item.product_price + discount;
    obj["addons_item"] = getAddons(_item.selected_topings);
    obj["variant_extra"] = getExtraItems(_item);
    obj["name"] = _item.name;
    obj["product_image"] = _item.product_image;

    obj["order_product_discount"] = discount;

    return obj;
  });
};

// export const createCheckoutObjArray = (items) => {

//     function getAddons(obj) {

//         const addons = [];
//         if (obj) {
//             const arr = Object.keys(obj);
//             arr.map((key) => {
//                 if (obj[key]) {
//                     // console.log('here i am', obj[key]);
//                     addons.push({
//                         add_ons_id: obj[key].id,
//                         add_ons_price: obj[key].price
//                     });
//                 }
//             })
//         }
//         return addons;
//     }

//     return items.map((_item) => {
//         const obj = {};
//         // console.log('cart object ',_item)
//         obj["product_id"] = _item.id;
//         obj["variant_id"] = _item.selected_size;
//         obj["quantity"] = _item.quantity;
//         obj["price"] = _item.product_price;
//         obj["addons_item"] = getAddons(_item.selected_topings);
//         obj["name"] = _item.name;
//         obj["product_image"] = _item.product_image;

//         return obj
//     })
// }

export const getProductDetailIncart = (item, id) => {
  let index = item.findIndex((val) => val.is_size == "1");
  if (index > -1) {
    let sizes = item[index];
    const index2 =
      sizes.product_item &&
      sizes.product_item.findIndex((item) => item.id == id);
    if (index2 > -1) {
      return sizes.product_item[index2];
    }
  } else {
    return [];
  }
};

export const objectMap = (obj, fn) =>
  Object.fromEntries(Object.entries(obj).map(([k, v], i) => [k, fn(v, k, i)]));

export const isTodaySameDate = (date) => {
  return moment().format("YYYY-MM-DD") === moment(date).format("YYYY-MM-DD");
};

export const getTaxValue = (kitchenData, totalAmount) => {
  const tax_percentage =
    kitchenData?.kitchen_info && kitchenData?.kitchen_info.tax > 0
      ? kitchenData?.kitchen_info.tax
      : 0;
  let tax = tax_percentage > 0 ? (tax_percentage * totalAmount) / 100 : 0;
  tax = tax.toFixed(2);
  return { tax, tax_percentage };
};

export function checkItemInStock(data) {
  // Check product_item_list
  for (let i = 0; i < data.product_item_list.length; i++) {
    const productItem = data.product_item_list[i].product_item;
    for (let j = 0; j < productItem.length; j++) {
      const totalInventory = productItem[j].total_inventory;
      for (let k = 0; k < totalInventory.length; k++) {
        if (totalInventory[k].quantity_total > 0) {
          return true;
        }
      }
    }
  }

  // Check product_variant
  for (let i = 0; i < data.product_variant.length; i++) {
    const totalInventory = data.product_variant[i].total_inventory;
    for (let j = 0; j < totalInventory.length; j++) {
      if (totalInventory[j].quantity_total > 0) {
        return true;
      }
    }
  }

  return false;
}
