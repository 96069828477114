// store/reducers.js

import { combineReducers } from 'redux'
import reducer from './reducers'
import cartReducers from './cartReducers'

const rootReducer = combineReducers({
  root: reducer,
  cart:cartReducers
})

export default rootReducer
