import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import FormError from "../../components/common/FormError";
import intlTelInput from "intl-tel-input";
import "intl-tel-input/build/css/intlTelInput.min.css";
import { AppConst } from "../../AppConst";
import { useDispatch, useSelector } from "react-redux";
import { saveUserData } from "../../store/actions/actions";
import { callApi } from "../../hooks/useapi";
import { getToken, setCookies } from "../../utility/helper";
import AlertError from "../../components/common/AlertError";
import { Link, useNavigate } from "react-router-dom";
// import { messaging } from "../../firebase";
// import { getToken } from "firebase/messaging";
import cookies from 'react-cookies'
import { requestPermission } from "../../firebase";
// import GoogleLogin from "react-google-login";

const Login = () => {
  const {
    register,

    formState: { errors },
    handleSubmit,
  } = useForm({ mode: "onBlur" });
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [fcmToken, setFcmToken] = useState('')
  const dispatch = useDispatch();
  const user_address = useSelector((state) => state.root.user_address);
  const [apiMessage, setApiMessage] = useState({
    show: false,
    type: "success",
    message: "",
  });
  useEffect(() => {
    requestPermission().then(res => {
      setFcmToken(res)
    });
    // requestNotificationPermission()
    if (!user_address?.address) {
      navigate("/");
    } else if (getToken()) {
      navigate("/stores");
    }
    const input = document.querySelector("#CountryCode");
    intlTelInput(input, {
      separateDialCode: true,
      initialCountry: "za",
    });
  }, []);


  const onSubmit = (data) => {
    const input = document.querySelector(".iti__selected-dial-code");
    data.buss_id = process.env.REACT_APP_BUSS_ID;
    data.owner_id = process.env.REACT_APP_BUSS_ID;
    data.device_token = fcmToken
    data.device_type = 'android'
    data.phone_code = input.innerHTML;
    data.mobile = data.mobile.replace(/^0+/, "");
    data.mobile = data.mobile.replace(
      `/^${AppConst.getBussInfo.country_code}+/`,
      ""
    );
    let PostRequest = data;
    callApi("user/check-status", {}).then((response) => {
      if (response.status) {
        if (response.is_login === 0) {
          setApiMessage({
            show: true,
            type: "danger",
            message: "You are logged out by administration! ",
          });
          toggleAlert();
        }
      }
    });

    callApi("user/login", { data: PostRequest, method: "POST" })
      .then((response) => {
        // console.log("server reponse"+JSON.stringify(response));
        if (response.status === true) {
          dispatch(saveUserData(response.data));
          setCookies(
            process.env.REACT_APP_BUSINESS + "user_data",
            JSON.stringify(response.data),
            { path: "/" }
          );
          setCookies(
            process.env.REACT_APP_BUSINESS + "token",
            response.data.token,
            { path: "/" }
          );
          setCookies(
            process.env.REACT_APP_BUSINESS + "type",
            response.data.type,
            { path: "/" }
          );
          setApiMessage({
            show: true,
            type: "success",
            message: "Logged in successfully ",
          });

          navigate("/stores");
        } else {
          // console.log(response.errors);

          //console.log(index+ value.detail)
          setApiMessage({
            show: true,
            type: "danger",
            message: response.errors.detail,
          });
          toggleAlert();
        }
        //console.log("new:"+this.state.Screentype);
      })
      .catch((errors) => {
        //return error.data;
        console.log(errors);
      });
  };
  const toggleAlert = () => {
    setTimeout(() => {
      setApiMessage({ show: false, message: "", type: "success" });
    }, 5000);
  };
  const onSuccess = (response) => {
    // if (response) {
    let data = {
      email: response?.profileObj?.email,

      social_type: "google",
    };
    data.buss_id = process.env.REACT_APP_BUSS_ID;
    data.owner_id = process.env.REACT_APP_BUSS_ID;

    callApi("user/social-login", data).then((responce) => {
      if (response.status === true) {
        dispatch(saveUserData(response.data));
        setCookies(
          process.env.REACT_APP_BUSINESS + "user_data",
          JSON.stringify(response.data)
        );
        setCookies(
          process.env.REACT_APP_BUSINESS + "token",
          response.data.token
        );
        setCookies(process.env.REACT_APP_BUSINESS + "type", response.data.type);
        setApiMessage({
          show: true,
          type: "success",
          message: "Logged in successfully ",
        });

        navigate("/stores");
      } else {
        // console.log(response.errors);

        //console.log(index+ value.detail)
        setApiMessage({
          show: true,
          type: "danger",
          message: response.errors.detail,
        });
        toggleAlert();
      }
    });
  };

  const onFailure = (error) => {
    console.log("onFailure--------", error);
    // setApiMessage({
    //   show: true,
    //   type: "danger",
    //   message: "Social login failed, Please try again ",
    // });
    // toggleAlert();
  };
  return (
    <>
      <div className=" main-container entrypage login">
        <div className="row mx-0 px-0 justify-content-between h-100">
          <div className="col-xl-5 col-lg-6 col-12 px-5 position-relative">
            {/*----- Warning/information alert -----*/}
            {apiMessage.show === true && <AlertError alert={apiMessage} />}
            <div className="entrylogo">
              <img
                src="./assets/img/GreenTExtlogo.png"
                className="w-100 h-100"
                alt=""
              />
            </div>
            <h1 className="text-primary fw-600 text-uppercase text-center mb-5 pb-4">
              Log in to your account
            </h1>
            <div className="entryscroll">
              <form
                onSubmit={handleSubmit(onSubmit)}
                className="regform position-relative"
              >
                {/*----- select country dropdown input ------*/}
                <div className="position-relative pb-4 mb-4">
                  <input
                    type="text"
                    id="CountryCode"
                    className="form-control intl-tel-input"
                    placeholder="Phone number"
                    {...register("mobile", {
                      required: "phone number is required",
                      pattern: {
                        value: /^\d+/,
                        message: "Invalid pattern Must be use numeric ",
                      },
                    })}
                  />
                  <FormError message={errors?.mobile?.message} />
                </div>
                <div className="position-relative pb-4 mb-4">
                  <input
                    type={!showPassword ? `password` : "text"}
                    className="form-control"
                    id="loginpassword"
                    placeholder="Password"
                    {...register("password", {
                      required: "password is required",
                      minLength: {
                        value: 4,
                        message: "Password min length 4 Character",
                      },
                    })}
                  />

                  <button
                    type="button"
                    className="showhidebutton"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {!showPassword ? (
                      <svg
                        height={20}
                        width={20}
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 640 512"
                      >
                        <path d="M320 400c-75.85 0-137.25-58.71-142.9-133.11L72.2 185.82c-13.79 17.3-26.48 35.59-36.72 55.59a32.35 32.35 0 0 0 0 29.19C89.71 376.41 197.07 448 320 448c26.91 0 52.87-4 77.89-10.46L346 397.39a144.13 144.13 0 0 1-26 2.61zm313.82 58.1l-110.55-85.44a331.25 331.25 0 0 0 81.25-102.07 32.35 32.35 0 0 0 0-29.19C550.29 135.59 442.93 64 320 64a308.15 308.15 0 0 0-147.32 37.7L45.46 3.37A16 16 0 0 0 23 6.18L3.37 31.45A16 16 0 0 0 6.18 53.9l588.36 454.73a16 16 0 0 0 22.46-2.81l19.64-25.27a16 16 0 0 0-2.82-22.45zm-183.72-142l-39.3-30.38A94.75 94.75 0 0 0 416 256a94.76 94.76 0 0 0-121.31-92.21A47.65 47.65 0 0 1 304 192a46.64 46.64 0 0 1-1.54 10l-73.61-56.89A142.31 142.31 0 0 1 320 112a143.92 143.92 0 0 1 144 144c0 21.63-5.29 41.79-13.9 60.11z" />
                      </svg>
                    ) : (
                      <svg
                        height={20}
                        width={20}
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 576 512"
                      >
                        <path d="M572.52 241.4C518.29 135.59 410.93 64 288 64S57.68 135.64 3.48 241.41a32.35 32.35 0 0 0 0 29.19C57.71 376.41 165.07 448 288 448s230.32-71.64 284.52-177.41a32.35 32.35 0 0 0 0-29.19zM288 400a144 144 0 1 1 144-144 143.93 143.93 0 0 1-144 144zm0-240a95.31 95.31 0 0 0-25.31 3.79 47.85 47.85 0 0 1-66.9 66.9A95.78 95.78 0 1 0 288 160z" />
                      </svg>
                    )}
                  </button>
                  <FormError message={errors?.password?.message} />
                </div>

                <div>
                  {/*----- Forgot and create account option -----*/}
                  <div className="otheroption">
                    <div className="hoverlineeffect">
                      <p className="mb-0">
                        <Link to={"/signup"}>Don’t have an account ?</Link>
                      </p>
                    </div>
                    <div className="hoverlineeffect">
                      <p className="mb-0">
                        <Link to={"/forgot-password"}>Forgot password</Link>
                      </p>
                    </div>
                  </div>
                  {/*----- Social logins option -----*/}
                  {/* <div className="sociallogins">
                    <a href="#!">
                      <img src="./assets/img/appleicon.png" alt="" />
                      <span className="d-inline-block ms-3">
                        Login with apple
                      </span>
                    </a>

                    <GoogleLogin
                      clientId={process.env.REACT_APP_GOOGLE_CLIENTID}
                      buttonText="Login"
                      onSuccess={(e) => onSuccess(e)}
                      onFailure={(e) => onFailure(e)}
                      cookiePolicy={"single_host_origin"}
                      isSignedIn={false}
                      render={(renderProps) => (
                        <a href="#!" onClick={renderProps.onClick}>
                          <img src="./assets/img/gogleicon.png" alt="" />
                          <span className="d-inline-block ms-3">
                            Login with google
                          </span>
                        </a>
                      )}
                    />
                  </div> */}
                  {/*----- Submit button(Continue to login) -----*/}
                </div>

                <button
                  type="submit"
                  className="btn btn-primary d-block w-100 fs-2 py-4 mt-5"
                >
                  Continue to sign in
                </button>
                {/*----- Submit button(Continue as a guest) -----*/}
                <Link
                  to={"/stores"}
                  className="btn btn-outline-primary d-block w-100 fs-2 py-4 mt-5 guestloginbtn"
                >
                  Continue with guest
                  <i className="fa-solid fa-arrow-right ms-3 align-middle ms-3" />
                </Link>
              </form>
            </div>
          </div>

          <div className="col-xl-7 col-lg-6 col-12 px-0 h-100 position-relative d-lg-block d-none">
            {/*----- Banner container -----*/}
            <div
              className="entrypage-banner w-100 h-100"
              style={{ backgroundImage: "url(./assets/img/loginbg.png)" }}
            ></div>
            {/*----- Banner layer text -----*/}
            <div className="bannerlayertext">
              <p className="mb-0">
                Everything you need delivered now with
                <br />
                <span className="text-decoration-underline text-uppercase">
                  {AppConst.getBussInfo.app_name}
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
