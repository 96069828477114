import React, { useRef, useEffect, useState } from 'react';

const ScratchCard = ({ message, triggerApi = () => { } }) => {
    const canvasRef = useRef(null);
    const [isScratched, setIsScratched] = useState(false);
    const [isScratching, setIsScratching] = useState(false); // Track if mouse is pressed
    const threshold = 0.7; // 70% threshold

    useEffect(() => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        const scratchLayer = new Image();
        scratchLayer.src = process.env.PUBLIC_URL + "/assets/img/scratch1.png"; // Cover image

        scratchLayer.onload = () => {
            ctx.drawImage(scratchLayer, 0, 0, canvas.width, canvas.height);
        };

        const handleMouseDown = () => {
            setIsScratching(true); // Start scratching
        };

        const handleMouseUp = () => {
            setIsScratching(false); // Stop scratching
        };

        const handleMouseMove = (e) => {
            if (!isScratching) return; // Only scratch when mouse is pressed

            const rect = canvas.getBoundingClientRect();
            const x = e.clientX - rect.left;
            const y = e.clientY - rect.top;

            ctx.globalCompositeOperation = 'destination-out'; // Make the scratched area transparent
            ctx.beginPath();
            ctx.arc(x, y, 20, 0, Math.PI * 2); // Circle to scratch
            ctx.fill();

            checkScratched(); // Check how much is scratched
        };

        const checkScratched = () => {
            const pixels = ctx.getImageData(0, 0, canvas.width, canvas.height);
            const totalPixels = pixels.data.length / 4; // Each pixel has 4 elements (RGBA)
            let transparentPixels = 0;

            for (let i = 0; i < pixels.data.length; i += 4) {
                const alpha = pixels.data[i + 3]; // Alpha channel
                if (alpha === 0) transparentPixels += 1; // Count transparent pixels
            }

            const scratchedPercentage = transparentPixels / totalPixels;

            if (scratchedPercentage > threshold && !isScratched) {
                setIsScratched(true);
                triggerApi(); // Call API when 70% scratched
            }
        };


        // Add event listeners for scratch interaction
        canvas.addEventListener('mousedown', handleMouseDown);
        canvas.addEventListener('mouseup', handleMouseUp);
        canvas.addEventListener('mousemove', handleMouseMove);

        return () => {
            // Remove event listeners
            canvas.removeEventListener('mousedown', handleMouseDown);
            canvas.removeEventListener('mouseup', handleMouseUp);
            canvas.removeEventListener('mousemove', handleMouseMove);
        };
    }, [isScratched, isScratching]);

    return (
        <div style={{ position: 'relative', width: '300px', height: '200px' }}>
            {isScratched && <div className="revealed-content">{message}</div>}
            <canvas
                ref={canvasRef}
                width={300}
                height={200}
                style={{ position: 'absolute', top: 0, left: 0 }}
            />
        </div>
    );
};

export default ScratchCard;
