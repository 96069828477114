import React, { useEffect, useState } from "react";
import FormError from "../../components/common/FormError";
import { useForm } from "react-hook-form";
import intlTelInput from "intl-tel-input";
import "intl-tel-input/build/css/intlTelInput.min.css";
import { AppConst } from "../../AppConst";
import { callApi } from "../../hooks/useapi";
import { sendSuccessInfo } from "../../utility/helper";
import AlertError from "../../components/common/AlertError";
import { useNavigate } from "react-router-dom";
import { confirm } from "../../components/common/ConfirmDialog";
const ResetPassword = () => {
  const {
    register,

    formState: { errors },
    handleSubmit,
  } = useForm({ mode: "onBlur" });
  const navigate = useNavigate("/")
  const [apiMessage, setApiMessage] = useState({
    show: false,
    type: "success",
    message: "",
  });
  useEffect(() => {
    const input = document.querySelector("#CountryCode");
    intlTelInput(input, {
      separateDialCode: true,
      initialCountry: "za",
    });
  }, []);
  const onSubmit = (data) => {
    const input = document.querySelector(".iti__selected-dial-code");
    let postData = {}
    postData.phone_code = input.innerHTML;
    postData.owner_id = process.env.REACT_APP_BUSS_ID;
    postData.buss_id = process.env.REACT_APP_BUSS_ID;
    postData.mobile = data.mobile;
    data.mobile = data.mobile.replace(/^0+/, "");
    data.mobile = data.mobile.replace(
      `/^${AppConst.getBussInfo.country_code}+/`,
      ""
    );

    callApi("user/forgot-password", { method: "POST", data: postData })
      .then((response) => {
        if (response.status === true) {
          showOTPConfirmation(response)

        } else {
          setApiMessage({
            show: true,
            type: "danger",
            message: response.errors[0].detail || response.message,
          });
          toggleAlert();
        }
      })
      .catch((err) => console.log(err));
  };
  const toggleAlert = () => {
    setTimeout(() => {
      setApiMessage({ show: false, message: "", type: "success" });
    }, 5000);
  };

  const showOTPConfirmation = async (response) => {
    if (await confirm(`your new password is ${response.data.otp}`, 'OK', null)) {
      navigate("/login")
    } else {
      navigate("/login")
    }
  }
  return (
    <div className=" main-container entrypage resetpassword">
      <div className="row mx-0 px-0 justify-content-between h-100">
        <div className="col-lg-5 col-12 px-5 pt-5 position-relative">
          <div className="entrylogo">
            <img
              src="./assets/img/GreenTExtlogo.png"
              className="w-100 h-100"
              alt=""
            />
          </div>

          {apiMessage.show === true && <AlertError alert={apiMessage} />}
          <h1 className="text-primary fw-600  text-center mt-5 mb-3 pb-5">
            Reset your password
          </h1>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="regform position-relative h-75 pb-4"
          >
            <div className="d-flex flex-column h-100 justify-content-between">
              <div>
                {/*----- select country dropdown input ------*/}
                <div className="position-relative pb-4 mb-4">
                  <input
                    type="text"
                    id="CountryCode"
                    className="form-control intl-tel-input"
                    placeholder="Phone number"
                    {...register("mobile", {
                      required: "phone number is required",
                      pattern: {
                        value: /^\d+/,
                        message: "Invalid pattern Must be use numeric ",
                      },
                    })}
                  />
                  <FormError message={errors?.mobile?.message} />
                </div>
                {/*----- OTP input and Resend OTP button ---*/}
              </div>
              <div className="align-self-end">
                <div className="TnCcheckbox position-relative">
                  <input
                    type="checkbox"
                    id="tnccheck"
                    {...register("checkbox", {
                      required: "Please read and accept T&C",
                    })}
                    className="d-none"
                  />
                  {errors.checkbox && errors.checkbox.message && (
                    <div className="f-error">
                      <i className="fa-regular fa-circle-xmark me-1" />
                      {errors.checkbox && errors.checkbox.message}
                    </div>
                  )}
                  <label htmlFor="tnccheck">
                    <p className="mb-0 fs-3 text-muted">
                      By completing the signup you are agree to the
                      <a href="#!" className="text-primary fw-600 mx-2">
                        Privacy Policies
                      </a>
                      and
                      <a href="#!" className="text-primary fw-600 mx-2">
                        Terms and Conditions
                      </a>
                    </p>

                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="black"
                      strokeWidth={2}
                    >
                      <polyline points="20 6 9 17 4 12" className="check" />
                    </svg>
                  </label>
                </div>
                {/*----- Submit button(Continue to login) -----*/}
                <button
                  type="submit"
                  className="btn btn-primary d-block w-100 fs-2 py-4 updatepass"
                >
                  Update password
                </button>
              </div>
            </div>
          </form>
        </div>
        <div className="col-lg-7 col-12 px-0 h-100 position-relative d-lg-block d-none">
          {/*----- Banner container -----*/}
          <div
            className="entrypage-banner w-100 h-100"
            style={{ backgroundImage: "url(./assets/img/loginbg.png)" }}
          ></div>
          {/*----- Banner layer text -----*/}
          <div className="bannerlayertext">
            <p className="mb-0">
              Everything you need delivered now with
              <br />
              <span className="text-decoration-underline text-uppercase">
                {AppConst.getBussInfo.app_name}
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
