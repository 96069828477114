import React from "react";
import moment from "moment";
import { senderTypes } from "./ChatConstant";
import { AppConst } from "../../../../AppConst";
import { callApi } from "../../../../hooks/useapi";
import db from "../../../../firebase";


export const currentSender = senderTypes.customer


export function getId(limit = 20) {
  const min = 10 ** (limit - 1);
  const max = 10 ** limit - 1;
  return (Math.floor(Math.random() * (max - min + 1)) + min).toString();
}

const getMsg = ({
  msg,
  senderType,
  order_id,
  localId
}) => {
  return {
    localId: localId,
    msg: msg ? msg : "",
    senderType: senderType ? senderType : "",
    order_id: order_id,
    date: String(moment()),
    time: String(moment().format('MMM YYYY, hh:mm A')),
    isCustomerRead: false,
    isStoreRead: false,
    isDriverRead: false,
  }
}



export const sendNewMessage = async ({
  order_id = "1",
  msg = "hello",
  senderType = currentSender,
  collection,
  localId
}) => {
  const data = getMsg({ msg: msg, senderType: senderType, order_id: order_id, localId });
  if (collection) {
    collection.add(data).then((data) => {
    }).catch((error) => {
      console.log("error", error);
    });
  }
}

export const sendTypingEvent = ({
  is_start = false,
  order_id = 0,
  from,
  collection
}) => {
  const data = { order_id, from, is_start };
  if (collection) {
    collection.add(data).then((data) => {
    }).catch((error) => {
      console.log("error", error);
    });
  }
}


export const sortChat = (list = []) => {
  return list?.sort((a, b) => a?.localId - b?.localId);
}

export const filterChatsByDate = (list = []) => {
  const arr = []
  list?.map((item) => {
    const givenTime = item?.date;
    const parsedGivenTime = moment(givenTime, "ddd MMM D YYYY HH:mm:ss [GMT]ZZ");
    const currentDate = moment();
    const isToday = parsedGivenTime.isSame(currentDate, "day");
    // is chat for today
    if (isToday) {
      const index = arr?.findIndex((_item) => _item?.title === "Today");
      if (index > -1) {
        const chatList = arr[index]?.data;
        arr[index] = {
          ...arr[index],
          data: sortChat([...chatList, item])
        }
        return
      }
      arr.push({
        title: "Today",
        data: [item]
      });
      return
    }
    //if chat is not for today
    const date = moment(givenTime, item?.date).format("DD-MM-YYYY");
    const index = arr?.findIndex((_item) => _item?.title === date);
    if (index > -1) {
      const chatList = arr[index]?.data;
      arr[index] = {
        ...arr[index],
        data: sortChat([...chatList, item])
      }
      return
    }
    arr.push({
      title: date,
      data: [item]
    });
  });

  return arr;
}



const notificationHeader = ({
  serverKey
}) => {
  return {
    "Content-Type": "application/json",
    "Authorization": `key=${serverKey}`
  }
}

export const sendChatNotification = ({
  title,
  message,
  serverKey,
  to = [],
  order_id
}) => {
  console.log({
    title,
    message,
    serverKey,
    to,
    order_id
  })
  try {
    if (serverKey) {
      const URL = "https://fcm.googleapis.com/fcm/send";
      const header = notificationHeader({ serverKey });
      if (to?.length > 0) {
        to.forEach((token) => {
          const form = {
            "to": token,
            "data": {
              "title": title,
              "body": message,
              "message": message,
              "order_id": order_id,
              "isChat": true
            },
            "content_available": true,
            "priority": "high"
          }
          const config = {
            method: "post",
            headers: { ...header },
            body: JSON.stringify(form)
          }
          fetch(URL, config).then().catch();
        })
      }
      else {
        throw new Error("User token not found");
      }
      return
    }
    else {
      throw new Error("Server key not found");
    }
  } catch (error) {
    console.log("error", error);
  }

}




export const getNotificationTokens = async ({
  order_id
}) => {
  const arr = []
  const data = await getAllUserToken({ order_id });

  if (currentSender === senderTypes.customer) {
    if (data.driver) {
      arr.push(data.driver)
    }
    if (data.store) {
      arr.push(data.store)
    }
  }
  if (currentSender === senderTypes.store) {
    if (data.driver) {
      arr.push(data.driver)
    }
    if (data.user) {
      arr.push(data.user)
    }
  }
  if (currentSender === senderTypes.driver) {
    if (data.store) {
      arr.push(data.store)
    }
    if (data.user) {
      arr.push(data.user)
    }
  }
  return arr;
}


export const getUnReadChat = (arr = []) => {
  const obj = {
    list: [],
  }
  if (arr.length > 0) {
    const list = arr.filter((item) => !item?.isRead);
    obj['list'] = list;
  }
  return obj;
}


export const filterUnReadMessage = (arr = []) => {
  return arr.filter((I) => {
    return I?.senderType != currentSender && !I?.isCustomerRead;
  });
}

export const getUnReadableChatMessages = async ({
  order_id
}) => {
  const collectionName = `order_${order_id}`;
  const collectionData = await db.collection(collectionName).get();
  const chatList = filterUnReadMessage(collectionData.docs?.map((item) => ({ ...item?._data, id: item?.id })));
  return chatList;
}

export const unReadableChatMessagesListener = ({
  order_id
}, collBack) => {
  const collectionName = `order_${order_id}`;
  return db.collection(collectionName).onSnapshot(async (chat) => {
    const _list = await getUnReadableChatMessages({ order_id })
    collBack && collBack(_list?.length);
  });
}


export const readUnReadableChatMessages = ({
  order_id,
  chats = []
}) => {
  const collectionName = `order_${order_id}`;
  if (chats?.length > 0) {
    chats?.map((item) => {
      if (item?.id) {
        db.collection(collectionName).doc(item?.id).update({ isCustomerRead: true });
      }
    })
  }
}



export const getServerKey = async () => {
  const obj = {
    serverKey: "",
  };

  const result = await callApi(
    `user/get-bussiness-details/${AppConst.getBussInfo.business_owner_id}`,
    {
      method: "GET",
    }
  );
  if (result.status == true) {
    if (result && result?.status) {
      obj["serverKey"] = result?.data?.fcm_server_key;
    }
  }
  console.log("getServerKey=============", obj)
  return obj;
};

export const getAllUserToken = async ({ order_id }) => {
  const obj = {
    user: "",
    store: "",
    driver: "",
  };

  const res = await callApi("user/order-details/" + order_id, {
    method: "GET",
  });
  if (res.data.order_details.length > 0) {
    if (res.data.order_details[0]) {
      const order = res.data.order_details[0];
      if (order) {
        if (order?.user_info) {
          obj["user"] = order?.user_info?.device_token;
        }
        if (order?.delivery_boy_info) {
          obj["driver"] = order?.delivery_boy_info?.device_token;
        }
        if (order?.kitchen_info) {
          obj["store"] = order?.kitchen_info?.device_token;
        }
      }
    }
  }
  console.log("getAllUserToken", obj)
  return obj;
};