// store/index.js

import { configureStore } from "@reduxjs/toolkit";
import thunkMiddleware from "redux-thunk";
import rootReducer from "./reducers/rootReducer";
import { loadState } from "../utility/localstorage";
export const initStore = (initialState = {}) => {
  const persistedState = loadState();
  return configureStore({
    reducer: rootReducer,
    preloadedState: persistedState,

    middleware: [thunkMiddleware],
    devTools: process.env.NODE_ENV !== "production", // Enable Redux DevTools in development mode
  });
};
