import React from "react";
import { Route, Routes } from "react-router-dom";
import Index from "./pages/Index";
import Login from "./pages/Auth/Login";
import Signup from "./pages/Auth/Signup";
import { OTP } from "./pages/Auth/OTP";
import Home from "./pages/Auth/Home";
import NearbyKitchen from "./pages/NearbyKitchen";
import Checkout from "./pages/Checkout";
import Membership from "./pages/Membership";
import StoreDetails from "./pages/StoreDetails";
import Profile from "./pages/Profile";
import About from "./pages/About";
import Invoice from "./pages/Invoice";
import DnowInvoice from "./pages/DnowInvoice";
import ResetPassword from "./pages/Auth/ResetPassword";
import PricacyPolicy from "./pages/contentPages/PricacyPolicy";
import Faq from "./pages/contentPages/Faq";
import HelpnSupport from "./pages/contentPages/HelpnSupport";
import TnC from "./pages/contentPages/TnC";
import Offers from "./pages/Offers";

const App = () => {
  return (
    <Routes>
      <Route path="/" element={<Index />} />
      <Route path="/login" element={<Login />} />
      <Route path="/signup" element={<Signup />} />
      <Route path="/verify-otp" element={<OTP />} />
      <Route path="/home" element={<Home />} />
      <Route path="/stores" element={<NearbyKitchen />} />
      <Route path="/checkout" element={<Checkout />} />
      <Route path="/membership" element={<Membership />} />
      <Route path="/offers" element={<Offers />} />
      <Route path="/store-details/:id" element={<StoreDetails />} />
      <Route path="/profile/:tabId" element={<Profile />} />
      <Route path="/about" element={<About />} />
      <Route path="/invoice" element={<Invoice />} />
      <Route path="/forgot-password" element={<ResetPassword />} />
      <Route path="/dnowinvoice" element={<DnowInvoice />} />
      <Route path="/privacy" element={<PricacyPolicy />} />
      <Route path="/faq" element={<Faq />} />
      {/* <Route path="/helpandsupport" element={<HelpnSupport />} /> */}
      <Route path="/tnc" element={<TnC />} />
    </Routes>
  );
};

export default App;
