import React from "react";
import { AppConst } from "../../../AppConst";

const PremiumMemberSection = ({ plan, isPremiumMember, purchasePlan = () => { } }) => {

  return (
    <section className="premiummember bg-primary px-0 mx-0 pb-5">
      <div className="secPadding_left secMargin_left secPadding_right secMargin_right">
        <div className="row">
          <div className="col-xl-5 col-lg-6 col-12">
            <div className="d-flex align-items-center">
              <img
                src="./assets/img/prime.png"
                className="premiumimg"
                alt=""
              />
              <div className="ms-5">
                <p className="mb-0 text-secondary fs-3">PREMIUM MEMBERSHIP</p>
                <h1 className="mb-0 fw-bold text-white">
                  NOW ONLY
                  <span className="text-gold ms-1"> {AppConst.getBussInfo.currency}{plan?.price}</span>/{plan?.valid_days} Days
                </h1>
              </div>
            </div>

            <h1 className="text-white fw-600 mt-5 pt-5 text-lg-start text-center">
              Premium member account have access to these great feature below:
            </h1>

            <div className="row mx-0 mt-5 pt-5">
              <PremiumMembershipFeature
                title="Unlimited stores"
                description="We've got you covered with access to countless stores, all in one place"
              />
              <PremiumMembershipFeature
                title="Unlimited products"
                description="Enjoy an endless variety of dishes with our unlimited products."
              />
              <PremiumMembershipFeature
                title="No per-user fee"
                description="Say goodbye to per-user fees and enjoy our platform with no additional costs."
              />
            </div>
          </div>

          <div className="col-xl-5 col-lg-6 col-12 mx-auto">
            <p className="default_title text-white text-lg-start text-center">
              Become a premium member today &amp; Get months worths of benefits
            </p>
            <p className=" fs-3 mt-4 fw-100 text-white text-lg-start text-center">
              Be a part oof our growing network of thousand of payments
            </p>
            <img
              src="./assets/img/member-image.png"
              className="prembanner"
              alt=""
            />
            {isPremiumMember === false && <p className="mb-0 text-center bg-gold rounded-3 py-2" style={{ cursor: "pointer" }} onClick={() => purchasePlan()} >
              <span className="d-block text-uppercase fw-bold fs-1 memalert">
                <strong className="fw-bold">BECOME A MEMBER NOW {AppConst.getBussInfo.currency}{plan?.price}/</strong>
                <small className="fs-5 fw-600">{plan?.valid_days} Days</small>
              </span>
            </p>}
          </div>
        </div>
      </div>
    </section>
  );
};

export default PremiumMemberSection;

const PremiumMembershipFeature = ({ title, description }) => {
  return (
    <div className="col-lg-6 col-11 mx-auto ps-xl-0 pe-xl-5 mb-5 pb-3 h-100">
      <div className="card border-0 text-center position-relative px-4 pt-4 pb-4 rounded-3 h-100">
        <div className="card-body h-100">
          <p className="mb-2 fw-600 fs-2">{title}</p>
          <p className="text-secondary mb-0">{description}</p>
          <img
            src="./assets/img/greencheck.png"
            className="greencheck"
            alt=""
          />
        </div>
      </div>
    </div>
  );
};
