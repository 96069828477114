import React, { useEffect, useState } from "react";
import { AppConst } from "../../../AppConst";
import { callApi } from "../../../hooks/useapi";
import TrackOrder from "../Orders/TrackOrder";
import { Socket, getSocketConnect } from "../../../utility/Socket";
import { getToken } from "../../../utility/helper";
import moment from "moment";
const OrderTrackSection = () => {
  const [currentOrder, setCurrentOrder] = useState([]);
  const [trachOrder, setTrackOrder] = useState(false);
  const [deliverLocation, setDeliveryLocation] = useState([]);
  let sockets;
  useEffect(() => {
    // setInterval(() => {
    if (getToken()) {
      getSocketConnect((socket) => {
        Socket.setMySocket(socket);
        sockets = socket;
        socket.addEventListener("OrderStatusChange", (data) => {
          getCurrentOrders();
          if (data.order_status == "6") {
            setTrackOrder(false);
          }
        });
      });
      return () => {
        sockets.removeEventListener();
        sockets.removeEventListener("OrderStatusChange");
      };
    }
  }, [trachOrder]);
  useEffect(() => {
    getCurrentOrders();
  }, []);
  const getCurrentOrders = () => {
    callApi("user/current-order", { method: "GET" }).then((response) => {
      if (response.status === true) {
        if (response.data.order.length > 0) {
          setCurrentOrder(response.data.order[0]);
          let data = response.data.order[0];
          setDeliveryLocation([
            {
              lat: parseFloat(data.kitchen_info.lat),
              lng: parseFloat(data.kitchen_info.lng),
              name: data.name,
              description: data.kitchen_info.address,
            },
            {
              lat: parseFloat(data.d_lat),
              lng: parseFloat(data.d_lng),
              name: data.name,
              description: data.kitchen_info.address,
            },
          ]);
        }
      }
    });
  };

  const getExtra = () => {
    let order_extra =
      currentOrder &&
      currentOrder?.items &&
      currentOrder?.items[0] &&
      currentOrder?.items[0].order_extra;
    return (
      <>
        {order_extra &&
          order_extra[0]?.order_extra_item.length > 0 &&
          order_extra[0]?.order_extra_item.map((extra) => (
            <span className="ms-2 fw-bold text-dark" key={extra.id}>
              ( {extra.name} )
            </span>
          ))}
      </>
    );
  };
  if (currentOrder?.length === 0) {
    return <></>;
  } else {
    return (
      <section className="orderTrackifocard mx-0 px-0 mt-0 pt-0">
        <div className="trackingCard mt-0">
          <div className="row justify-content-between align-items-center">
            <div className="col-lg-12 col-12">
              <p className="mb-lg-0 mb-3 text-success fs-1 fw-bold text-lg-start text-center secPadding_left secMargin_left">
                Thank you! for your order
              </p>
            </div>

            <div className="col-lg-1 col-5">
              <img
                src="./assets/img/ordertrachwatch.png"
                className="w-100 px-lg-3"
                alt=""
              />
            </div>
            <div className="col-lg-4 col-6 me-lg-auto">
              <span className="fs-2 text-success fw-bold">
                {getOrderStatus(currentOrder?.type)}
              </span>
              <span className="fs-4 fw-bold text-muted ms-4">
                Order Id: #{currentOrder?.id}
              </span>
              <p className="orderbried text-muted fw-bold fs-3 mb-0">
                <span>
                  {currentOrder &&
                    currentOrder?.items &&
                    currentOrder?.items[0]?.name}
                </span>

                {getExtra()}
                <p className="mb-1">
                  Order amount :
                  <span className="ms-2 text-dark">
                    {AppConst.getBussInfo.currency} {currentOrder?.amount}
                  </span>
                </p>
              </p>
              <p className="orderdate text-muted fw-bold fs-3 mb-0">
                <span>
                  {""}
                  {currentOrder?.pre_full_date_time
                    ? currentOrder.pre_full_date_time
                    : moment(currentOrder?.pre_date).format("YYYY-MM-DD")}
                </span>
              </p>
            </div>

            <div className="col-lg-3 col-12 text-lg-end text-center">
              <p className="mb-2 text-lg-end text-center text-muted fw-bold fs-3">
                Everything you need delivered now with {AppConst.getBussInfo.app_name} fast and
                secure delivery
              </p>

              <button
                type="button"
                className="trackorderbtn text-primary"
                onClick={() => setTrackOrder(true)}
              >
                Track order
              </button>
            </div>
          </div>
        </div>

        <TrackOrder
          isOpen={trachOrder}
          orderId={currentOrder?.id}
          orderStatus={currentOrder?.type}
          user_otp={currentOrder.user_otp}
          toggleCanvas={() => setTrackOrder(!trachOrder)}
          location={deliverLocation}
          pickup={currentOrder.is_self == 1 ? true : false}
        />
      </section>
    );
  }
};

export default OrderTrackSection;

const getOrderStatus = (type) => {
  return type == "0"
    ? "Canceled"
    : type == "1"
    ? "Pending"
    : type == "2"
    ? "Preparing"
    : type == "3"
    ? "prepared"
    : type == "4"
    ? "Picked By Driver"
    : type == "5"
    ? "Delivered"
    : "Completed";
};
