import {
  ADD_KITCHENDATA,
  ADD_KITCHEN_ID,
  ADD_KITCHEN_NAME,
  CLEAR_CART,
  POPUPMENUOFFER,
  SETCARTPRODUCTS,
  SETPRODUCTDETAILS,
  TODAYS_MENU,
  USER_LOCATION,
  REORDER,
  DELIVERY_TYPE,
  SAVE_USER_DATA,
  MENU_POPUP_SHOW,
  CART_SLIDER_SHOW,
  GENERATE_TRANSECTION_KEY,
  REGISTER_USER_DATA,
  ADD_ADDITIONAL_INFO,
} from "../types";

export function addKitchenData(payload) {
  return { type: ADD_KITCHENDATA, payload };
}
export function saveUserData(payload) {
  return { type: SAVE_USER_DATA, payload };
}
export function setUserLocation(payload) {
  return { type: USER_LOCATION, payload };
}
export function addKitchenId(payload) {
  return { type: ADD_KITCHEN_ID, payload };
}

export function addKitchenName(payload) {
  return { type: ADD_KITCHEN_NAME, payload };
}
export function clearCartCount() {
  return { type: CLEAR_CART };
}

export function setTodaysMenu(payload) {
  return { type: TODAYS_MENU, payload };
}

export function setPopMenuOffer(payload) {
  return { type: POPUPMENUOFFER, payload };
}

export function setCartProducts(payload) {
  return { type: SETCARTPRODUCTS, payload };
}

export function setProductDetails(payload) {
  return { type: SETPRODUCTDETAILS, payload };
}

export function changeOrderType(payload) {
  return { type: REORDER, payload };
}

export function setDeliverType(payload) {
  return { type: DELIVERY_TYPE, payload };
}

export function setMenuPopUpShow(payload) {
  return { type: MENU_POPUP_SHOW, payload };
}

export function setCartSliderShow(payload) {
  return { type: CART_SLIDER_SHOW, payload };
}

export function generateTransectionKey(payload) {
  return { type: GENERATE_TRANSECTION_KEY, payload };
}

export function addAdditionalInfo(payload) {
  return { type: ADD_ADDITIONAL_INFO, payload };
}
