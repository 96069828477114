import PropTypes from "prop-types";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { initStore } from "../../../store";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { useSelector } from "react-redux";
const store = initStore();

const nextDayDate = (days = 0) => {
  const now = new Date();
  return now.setDate(now.getDate() + parseInt(days));
};

const getDays = (nextDate) => {
  let arr = [];
  for (let i = 0; i <= nextDate; i++) {
    let date = moment().add(i, "days");
    arr.push({
      label: i == 0 ? "Today" : date.format("DD MMM"),
      date: date,
    });
  }
  return arr;
};

const getTimeByInterval = (startTime, endTime, selectedDateIndex, prepare_time) => {
  let starts = startTime.split(':');
  let roundStartTime = parseInt(starts[0]) + ":" + ((5 - (parseInt(starts[1]) % 5) + parseInt(starts[1]))); // Its Math Magic

  const startTimeObj = roundTimeToNextHour(startTime)
  const endTimeObj = roundTimeToPreviousHour(endTime)

  let intervals = [];

  let isFirst = true;

  while (endTimeObj > startTimeObj) {
    if (isFirst) {
      intervals.push({
        timeInAmPM: startTimeObj.format('hh:mm A'),
        timeIn24hr: startTimeObj.format('HH:mm'),
      });
      isFirst = false;
    }
    else {
      startTimeObj.add(30, 'minutes');
      intervals.push({
        timeInAmPM: startTimeObj.format('hh:mm A'),
        timeIn24hr: startTimeObj.format('HH:mm'),
      });
    }
  }
  if (selectedDateIndex == 0) {
    const text = `ASAP < (${prepare_time} min)`;
    return [{
      timeInAmPM: text,
      timeIn24hr: text,
    }, ...intervals];
  }
  return intervals;
}

const DateTimePicker = (props) => {
  const [selectedDateIndex, setDateIndex] = useState(-1);
  const [selectedTimeIndex, setTimeIndex] = useState(-1);
  const kitchen_id = useSelector((state) => state.root.kitchen_id);
  const kitchen_data = useSelector((state) => state.root.kitchen_data);
  const timeSlots = React.useRef([]);
  const dateSlots = React.useRef([]);

  useEffect(() => {
    let maxDays = props.maxDays || 0;
    const dates = getDays(parseInt(maxDays));
    const slots = getTimeSlotsByDate(dates[0].date);

    dateSlots.current = dates;
    timeSlots.current = slots;
    setDateIndex(0);
  }, [props.maxDays]);

  const getTimeSlotsByDate = (date, index = 0) => {

    const _date = moment(date).format("DD-MM-YYYY");
    const today = moment().format("DD-MM-YYYY");
    const current_time = moment().format("HH:mm");
    const isTodayDate = _date === today;


    const indexs = kitchen_data.findIndex((item) => item.id == kitchen_id);
    const kitchen = kitchen_data[indexs];

    const endTime = getTimeByFixing(kitchen?.kitchen_info?.delivery_close);
    let startTime = getTimeByFixing(kitchen?.kitchen_info?.delivery_open);


    const prepare_time = parseInt(kitchen?.kitchen_info.preparation_time);
    let maxTime = +(kitchen?.pre_order_time || 0) + prepare_time;

    if (moment(date).format('DD MM') == moment().format('DD MM')) {
      startTime = getTimeByFixing(moment().add(parseInt(maxTime), 'minutes').format('HH:mm'));
    }
    return getTimeByInterval(startTime, endTime, index, maxTime);
  }

  const onSelectDate = (item, index) => {
    console.log(index);
    const slots = getTimeSlotsByDate(item.date, index);
    timeSlots.current = slots;
    setDateIndex(index);
  };

  const onSelectTimeSlot = (item, index) => {
    let selectedDate = moment(dateSlots.current[selectedDateIndex]?.date);
    const times = item.timeIn24hr.split(":");
    selectedDate
      .set("hours", parseInt(times[0]))
      .set("minutes", parseInt(parseInt(times[1])));
    setTimeIndex(index);
    const obj = {
      time: selectedDate,
      date: selectedDate.format("DD MMM YYYY"),
      label: selectedDate.format("hh:mm A, DD MMM Y"),
      ...item,
    };
    props.onSelectDate && props.onSelectDate(obj);
  };
  const [dropdownDateOpen, setDateDropdownOpen] = useState(false);

  const toggleDate = () => setDateDropdownOpen((prevState) => !prevState);
  const [dropdownTimeOpen, setTimeDropdownOpen] = useState(false);

  const toggleTime = () => setTimeDropdownOpen((prevState) => !prevState);
  return (
    <>
      <div className="row g-2 mb-4" onClick={() => props.onSelectDate(null, null)}>
        <div className="col-6">
          <Dropdown
            className="timeMenu"
            isOpen={dropdownDateOpen}
            toggle={toggleDate}
          >
            <DropdownToggle className="w-100 " caret>
              <i class="far fa-calendar-alt me-2"></i>{" "}
              {selectedDateIndex > -1
                ? dateSlots.current &&
                dateSlots.current[selectedDateIndex].label
                : "Select date"}
            </DropdownToggle>

            <DropdownMenu className="customdatedropdown">
              {dateSlots.current &&
                dateSlots.current.length > 0 &&
                dateSlots.current.map((item, index) => (
                  <DropdownItem
                    className="custdropbtn"
                    key={index}
                    onClick={() => onSelectDate(item, index)}
                  >
                    {item.label}
                  </DropdownItem>
                ))}
            </DropdownMenu>
          </Dropdown>
        </div>

        <div className="col-6">
          <Dropdown
            className="timeMenu"
            isOpen={dropdownTimeOpen}
            toggle={toggleTime}
          >
            <DropdownToggle className="btn btn-primary w-100 " caret>
              <i class="far fa-clock me-2"></i>
              {selectedTimeIndex > -1
                ? timeSlots.current[selectedTimeIndex].timeInAmPM
                : "Select time"}
            </DropdownToggle>
            <DropdownMenu className="customdatedropdown">
              {timeSlots.current &&
                timeSlots.current.length > 0 &&
                timeSlots.current.map((item, index) => (
                  <DropdownItem
                    className="custdropbtn"
                    key={index}
                    onClick={() => onSelectTimeSlot(item, index)}
                  >
                    {item.timeInAmPM}
                  </DropdownItem>
                ))}
            </DropdownMenu>
          </Dropdown>
        </div>
      </div>
    </>
  );
};

DateTimePicker.propTypes = {
  mode: PropTypes.oneOf(["time", "date"]),
  onSelectDate: PropTypes.func,
};

export default DateTimePicker;


const getTimeByFixing = (time = "05:17") => {

  const res = String(time).split(':');
  if (res.length > 1) {
    if (res[0] !== "00") {
      const obj = moment(time, "hh:mm").add(1, "hours").format("hh");
      const objRes = String(obj).split(" ");
      if (objRes.length > 1) {
        return `${objRes[0]}:00 ${objRes[1]}`;
      }
    }
  }
  return time
}

function roundTimeToNextHour(time) {
  if (time) {
    const [hours, minutes] = String(time).split(':').map(Number);
    if (minutes >= 0) {
      if (minutes <= 30) {
        return moment(`${String(hours)}:30`, "HH-mm");
      }
    }
    const roundedHours = hours + 1;
    return moment(`${String(roundedHours)}:00`, "HH-mm");
  }
  return time;
}

function roundTimeToPreviousHour(time) {
  if (time) {
    const [hours, minutes] = String(time).split(':').map(Number);
    //22:00
    if (minutes == 0) {
      return moment(`${hours}:00`, "HH-mm");
    }
    if (minutes == 30 || minutes < 30) {
      return moment(`${hours}:00`, "HH-mm");
    }
    if (minutes > 30) {
      return moment(`${hours}:30`, "HH-mm");
    }
  }
  return time;
}