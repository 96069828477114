import React, { useEffect, useState } from "react";
import Layout from "../components/common/Layout";
import { getDistance } from "../utility/helper";
import { callApi } from "../hooks/useapi";
import { useDispatch, useSelector } from "react-redux";
import {
  addKitchenData,
  addKitchenId,
  addKitchenName,
  clearCartCount,
} from "../store/actions/actions";
import { confirm } from "../components/common/ConfirmDialog";
import { Link, useNavigate } from "react-router-dom";
import MapWithMarker from "../components/common/Maps/MapWithMarker";
const NearbyKitchen = () => {
  const [kitchenData, SetKitchenData] = useState([]);
  const [mapKitchen, setMapKitchens] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const kitchen_id = useSelector((state) => state.root.kitchen_id);
  const cart_items = useSelector((state) => state.cart.items);
  const user_address = useSelector((state) => state.root.user_address);
  useEffect(() => {
    if (user_address?.lat !== "" && user_address?.lng !== "") {
      //

      callApi(
        "user/kitchen-list?lat=" +
        user_address?.lat +
        "&lng=" +
        user_address?.lng +
        "&bus_owner_id=" +
        process.env.REACT_APP_BUSS_ID,
        { method: "GET" }
      )
        .then((response) => {
          //
          // console.log("server reponse" + JSON.stringify(response));
          if (response.status) {
            if (response.data) {
              let arr = response.data.kitchen_list.concat(
                response.data.kitchen_offline_list, response.data.courier_store_list
              );
              let kitchens = arr.reduce((accumulator, currentObj) => {
                if (!accumulator.some(obj => obj.id === currentObj.id)) {
                  accumulator.push(currentObj);
                }
                return accumulator;
              }, []);
              dispatch(addKitchenData(kitchens));
              SetKitchenData(kitchens);
              let data = [];
              kitchens.length > 0 &&
                kitchens.forEach((each) => {
                  data.push({
                    lat: parseFloat(each.kitchen_info.lat),
                    lng: parseFloat(each.kitchen_info.lng),
                    name: each.name,
                    description: each.kitchen_info.address,
                  });
                });
              setMapKitchens(data);
              window.localStorage.setItem(
                process.env.REACT_APP_BUSINESS + "kitchen_data",
                JSON.stringify(kitchens)
              );
            }
          } else {
          }
          //console.log("new:"+this.state.Screentype);
        })
        .catch((error) => {
          //return error.data;
          console.log(error);
        });
    }
  }, []);
  const handleKitchenId = async (id, name) => {
    if (id != kitchen_id && cart_items.length > 0) {
      if (
        await confirm(
          "You have some product(s) in your cart. If you will change your kitchen, your product(s) will be deleted. Are you sure you want to remove cart product(s)"
        )
      ) {
        dispatch(clearCartCount());
        localStorage.removeItem(
          process.env.REACT_APP_BUSINESS + "productsInCart"
        );
        dispatch(addKitchenId(id));
        dispatch(addKitchenName(name));
        localStorage.setItem(process.env.REACT_APP_BUSINESS + "kitchen_id", id);
        navigate("/home");
      } else {
        return;
      }
    } else {
      dispatch(addKitchenId(id));
      dispatch(addKitchenName(name));
      localStorage.setItem(process.env.REACT_APP_BUSINESS + "kitchen_id", id);
      navigate("/home");
    }
  };

  const goToStore = (e, kitchen) => {
    e.stopPropagation();
    navigate("/store-details/" + kitchen);
  };
  return (
    <Layout pageClass={"nearbystorepage"}>
      <section className="Pagetitledisc px-0 m-0">
        <div className="container-fluid py-3">
          <div className="row">
            <div className="col-lg-10 col-12 order-lg-1 order-2 text-lg-start text-center">
              {/*----- Section title -----*/}
              <h1 className="default_title secPadding_left secMargin_left">
                Explore the selection of stores and the products
                <br />
                they have available for you to discover
              </h1>

              {/*----- Section Sub title -----*/}
              <p className="default_subtitle secPadding_left secMargin_left mb-0 d-none">
                Explore the selection of stores and the products they have
                available for you to discover.
              </p>
            </div>

            <div className="col-lg-2 col-9 mx-auto order-lg-2 order-1 mb-lg-0 mb-4">
              <div className="nbslogo">
                <img
                  src="./assets/img/GreenTExtlogo.png"
                  className="w-100 h-100"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*----- Page title and discription section end -----*/}

      {/*----- Nearby stores listing and map section start -----*/}
      <section className="storelistingandmap m-0 pe-lg-0 py-0">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-7">
              {/*----- store list filter -----*/}
              <div className="storelistFilter d-flex justify-content-between align-items-center mt-5 mb-4">
                <p className="mb-0 fw-bold text-muted fs-3">
                  Displaying{" "}
                  {kitchenData?.filter((kitchen) => kitchen.status === "0")
                    ?.length || 0}{" "}
                  nearby stores for your convenience
                </p>
                <Link
                  to={"/"}
                  style={{ textDecoration: "none" }}
                  className="nearbydistance text-warning d-inline-block mb-0"
                >
                  Change Location
                </Link>
              </div>

              {/*---- Store list card -----*/}
              {kitchenData && kitchenData?.length === 0 && (
                <h1 className="text-danger fw-bold text-center mt-5">
                  No Store available right now on this location{" "}
                </h1>
              )}

              <div className="storECARDLIsting">
                {kitchenData &&
                  kitchenData?.length > 0 &&
                  kitchenData
                    ?.filter((kitchen) => kitchen.status === "0")
                    .map((kitchen) => (
                      <div
                        className="card storelistcard"
                      // style={{ cursor: "pointer" }}
                      // onClick={() =>
                      //   handleKitchenId(kitchen?.id, kitchen?.name)
                      // }
                      >
                        <div className="card-body py-4">
                          <div className="row justify-content-around">
                            <div className="col-lg-4 col-xl-3 col-12 position-relative">
                              <img
                                src={
                                  kitchen?.kitchen_info?.image
                                    ? kitchen?.kitchen_info?.image
                                    : "http://delivernow.co.za/deliver_now_webapp/static/media/user.76c5b890.png"
                                }
                                className="CardProductIMG"
                                alt=""
                              />

                              <span
                                className={
                                  kitchen?.online_status === "online"
                                    ? "badge bg-success"
                                    : "badge bg-danger"
                                }
                              >
                                {kitchen?.online_status === "online"
                                  ? "Online"
                                  : "Offline"}
                              </span>
                            </div>

                            <div className="col-lg-8 col-xl-9 col-12 pe-lg-4">
                              <div className="d-xl-flex d-block justify-content-between align-items-center">
                                <h2 className="fw-bold mb-lg-2 mb-1 mt-lg-0 mt-3">
                                  {kitchen?.name}
                                </h2>

                                <p className="nearbydistance text-warning d-inline-block mb-0">
                                  {getDistance(
                                    user_address?.lat,
                                    user_address?.lng,
                                    kitchen?.kitchen_info?.lat,
                                    kitchen?.kitchen_info?.lng
                                  ).toFixed(2)}
                                  KM away from you
                                </p>
                              </div>
                              <p className="text-truncate text-secondary fs-3 fw-600 my-4">
                                <i className="fa-solid fa-location-dot text-warning me-2" />
                                {kitchen?.kitchen_info?.address}
                              </p>

                              <div className="d-xl-flex flex-xl-row justify-content-between align-items-lg-center">
                                <div className="d-flex justify-content-between">
                                  {kitchen?.delivery_active === "1" && (
                                    <span className="bannerlabel label-light mx-xl-4 mb-xl-0 mb-lg-4 mb-4">
                                      <img
                                        src="./assets/img/bannerdeliverygreen.png"
                                        className="me-3"
                                        alt=""
                                      />
                                      <br className="d-xxl-none d-xl-block d-block" />
                                      Delivery
                                    </span>
                                  )}
                                  {kitchen?.kitchen_info?.pickup_on_off ===
                                    "1" && (
                                      <span className="bannerlabel label-light mx-xl-4 mb-xl-0 mb-lg-4 mb-4">
                                        <img
                                          src="./assets/img/bannerpickupgreen.png"
                                          className="me-3"
                                          alt=""
                                        />
                                        <br className="d-xxl-none d-xl-block d-block" />
                                        Pickup
                                      </span>
                                    )}
                                  {kitchen?.is_courier === "1" && (
                                    <span className="bannerlabel label-light mx-xl-4 mb-xl-0 mb-lg-4 mb-4">
                                      <img
                                        src="./assets/img/bannercuriorgreen.png"
                                        className="me-3"
                                        alt=""
                                      />
                                      <br className="d-xxl-none d-xl-block d-block" />
                                      Courier
                                    </span>
                                  )}
                                </div>

                                <div className="fs-3">
                                  <a
                                    href="#!"
                                    onClick={(e) => goToStore(e, kitchen.id)}
                                    className="text-decoration-none border border-1 text-lg-start text-center text-dark p-xxl-3 p-xl-4 px-5 py-lg-2 py-3 rounded-3  fw-600  d-lg-inline-block d-block nbsViewstore"
                                  >
                                    View Store
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
              </div>
            </div>

            <div className="col-lg-5 col-12 ps-lg-5 d-lg-block d-none">
              <MapWithMarker locations={mapKitchen} />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default NearbyKitchen;
